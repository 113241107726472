import _ from 'lodash';

/** Base class for all models. */
class BaseModel {
    constructor(properties: AnyObj) {
        Object.assign(this, properties || {});
    }

    /**
     * Get the fields to get server side when we
     * ask for a listing
     */
    static getListingFields() : string[] {
        return [];
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() : AnyObj {
        const prepared = _.cloneDeep(this) as AnyObj;
        return prepared;
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate(options: AnyObj) : AnyObj {
        const prepared = _.cloneDeep(this) as AnyObj;
        return prepared;
    }
}

export default BaseModel;
