import { Dispatch } from 'redux';
import { Auth } from '../../services';
import type { Credentials } from '../../services';
import { User } from '../../models';

const LOG_IN                   = 'yoda/auth/LOG_IN';
const LOG_IN_SUCCESS           = 'yoda/auth/LOG_IN_SUCCESS';
const LOG_IN_FAILURE           = 'yoda/auth/LOG_IN_FAILURE';
const LOG_OUT                  = 'yoda/auth/LOG_OUT';
const LOG_OUT_SUCCESS          = 'yoda/auth/LOG_OUT_SUCCESS';
const LOG_OUT_FAILURE          = 'yoda/auth/LOG_OUT_FAILURE';
const START_CHECKING_JWT       = 'yoda/auth/START_CHECKING_JWT';
const END_CHECKING_JWT         = 'yoda/auth/END_CHECKING_JWT';
const JWT_TOKEN_EXPIRED        = 'yoda/auth/JWT_TOKEN_EXPIRED';
const OPENID_REDIRECT          = 'yoda/auth/OPENID_REDIRECT';
const OPENID_REDIRECT_SUCCESS  = 'yoda/auth/OPENID_REDIRECT_SUCCESS';
const OPENID_REDIRECT_FAILURE  = 'yoda/auth/OPENID_REDIRECT_FAILURE';
const GET_AUTH_METHODS         = 'yoda/auth/GET_AUTH_METHODS';
const GET_AUTH_METHODS_SUCCESS = 'yoda/auth/GET_AUTH_METHODS_SUCCESS';
const GET_AUTH_METHODS_FAILURE = 'yoda/auth/GET_AUTH_METHODS_FAILURE';

interface AuthState {
    user?: User,
    isAuthenticating: boolean,
    isLoggingIn: boolean,
    logInError: boolean,
    logInErrorMessage: string,
    tokenExpired: boolean,
    isLoadingOpenidUrl: boolean,
    authMethods?: AnyObj
}

const initialState : AuthState = {
    user: undefined,
    isAuthenticating: false,
    isLoggingIn: false,
    logInError: false,
    logInErrorMessage: '',
    tokenExpired: false,
    isLoadingOpenidUrl: false,
    authMethods: {
        password: false,
        openid: false
    }
};

// We hook on the user update action, to change the connected user
// in this store if needed...
const UPDATING_USER_SUCCESS = 'yoda/user/UPDATING_ITEM_SUCCESS';
const SET_AVATAR_SUCCESS    = 'yoda/users/SET_AVATAR_SUCCESS';

// We hook on the user update action, to change the connected user
// in this store if needed...
const UPDATING_FAVORITES_SUCCESS = 'yoda/users/UPDATING_FAVORITES_SUCCESS';

export default function reducer(state : AuthState = initialState, action : any) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isLoggingIn: true,
                logInError: false
            };
        case LOG_IN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                tokenExpired: false,
                user: action.user
            };
        case LOG_IN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                logInError: true,
                logInErrorMessage: action.error.message
            };
        case START_CHECKING_JWT:
            return {
                ...state,
                isAuthenticating: true
            };
        case END_CHECKING_JWT:
            return {
                ...state,
                isAuthenticating: false
            };
        case LOG_OUT:
            return {
                ...initialState,
            };
        case UPDATING_USER_SUCCESS:
        case SET_AVATAR_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                user: action.user._id === state.user?._id ? action.user : state.user
            };
        case UPDATING_FAVORITES_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                user: action.user._id === state.user?._id ? action.user : state.user
            };
        case JWT_TOKEN_EXPIRED:
            return {
                ...state,
                tokenExpired: true
            };
        case OPENID_REDIRECT:
            return {
                ...state,
                openidUrl: null,
                isLoadingOpenidUrl: true,
            };
        case OPENID_REDIRECT_SUCCESS:
            return {
                ...state,
                openidUrl: action.url,
                isLoadingOpenidUrl: false,
            };
        case OPENID_REDIRECT_FAILURE:
            return {
                ...state,
                openidUrl: null,
                isLoadingOpenidUrl: false,
            };
        case GET_AUTH_METHODS:
            return {
                ...state,
                methods: null,
                isLoggingIn: true,
                logInError: false
            };
        case GET_AUTH_METHODS_SUCCESS:
            return {
                ...state,
                authMethods: action.methods,
                isLoggingIn: false,
                logInError: false
            };
        case GET_AUTH_METHODS_FAILURE:
            return {
                ...state,
                methods: null,
                isLoggingIn: false,
                logInError: true,
                logInErrorMessage: action.error.message
            };
        default:
            return state;
    };
};

function logIn() { return { type: LOG_IN } }
function logInSuccess(user : User) { return { type: LOG_IN_SUCCESS, user: user } }
function logInFailure(err : Error) { return { type: LOG_IN_FAILURE, error: err } }
function logOut() { return { type: LOG_OUT } }
function logOutSuccess() { return { type: LOG_OUT_SUCCESS } }
function logOutFailure(err : Error) { return { type: LOG_OUT_FAILURE, error: err } }

function startCheckingJwt() { return { type: START_CHECKING_JWT } }
function endCheckingJwt() { return { type: END_CHECKING_JWT } }
export function getLastConnectedUser() {
    return async (dispatch:Dispatch) => {
        dispatch(startCheckingJwt());

        Auth.getUserProfile().then((data: AnyObj) => {
            const user = new User(data.user);
            dispatch(logInSuccess(new User(user)));
            dispatch(endCheckingJwt());
        }).catch(error => {
            console.error('Une erreur s\'est produite :', error);
            dispatch(endCheckingJwt());
        });
    }
}

export function detectedLogout() {
    return (dispatch : Dispatch) => {
        dispatch({type: JWT_TOKEN_EXPIRED});
    }
}

export function authenticate(credentials : Credentials) {
    return (dispatch : Dispatch) => {
        dispatch(logIn());
        Auth.logIn(credentials).then((data: AnyObj) => {
            const user = data.user;
            dispatch(logInSuccess(new User(user)));
        }).catch((err: Error) => {
            localStorage.removeItem('user');
            dispatch(logInFailure(new Error('Identifiant ou mot de passe invalide')))
        });
    }
}

export function logout() {
    return (dispatch : Dispatch) => {
        dispatch(logOut());
        Auth.logOut().then((data: AnyObj) => {
            localStorage.removeItem('token');
            dispatch(logOutSuccess());
        }).catch((err: Error) => {
            dispatch(logOutFailure(new Error('Impossibe de se déconnecter')))
        });
    }
}

function _getAuthMethods() { return { type: GET_AUTH_METHODS } }
function _getAuthMethodsSuccess(methods : string) { return { type: GET_AUTH_METHODS_SUCCESS, methods } }
function _getAuthMethodsFailure(err : Error) { return { type: GET_AUTH_METHODS_FAILURE, error: err } }
export function getAuthMethods(callback?: (err: Error | null, url?: string) => void) {
    return (dispatch : Dispatch) => {
        dispatch(_getAuthMethods());
        Auth.getAuthMethods().then((data: AnyObj) => {
            const methods = data.methods;
            dispatch(_getAuthMethodsSuccess(methods));
            callback && callback(/*err*/null, methods);
        }).catch((err: Error) => {
            dispatch(_getAuthMethodsFailure(new Error('Identifiant ou mot de passe invalide')))
            callback && callback(err);
        });
    }
}

function openidRedirect() { return { type: OPENID_REDIRECT } }
function openidRedirectSuccess(url : string) { return { type: OPENID_REDIRECT_SUCCESS, url } }
function openidRedirectFailure(err : Error) { return { type: OPENID_REDIRECT_FAILURE, error: err } }
export function getOpenIdUrl(callback?: (err: Error | null, url?: string) => void) {
    return (dispatch : Dispatch) => {
        dispatch(openidRedirect());
        Auth.getOpenIdUrl().then((data: AnyObj) => {
            const url = data.url;
            dispatch(openidRedirectSuccess(url));
            callback && callback(/*err*/null, url);
        }).catch((err: Error) => {
            dispatch(openidRedirectFailure(new Error('Identifiant ou mot de passe invalide')))
            callback && callback(err);
        });
    }
}
