import BaseModel from './BaseModel';
import Change from './Change';
import User from './User';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';

interface SprintResource {
    member: User;
    availability: {
        percentage: number;
        hours: number;
    }
}

/** Class representing an user. */
class Sprint extends BaseModel {
    _id: string;
    name: string;
    resources: SprintResource[];
    changes: Change[];
    startAt: Moment;
    stopAt: Moment;
    estimate: Number;
    realised: Number;
    velocity: Number;

    constructor(properties: AnyObj) {
        super({});

        this._id      = properties._id;
        this.name     = properties.name;
        this.changes  = (properties.changes || []).map((c: AnyObj) => new Change(c));
        this.startAt  = moment(properties.startAt);
        this.stopAt   = moment(properties.stopAt);
        this.estimate = properties.estimate || 0;
        this.realised = properties.realised || 0;
        this.velocity = properties.velocity || 0;

        this.resources = (properties.resources || []).map((resource: AnyObj) => {
            if (resource.member?._id)
                resource.member = new User(resource.member);
            return resource;
        });
    }

    /**
     * Check if this sprint is running
     *
     * @return {Boolean}
     */
    isRunning(): boolean {
        const now = moment();

        return now.isAfter(this.startAt) && now.isBefore(this.stopAt);
    }

    /**
     * Check if this sprint is finished
     *
     * @return {Boolean}
     */
    isFinished(): boolean {
        return moment().isAfter(this.stopAt);
    }

    /**
     * Get the sum of all the resources available hours,
     * for this sprint
     *
     * @param {string} userId filter
     * @return {Number}
     */
    getAvailableHours(userId?: string): number {
        if (!this.resources.length)
            return 0;

        let hours = 0;
        this.resources.forEach((res: SprintResource) => {
            if (!res.member.work_rate)
                return;

            if (userId && res.member._id !== userId)
                return;

            hours += (
                Math.round((
                    (42 * res.member?.work_rate / 100) *
                    (res.availability?.percentage / 100)
                    || 0
                ) / 0.5) * 0.5
            );
        });

        return hours;
    }

    /**
     * Get the sum of all the cards estimated hours,
     * for this sprint
     *
     * @param {string} projectId filter
     * @param {string} userId filter
     *
     * @return {Number}
     */
    getEstimatedHours(projectId?: string, userId?: string): number {
        if (!this.changes.length)
            return 0;

        return this.changes
            .filter((c: Change) => (!projectId || (projectId === c.project?._id)))
            .filter((c: Change) => (!userId || (userId === c.assignee?._id)))
            .reduce((acc: number, change: Change) => acc + change.estimate, 0);
    }

    /**
     * Get the sum of all the realised cards estimated hours,
     * for this sprint
     *
     * @param {Moment} at
     * @param {string} projectId filter
     * @param {string} userId filter
     *
     * @return {Number}
     */
    getRealisedHours(at?: Moment, projectId?: string, userId?: string): number {
        if (!this.changes.length)
            return 0;

        return this.changes
            .filter((c: Change) => c.isWorkCompleted(at))
            .filter((c: Change) => (!projectId || (projectId === c.project?._id)))
            .filter((c: Change) => (!userId || (userId === c.assignee?._id)))
            .reduce((acc: number, change: Change) => acc + change.estimate, 0);
    }

    /**
     * Get the sum of all the todo cards estimated hours,
     * for this sprint
     *
     * @param {Moment} at
     * @param {string} projectId filter
     * @param {string} userId filter
     *
     * @return {Number}
     */
    getRemainingHours(at?: Moment, projectId?: string, userId?: string): number {
        return this.getEstimatedHours(projectId, userId) - this.getRealisedHours(at, projectId, userId);
    }

    /**
     * Sort the provided changes by their project
     * name and slug
     *
     * @param {Change[]} changes
     * @param {String} sortKey
     *
     * @return {Change[]}
     */
    static sortChangesBy(changes: Change[], sortKey: string): Change[] {
        if (!changes.length)
            return [];

        return changes.sort((a: Change, b: Change) => {
            if (sortKey === 'project') {
                if (a.project?.name > b.project?.name)
                    return 1;
                if (a.project?.name < b.project?.name)
                    return -1;
            } else if (sortKey === 'assignee') {
                if (!a.assignee)
                    return -1;
                if (!b.assignee)
                    return 1;
                if (a.assignee?.fullname() > b.assignee?.fullname())
                    return 1;
                if (a.assignee?.fullname() < b.assignee?.fullname())
                    return -1;
            } else if (sortKey === 'slug') {
                return a.slug > b.slug ? 1 : -1;
            } else if (sortKey === 'priority') {
                return b.priority > a.priority ? 1 : -1;
            }

            return a.slugName() > b.slugName() ? 1 : -1;
        });
    }

    /**
     * Get an object indexed by the projects _id and
     * containing the project name and an array of changes
     *
     * @param {Change[]}
     * @return {Object}
     */
    static groupChangesByProject(changes: Change[]): AnyObj {
        const groups: AnyObj = {};

        if (!changes.length)
            return groups;

        changes.forEach((change: Change) => {
            const _id = change.project?._id;
            if (!_id)
                return;
            if (!(_id in groups))
                groups[_id] = {
                    name: change.project.name,
                    changes: []
                };
            groups[_id].changes.push(change);
        });

        return groups;
    }

    /**
     * Get an object indexed by the users _id and
     * containing the user name and an array of changes
     *
     * @param {Change[]}
     * @return {Object}
     */
    static groupChangesByUser(changes: Change[]): AnyObj {
        const groups: AnyObj = {};

        if (!changes.length)
            return groups;

        changes.forEach((change: Change) => {
            const userId = change.assignee?._id;
            if (!userId)
                return;
            if (!(userId in groups))
                groups[userId] = {
                    name: change.assignee?.fullname(),
                    changes: []
                };
            groups[userId].changes.push(change);
        });

        return groups;
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() : AnyObj {
        const prepared = _.cloneDeep(this) as AnyObj;

        prepared.startAt = prepared.startAt.startOf('day');
        prepared.stopAt  = prepared.stopAt.endOf('day');

        return prepared;
    }
}

export default Sprint;
