import { Checklist } from '../../models';
import { Checklists } from '../../services';
import { createRestSlices, applyReducers, Status, Response } from './rest';
import { ActionPayload } from '..';
import { Dispatch } from 'react';

const {
    initialState,
    getReducer, listReducer,
    createAction,
    getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Checklists);

const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'checklist.updated':
            if (!(state.checklists?.length > 0))
                return state;

            return {
                ...state,
                checklists: state.checklists.map((checklist: Checklist) => {
                    if (checklist._id === data._id) {
                        return new Checklist(data);
                    }
                    return checklist;
                })
            };
        case 'checklist.created':
            return {
                ...state,
                checklists: [
                    new Checklist(data),
                    ...(state.checklists || [])
                ]
            };
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        getReducer, listReducer, onRemoteUpdateReducer,
        updateChecklistItemReducer, deleteChecklistItemActionReducer
    ]);
}

const UPDATING_ITEM         = 'yoda/checklists/UPDATING_ITEM';
const UPDATING_ITEM_SUCCESS = 'yoda/checklists/UPDATING_ITEM_SUCCESS';
const UPDATING_ITEM_FAILURE = 'yoda/checklists/UPDATING_ITEM_FAILURE';

const updateChecklistItemReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case UPDATING_ITEM:
            return {
                ...state,
                loadingOne: 'pending' as Status,
                loadingError: null
            };
        case UPDATING_ITEM_SUCCESS:
            return {
                ...state,
                loadingOne: 'succeeded' as Status,
                loadingError: null,
                checklists: state.checklists.map((checklist: any) => {
                    if (checklist._id === action.checklist._id)
                        return action.checklist;
                    return checklist;
                })
            };
        case UPDATING_ITEM_FAILURE:
            return {
                ...state,
                loadingOne: 'failed' as Status,
                loadingError: action.error,
                current: null
            };
        default:
            return state;
    }
};

const DELETE_ITEM         = 'yoda/checklists/DELETE_ITEM';
const DELETE_ITEM_SUCCESS = 'yoda/checklists/DELETE_ITEM_SUCCESS';
const DELETE_ITEM_FAILURE = 'yoda/checklists/DELETE_ITEM_FAILURE';

const deleteChecklistItemActionReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case DELETE_ITEM:
            return {
                ...state,
                loadingOne: 'pending' as Status,
                loadingError: null
            };
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
                loadingOne: 'succeeded' as Status,
                loadingError: null,
                checklists: state.checklists.map((checklist: any) => {
                    if (checklist._id === action.checklist._id)
                        return action.checklist;
                    return checklist;
                })
            };
        case DELETE_ITEM_FAILURE:
            return {
                ...state,
                loadingOne: 'failed' as Status,
                loadingError: action.error,
                current: null
            };
        default:
            return state;
    }
};

const updateChecklistItemAction = (_id: string, params: AnyObj) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({type: UPDATING_ITEM});
        return Checklists.updateItem(_id, params)
            .then((data: Response) => {
                if ("checklist" in data) {
                    const { checklist} = data;
                    dispatch({type: UPDATING_ITEM_SUCCESS, checklist});
                }
            })
            .catch((error: Error) => {
                console.log('error', error.message);
                dispatch({type: UPDATING_ITEM_FAILURE, error: error.message});
            });
    }
};

const deleteChecklistItemAction = (listId: string, itemId: string) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({type: DELETE_ITEM});
        return Checklists.removeItem(listId, itemId)
            .then((data: Response) => {
                if ("checklist" in data) {
                    const { checklist} = data;
                    dispatch({type: DELETE_ITEM_SUCCESS, checklist});
                }
            })
            .catch((error: Error) => {
                console.log('error', error.message);
                dispatch({type: DELETE_ITEM_FAILURE, error: error.message});
            });
    }
};
/* Export CRUD actions */
export const loadChecklist       = getAction;
export const loadChecklists      = listAction;

export const addChecklist        = createAction;
export const updateChecklist     = updateAction;
export const deleteChecklist     = deleteAction;

// export const addChecklistItem    = addChecklistItemAction;
export const updateChecklistItem = updateChecklistItemAction;
export const deleteChecklistItem = deleteChecklistItemAction;
