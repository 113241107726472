import RestClient from './RestClient';
import { Routine } from '../models';

class Routines extends RestClient {
    constructor() {
        super({
            // rest config
            model: Routine,
            entryPoint: 'routines',
            //sortBy: 'date',
            id_field: '_id',
            // redux config
            resource: 'routine',
            resources: 'routines',
        });
    }
}

const RoutinesApi = new Routines();
export default RoutinesApi;
