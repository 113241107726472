import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Change, Project, Client, User } from '../../../../models';
import type { ColumnData } from '../../../../models';
import { useAppSelector, useAppDispatch, loadUsers, loadClients, loadProjects } from '../../../../redux';
import { Button, ToggleField, MultiSelectField } from '../../../';
import { Modal} from 'flowbite-react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { Transition } from '@headlessui/react';

interface ColumnModalProps {
    column: ColumnData;
    show: boolean;
    onClose: () => void;
    onSave: (column: ColumnData) => void;
}

function ColumnModal(props: ColumnModalProps) {
    const { column, show, onClose, onSave } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { users }    = useAppSelector(state => state.users);
    const { projects } = useAppSelector(state => state.projects);
    const { clients }  = useAppSelector(state => state.clients);

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadClients());
        dispatch(loadProjects());
    }, []);

    if (!column)
        return null;

    const newSortRule = () => ({
        property: 'slug',
        order: 1
    });

    const newFilter = () => ({
        property: 'progress.status',
        op: '$eq',
        values: [ Change.STATUS_DEPLOYED ]
    });

    const valuesField = (rule: AnyObj, index: number) => {
        const { property, op } = rule;

        if (['slug', 'estimate', 'priority'].includes(property))
            return (
                <Field
                    type="number"
                    name={`rules.filters[${index}].values`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            );

        let options    = [];
        let isMultiple = Change.isMultipleOperator(op);

        if (Change.getPropertyType(property) === 'date') {
            isMultiple = false;
            options = Change.getDateFilterValues().map((value: string) => ({
                value,
                label: t(`projects.column_rules.date_values.d${value.replace('-', '_').replace(':', '_')}`)
            }));
        } else if (property === 'assignee') {
            options = users?.sort((a: User, b: User) => a.fullname().localeCompare(b.fullname())).map((user: User) => ({
                value: user._id,
                label: user.fullname()
            }));
        } else if (property === 'project') {
            options = projects?.map((project: Project) => ({
                value: project._id,
                label: project.name
            }));
        } else if (property === 'client') {
            options = clients?.map((client: Client) => ({
                value: client._id,
                label: `${client.company} - ${client.firstname} ${client.lastname}`
            }));
        } else if (property === 'type') {
            options = Change.types().map((type: string) => ({
                value: type,
                label: t(`projects.${type}`)
            }));
        } else if (property === 'importance') {
            options = Change.getImportanceValues().map(value => ({
                value,
                label: t(`changes.importance_value_${value}`)
            }));
        } else if (property === 'progress.status') {
            options = Change.allStatus().map((status: string) => ({
                value: status,
                label: t(`changes.progress_status_type.${status}`)
            }));
        }

        if (isMultiple)
            return (
                <MultiSelectField
                    name={`rules.filters[${index}].values`}
                    className="!mt-0 w-full rounded text-xs border !border-gray-500"
                    selectedClassName="text-xs"
                    optionsClassName="text-xs"
                    options={options}
                />
            );

        return (
            <Field
                as="select"
                name={`rules.filters[${index}].values`}
                className="w-full rounded text-xs"
                options={options}
            >
                { options.map((option: AnyObj) => (
                    <option value={option.value}>{option.label}</option>
                ))}
            </Field>
        );
    };

    return (
        <div className="ColumnModal">
            <Modal onClose={onClose} dismissible size="xl" show={show} className="focus:outline-0">
                <Formik
                    initialValues={column || {}}
                    enableReinitialize={true}
                    onSubmit={onSave}
                >
                    {({ isSubmitting, values }) => (
                        <Form tabIndex={-1} className='bg-white max-h-full rounded-lg'>
                            <Modal.Header className="w-full" theme={{ "title": "text-xl font-medium text-gray-900 dark:text-white w-full" }}>
                                <span className="text-xl font-medium">
                                    {t('projects.column_edition')}
                                </span>
                            </Modal.Header>
                            <Modal.Body style={{ minHeight: '500px' }}>
                                <Transition
                                    as={'div'}
                                    appear={true}
                                    show={!!column}
                                    enter="transition ease-out duration-1000"
                                    enterFrom="transform opacity-0"
                                    enterTo="transform opacity-100"
                                >
                                    <div className="flex flex-row justify-between items-center space-x-2 p-2 pb-4 border">
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="title"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                autoFocus
                                                required
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <ToggleField
                                                name="isSmart"
                                                label={t('projects.column_is_smart') || ""}
                                            />
                                        </div>
                                    </div>
                                    {column.isSmart && (
                                        <div className="mt-4 flex flex-col space-y-3">
                                            <div className=" flex flex-row justify-between p-2 border">
                                                <ToggleField
                                                    name="rules.hideIfEmpty"
                                                    label={t('projects.column_hide_if_empty') || ""}
                                                />
                                            </div>
                                            <div className=" flex flex-row justify-between p-2 border">
                                                <label htmlFor="limit" className="block text-sm font-medium leading-6 text-gray-900">
                                                    {t('projects.column_rules.limit')}
                                                    <small className="block">
                                                        {t('projects.column_rules.limit_desc')}
                                                    </small>
                                                </label>
                                                <div className="mt-2">
                                                    <Field
                                                        type="text"
                                                        size={2}
                                                        name="rules.limit"
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-center font-bold"
                                                        required
                                                    />
                                                    <ErrorMessage name="limit" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                </div>
                                            </div>
                                            <FieldArray name="rules.sort">
                                                {({ insert, remove, push }) => (
                                                    <div className="p-2 border">
                                                        <div className="flex flex-row justify-between items-end">
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                {t('projects.column_rules.sort')}
                                                                <small className="block">
                                                                    {t('projects.column_rules.sort_desc')}
                                                                </small>
                                                            </label>
                                                            <div className="mt-2">
                                                                <Button
                                                                    small
                                                                    icon="plus"
                                                                    iconSize={3}
                                                                    color="primary"
                                                                    onClick={() => {push(newSortRule())}}
                                                                    title={t('projects.column_rules.add_sort_rule')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {values.rules.sort.map((rule: AnyObj, index: number) => (
                                                            <div className="flex flex-row justify-start items-end p-2 bg-gray-50 border space-x-2 mt-2">
                                                                <div className="flex-1 flex flex-col justify-center items-start space-y-0">
                                                                    <label className="block text-xs font-medium leading-6 text-gray-900">
                                                                        {t('projects.column_rules.sort_on')}
                                                                    </label>
                                                                    <div className="mt-2 w-full">
                                                                        <Field
                                                                            as="select"
                                                                            name={`rules.sort[${index}].property`}
                                                                            className="w-full rounded text-xs"
                                                                        >
                                                                            {Change.getSortableProperties('fr').map((prop: AnyObj) => (
                                                                                <option value={prop.property}>{t(prop.label)}</option>
                                                                            ))}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 flex flex-col justify-center items-start space-y-0">
                                                                    <label className="block text-xs font-medium leading-6 text-gray-900">
                                                                        {t('projects.column_rules.sort_dir')}
                                                                    </label>
                                                                    <div className="mt-2 w-full">
                                                                        <Field
                                                                            as="select"
                                                                            name={`rules.sort[${index}].order`}
                                                                            className="w-full rounded text-xs"
                                                                        >
                                                                            <option value={1}>{t('projects.column_rules.sort_dir_asc')}</option>
                                                                            <option value={-1}>{t('projects.column_rules.sort_dir_desc')}</option>
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="lex-1 flex justify-end items-start space-y-0 mb-2.5">
                                                                    <Button
                                                                        small
                                                                        icon="delete"
                                                                        iconSize={4}
                                                                        color="danger"
                                                                        onClick={() => {remove(index)}}
                                                                        title=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <FieldArray name="rules.filters">
                                                {({ insert, remove, push }) => (
                                                    <div className="p-2 border">
                                                        <div className="flex flex-row justify-between items-end">
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                {t('projects.column_rules.filters')}
                                                                <small className="block">
                                                                    {t('projects.column_rules.filters_desc')}
                                                                </small>
                                                            </label>
                                                            <div className="mt-2">
                                                                <Button
                                                                    small
                                                                    icon="plus"
                                                                    iconSize={3}
                                                                    color="primary"
                                                                    onClick={() => {push(newFilter())}}
                                                                    title={t('projects.column_rules.add_filter')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {values.rules.filters.map((rule: AnyObj, index: number) => (
                                                            <div className="flex flex-row justify-start items-start p-2 bg-gray-50 border space-x-2 mt-2">
                                                                <div className="flex-1 flex flex-col justify-center items-start space-y-0">
                                                                    <div className="w-full">
                                                                        <Field
                                                                            as="select"
                                                                            name={`rules.filters[${index}].property`}
                                                                            className="w-full rounded text-xs"
                                                                        >
                                                                            {Change.getFilterableProperties('fr').map((prop: AnyObj) => (
                                                                                <option value={prop.property}>{t(prop.label)}</option>
                                                                            ))}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 flex flex-col justify-center items-start space-y-0">
                                                                    <div className="w-full">
                                                                        <Field
                                                                            as="select"
                                                                            name={`rules.filters[${index}].op`}
                                                                            className="w-full rounded text-xs"
                                                                        >
                                                                            {Change.getFilterOperators(rule.property).map((prop: AnyObj) => (
                                                                                <option value={prop.property}>{t(prop.label)}</option>
                                                                            ))}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-1 flex flex-col justify-center items-start space-y-0">
                                                                    <div className="w-full">
                                                                        { valuesField(rule, index) }
                                                                    </div>
                                                                </div>
                                                                <div className="lex-1 flex justify-end items-start space-y-0">
                                                                    <Button
                                                                        small
                                                                        icon="delete"
                                                                        iconSize={4}
                                                                        color="danger"
                                                                        onClick={() => {remove(index)}}
                                                                        title=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                    )}
                                </Transition>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex flex-col sm:flex-row w-full gap-x-2 gap-y-2 items-center justify-between">
                                    <Button icon="close" color="navigateBack" onClick={onClose} title={t('common.close')} />
                                    <Button type="submit" icon={isSubmitting ? 'loading' : 'save'} color="primary" title={t('common.save')} />
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
}

export default ColumnModal;

