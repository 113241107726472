import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useAppSelector, useAppDispatch, loadSuggestedTracks } from '../../redux';
import { Project, Client, Track } from '../../models';
import { loadProjects, loadActiveTrack, stopActiveTrack, createTrack, updateTrack } from '../../redux';
import { Button, SelectField, Icon, ResumeTrack } from '..';
import moment from 'moment';

interface ActiveTrackProps {}

function ActiveTrack(props: ActiveTrackProps) {
    const { t, i18n } = useTranslation();
    const navigate    = useNavigate();
    const dispatch    = useAppDispatch();

    const titleInput = useRef<HTMLInputElement>(null);

    const [activeDuration, setActiveDuration]    = useState("00:00");
    const [detailsAreVisible, setDetailsVisible] = useState(false);

    const { projects } = useAppSelector(state => state.projects);
    const { user }     = useAppSelector(state => state.auth);

    const {
        suggested, loadingSuggested,
        active, loadingActive,
        stoppingActive, creatingError
    } = useAppSelector(state => state.tracks);

    useEffect(() => {
        let interval: any;
        if (active)
            interval = setInterval(() => {
                setActiveDuration(active?.formattedDuration());
            }, 1000);

        return () => interval && clearInterval(interval);
    }, [active]);

    useEffect(() => {
        dispatch(loadProjects());
        dispatch(loadActiveTrack());
    }, [dispatch]);

    useEffect(() => {
        if (detailsAreVisible && !initialValues?.title)
            dispatch(loadSuggestedTracks({ limit: 10 }));
    }, [detailsAreVisible]);

    const stop = () => {
        dispatch(stopActiveTrack((err: Error | null) => setDetailsVisible(false)));
    };

    const submit = function (values: AnyObj) : void {
        const { startAtTime, ...properties } = values;
        const [hours, minutes] = startAtTime.split(':');

        if (!properties.startAt)
            properties.startAt = moment();

        properties.startAt = properties.startAt
            .hours(parseInt(hours))
            .minutes(parseInt(minutes))
            .seconds(0)
            .milliseconds(0);

        if (!properties.project)
            return;

        if (!properties.client) {
            const project = projects.find((project: Project) => project._id === properties.project._id);
            if (project?.clients?.length === 1)
                properties.client = project.clients[0];
        }

        dispatch(properties._id ?
            updateTrack(properties) :
            createTrack(properties, {}, (err: Error | null) => dispatch(loadActiveTrack()))
        );
    }

    const toggleDetails = () => {
        setDetailsVisible(!detailsAreVisible);
    };

    const handleKeyPress = (e: AnyObj) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
            // nada for now
        } else if (e.key === 'Escape' || e.code === 'Escape') {
            setDetailsVisible(false);
        }
    }

    const initialValues = {
        ...active,
        startAtTime: (active?.startAt || moment()).format('HH:mm')
    };

    const projectsOptions = projects?.map((project: Project) => ({
        label: project.name,
        value: project._id
    }));

    const project = projects.find((project: Project) => project._id === initialValues.project?._id);
    const clientsOptions = project ? project.clients.map((client: Client) => ({
        label: client.name(),
        value: client._id
    })) : [];

    document.title = active ? (activeDuration + ' - ' + active.title) : document.title;

    return (
        <div className="relative h-8">
            {detailsAreVisible && (
                <div onClick={toggleDetails} className="fixed w-[100vw] h-[100vh] top-0 left-0 z-1 bg-gray-900/50 z-10"></div>
            )}
            <div className={`flex flex-row space-x-1 px-2 py-1 ring-1 bg-gray-900 rounded-md ${detailsAreVisible ? 'rounded-b-none' : ''} justify-between items-center absolute z-10 right-0`}>
                <div className="flex flex-row space-x-1 justify-start items-center min-w-[1rem]">
                    <Icon type={(loadingActive === 'pending') ? 'loading' : 'timer'} color="white" className={`inline ${active ? 'animate-pulse' : ''}`} size={5} />
                    {active && (
                        <>
                            <span className="block font-netox font-bold text-sm text-white text-left w-15">
                                {activeDuration}
                            </span>
                            <span className="font-netox font-bold text-xs text-white text-left w-12 lg:w-48 truncate">
                                |&nbsp;{active?.title}
                            </span>
                        </>
                    )}
                </div>
                <div className="flex items-center cursor-pointer">
                    {active && (
                        <div className="flex items-center" onClick={() => stop()}>
                            <Icon type={(stoppingActive === 'pending') ? 'loading' : 'stop'} color="red-500" className="inline ml-2" size={5} />
                        </div>
                    )}
                    <div className="flex items-center" onClick={() => toggleDetails()}>
                        <Icon type={(loadingActive === 'pending') ? 'loading' : (detailsAreVisible ? 'up' : 'down')} color="white" className="inline ml-1" size={5} />
                    </div>
                </div>
            </div>
            {detailsAreVisible && (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        submit(values);
                    }}
                >
                    {({ values, dirty, handleSubmit }) => (
                        <Form>
                            <div className="rounded-md ring-1 md:rounded-tr-none fixed md:absolute top-[50%] md:top-7 left-[50%] md:left-auto translate-x-[-50%] md:translate-x-0 translate-y-[-50%] md:translate-y-0 md:right-0 z-10 bg-gray-900 w-[20rem] md:w-[30rem] p-0">
                                <div className="flex flex-col gap-y-6 justify-center p-2 rounded-md text-gray-900">
                                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 items-start md:items-center">
                                        <div className="flex flex-row w-full md:w-fit justify-start items-center">
                                            <Icon type="from" color="white" className="inline mr-1" />
                                            <div className="flex-1 flex justify-center w-full md:w-fit focus:ring-0 border-0 shadow-sm font-bold text-sm sm:leading-6">
                                                <Field
                                                    type="time"
                                                    name="startAtTime"
                                                    className="block text-white bg-gray-900 border-0 focus:ring-0 border-white border-b-2"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row w-full justify-start items-center">
                                            <Icon type="comment" color="white" className="inline ml-0 md:ml-3 mr-1" />
                                            <div className="flex-1 flex justify-center w-full md:w-fit focus:ring-0 shadow-sm font-bold text-sm sm:leading-6">
                                                <Field
                                                    ref={titleInput}
                                                    type="text"
                                                    name="title"
                                                    className="block text-center text-white bg-gray-900 border-0 placeholder:text-sm focus:ring-0 border-white border-b-2"
                                                    placeholder={t('tracks.title_placeholder') || ''}
                                                    onKeyDown={handleKeyPress}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                    {!values.title &&
                                        <div className="flex flex-col space-y-0">
                                            {(loadingSuggested === 'pending') ? (
                                                <div className="flex justify-center items-center">
                                                    <Icon type="loading" color="white" className={`inline ${active ? 'animate-pulse' : ''}`} size={5} />
                                                </div>
                                            ) : (loadingSuggested !== 'pending') && suggested.map((track: Track) => (
                                                <div className="flex flex-col md:flex-row first:rounded-t last:rounded-b border border-gray-800 border-b-0 last:border-b-0 hover:bg-gray-800 p-1 pt-0">
                                                    <div className="flex-1 flex-col space-y-0">
                                                        <span className="block text-white text-center md:text-left text-sm font-bold w-full truncate md:w-[24rem] lg:w-auto ">
                                                            {track.title}
                                                        </span>
                                                        <div className="flex flex-col items-center md:items-start lg:flex-row space-x-0 lg:space-x-2">
                                                            <div onClick={() => navigate(`/projects/${track.project?._id}`)} className="cursor-pointer">
                                                                <div className="flex flex-row space-x-1.5 items-center text-white">
                                                                    <Icon type="project" className="" size={3} color="white" />
                                                                    <div className="text-xs font-light w-full md:w-64 truncate">{track.project?.name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-auto flex justify-center md:justify-end items-center">
                                                        <ResumeTrack
                                                            track={track}
                                                            className="w-12"
                                                            hideRemove
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    </div>
                                    {values.title && (
                                    <div className="flex flex-col gap-y-0">
                                        {active?.project ? (
                                            <div className="flex justify-between items-center text-white text-xs font-bold py-2">
                                                <div className="flex items-center text-white text-xs font-bold">
                                                    <Icon type="project" color="white" className="inline mr-1.5" />
                                                    {active.project.name}
                                                </div>
                                                <div className="flex items-center text-white text-xs font-bold cursor-pointer">
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row items-center">
                                                <Icon type="project" color="white" className="inline mr-1" />
                                                <div className="flex-1 w-fit">
                                                    <SelectField
                                                        name="project._id"
                                                        placeholder={t('tracks.select_project') || ''}
                                                        containerClassName="block w-full focus:ring-0 border-0 border-b-2 border-white bg-transparent py-1.5 shadow-sm font-bold text-sm text-white sm:leading-6 -mb-1"
                                                        optionsContainerClassName="rounded-t-none"
                                                        options={projectsOptions}
                                                        optionsHeight={'max-h-96'}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {active?.client ? (
                                        <div className="flex justify-between items-center text-white text-xs font-bold py-2">
                                            <div className="flex items-center text-white text-xs font-bold">
                                                <Icon type="user" color="white" className="inline mr-1.5" />
                                                {active.client.name()}
                                            </div>
                                            <div className="flex items-center text-white text-xs font-bold cursor-pointer">
                                            </div>
                                        </div>
                                    ) : (active?.project && (clientsOptions?.length > 0) && (
                                            <div className="flex flex-row items-center">
                                                <Icon type="user" color="white" className="inline mr-1" />
                                                <div className="flex-1 w-fit">
                                                    <SelectField
                                                        name="client._id"
                                                        placeholder={t('tracks.select_client') || ''}
                                                        containerClassName="block w-full focus:ring-0 border-0 border-b-2 border-white bg-transparent py-1.5 shadow-sm font-bold text-sm text-white sm:leading-6"
                                                        options={clientsOptions}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {active?.change && (
                                        <div className="flex justify-between items-center text-white text-xs font-bold py-2">
                                            <div className="flex items-center text-white text-xs font-bold">
                                                <Icon type={active.change.iconName()} color="white" className="inline mr-1.5" />
                                                {active.change.slugName()}&nbsp;
                                                {active.change.localizedTitle(i18n.language)}
                                            </div>
                                            <div className="flex items-center text-white text-xs font-bold cursor-pointer">
                                            </div>
                                        </div>
                                        )}
                                        {dirty && (
                                            <div className="flex justify-center items-center mt-2">
                                                <Button
                                                    type="submit"
                                                    title={t('common.save')}
                                                    icon={(loadingActive === 'pending') ? 'loading' : 'check'}
                                                    color="none"
                                                    className="inline ml-1"
                                                />
                                            {creatingError && (
                                                <div className="text-center text-xs font-bold text-red-600">
                                                    {creatingError}
                                                </div>
                                            )}
                                            </div>
                                        )}
                                    </div>
                                    )}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
}

export default ActiveTrack;
