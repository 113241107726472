import React, { useState } from 'react';
import {Alert, Badge} from "flowbite-react";
import { Change } from "../../../../models";
import { Field, Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserEnvironmentInfo, formatDeveloperNotes } from '../../../../utils/getUserEnvironmentInfo';
import { Button, Icon, Uploader, Loader } from "../../../../components";
import {
    addChangeToBoardColumn,
    createBugReport,
    RootState,
    useAppDispatch,
    useAppSelector
} from '../../../../redux';
import moment from "moment";

function PublicProjectReportView() {
    const { t, i18n }    = useTranslation();
    const { projectId} = useParams<{ projectId: string }>();
    const { source} = useParams<{ source: string }>();
    const dispatch = useAppDispatch();
    const { change, bugReportStatus } = useAppSelector((state: RootState) => state.changes);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const createNewChange = (change: AnyObj) => {
        if (!projectId) {
            console.warn("projectId is undefined");
            return;
        }
        if (!source) {
            console.warn("source is undefined");
            return;
        }

        const currentDate = moment().format('DD/MM/YY');

        const developerNotes = formatDeveloperNotes(change, getUserEnvironmentInfo());
        const initial = {
            change: {
                project: projectId,
                description: change.description,
                title: {
                    fr: "Support - " + source + " - " + currentDate,
                    en: "Support - " + source + " - " + currentDate,
                    de: "Support - " + source + " - " + currentDate,
                },
                type: Change.TYPE_FIX,
                developer_notes: developerNotes
            }
        };

        dispatch(createBugReport(projectId, initial, (error, newChange) => {
            if (error) {
                console.error("Failed to create new change:", error);
                return;
            }

            if (newChange && typeof newChange === 'object' && '_id' in newChange) {
                const columnTitle = "Backlog";
                dispatch(addChangeToBoardColumn(projectId, columnTitle, newChange._id, (err, updatedBoard) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                }));
            }
        }));
    };

    if (bugReportStatus === 'pending') {
        return <Loader/>;
    }

    if (change && projectId && !showAlert) {
        return (
            <div className="flex h-full justify-center items-center my-5">
                <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-1xl font-bold">{ t("bugReport.title_add_attachments") }</h3>
                    <Uploader projectId={projectId} changeId={change._id} isBugReport={true} />
                    <div className="mt-5 flex justify-center">
                        <Button
                            type="submit"
                            title={ t('common.save') }
                            color="primary"
                            onClick={() => setShowAlert(true)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (showAlert) {
        return (
            <div className="flex h-full justify-center items-center my-5">
                <div className="max-w-md w-full p-6">
                    <Alert color="success" className="text-center">{ t("bugReport.success_send_report")}</Alert>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-full justify-center items-center my-2">
            <div className="flex flex-col items-center w-full px-6">
                <div className="my-4 text-center">
                    <div className="text-1xl font-bold">{ t("bugReport.title_have_problem")}</div>
                    <div className="text-1xl font-bold">{ t("bugReport.title_help_you")}</div>
                </div>
                <div className="flex flex-col items-center space-y-2 max-w-md w-full bg-white rounded-lg shadow-md p-2 lg:px-6">
                    <div className="pt-4 pr-4 flex space-x-2">
                        <Badge
                            className="cursor-pointer"
                            onClick={() => i18n.changeLanguage('fr')}
                            color={i18n.language === 'fr' ? 'dark' : 'light'}
                        >
                            FR
                        </Badge>
                        <Badge
                            className="cursor-pointer"
                            onClick={() => i18n.changeLanguage('en')}
                            color={i18n.language === 'en' ? 'dark' : 'light'}
                        >
                            EN
                        </Badge>
                        <Badge
                            className="cursor-pointer"
                            onClick={() => i18n.changeLanguage('de')}
                            color={i18n.language === 'de' ? 'dark' : 'light'}
                        >
                            DE
                        </Badge>
                    </div>
                    <div className="flex-1 w-full">
                        <Formik
                            initialValues={{
                                // title: '',
                                description: '',
                                steps: '',
                                expectedResult: '',
                                actualResult: '',
                            }}
                            onSubmit={(values) => createNewChange(values)}
                        >
                            <Form className="my-3">
                                <div className="mb-4">
                                    <label className="block font-medium text-gray-900">
                                        <Icon type="comment" className="inline mr-1.5" />
                                        {t("bugReport.description")}
                                    </label>
                                    <Field
                                        component="textarea"
                                        required
                                        name="description"
                                        rows="5"
                                        placeholder={t("bugReport.placeholder_description")}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 placeholder:text-sm"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium text-gray-900">
                                        <Icon type="list" className="inline mr-1.5" />
                                        {t("bugReport.steps")}
                                    </label>
                                    <Field
                                        component="textarea"
                                        required
                                        name="steps"
                                        rows="8"
                                        placeholder={t("bugReport.placeholder_steps")}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 placeholder:text-sm"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium text-gray-900">
                                        <Icon type="check" className="inline mr-1.5" />
                                        {t("bugReport.expected_result")}
                                    </label>
                                    <Field
                                        component="textarea"
                                        required
                                        name="expectedResult"
                                        rows="5"
                                        placeholder={t("bugReport.placeholder_expected_result")}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 placeholder:text-sm"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block font-medium text-gray-900">
                                        <Icon type="warning" className="inline mr-1.5" />
                                        {t("bugReport.actual_result")}
                                    </label>
                                    <Field
                                        component="textarea"
                                        required
                                        name="actualResult"
                                        rows="5"
                                        placeholder={t("bugReport.placeholder_actual_result")}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 placeholder:text-sm"
                                    />
                                </div>
                                <div className="mt-5 flex justify-center">
                                    <Button
                                        type="submit"
                                        title={t("common.next")}
                                        color="primary"
                                    />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PublicProjectReportView;
