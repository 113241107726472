import { Dispatch } from 'redux';
import { Attachment } from "../../models";
import { Attachments } from "../../services";
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
} = createRestSlices(Attachments);

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer
    ]);
}

const CREATING_ATTTACHMENT_SUCCESS = `yoda/attachment/CREATING_ITEM_SUCCESS`;
export const addedAttachment = (attachment: Attachment) => {
    return (dispatch: Dispatch) => {
        dispatch({type: CREATING_ATTTACHMENT_SUCCESS, attachment});
    }
};
const DELETING_ATTTACHMENT_SUCCESS = `yoda/attachment/DELETING_ITEM_SUCCESS`;
export const deletedAttachment = (attachment: Attachment) => {
    return (dispatch: Dispatch) => {
        dispatch({type: DELETING_ATTTACHMENT_SUCCESS, attachment});
    }
};

/* Export CRUD actions */
export const createAttachment      = createAction;
export const startCreateAttachment = startCreateAction;
export const loadAttachment        = getAction;
export const loadAttachments       = listAction;
export const updateAttachment      = updateAction;
export const deleteAttachment      = deleteAction;
