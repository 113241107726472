import { Releases } from '../../services';
import { Release, Change } from '../../models';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const {
    initialState,
    createReducer, startCreateReducer, cancelCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction, cancelCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Releases);

const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'change.updated':
            if (!state.releases?.length)
                return state;

            // let's replace the original change in the state release
            // with the updated one
            const releases = (state.releases || []).map((release: Release) => {
                release.changes = release.changes.map((original: Change) => {
                    return original._id === data._id ? new Change(data) : original;
                });
                return release;
            });
            return {
                ...state,
                releases
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle modifications on changes when we are
 * looking at a specific release...
 */
const UPDATING_CHANGE_SUCCESS = 'yoda/change/UPDATING_ITEM_SUCCESS';
const updateChangeReducer = (state = initialState, action: ActionPayload) => {
    if (!state.releases?.length)
        return state;

    switch (action.type) {
        case UPDATING_CHANGE_SUCCESS:
            const { change } = action;

            // let's replace the original change in the state release
            // with the updated one
            const releases = (state.releases || []).map((release: Release) => {
                release.changes = release.changes.map((original: Change) => {
                    return original._id !== change._id ? original : new Change({
                        ...original,
                        ...change
                    });
                });
                return release;
            });
            return {
                ...state,
                releases
            };
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer, cancelCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer, duplicateReducer,
        onRemoteUpdateReducer, updateChangeReducer
    ]);
}

/* Export CRUD actions */
export const createRelease       = createAction;
export const startCreateRelease  = startCreateAction;
export const cancelCreateRelease = cancelCreateAction;
export const loadRelease         = getAction;
export const loadReleases        = listAction;
export const updateRelease       = updateAction;
export const deleteRelease       = deleteAction;
export const duplicateRelease    = duplicateAction;
