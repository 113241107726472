import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'flowbite-react';
import { Change, Sprint } from '../../../models';
import { Button, Icon } from '../..';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { loadSprints, updateChange } from '../../../redux';

interface SprintsProps {
    change: Change;
    readOnly?: boolean;
}

function Sprints(props: SprintsProps) {
    const { change, readOnly } = props;

    const [pickerIsVisible, setPickerVisible] = useState(false);

    const { t }    = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { updating }         = useAppSelector((state: RootState) => state.changes);
    const { sprints, loading } = useAppSelector((state: RootState) => state.sprints);

    useEffect(() => {
        if (pickerIsVisible)
            dispatch(loadSprints({'notfinished': true}));
    }, [dispatch, pickerIsVisible]);

    if (!change)
        return null;

    const addSprint = async (sprint: Sprint) => {
        if (!change)
            return;

        const params = {
            "_id":  change._id,
            "sprints": [
                ...change.sprints,
                sprint._id
            ]
        };

        setPickerVisible(false);
        await dispatch(updateChange(params, /*patch*/true));
    }

    const removeSprint = async (sprint: Sprint) => {
        if (!change)
            return;

        const params = {
            "_id":  change._id,
            "sprints": change.sprints.filter((s: Sprint) => s._id !== sprint._id)
        };

        await dispatch(updateChange(params, /*patch*/true));
    }

    const changeSprints = change.sprints.sort((a: Sprint, b: Sprint) => (a.stopAt > b.stopAt ? 1 : -1));

    const badgeColor = (sprint: Sprint) => {
        const finishedAt = change.finishedAt();

        if (change.isFinished())
            return (finishedAt?.isAfter(sprint.startAt) && finishedAt?.isBefore(sprint.stopAt)) ? 'success' : 'failure';

        if (!sprint.isFinished())
            // sprint is in running or in the future and the change is not finished
            return 'warning';

        // sprint is in the past and the change is not finished
        return 'failure';
    };

    if (updating === 'pending')
        return (
            <div className="flex justify-center items-center border border-gray-100 bg-gray-50 p-2">
                <Icon type="loading" size={6} />
            </div>
        );

    // we only show the last sprint and the current, if any
    let nbSprintsToShow = 1;
    if (changeSprints?.length && !changeSprints[changeSprints.length - 1].isFinished())
        nbSprintsToShow = 2;

    return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 border border-gray-100 bg-gray-50 p-2">
            {!pickerIsVisible && (
                <div className="col-span-full flex flex-row justify-center align-center gap-x-2">
                    {changeSprints.length > nbSprintsToShow && (
                        <div className="flex flex-row gap-0 border rounded">
                            <Badge
                                size="xs"
                                color="failure"
                                className="w-full h-100 font-normal cursor-pointer"
                            >
                                <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                { t('changes.n_previous_sprints', { nb: changeSprints.length - nbSprintsToShow }) }
                            </Badge>
                        </div>
                    )}
                    {changeSprints.slice(-1 * nbSprintsToShow).map((sprint: Sprint) => (
                        <div className="flex flex-row gap-0 border rounded" key={sprint._id}>
                            <Badge
                                size="xs"
                                color={badgeColor(sprint)}
                                className="flex-1 h-100 w-fit font-normal cursor-pointer"
                                onClick={(e: any) => navigate(`/sprints/${sprint._id}`)}
                            >
                                <div className="flex flex-row items-center">
                                    <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                    <div className="flex-1 flex flex-col items-center">
                                        {sprint.name}
                                        <div className="pl-5">{sprint.startAt.format('L')} - {sprint.stopAt.format('L')}</div>
                                    </div>
                                </div>
                            </Badge>
                            {!sprint.isFinished() && (
                                <div onClick={(e: any) => removeSprint(sprint)} className="flex items-center rounded-md rounded-l-none border-l-0 mx-1 cursor-pointer">
                                    <Icon type="close" size={4} color="red-900" />
                                </div>
                            )}
                        </div>
                    ))}
                    {readOnly && !changeSprints.length && (
                        <label className="italic text-sm">
                            {t('changes.no_sprint_yet')}
                        </label>
                    )}
                    {!readOnly && change.isSprintable() && (
                        <Button
                            icon="plus"
                            title={!changeSprints.length ? t('changes.add_in_sprint') : ''}
                            onClick={() => setPickerVisible(true)}
                            iconSize={3}
                            color="primary"
                            small
                        />
                    )}
                </div>
            )}
            {pickerIsVisible && (
                <div className="col-span-full flex-col space-y-2 text-center">
                    <label className="block text-sm font-light leading-6 text-gray-900">{t('changes.choose_a_sprint')}</label>
                    {loading === 'pending' ? (
                        <div className="flex justify-center items-center">
                            <Icon type="loading" size={6} />
                        </div>
                    ) : (
                        <>
                            <div className="grid grid-cols-2 gap-1 place-content-center">
                                {sprints.slice().reverse().map((sprint: Sprint) => (
                                    <div key={sprint._id} className="col-span-1">
                                        <Badge
                                            size="xs"
                                            color="warning"
                                            className="font-normal border cursor-pointer"
                                            onClick={(e: any) => addSprint(sprint)}
                                        >
                                            <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                            <span className="">{sprint.name}</span>&nbsp;|&nbsp;
                                            <span className="">{sprint.startAt.format('ll')}</span>&nbsp;-&nbsp;
                                            <span className="">{sprint.stopAt.format('ll')}</span>
                                        </Badge>
                                    </div>
                                ))}
                            </div>
                            <Button
                                icon="close"
                                title={t('common.cancel')}
                                onClick={() => setPickerVisible(false)}
                                iconSize={3}
                                color="primary"
                                small
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default Sprints;
