/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {loadAttachments, deleteAttachment } from '../../redux/ducks/attachments';
import { RootState, useAppDispatch, useAppSelector } from "../../redux";
import { Attachment } from '../../models';
import { Confirm, Icon } from '..';
import { Badge } from 'flowbite-react';
import ReactTimeAgo from 'react-time-ago';

interface AttachmentsListProps {
    projectId?: string;
    changeId?: string;
    showChangeSlug?: boolean;
    isBugReport?: boolean;
}

const AttachmentsList = (props: AttachmentsListProps) => {
    const { isBugReport, projectId, changeId, showChangeSlug } = props;
    const { attachments, attachment } = useAppSelector((state: RootState) => state.attachments);
    const dispatch    = useAppDispatch();
    const { t, i18n } = useTranslation();
    const [confirmID, setConfirmID] = useState('');
    const [toDelete, setToDelete]   = useState<Attachment>();
    const [allAreVisible, showAll]  = useState({
        project: false,
        changes: false
    });

    useEffect(() => {
        if (isBugReport) {
            Attachment.loadBugReportAttachmentUrl(changeId ?? "");
        } else if (projectId && !isBugReport) {
            const filters: AnyObj = { project: projectId };
            if (changeId)
                filters.change = changeId;
            dispatch(loadAttachments(filters));
        }
    }, [dispatch, projectId, changeId, isBugReport, attachment]);


    const handleDeleteFiles = () => {
        dispatch(deleteAttachment(confirmID))
        setToDelete(undefined);
    }

    const askForDelete = (e: AnyObj, attachment: Attachment) => {
        e.preventDefault();
        e.stopPropagation();
        setConfirmID(attachment._id);
        setToDelete(attachment);
    }

    const nbProjectAttachments = attachments?.filter((a: Attachment) => !a.change)?.length || 0;
    const nbChangesAttachments = attachments?.filter((a: Attachment) => !!a.change)?.length || 0;

    const projectAttachments: Attachment[] = useMemo(() => {
        if (!attachments?.length)
            return [];

        return attachments.filter((a: Attachment) => !a.change).sort((a: Attachment, b: Attachment) => {
            if (!a.updatedAt)
                return -1;

            if (!b.updatedAt)
                return 1;

            return a.updatedAt > b.updatedAt ? -1 : 1;
        }).slice(0, allAreVisible.project ? attachments.length : 5);
    }, [attachments, allAreVisible.project]);

    const changesAttachments: Attachment[] = useMemo(() => {
        if (!attachments?.length)
            return [];

        return attachments.filter((a: Attachment) => !!a.change).sort((a: Attachment, b: Attachment) => {
            if (!a.updatedAt)
                return -1;

            if (!b.updatedAt)
                return 1;

            return a.updatedAt > b.updatedAt ? -1 : 1;
        }).slice(0, allAreVisible.changes ? attachments.length : 5);
    }, [attachments, allAreVisible.changes]);

    const showList = (attachments: Attachment[]) => attachments.map((attachment: Attachment) => (
        <a key={attachment._id} href={attachment.downloadUrl()} target="_blank" rel="noreferrer" className="flex flex-row my-4 hover:bg-slate-100 hover:no-underline rounded border border-transparent hover:border-slate-200">
            <span className="inline-block h-24 w-32 flex justify-center items-center bg-slate-300 hover:no-underline flex rounded">
                {attachment.isPicture() ? (
                    <span
                        className="border inline-block w-full h-full text-2xl text-center font-bold uppercase text-slate-500 bg-cover rounded"
                        style={{
                            backgroundImage: `url(${attachment.downloadUrl()})`,
                            backgroundColor: "#00FF00",
                        }}
                    />
                ) : (
                    <span className="inline-block w-full text-lg text-center font-bold uppercase text-slate-500 rounded">
                        {attachment.getExtension()}
                    </span>
                )}
            </span>
            <span className="h-24 pl-4 flex-auto flex flex-col justify-center">
                <p className="text-sm col-span-2 col-start-2 font-semibold">{attachment.name}</p>
                <p className="text-xs row-start-2 col-start-2 col-span-2 italic text-gray-800">
                    {t("attachments.upload_file", { firstname: attachment.author?.firstname })}&nbsp;
                    {attachment.createdAt && (
                        <ReactTimeAgo locale={i18n.language} date={attachment.createdAt.toDate()} />
                    )}
                </p>
                {showChangeSlug && attachment.change && (
                    <p className="mt-1">
                        <Badge size="xs" color={attachment.change.color()} className="w-fit text-xs">
                            <Icon type={attachment.change.iconName()} size={3} className="inline" />&nbsp;
                            {attachment.change.slugName()}&nbsp;|&nbsp;
                            {attachment.change.localizedTitle(i18n.language)}
                        </Badge>
                    </p>
                )}
                <p className="text-xs text-gray-800 underline mt-1" onClick={(e: AnyObj) => askForDelete(e, attachment)}>
                    {t('attachments.delete_attachment')}
                </p>
            </span>
        </a>
    ));

    return (
            <>
                <div className="my-3 grid grid-cols-1 lg:grid-cols-2">
                    {projectAttachments?.length > 0 && (
                        <div className={`col-span-${changesAttachments?.length > 0 ? 1 : 2}`}>
                            <h3 className="font-bold">{t('attachments.project_attachments')}</h3>
                            {showList(projectAttachments)}
                            {nbProjectAttachments > 5 && (
                                <p className="mt-5 flex justify-start items-center mb-10">
                                    <span className="text-xs text-white py-1.5 px-2 bg-gray-500 text-center cursor-pointer rounded" onClick={() => showAll({...allAreVisible, project: !allAreVisible.project})}>
                                        {t(allAreVisible.project ? 'attachments.show_less' : 'attachments.show_all', {hidden: nbProjectAttachments - 5})}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                    {changesAttachments?.length > 0 && (
                        <div className={`col-span-${projectAttachments?.length > 0 ? 1 : 2}`}>
                            {!changeId && (
                                <h3 className="font-bold">{t('attachments.changes_attachments')}</h3>
                            )}
                            {showList(changesAttachments)}
                            {nbChangesAttachments > 5 && (
                                <p className="mt-5 flex justify-start items-center">
                                    <span className="text-xs text-white py-1.5 px-2 bg-gray-500 text-center cursor-pointer rounded" onClick={() => showAll({...allAreVisible, changes: !allAreVisible.changes})}>
                                        {t(allAreVisible.changes ? 'attachments.show_less' : 'attachments.show_all', {hidden: nbChangesAttachments - 5})}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                    <Confirm
                        visible={!!toDelete}
                        title={ t(toDelete?.isPicture() ? 'changes.delete_this_img' : 'changes.delete_this_file') || "" }
                        onConfirm={handleDeleteFiles}
                        onCancel={() => setToDelete(undefined)}
                    />
                </div>
            </>
    );
};

export default AttachmentsList;

