import React from 'react';
import { Icon } from '..';

interface LoaderProps {}

function Loader(props: LoaderProps) {
    return (
        <div className="flex w-screen h-screen bg-gray-150 opacity-50 z-40 fixed top-0 bottom-0 left-0 right-0 justify-center items-center p-6">
            <div className="bg-gray-600 p-6 rounded">
                <Icon type="loading" color="white" size={10}/>
            </div>
        </div>
    );
}

export default Loader;
