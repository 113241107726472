import { Clients } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const {
    initialState,
    getReducer, listReducer,
    getAction, listAction,
} = createRestSlices(Clients);

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [ getReducer, listReducer ]);
}

/* Export CRUD actions */
export const loadClient  = getAction;
export const loadClients = listAction;
