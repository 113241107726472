import BaseModel from './BaseModel';
import User from './User';
import Project from './Project';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';

export type Thread = {
    _id: string;
    comments: Comment[];
    startAt: Moment;
}

/** Class representing a comment. */
class Comment extends BaseModel {
    _id: string;
    threadId?: string;
    createdAt: Moment;
    updatedAt?: Moment;
    author: User;
    project: Project;
    change: Change;
    message: string;
    commentIndex?: number;
    level?: number;

    constructor(properties: AnyObj) {
        super({});

        this._id      = properties._id;
        this.threadId = properties.threadId;
        this.author   = properties.author;
        this.project  = properties.project;
        this.change   = properties.change;
        this.message  = properties.message;

        this.createdAt = moment(properties.createdAt);
        if (properties.updatedAt)
            this.updatedAt = moment(properties.createdAt);

        if (properties.author)
            this.author = new User(properties.author);

        if (properties.project)
            this.project = new Project(properties.project);

        if (properties.change)
            this.change = new Change(properties.change);

        if ('commentIndex' in properties)
            this.commentIndex = properties.commentIndex;

        if ('level' in properties)
            this.level = properties.level;
    }

    authorName() {
        return [this.author?.firstname, this.author?.lastname].filter(s => !!s).join(' ');
    }

    /**
     * Build a list of ordered threads
     */
    static toThreads(comments: Comment[]) : Thread[] {
        const threads: Record<string, Thread> = {};

        comments.forEach((c: Comment) => {
            if (!c.threadId)
                return;

            if (!(c.threadId in threads))
                threads[c.threadId] = {
                    _id: c.threadId,
                    comments: [],
                    startAt: c.createdAt
                };

            const thread = threads[c.threadId];
            c.level = thread.comments.length === 0 ? 0 : 1;
            thread.comments.push(c);
            thread.comments = thread.comments.sort((a: Comment, b: Comment) => a.createdAt.isBefore(b.createdAt) ? -1 : 1);
            if (c.createdAt.isBefore(thread.startAt))
                thread.startAt = c.createdAt;
        });

        return Object.values(threads).sort((a: Thread, b: Thread) => a.startAt.isBefore(b.startAt) ? 1 : -1);
    }
}

export default Comment;
