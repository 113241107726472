import React from 'react';
import { useTranslation } from 'react-i18next';
import { Report as ReportModel } from '../../../models';

interface ReportProps {
    report: ReportModel;
}

function Report(props: ReportProps) {
    const { report }  = props;
    const { t }       = useTranslation();

    if (!report)
        return null;

    const numbers = [{
        title: 'report.titles.changes',
        entries: [[
            { title: 'report.changes.estimated_hours', value: `${report.changes?.estimated_hours.toFixed(2)} h`, },
            { title: 'report.changes.assigned_hours', value: `${report.changes.assigned_hours.toFixed(2)} h`, },
        ], [
            { title: 'report.changes.deployed_hours', value: `${report.changes.deployed_hours.toFixed(2)} h`, },
            { title: 'report.changes.to_deploy_hours', value: `${report.changes.to_deploy_hours.toFixed(2)} h`, },
        ]]
    }, {
        title: 'report.titles.billable',
        entries: [[
            { title: 'report.changes.billable_estimated_hours', value: `${report.changes.billable_estimated_hours.toFixed(2)} h`, },
            { title: 'report.changes.billable_assigned_hours', value: `${report.changes.billable_assigned_hours.toFixed(2)} h`, },
        ], [
            { title: 'report.changes.billable_deployed_hours', value: `${report.changes.billable_deployed_hours.toFixed(2)} h`, },
            { title: 'report.changes.billable_to_deploy_hours', value: `${report.changes.billable_to_deploy_hours.toFixed(2)} h`, },
        ]]
    }, {
        title: 'report.titles.assignment',
        entries: [[
            { title: 'report.changes.nb_estimated', value: report.changes.nb_estimated, },
            { title: 'report.changes.nb_assigned', value: report.changes.nb_assigned, },
            { title: 'report.changes.nb_deployed', value: report.changes.nb_deployed, },
        ], [
            { title: 'report.changes.assignment_ratio', value: `${report.changes.assignment_ratio} %` },
            { title: 'report.changes.deployment_ratio', value: `${report.changes.deployment_ratio} %` },
        ]]
    }, {
        title: 'report.titles.bugs',
        entries: [[
            { title: 'report.changes.type.bugs.nb_estimated', value: report.changes?.type.bugs.nb_estimated, },
            { title: 'report.changes.type.bugs.nb_assigned', value: report.changes?.type.bugs.nb_assigned, },
            { title: 'report.changes.type.bugs.nb_deployed', value: report.changes?.type.bugs.nb_deployed, },
        ], [
            { title: 'report.changes.type.bugs.assignment_ratio', value: `${report.changes?.type.bugs.assignment_ratio} %` },
            { title: 'report.changes.type.bugs.deployment_ratio', value: `${report.changes?.type.bugs.deployment_ratio} %` },
        ]]
    }, {
        title: 'report.titles.features',
        entries: [[
            { title: 'report.changes.type.features.nb_estimated', value: report.changes?.type.features.nb_estimated, },
            { title: 'report.changes.type.features.nb_assigned', value: report.changes?.type.features.nb_assigned, },
            { title: 'report.changes.type.features.nb_deployed', value: report.changes?.type.features.nb_deployed, },
        ], [
            { title: 'report.changes.type.features.assignment_ratio', value: `${report.changes?.type.features.assignment_ratio} %` },
            { title: 'report.changes.type.features.deployment_ratio', value: `${report.changes?.type.features.deployment_ratio} %` },
        ]]
    }, {
        title: 'report.titles.other',
        entries: [[
            { title: 'report.changes.type.other.nb_estimated', value: report.changes?.type.other.nb_estimated, },
            { title: 'report.changes.type.other.nb_assigned', value: report.changes?.type.other.nb_assigned, },
            { title: 'report.changes.type.other.nb_deployed', value: report.changes?.type.other.nb_deployed, },
        ], [
            { title: 'report.changes.type.other.assignment_ratio', value: `${report.changes?.type.other.assignment_ratio} %` },
            { title: 'report.changes.type.other.deployment_ratio', value: `${report.changes?.type.other.deployment_ratio} %` },
        ]]
    }, {
        title: 'report.titles.tracking',
        entries: [[
            { title: 'report.tracks.nb', value: report.tracks?.nb, },
            { title: 'report.tracks.tracked_hours', value: `${report.tracks?.tracked_hours.toFixed(2)} h` },
        ], [
            { title: 'report.tracks.average_hours_per_track', value: `${report.tracks?.average_hours_per_track} h` },
            { title: 'report.users.tracking_ratio', value: `${report.users?.tracking_ratio?.toFixed(2)} %`, },
        ]]
    }, {
        title: 'report.titles.tracked_changes',
        entries: [[
            { title: 'report.changes.tracked.estimated_hours', value: `${report.changes.tracked.estimated_hours.toFixed(2)} h`, },
            { title: 'report.changes.tracked.tracked_hours', value: `${report.changes.tracked.tracked_hours.toFixed(2)} h`, },
            { title: 'report.changes.tracked.billable_tracked_hours', value: `${report.changes.tracked.billable_tracked_hours.toFixed(2)} h`, },
        ], [
            { title: 'report.changes.tracked.nb_tracked', value: report.changes.tracked.nb_tracked, },
            { title: 'report.changes.tracked.tracking_ratio', value: `${report.changes.tracked.tracking_ratio} %`, },
        ]]
    }, {
        title: 'report.titles.workers',
        entries: [[
            { title: 'report.users.nb', value: report.users?.nb },
            { title: 'report.users.worked_hours', value: `${report.users?.worked_hours.toFixed(2)} h` },
            { title: 'report.users.worked_man_days', value: `${report.users?.worked_man_days.toFixed(2)} j/h` },
        ], [
            { title: 'report.users.remaining_hours', value: `${report.users?.remaining_hours.toFixed(2)} h` },
            { title: 'report.users.remaining_man_days', value: `${report.users?.remaining_man_days.toFixed(2)} j/h` },
        ]]
    }];

    const widget = (title: string, value: string) => (
        <div key={title} className={`col-span-1 border rounded-md p-4 w-full flex flex-col justify-between items-center bg-white hover:bg-gray-100`}>
            <span className="font-normal">
                { t(title) || '' }
            </span>
            {t(`${title}_desc`) !== `${title}_desc` && (
                <span className="text-xs text-center italic font-light">
                    { t(`${title}_desc`) || '' }
                </span>
            )}
            <span className={`text-5xl font-bold text-gray-900`}>
                { value }
            </span>
        </div>
    );

    const color = (i: number) => (
        i % 3 === 0 ? 'blue' : (
            i % 3 === 1 ? 'purple' : 'yellow'
        )
    );

    return (
        <div className="w-full overflow-x-auto z-0">
            <div className="flex flex-col">
                <div className="w-full font-normal text-md text-gray-900 mb-3">
                    {numbers.map((group: AnyObj, i: number) => (
                    <div key={`group-${i}`} className={`mb-5 p-2 rounded-md border border-${color(i)}-100 bg-${color(i)}-50`}>
                        <h3 className="m-2 text-xl font-bold">
                            {t(group.title)}
                        </h3>
                        {group.entries.map((entries: Record<string, any>, j: number) => (
                        <div key={j} className="mb-2">
                            <div key={j} className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${entries.length} gap-2 mb-2`}>
                                {entries.map((entry: AnyObj) => widget(entry.title, entry.value))}
                            </div>
                        </div>
                        ))}
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Report;
