import React from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppSelector } from '../../../redux';
import { Reporting, Icon } from '../../../components';

function UserTracks() {
    const { t }    = useTranslation();
    const { user } = useAppSelector((state: RootState) => state.auth);

    return (
        <div className="UserTracks">
            <header className="bg-white border-b px-4 py-0 sm:pl-6 lg:pl-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="timer" size={7} className="mr-1.5 inline" />
                            { t('app.user_tracks') }
                        </h2>
                    </div>
                </div>
            </header>
            <main>
                { user && (
                    <div className="w-full p-5">
                        <Reporting
                            user={user}
                            tabs={['tracks', 'stats', 'report']}
                        />
                    </div>
                )}
            </main>
        </div>
    );
}

export default UserTracks;
