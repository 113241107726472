import BaseModel from './BaseModel';
import User from './User';
import Project from './Project';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a reminder. */
class Reminder extends BaseModel {
    _id: string;
    createdAt?: Moment;
    updatedAt?: Moment;
    offset: string;
    author?: User;
    project?: Project;
    change?: Change;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.createdAt = properties.createdAt ? moment(properties.createdAt) : undefined;
        this.updatedAt = properties.updatedAt ? moment(properties.updatedAt) : undefined;
        this.offset    = properties.offset;
        this.author    = properties.author?._id ? new User(properties.author) : properties.author;
        this.project   = properties.project?._id ? new Project(properties.project) : properties.project;
        this.change  = properties.change?._id ? new Change(properties.change) : properties.change;
    }

    static offsets = () => [
        'P1DT',
        'P2DT',
        'P3DT',
        'P7DT',
        'P14DT',
        'P1MT'
    ];
}

export default Reminder;
