import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import { Change } from '../../../models';
import { Alert, Button, Icon, Confirm } from '../..';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { toChangeStep, loadActiveTrack } from '../../../redux';

interface ProgressStepsProps {
    change: Change;
}

function ProgressSteps(props: ProgressStepsProps) {
    const { change } = props;

    const [showFinishConfirmModal, setShowFinishConfirmModal] = useState(false);

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { user }   = useAppSelector((state: RootState) => state.auth);
    const { toStep } = useAppSelector((state: RootState) => state.changes);

    const goToProgressStep = async (step: string) => {
        await dispatch(toChangeStep(change._id, step, /*params*/{}, (err: Error | null) => {
            if ([Change.STATUS_IN_PROGRESS, Change.STATUS_FINISHED].includes(step))
                dispatch(loadActiveTrack());
        }));
    }

    if (!change)
        return null;

    return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            {change.progress?.startedProgressAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.started_progress_at', {
                        date: change.progress.startedProgressAt.format('LL'),
                        time: change.progress.startedProgressAt.format('LT')
                    })}
                </label>
            </div>
            )}
            {change.progress?.startedTeamReviewAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.started_team_review_at', {
                        date: change.progress.startedTeamReviewAt.format('LL'),
                        time: change.progress.startedTeamReviewAt.format('LT')
                    })}
                </label>
            </div>
            )}
            {change.progress?.startedClientReviewAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.started_client_review_at', {
                        date: change.progress.startedClientReviewAt.format('LL'),
                        time: change.progress.startedClientReviewAt.format('LT')
                    })}
                </label>
            </div>
            )}
            {change.progress?.clientReviewAcceptedAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.client_review_accepted_at', {
                        date: change.progress.clientReviewAcceptedAt.format('LL'),
                        time: change.progress.clientReviewAcceptedAt.format('LT')
                    })}
                </label>
            </div>
            )}
            {change.progress?.deployedAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.deployed_at', {
                        date: change.progress.deployedAt.format('LL'),
                        time: change.progress.deployedAt.format('LT')
                    })}
                </label>
            </div>
            )}
            {change.progress?.finishedAt && (
            <div className="col-span-full flex flex-col gap-x1 border border-gray-100 bg-gray-50 p-2 py-1">
                <label className="block text-xs font-light leading-6 text-gray-900">
                    <Icon type="check" className="inline mr-1.5" />
                    {t('changes.finished_at', {
                        date: change.progress.finishedAt.format('LL'),
                        time: change.progress.finishedAt.format('LT')
                    })}
                </label>
            </div>
            )}

            {change.isPlannable() ? (
                <div className="col-span-full rounded p-2 bg-blue-100 border-blue-200 font-normal text-md text-center font-bold leading-6">
                    <Icon type="progress" className="inline mr-1" />
                    {t(`changes.progress_status_type.${change.progress?.status}`)}
                </div>
            ) : (
                <div className="col-span-full rounded p-2 bg-yellow-100 border-yellow-200 font-normal text-md text-center font-bold leading-6">
                    <Icon type="law" className="inline mr-1" />
                    {t(`quotes.status_type.${change.quote?.status || 'pending'}`)}
                </div>
            )}

            {change.isPlannable() && (
                <>
                    {(change.progress?.status === Change.STATUS_ASSIGNED) && (!change.progress.startedProgressAt) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            {(change.assignee?._id === user?._id) ? (
                                <Button
                                    icon={toStep === 'pending' ? 'loading' : 'check'}
                                    color="primary"
                                    onClick={() => goToProgressStep(Change.STATUS_IN_PROGRESS)}
                                    title={t('changes.start_progress')}
                                />
                            ) : (
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    <Alert title={t('changes.did_not_start_yet', {name: change.assignee?.firstname})} icon="info" color="info"/>
                                </label>
                            )}
                        </div>
                    )}
                    {(change.progress?.status === Change.STATUS_IN_PROGRESS) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            {(change.assignee?._id === user?._id) && change.isDev() && (
                                <Button
                                    icon={toStep === 'pending' ? 'loading' : 'check'}
                                    color="primary"
                                    onClick={() => goToProgressStep(Change.STATUS_TEAM_REVIEW)}
                                    title={t('changes.start_team_review')}
                                />
                            )}
                            {(change.assignee?._id === user?._id) && !change.isDev() && (
                                <>
                                    <Button
                                        icon={toStep === 'pending' ? 'loading' : 'check'}
                                        color="success"
                                        onClick={() => setShowFinishConfirmModal(true)}
                                        title={t('changes.finish')}
                                    />
                                    <Confirm
                                        visible={showFinishConfirmModal}
                                        title={t('changes.are_you_sure_to_finish') || ''}
                                        confirmText={t('changes.confirm_finish') || ''}
                                        onConfirm={() => goToProgressStep(Change.STATUS_FINISHED)}
                                        onCancel={() => setShowFinishConfirmModal(false)}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    {(change.progress?.status === Change.STATUS_TEAM_REVIEW) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            {(change.assignee?._id === user?._id) && (
                                <div className="flex justify-between w-full text-sm font-medium leading-6 text-gray-900">
                                    <Button
                                        icon={toStep === 'pending' ? 'loading' : 'close'}
                                        color="warning"
                                        onClick={() => goToProgressStep(Change.STATUS_IN_PROGRESS)}
                                        title={t('changes.team_review_refused')}
                                    />
                                    <Button
                                        icon={toStep === 'pending' ? 'loading' : 'right'}
                                        color="primary"
                                        onClick={() => goToProgressStep(Change.STATUS_CLIENT_REVIEW)}
                                        title={t('changes.start_client_review')}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {(change.progress?.status === Change.STATUS_CLIENT_REVIEW) && (!change.progress.clientReviewAcceptedAt) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            {(change.assignee?._id === user?._id) && (
                                <div className="flex justify-between w-full text-sm font-medium leading-6 text-gray-900">
                                    <Button
                                        icon={toStep === 'pending' ? 'loading' : 'close'}
                                        color="warning"
                                        onClick={() => goToProgressStep(Change.STATUS_IN_PROGRESS)}
                                        title={t('changes.client_review_refused')}
                                    />
                                    <Button
                                        icon={toStep === 'pending' ? 'loading' : 'check'}
                                        color="primary"
                                        onClick={() => goToProgressStep(Change.STATUS_PENDING_DEPLOYMENT)}
                                        title={t('changes.client_review_accepted')}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {!(change.estimate > 0) && (
                <div className="col-span-full">
                    <Alert title={t('changes.estimate_to_continue')} icon="warning" color="warning"/>
                </div>
            )}
        </div>
    );
}

export default ProgressSteps;
