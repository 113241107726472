import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, RootState, loadChanges } from '../../redux';
import {
    Loader, Ability, Icon,
    MyChanges, LastActivity, LastReleases,
    MySprint, MyOpenTasksInPreviousSprints,
    ValuesList, ChangesTeamReview, TriageChanges,
    ChangesList
} from '../../components';
import { loadUsers, getStatistics, useAppDispatch } from '../../redux';

import './Dashboard.css';
import { Change } from '../../models';

function Dashboard() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { user }       = useAppSelector((state: RootState) => state.auth);
    const { loading }    = useAppSelector((state: RootState) => state.changes);
    const { statistics } = useAppSelector((state: RootState) => state.statistics || {});

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(getStatistics(["changes"]));
        if (user) {
            dispatch(loadChanges({
                'assignee': user._id,
                'notfinished': true
            }));
        }
    }, []);

    const colsWidhts       = user?.can('change:start') ? [3, 1] : [0, 4];

    document.title = 'Yoda';

    return (
        <div className="Dashboard">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="home" size={7} className="mr-1.5 inline" />
                            { t('app.dashboard') }
                        </h2>
                    </div>
                </div>
            </header>
            <main>
                { statistics &&
                    <ValuesList data={statistics?.changes?.status} total={statistics.changes.total} i18nKey={"changes.progress_status_type."} className={"dark:bg-black rounded-lg rounded-t-none"} />
                }
                {loading === 'pending' && <Loader />}
                <div className={`${loading === 'pending' ? 'hidden' : ''} m-5 grid gap-y-3 xl:gap-3 grid-cols-1 2xl:grid-cols-4`}>
                    <Ability can="change:start">
                        <div className={`flex flex-col space-y-3 col-span-4 lg:col-span-${colsWidhts[0]}`}>
                            <MySprint />
                            <MyOpenTasksInPreviousSprints />
                            <MyChanges withHeader />
                        </div>
                    </Ability>
                    <div className={`flex flex-col space-y-3 col-span-4 lg:col-span-${colsWidhts[1]}`}>
                        <Ability can="change:support:read">
                            <TriageChanges />
                        </Ability>
                        <Ability can="change:read">
                            <ChangesTeamReview />
                        </Ability>
                        <Ability can="release:read">
                            <LastReleases withHeader />
                        </Ability>
                        <Ability can="log:read">
                            <LastActivity withHeader />
                        </Ability>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Dashboard;
