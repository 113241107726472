import React, { useState } from 'react';
import Dropzone, {
    IDropzoneProps,
    ILayoutProps,
} from "react-dropzone-uploader";
import { useAppDispatch } from "../../redux";
import { addedAttachment } from "../../redux";
import { Attachment } from '../../models';
import { AttachmentsList } from '../AttachmentsList';
import { Alert, Icon } from '..';
import { t } from 'i18next';

interface UploaderProps {
    projectId: string;
    isBugReport?: boolean;
    changeId?: string;
    showChangeSlug?: boolean;
    hideFiles?: boolean;
    onUpload?: (attachment: Attachment) => void;
}

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({ dropzoneProps, input }: ILayoutProps) => {
    dropzoneProps.className += ' my-2 text-center border-2 border-gray-300 border-dotted p-4 hover:bg-gray-100';

    return (
        <div {...dropzoneProps}>
            {input}
        </div>
    );
};

const Uploader = (props: UploaderProps) => {
    const { projectId, isBugReport, changeId, showChangeSlug, hideFiles, onUpload } = props;

    const [ fileStatus, setFileStatus ] = useState('');

    const dispatch = useAppDispatch();

    const getUploadParams: IDropzoneProps["getUploadParams"] = () => {
        let url;
        if (isBugReport) {
            url = Attachment.createBugReportAttachmentUrl(changeId ?? "");
        } else if (changeId) {
            url = Attachment.createChangeAttachmentUrl(changeId);
        } else {
            url = Attachment.createProjectAttachmentUrl(projectId);
        }

        return { url };
    };

    const handleStatus: IDropzoneProps['onChangeStatus'] = (meta: AnyObj) => {
        setFileStatus(meta.meta.status);
        if (meta.meta.status === 'done') {
            const response = JSON.parse(meta.xhr.response);
            const newAttachment = new Attachment(response.attachment);
            dispatch(addedAttachment(newAttachment));
            if (onUpload)
                onUpload(newAttachment);
        }
    };

    return (
        <>
            {fileStatus === "rejected_file_type" && (
                <Alert color='failure' title={t('attachments.unauthorized_file')} icon='warning'/>
            )}
            <div className="h-20 flex justify-center items-center">
                {(fileStatus && fileStatus !== 'done') && (
                    <Icon type="loading" size={8} />
                )}
                {(!fileStatus || fileStatus === 'done') && (
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleStatus}
                        LayoutComponent={Layout}
                        accept={Attachment.acceptedFiles()}
                        inputContent={t('attachments.drag_files')}
                        inputWithFilesContent={t('attachments.add_files')}
                        styles={{
                            input: { display: 'none' },
                            inputLabel: { cursor: 'pointer', margin: '10px auto auto auto' },
                            dropzone: { width: '100%', height: "4rem" }
                        }}
                    />
                )}
            </div>
            {!hideFiles && (
                <AttachmentsList projectId={projectId} changeId={changeId} showChangeSlug={showChangeSlug} />
            )}
        </>
    );
};

export default Uploader;
