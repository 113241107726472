import {Dispatch} from "redux";
import SearchApi from "../../services/Search";
import {Change, Project} from "../../models";

interface SearchState {
    results: {
        changes: Change[],
        projects: Project[],
    },
    query: string,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    searchError?: string;
}

const initialState: SearchState = {
    query: '',
    loading: 'idle',
    results: {
        changes: [],
        projects: []
    },
};

// Actions types
const SEARCH         = 'yoda/search/SEARCH';
const SEARCH_SUCCESS = 'yoda/search/SEARCH_SUCCESS';
const SEARCH_FAILURE = 'yoda/search/SEARCH_FAILURE';
const SEARCH_RESET   = 'yoda/search/SEARCH_RESET';


// Thunk action
export const search = (query: string, lang: string) => async (dispatch: Dispatch) => {
    dispatch({ type: SEARCH, query });
    try {
        const results = await SearchApi.search(query, lang);
        dispatch({ type: SEARCH_SUCCESS, results });
    } catch (error) {
        dispatch({ type: SEARCH_FAILURE, error: "Search failure" });
    }
};
export const resetSearch = () => (dispatch: Dispatch) => dispatch({ type: SEARCH_RESET });

// Reducer
const searchReducer = (state: SearchState = initialState, action: any): SearchState => {
    switch (action.type) {
        case SEARCH:
            return {
                ...state,
                query: action.query,
                loading: 'pending',
                searchError: undefined
            };
        case SEARCH_SUCCESS:
            return { ...state, loading: 'succeeded', results: action.results };
        case SEARCH_FAILURE:
            return { ...state, loading: 'failed', searchError: action.error };
        case SEARCH_RESET:
            return { ...initialState };
        default:
            return state;
    }
};

export default searchReducer;
