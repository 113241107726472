import React from 'react';
import { Tooltip } from '..';
import { User } from '../../models';

interface UserAvatarProps {
    user: User;
    onClick?: () => void;
    selected?: boolean;
    stacked?: boolean;
    hideInitials?: boolean;
    noTooltip?: boolean;
    noCache?: boolean;
    size?: number;
    className?: string;
    children?: JSX.Element | JSX.Element[];
};

const UserAvatar = (props: UserAvatarProps) => {
    const {
        user, onClick, selected, noCache,
        hideInitials, noTooltip, size,
        className, children
    } = props;

    const content = (
        <div
            onClick={onClick}
            className={`overflow-hidden relative cursor-pointer w-${size  || 8} h-${size || 8} select-none text-center rounded-full text-xs ring ring-offset-0 flex items-center justify-center font-bold ${selected ? 'ring-4' : 'hover:bg-blue-100 hover:ring-2 hover:ring-blue-100 ring-1 ring-gray-300'} ${className}`}
        >
            <div className="flex justify-center items-center space-x-4 w-full h-full">
                <img alt="" src={user.getAvatarUrl(80, noCache)} className="!rounded-full rounded w-full h-full0 rounded" />
                {children}
            </div>
            {!hideInitials && (
                <>
                    <span className="w-full h-full bg-gray-900 absolute opacity-30"></span>
                    <span className="absolute text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                        {user.getInitials()}
                    </span>
                </>
            )}
        </div>
    );

    if (noTooltip)
        return content;

    return (
        <Tooltip key={user.fullname()} content={user.fullname()}>
            {content}
        </Tooltip>
    );
};

export default UserAvatar;
