import RestClient from './RestClient';
import { Log } from '../models';

class Logs extends RestClient {
    constructor() {
        super({
            // rest config
            model: Log,
            entryPoint: 'logs',
            //sortBy: 'date',
            id_field: '_id',
            // redux config
            resource: 'log',
            resources: 'logs',
        });
    }
}

const LogsApi = new Logs();
export default LogsApi;
