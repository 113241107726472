import BaseModel from './BaseModel';
import { Moment } from 'moment';
import Project from './Project';
import Change from './Change';
import Quote from './Quote';
import Commit from './Commit';
import Release from './Release';
import User from './User';
import moment from 'moment';
import { t } from 'i18next';
import { i18n } from '../locales';

/** class representing a log */
class Log extends BaseModel {

    static TYPE_CHANGE_CREATE                 = 'change:create';
    static TYPE_CHANGE_PATCH                  = 'change:patch';
    static TYPE_CHANGE_ESTIMATED              = 'change:estimated';
    static TYPE_CHANGE_UNESTIMATED            = 'change:unestimated';
    static TYPE_CHANGE_ASSIGNED               = 'change:assigned';
    static TYPE_CHANGE_UNASSIGNED             = 'change:unassigned';
    static TYPE_CHANGE_PLANNED                = 'change:planned';
    static TYPE_CHANGE_UNPLANNED              = 'change:unplanned';
    static TYPE_CHANGE_STARTED                = 'change:started';
    static TYPE_CHANGE_TEAM_REVIEW_STARTED    = 'change:review:started';
    static TYPE_CHANGE_TEAM_REVIEW_FINISHED   = 'change:review:finished';
    static TYPE_CHANGE_CLIENT_REVIEW_STARTED  = 'change:tests:started';
    static TYPE_CHANGE_CLIENT_REVIEW_FINISHED = 'change:tests:finished';
    static TYPE_CHANGE_DEPLOYED               = 'change:deployed';
    static TYPE_CHANGE_SPRINT_ADDED           = 'change:sprint:added';
    static TYPE_CHANGE_SPRINT_REMOVED         = 'change:sprint:removed';
    static TYPE_QUOTE_CREATED                 = 'quote:created';
    static TYPE_QUOTE_SUBMITTED               = 'quote:submitted';
    static TYPE_QUOTE_ACCEPTED                = 'quote:accepted';
    static TYPE_QUOTE_REFUSED                 = 'quote:refused';
    static TYPE_QUOTE_ADVANCE_BILLED          = 'quote:advance:billed';
    static TYPE_QUOTE_ADVANCE_PAID            = 'quote:advance:paid';
    static TYPE_QUOTE_BILLED                  = 'quote:billed';
    static TYPE_QUOTE_PAID                    = 'quote:paid';
    static TYPE_COMMENT_CREATE                = 'comment:create';
    static TYPE_COMMENT_UPDATE                = 'comment:update';

    _id: string;
    author: User;
    type: string;
    createdAt: Moment;
    updateAt: Moment;
    context: AnyObj;
    project?: Project;
    change?: Change;
    quote?: Quote;
    commit?: Commit;
    release?: Release;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.type      = properties.type;
        this.author    = new User(properties.author || {});
        this.createdAt = moment(properties.createdAt);
        this.updateAt  = moment(properties.updateAt);
        this.context   = properties.context;

        if (properties.project)
            this.project = new Project(properties.project);
        if (properties.change)
            this.change = new Change(properties.change);
        if (properties.quote)
            this.quote = new Quote(properties.quote);
        if (properties.commit)
            this.commit = new Commit(properties.commit);
        if (properties.release)
            this.release = new Release(properties.release);
    }

    describe(fromOutside?: boolean) {
        const key = fromOutside ? 'logs.outside' : 'logs';

        const params: AnyObj = {
            card: this.change?.localizedTitle(i18n.language),
            quote: this.quote?.slug,
            firstname: this.author?.firstname,
            field: t(`changes.${this.context?.field}`),
            value: this.context?.value,
            sprint: this.context?.sprint
        };
        params.link = this.quote ?
            `/quotes/${this.quote?._id}` :
            `/projects/${this.project?._id}/change/${this.change?._id}`;

        if (this.type === Log.TYPE_CHANGE_PATCH) {
            switch (this.context?.field) {
                case 'type':
                    return t(`${key}.patch_title_change`, { ...params, value: t(`projects.${this.context.value}`) });
                case 'title.en':
                case 'title.de':
                case 'title.fr':
                    if (this.context.field.match(/\.(fr|en|de)$/))
                        params.field = this.context.field.replace(/\.(fr|en|de)$/, '');
                    return t(`${key}.patch_title_change`, params);
                case 'importance':
                    return t(`${key}.patch_importance`, params);
                case 'developer_notes':
                    return t(`${key}.patch_developer_notes`, params);
                case 'description.en':
                case 'description.de':
                case 'description.fr':
                    if (this.context.field.match(/\.(fr|en|de)$/))
                        params.field = this.context.field.replace(/\.(fr|en|de)$/, '');
                    return t(`${key}.patch_description_change`, params);
                case 'needsAdvance':
                    if (this.context.value)
                        return t(`${key}.needsAdvance_change`, params);
                    return t(`${key}.unNeedsAdvance_change`, params);
                case 'billable':
                    if (this.context.value)
                        return t(`${key}.billable_change`, params);
                    return t(`${key}.unbillable_change`, params);
                case 'budget.status':
                    return t(`${key}.budget_status_changed`, {
                        ...params,
                        value: t(`changes.budget_status_type.${params.value}`)
                    });
                case 'budget.quotedAt':
                case 'budget.quoteAcceptedAt':
                case 'budget.quoteRefusedAt':
                case 'budget.advanceBilledAt':
                case 'budget.advancePaidAt':
                case 'budget.billedAt':
                case 'budget.paidAt':
                    return t(`${key}.changed_${this.context.field}`, {
                        ...params,
                        value: moment(params.value).format('LL')
                    });
                default:
                    return t(`${key}.patch_change`, params);
            }
        }

        switch (this.type) {
            case Log.TYPE_CHANGE_CREATE:
                return t(`${key}.created_change`, params);
            case Log.TYPE_CHANGE_ESTIMATED:
                return t(`${key}.estimated_change`, { ...params, value: this.context.estimate });
            case Log.TYPE_CHANGE_UNESTIMATED:
                return t(`${key}.unEstimated_change`, params);
            case Log.TYPE_CHANGE_PLANNED:
                return t(`${key}.planned_change`, { ...params, deadline: moment(this.context.deadline).format('ll') });
            case Log.TYPE_CHANGE_UNPLANNED:
                return t(`${key}.unplanned_change`, params);
            case Log.TYPE_CHANGE_ASSIGNED:
                if (this.context.assignee._id !== this.author._id)
                    return t(`${key}.assigned_change`, { ...params, assignee: this.context.assignee.firstname });
                return t(`${key}.took_change`, params);
            case Log.TYPE_CHANGE_UNASSIGNED:
                if (this.context.assignee._id !== this.author._id)
                    return t(`${key}.unassigned_change`, { ...params, assignee: this.context.assignee.firstname });
                return t(`${key}.got_rid_of_change`, params);
            case Log.TYPE_CHANGE_STARTED:
                return t(`${key}.started_change`, params);
            case Log.TYPE_CHANGE_TEAM_REVIEW_STARTED:
                return t(`${key}.started_team_review`, { ...params, ...this.context });
            case Log.TYPE_CHANGE_TEAM_REVIEW_FINISHED:
                return t(`${key}.finished_team_review`, { ...params, ...this.context });
            case Log.TYPE_CHANGE_CLIENT_REVIEW_STARTED:
                return t(`${key}.started_client_review`, params);
            case Log.TYPE_CHANGE_CLIENT_REVIEW_FINISHED:
                return t(`${key}.finished_client_review`, params);
            case Log.TYPE_CHANGE_DEPLOYED:
                return t(`${key}.deployed_change`, params);
            case Log.TYPE_CHANGE_SPRINT_ADDED:
                return t(`${key}.sprint_added`, params);
            case Log.TYPE_CHANGE_SPRINT_REMOVED:
                return t(`${key}.sprint_removed`, params);

            case Log.TYPE_QUOTE_CREATED:
                return t(`${key}.quote_created`, params);
            case Log.TYPE_QUOTE_ACCEPTED:
                return t(`${key}.quote_accepted`, params);
            case Log.TYPE_QUOTE_REFUSED:
                return t(`${key}.quote_refused`, params);
            case Log.TYPE_QUOTE_ADVANCE_BILLED:
                return t(`${key}.advance_billed`, params);
            case Log.TYPE_QUOTE_ADVANCE_PAID:
                return t(`${key}.advance_paid`, params);
            case Log.TYPE_QUOTE_BILLED:
                return t(`${key}.billed`, params);
            case Log.TYPE_QUOTE_PAID:
                return t(`${key}.paid`, params);
            case Log.TYPE_COMMENT_CREATE:
                return t(`${key}.comment_created`, params);
            case Log.TYPE_COMMENT_UPDATE:
                return t(`${key}.comment_updated`, params);
            default:
                return "";
        }
    }
}

export default Log;
