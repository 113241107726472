import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { startCreateProject, createProject } from '../../../redux';
import { Ability, Button } from '../../../components';
import ProjectForm from '../ProjectForm/ProjectForm';

function ProjectCreate() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { project, created } = useAppSelector((state: RootState) => state.projects);

    useEffect(() => {
        dispatch(startCreateProject());
    }, [dispatch]);

    const submit = function (values: AnyObj) : void {
        dispatch(createProject(values));
    }

    if (created)
        navigate(`/projects/edit/${project._id}`);

    return (
        <Ability can="project:create" redirect="/projects">
            <div className="ProjectCreate">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-black-900">
                                { t('projects.add_a_project') }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/projects" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                <main className="mx-6 my-6 shadow">
                    {project && (!project._id) && (
                        <ProjectForm isCreation={true} initialValues={project} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default ProjectCreate;
