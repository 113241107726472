import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../..'
import { User } from '../../../models'
import type { BoardFilters } from '../../../models'
import { UserAvatar } from '../../';
import { TextInput } from 'flowbite-react';

interface FiltersProps {
    currentUser: User;
    users: User[];
    onChange: (filters: BoardFilters) => void;
    initialValues: BoardFilters;
}

function Filters(props: FiltersProps) {
    const { currentUser, users, onChange } = props;
    const [filters, setFilters] = React.useState<BoardFilters>(props.initialValues);

    const { t } = useTranslation();

    useEffect(() => {
        onChange(filters);
    }, [filters]);

    const resetSearch = () => {
        setFilters({
            ...filters,
            search: ''
        } as BoardFilters);
    };

    const onSearchChange = (e: AnyObj) => {
        setFilters({
            ...filters,
            search: e.target.value
        } as BoardFilters);
    }

    const onUserChange = (userId?: string) => {
        setFilters({
            ...filters,
            user: users.find(u => u._id === userId)
        } as BoardFilters);
    }

    const handleKeyPress = (e: AnyObj) => {
        if ((e.key === 'Escape' || e.code === 'Escape'))
            resetSearch();
    }

    const sorted = React.useMemo(() => {
        return users?.sort((a: User, b: User) => {
            return a.fullname() > b.fullname() ? 1 : -1;
        });
    }, [users]);

    const avatar = (user: User) => {
        const active = filters?.user?._id === user?._id;
        return (
            <UserAvatar
                key={user.email}
                size={6}
                user={user}
                onClick={() => onUserChange(active ? undefined : user._id)}
                selected={active}
            />
        );
    }

    return (
        <div className="Filters relative">
            <TextInput
                sizing="sm"
                icon={() => <Icon type="search" />}
                onChange={onSearchChange}
                value={filters.search}
                placeholder={t('changes.search_placeholder') || ''}
                type="text"
                onKeyUp={handleKeyPress}
                className={`w-fit ${users?.length > 0 ? ' rounded-r-none border-r-0' : ''}`}
                addon={users?.length > 0 ? (
                    <div className="flex justify-start space-x-2">
                        {users?.filter(u => u._id === currentUser?._id).map(user => avatar(user))}
                        {sorted?.filter(u => u._id !== currentUser?._id).map(user => avatar(user))}
                    </div>
                ) : null}
            />
            {filters.search && (
                <span className="absolute right-2 top-1 text-bold cursor-pointer" onClick={resetSearch}>
                    <Icon type="close" color="black" size={5} />
                </span>
            )}
        </div>
    );
}

export default Filters;
