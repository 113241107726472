import React from 'react';
import { useTranslation } from "react-i18next";
import { TextInput } from "flowbite-react";
import { Icon } from "../Icon";
import { useAppSelector } from '../../redux';

interface SearchComponentProps {
    query: string;
    onQueryChange: (query: string) => void;
}

const SearchBar: React.FC<SearchComponentProps> = ({ query, onQueryChange }) => {
    const { t }   = useTranslation();
    const loading = useAppSelector(state => state.search.loading);

    const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.select();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onQueryChange(event.target.value);
    };

    return (
        <TextInput
            type="text"
            value={query}
            rightIcon={() => <Icon type={loading === 'pending' ? 'loading' : 'search'} />}
            placeholder={t("search.search_input") || ""}
            autoFocus
            onFocus={onFocus}
            onChange={handleChange}
        />
    );
};

export default SearchBar;
