import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadUser, deleteUser } from '../../../redux';
import { Icon, Button } from '../../../components';

function UserDelete() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let { id }     = useParams();

    const { user, deleting, deleted } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        if (id)
            dispatch(loadUser(id));
    }, [dispatch, id]);

    const doDelete = () => {
        dispatch(deleteUser(user._id));
    };

    if (deleted)
        navigate('/users');

    return (
        <div className="UserDelete">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="user" size={7} />&nbsp;
                            { t('users.delete_user', {name: user?.fullname()}) }
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button title={t('common.back')} color="navigateBack" to="/users" icon="back" />
                        </span>
                    </div>
                </div>
            </header>
            <main className="mx-6 my-6">
                {user && (user._id === id) && (
                <div className="flex">
                    <div className="w-fit mx-auto">
                        <h1 className="mx-auto font-bold p-2 mb-2">
                            { t('users.sure_to_delete_user', {name: user?.fullname()}) }
                        </h1>
                        <div className="mt-5 flex justify-evenly">
                            <Button title={t('common.cancel')} color="navigateBack" to="/users" icon="back" />
                            <Button
                                onClick={doDelete}
                                title={t('common.delete')}
                                icon="delete"
                                loading={deleting === 'pending'}
                                color="danger"
                            />
                        </div>
                    </div>
                </div>
                )}
            </main>
        </div>
    );
}

export default UserDelete;
