import { Dispatch } from 'redux';
import { Routine } from '../../models';
import { Routines } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Callback } from './rest';

const {
    initialState, resetReducer,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    resetAction, createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
} = createRestSlices(Routines);

const CHANGE_ROUTINE = 'yoda/routines/CHANGE_ROUTINE';
const addActionReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case CHANGE_ROUTINE:
            return {
                ...state,
                routine: { ...state.routine }
            };
        default:
            return state;
    }
};
/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        resetReducer,
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        addActionReducer
    ]);
}

const changeRoutineAction = (routine: Routine, callback?: Callback) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CHANGE_ROUTINE, routine });
        callback && callback(/*err*/null);
    }
}

/* Export CRUD actions */
export const resetRoutine       = resetAction;
export const createRoutine      = createAction;
export const startCreateRoutine = startCreateAction;
export const loadRoutine        = getAction;
export const loadRoutines       = listAction;
export const updateRoutine      = updateAction;
export const deleteRoutine      = deleteAction;
export const changeRoutine      = changeRoutineAction;
