import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";

function Projects() {
    const { t } = useTranslation();

    document.title = `Yoda - ${t('app.projects')}`;

    return (
        <div className="Project">
            <Outlet />
        </div>
    );
}

export default Projects;
