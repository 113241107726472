import React, { useState, useEffect } from 'react';
import { Field, useField } from 'formik';
import { Icon } from '..';
import { t } from 'i18next';

interface TranslatableInputProps {
    name: string;
    value: AnyObj;
    langs?: string[];
    label?: JSX.Element;
    placeholder?: string;
    required?: boolean;
    readOnly?: boolean;
    hideEditLink?: boolean;
    inline?: boolean;
    className?: string;
    onChange?: (value: AnyObj) => void;
    children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

const TranslatableInput = (props: TranslatableInputProps) => {
    const {
        name, value, langs, label, hideEditLink, inline,
        placeholder, required, onChange, className, readOnly
    } = props;

    const [ isEditing, setIsEditing ] = useState(false);

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    // following should work but for now it returns only ['fr']...
    const languages = langs?.length ? langs : ['fr', 'en', 'de'];
    const [selectedLang, setSelectedLang] = useState(languages[0]);

    const select_lang = (lang: string) => {
        setSelectedLang(lang);
    }

    const close = () => {
        setIsEditing(false);
    }

    const handleChange = (lang: string, newValue: string) => {
        onChange && onChange({
            ...value,
            [lang]: newValue
        });
    }

    const handleKeyPress = (e: AnyObj) => {
        e.stopPropagation();
        if (e.key === 'Escape' || e.code === 'Escape') {
            return close();
        }
    }

    if (readOnly || (!isEditing && !inline))
        return (
            <div className="TranslatableInput">
                <div className="mb-1 flex justify-between">
                    {label}
                    {!readOnly && value?.[languages[0]] && !hideEditLink && (
                        <div className="text-xs cursor-pointer font-bold border-none text-gray-500" onClick={() => setIsEditing(true)}>
                            {t('common.edit')}
                        </div>
                    )}
                </div>
                <div onClick={() => !readOnly && setIsEditing(true)} className="cursor-pointer">
                    {value ? (
                        <div className={`text-gray-700 rounded leading-6 pt-0.5 p-1.5 ${className}`}>
                            <div dangerouslySetInnerHTML={{ __html: value[languages[0]] }} />
                        </div>
                    ) : (
                        <div className="hover:bg-gray-200 text-gray-500 italic rounded text-sm">
                            <div>{t('changes.title')}</div>
                        </div>
                    )}
                </div>
            </div>
        );

    return (
        <div className="TranslatableInput">
            <div>
                {label}
            </div>
            <div className="flex">
                <div className="flex-1">
                    {languages.length && languages.map((l, i) => (
                        <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                            <input
                                type="text"
                                id={`name.${l}`}
                                name={`${name}.${l}`}
                                value={value && (l in value) ? value[l] : ''}
                                autoFocus
                                onKeyUp={handleKeyPress}
                                onChange={(e: AnyObj) => handleChange(l, e.target.value)}
                                className={`block w-full rounded-md rounded-r-0 border-0 focus:border-0 ${languages.length > 1 ? 'rounded-r-none border-r-0' : ''} p-1.5 bg-gray-100 text-gray-700 placeholder:text-gray-400 sm:text-xl sm:leading-6 focus:ring-transparent ${className || ''}`}
                                required={!!required}
                                placeholder={placeholder}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex-none">
                    {languages.length > 1 && (
                        <div className="flex h-full border border-gray-300 rounded rounded-l-none overflow-hidden">
                            <span className={`cursor-pointer font-light flex items-center text-sm px-2 bg-gray-50"}`}>
                                <Icon type="globe" />
                            </span>
                            {languages.map((l) => (
                                <span key={l} onClick={(e) => { select_lang(l); return false; }} className={`cursor-pointer font-light flex items-center text-sm px-2 ${selectedLang === l ? "bg-blue-100" : "bg-gray-50"}`}>
                                    {l.toUpperCase()}
                                </span>
                            ))}
                            {!inline && (
                                <span onClick={close} className={"cursor-pointer font-light flex items-center text-sm px-2 bg-white"}>
                                    <Icon type="close" color="red-600"/>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TranslatableInput;
