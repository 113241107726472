import React from 'react';
import { Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../models';
import { Alert, Icon } from '../../components';
import { Button } from '..';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { syncProject } from '../../redux';
import { useState } from 'react';

interface ProjectResumeProps {
    project: Project,
    minimal?: boolean
}

function ProjectResume(props: ProjectResumeProps) {
    const { t }       = useTranslation();
    const dispatch    = useAppDispatch();
    const { project, minimal } = props;
    const [forceResetDisplay, setForceResetDisplay] = useState(false);
    const [bottomRadius, setBottomRadius] = useState(false);

    const openFullSynchronisation = () => {
        setBottomRadius(!bottomRadius);
        setForceResetDisplay(!forceResetDisplay);
    }


    const { sync, syncProjectId, syncError } = useAppSelector((state: RootState) => state.projects);

    const doSync = (id: string, forceRefresh: boolean) => {
        if (id)
            dispatch(syncProject(id, forceRefresh));
    };

    return (
        <Card className="ProjectResume h-full bg-pink-200">
            <div className="flex flex-row justify-between gap-x-3 h-full">
                <div className="flex-1 flex">
                    <h2 className="flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        {!minimal && <Icon type="project" size={7} className="mr-1.5 inline"/>}
                        <a href={`/projects/${project._id}`}>
                            {minimal ? t('projects.resume') : project?.name }
                        </a>
                    </h2>
                </div>
                <div className="w-fit flex items-end">
                <span className="relative sm:block">
                    <Button
                        onClick={() => doSync(project._id, /*forceRefresh*/false)}
                        title={t('projects.sync')}
                        icon="sync"
                        loading={(syncProjectId === project._id) && (sync === 'pending')}
                        color="primary"
                        className={`rounded-e-none ${bottomRadius ? "rounded-b-none" : ""}`}
                    />
                    <Button
                        className={`h-9 rounded-s-none border-l ${bottomRadius ? "rounded-b-none" : ""}`}
                        color='primary'
                        title=''
                        icon="down"
                        onClick={() => openFullSynchronisation()}
                    />
                    <Button
                        onClick={() => doSync(project._id, /*forceRefresh*/true)}
                        title={t('projects.sync_full')}
                        icon="sync"
                        loading={(syncProjectId === project._id) && (sync === 'pending')}
                        color="default"
                        className={`border-t rounded-t-none absolute top-8 md:right-0 left-0 ${forceResetDisplay ? 'flex' : 'hidden'}`}
                    />
                </span>
                </div>
            </div>
            <div className="min-w-0 flex-1">
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <Icon type="branch" />&nbsp;
                        { project?.repository.branch }
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <Icon type="commit" />&nbsp;
                        <b>{ project.nbCommits }</b>&nbsp;{ t('projects.commits') }
                    </div>
                    <div className="mt-1 flex items-center text-sm text-gray-500">
                        <Icon type="release" />&nbsp;
                        <b>{ project.nbReleases }</b>&nbsp;{ t('projects.releases') }
                    </div>
                </div>
                <div>
                    {project?.lastRelease && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                        <Icon type="date" />&nbsp;
                        {t('projects.last_release', {date: project.lastRelease.date.format('LL')})}
                    </div>
                    )}
                </div>
            </div>
            {(syncProjectId === project._id) && (sync !== 'idle') && (
            <div className="flex-1">
                {(syncProjectId === project._id) && (sync === 'pending') && (
                    <Alert title={t('projects.syncing')} color="info" icon="loading" />
                )}
                {(syncProjectId === project._id) && syncError && (
                    <Alert title={t('projects.sync_failed') + ' : ' + syncError} color="failure" icon="warning" />
                )}
                {(syncProjectId === project._id) && (sync === 'succeeded') && (
                    <Alert title={t('projects.sync_succeeded')} color="success" icon="check" />
                )}
            </div>
            )}
        </Card>
    );
}

export default ProjectResume;
