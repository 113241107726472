import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, UserAvatar, ChangeTrack, Tooltip } from '../../../components';
import { Board, Change } from '../../../models';
import type { ColumnData } from '../../../models';
import { useAppSelector } from '../../../redux';
import { Table, Badge, Progress } from 'flowbite-react';
import moment from 'moment';

interface ListProps {
    board: Board;
    onEditCard: (change: Change) => void;
}

const List = (props: ListProps) => {
    const { t, i18n }           = useTranslation();
    const { board, onEditCard } = props;

    const { user }   = useAppSelector(state => state.auth);
    const { active } = useAppSelector(state => state.tracks);

    let projectsMap: Record<any, boolean> = {};
    board.columns.forEach((column: ColumnData) => column.changes.forEach((change: Change) => {
        projectsMap[change.project._id] = true;
    }));
    const projectsIds = Object.keys(projectsMap);

    const changeRow = React.useMemo(() => (change: Change, column: ColumnData) => {
        const progression = Math.round(change.progression());

        return (
            <Table.Row key={change._id} className="cursor-pointer border-t" onClick={() => onEditCard(change)}>
                <Table.Cell>
                    <div className="flex items-start space-x-2">
                        <div className="flex-col space-y-1">
                            <h4 className="font-medium heading-md text-gray-900">
                                {change.title.fr || ""}
                            </h4>
                            <div className="flex space-x-2">
                                <Badge color={change.color()} className="w-fit text-xs px-1">
                                    <Icon type={change.iconName()} size={3} className="inline" />&nbsp;
                                    {change.slugName()}
                                </Badge>
                                {(change.nbComments > 0) && (
                                    <Badge color="indigo" className="w-fit text-xs px-1">
                                        <Icon type="comment" className="inline" />&nbsp;
                                        {change.nbComments}
                                    </Badge>
                                )}
                                {change.nbAttachments > 0 && (
                                    <Badge color="gray" className="w-fit text-xs px-1">
                                        <Icon type="file" className="inline" />&nbsp;
                                        {change.nbAttachments}
                                    </Badge>
                                )}
                                {change.developer_notes && (
                                    <Badge color="indigo" className="w-fit text-xs px-1">
                                        <Icon type="notes" className="inline" />
                                    </Badge>
                                )}
                                {change.quote && (
                                    <Badge color="blue" className="w-fit text-xs px-1">
                                        <Icon type="law" className="inline" />&nbsp;
                                        {change.quote.slug} | {t(`quotes.status_type.${change.quote.status}`)}
                                    </Badge>
                                )}
                                {change.release && (
                                    <Badge color="indigo" size="sm" className="flex-1 w-fit flex space-x-3 justify-center px-1">
                                        <Icon type="release" color="indigo" className="inline mr-1.5 -ml-2"/>
                                        {change.release.version}
                                    </Badge>
                                )}
                                {(change.commits?.length > 0) && (
                                    <div className="flex items-center space-x-1">
                                        {change.commits?.map(commit => (
                                        <Badge key={commit.hash} color="success" className="w-fit px-1">
                                            <Icon type="commit" size={3} className="inline"/>&nbsp;
                                            {commit.message}
                                        </Badge>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Table.Cell>
                {projectsIds.length > 1 && (
                    <Table.Cell>
                        <span className="font-medium">{change.project?.name}</span>
                    </Table.Cell>
                )}
                <Table.Cell>
                    <span className="font-medium">{column.title}</span>
                </Table.Cell>
                <Table.Cell>
                    {change.assignee && !change.isTimeTrackable(user) && (
                        <UserAvatar size={6} user={change.assignee} noTooltip className="my-1" />
                    )}
                    {change.assignee && change.isTimeTrackable(user) && (
                        <div className="group">
                            <div className="h-6 w-6 flex justify-center items-center">
                                {active?.change?._id === change._id ? (
                                    <ChangeTrack className="group-hover:hidden" change={change} />
                                ) : (
                                    <UserAvatar size={6} user={change.assignee} noTooltip className="group-hover:hidden my-1"/>
                                )}
                                <ChangeTrack className="hidden group-hover:block" change={change} />
                            </div>
                        </div>
                    )}
                    {!change.assignee && (
                        <div className="h-6 w-6"></div>
                    )}
                </Table.Cell>
                <Table.Cell>
                    <div className="flex-col space-y-1">
                        <div className="text-xs font-light border border-gray-100 rounded px-2 whitespace-nowrap flex flex-row justify-between">
                            <span className={`whitespace-nowrap text-ellipsis overflow-hidden truncate inline-block ${change.estimate > 0 ? 'max-w-[9rem]' : ''}`}>
                                <Icon type="progress" size={3} className="inline mr-1"/>&nbsp;
                                {t(`changes.progress_status_type.${change?.progress?.status}`)}
                            </span>
                        </div>
                        <Tooltip content={progression + '%'} placement="top">
                            <Progress
                                progress={progression}
                                size="sm"
                                color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                            />
                        </Tooltip>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    { change.estimate > 0 && (
                        <Badge color="warning" className="w-fit whitespace-nowrap px-1">
                            <>
                                <Icon type="estimated" size={3} className="inline mr-1"/>&nbsp;
                                {change?.estimate} {t('changes.estimate_units_min')}
                            </>
                        </Badge>
                    )}
                </Table.Cell>
                <Table.Cell>
                    <div className="flex-col space-y-1">
                        {change.deadline && (
                            <Badge color={change.deadline.isBefore(moment().startOf('day')) ? 'pink' : (change.deadline.isAfter(moment()) ? 'green' : 'indigo')} size="xs" className="w-fit">
                                <span className="font-normal">
                                    {change.deadline.locale(i18n.language).format('L')}
                                </span>
                            </Badge>
                        )}
                        {(change.sprints?.length > 0) && (
                            <Badge color="warning" size="xs" className="w-fit px-1">
                                <span className="text-xs font-normal">
                                    <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                    {change.sprints.slice(-1)[0].name}
                                </span>
                            </Badge>
                        )}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {t(`changes.importance_value_${change.importance}`)}
                </Table.Cell>
                <Table.Cell>
                    <Badge color="indigo" className="w-fit" size="xs">
                        <Icon type="priority" className="inline mr-1" />
                        {change.priority}
                    </Badge>
                </Table.Cell>
            </Table.Row>
        );
    }, []);

    return (
        <main className="overflow-x-auto no-scrollbar h-full w-full">
            <Table striped hoverable className="w-[calc(100vw - 2rem)] border rounded">
                <Table.Head>
                    <Table.HeadCell>{t('changes.change')}</Table.HeadCell>
                    {projectsIds.length > 1 && (
                        <Table.HeadCell>{t('projects.project')}</Table.HeadCell>
                    )}
                    <Table.HeadCell>{t('changes.column')}</Table.HeadCell>
                    <Table.HeadCell></Table.HeadCell>
                    <Table.HeadCell>{t('changes.status')}</Table.HeadCell>
                    <Table.HeadCell>{t('changes.estimate')}</Table.HeadCell>
                    <Table.HeadCell>{t('changes.deadline')}</Table.HeadCell>
                    <Table.HeadCell>{t('changes.importance')}</Table.HeadCell>
                    <Table.HeadCell>{t('changes.priority')}</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {board.columns.map((column: ColumnData, i: number) => column.changes.map((change: Change, j: number) => changeRow(change, column)))}
                </Table.Body>
            </Table>
        </main>
    );
};

export default List;
