import { Goals } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import { Goal, Change } from '../../models';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Goals);

/**
 * We have to add this hook to handle remote modifications on changes
 */
const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'goal.updated':
            if (!state.goal || state.goal._id !== data._id)
                return state;

            return {
                ...state,
                goal: new Goal({
                    ...state.goal,
                    ...data,
                })
            };
        case 'change.updated':
            if (!state.goal)
                return state;

            // let's replace the original change in the goal
            // with the updated one
            const changes = state.goal.changes.map((original: Change) => {
                return original._id === data._id ? new Change(data) : original;
            });
            return {
                ...state,
                goal: new Goal({
                    ...state.goal,
                    changes
                })
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle modifications on changes
 */
const TO_STEP_SUCCESS         = 'yoda/change/TO_STEP_SUCCESS';
const UPDATING_CHANGE_SUCCESS = 'yoda/change/UPDATING_ITEM_SUCCESS';
const updateChangeReducer = (state = initialState, action: ActionPayload) => {
    if (!state.goal)
        return state;

    switch (action.type) {
        case TO_STEP_SUCCESS:
        case UPDATING_CHANGE_SUCCESS:
            const { change } = action;
            const { goal } = state;

            const newState = { ...state };

            // let's remove this change from the goal
            if (goal) {
                const inGoal = change.goals.reduce((acc: boolean, g: Goal) => acc || g._id === goal._id, false);

                goal.changes = goal.changes.filter((original: Change) => {
                    return original._id !== change._id;
                });
                if (inGoal)
                    // let's add/replace the original change in the goal
                    // with the updated one
                    goal.changes.push(change);

                newState.goal = new Goal({ ...goal })
            }

            return newState;
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer,
        updateChangeReducer, onRemoteUpdateReducer,
    ]);
}

/* Export CRUD actions */
export const createGoal        = createAction;
export const startCreateGoal   = startCreateAction;
export const loadGoal          = getAction;
export const loadGoals         = listAction;
export const updateGoal        = updateAction;
export const deleteGoal        = deleteAction;
export const duplicateGoal     = duplicateAction;
