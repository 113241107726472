import BaseModel from './BaseModel';
import Change from './Change';
import Client from './Client';
import Project from './Project';
import User from './User';
import moment from 'moment';
import type { Moment } from 'moment';
import _ from 'lodash';

/** Class representing a time track entry. */
class Track extends BaseModel {
    /**
     * Track tags constants
     */
    static TAG_FREE    = 'free';
    static TAG_DEV     = 'dev';
    static TAG_PACKAGE = 'package';
    static TAG_247     = '24/7';

    _id: string;
    author?: User;
    project: Project;
    client?: Client;
    change?: Change;
    title: string;
    tags?: string[];
    duration?: {
        iso: string;
        seconds: number;
        hours: number;
        minutes: number;
    };
    startAt: Moment;
    stopAt?: Moment;
    createdAt?: Moment;
    updatedAt?: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id      = properties._id;
        this.title    = properties.title;
        this.tags     = properties.tags || [];
        this.duration = properties.duration || {
            iso: 'PT0',
            seconds: 0,
            minutes: 0,
            hours: 0
        };
        this.project  = properties.project;
        if (_.isObject(this.project))
            this.project = new Project(this.project);

        if (properties.author)
            this.author  = new User(properties.author);
        if (properties.client)
            this.client = new Client(properties.client);
        if (properties.change)
            this.change = new Change(properties.change);

        this.startAt = moment(properties.startAt);
        if (properties.stopAt)
            this.stopAt = moment(properties.stopAt);
        if (properties.createdAt)
            this.createdAt = moment(properties.createdAt);
        if (properties.updatedAt)
            this.updatedAt = moment(properties.updatedAt);
    }

    /**
     * Check if this track is running
     *
     * @return {Boolean}
     */
    isRunning(): boolean {
        return !this.stopAt;
    }

    /**
     * Get this track duration in seconds
     *
     * @return {Number}
     */
    seconds(): number {
        if (this.duration?.seconds && (this.duration.seconds > 0))
            return Math.round(this.duration.seconds);

        return (this.stopAt || moment()).diff(this.startAt, 'seconds');
    }

    /**
     * Get a formatted duration
     *
     * @return {String}
     */
    formattedDuration(): string {
        const duration = this.seconds();
        if (!duration)
            return '00:00';

        return Track.format(duration);
    }

    /**
     * Get a formatted string of a number duration
     *
     * @param {Number} duration in seconds
     *
     * @return {String}
     */
    static format(duration: number): string {
        const hours   = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        if (hours > 0)
            return [
                `${hours < 10 ? '0' : ''}${hours}`,
                `${minutes < 10 ? '0' : ''}${minutes}`,
                `${seconds < 10 ? '0' : ''}${seconds}`
            ].join(':')

        return [
            `${minutes < 10 ? '0' : ''}${minutes}`,
            `${seconds < 10 ? '0' : ''}${seconds}`
        ].join(':')
    }
}

export default Track;
