import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    DatepickerField, ClientSelectField, ChangeListItem,
    ChangePicker, Button, Icon, ResumeTrack, InlineField
} from '../../../components';
import { Client, Change, Track } from '../../../models';
import { RootState, useAppDispatch, useAppSelector, updateTrack } from '../../../redux';
import { Formik, Form, Field } from 'formik';
import { Table, Badge } from 'flowbite-react';
import moment from 'moment';
import type { Moment } from 'moment';

const condensedTdTheme = {
    "base": "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-1 py-1"
};

interface TracksProps {
    tracks: Track[];
    shortVersion?: boolean;
}

function Tracks(props: TracksProps) {
    const { tracks, shortVersion }  = props;

    const dispatch    = useAppDispatch();
    const navigate    = useNavigate();
    const { t, i18n } = useTranslation();

    const [isEditing, setEditing]         = useState<string|undefined>(undefined);
    const [changeFilter, setChangeFilter] = useState<string|undefined>(undefined);

    const {  updating } = useAppSelector((state: RootState) => state.tracks);

    const editTrack = (track: Track) => {
        if (isEditing === track._id)
            return setEditing(undefined);

        setEditing(track._id);
    }

    const tracksByDay = tracks?.reduce((acc: AnyObj, track: Track) => {
        const day = track.startAt.format('YYYY-MM-DD');
        if (!(day in acc))
            acc[day] = { tracks: [], total: 0 };
        acc[day].tracks.push(track);
        acc[day].total += track.seconds();

        return acc;
    }, {});


    const submit = function (values: AnyObj) : void {
        const { startAtDay, stopAtDay, startAtTime, stopAtTime, ...properties } = values;
        const [startHours, startMinutes] = startAtTime.split(':');

        properties.startAt
            .year(startAtDay.year())
            .month(startAtDay.month())
            .date(startAtDay.date());

        if (stopAtDay)
            properties.stopAt
                .year(stopAtDay.year())
                .month(stopAtDay.month())
                .date(stopAtDay.date());

        properties.startAt = properties.startAt
            .hours(parseInt(startHours))
            .minutes(parseInt(startMinutes))
            .seconds(0)
            .milliseconds(0);

        if (stopAtTime) {
            const [stopHours, stopMinutes]   = stopAtTime?.split(':');
            properties.stopAt = properties.stopAt
                .hours(parseInt(stopHours))
                .minutes(parseInt(stopMinutes))
                .seconds(0)
                .milliseconds(0);
        }

        dispatch(updateTrack(properties));
    }

    const displayedRow = (track: Track) => (
        <Table.Row key={track?._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell
                theme={condensedTdTheme}
                className={`whitespace-nowrap font-medium
                    ${!shortVersion ? 'text-gray-900 dark:text-white' : 'text-white dark:text-gray-900 bg-gray-900 !rounded-none'}
                    ${isEditing === track._id ? 'bg-blue-600' : ''}`}
            >
                <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 justify-between">
                    <div className="flex flex-row space-x-2 items-center">
                        <div className="flex-1 flex-col space-y-4 md:space-y-0">
                            <span className="bg-transparent text-center md:text-left text-md font-medium w-full md:w-[24rem] lg:w-auto truncate">
                                {track.title}
                            </span>
                            <div className="flex flex-col items-center md:items-start lg:flex-row space-x-0 lg:space-x-2">
                                <Badge color="success" onClick={() => navigate(`/projects/${track.project?._id}`)} className="cursor-pointer">
                                    <div className="flex flex-row space-x-1.5 items-center">
                                        <Icon type="project" className="" size={3} />
                                        <div className="text-xs font-normal w-full md:w-64 truncate">{track.project?.name}</div>
                                    </div>
                                </Badge>
                                {!shortVersion && track.client && (
                                    <Badge color="warning">
                                        <div className="flex flex-row space-x-1.5 items-center">
                                            <Icon type="user" className="" size={3} />
                                            <div className="text-xs font-normal w-full md:w-64 truncate">{track.client.name()}</div>
                                        </div>
                                    </Badge>
                                )}
                                {!shortVersion && track.change && (
                                    <Badge color={track.change.color()} onClick={() => navigate(`/projects/${track.project._id}/change/${track.change?._id}`)} className="cursor-pointer">
                                        <div className="flex flex-row space-x-1.5 items-center">
                                            <Icon type={track.change.iconName()} className="" size={3} />
                                            <div className="text-xs font-normal w-full md:w-64 truncate">
                                                {track.change.slugName()}&nbsp;|&nbsp;
                                                {track.change.localizedTitle(i18n.language)}
                                            </div>
                                        </div>
                                    </Badge>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 items-center md:items-end">
                        {!shortVersion && (
                        <>
                            <div className="flex flex-col text-xl">
                                <div className="flex flex-row space-x-4 justify-center items-center font-bold">
                                    <div className="flex flex-col items-center">
                                        <span className="bg-gray-50 border-gray-50 text-xs text-center font-medium">
                                            {track.startAt.format('LL')}
                                        </span>
                                            <span className="block font-bold text-sm">
                                            {track.startAt.format('HH:mm')}
                                        </span>
                                    </div>
                                    {track.stopAt && (
                                        <div className="flex flex-col items-center">
                                            <span className="bg-gray-50 border-gray-50 text-xs text-center font-medium">
                                                {track.stopAt.format('LL')}
                                            </span>
                                            <span className="block font-bold text-sm">
                                                {track.stopAt.format('HH:mm')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="text-2xl font-bold w-32 text-center md:text-right">
                                {!track.isRunning() && track.formattedDuration()}
                            </div>
                        </>
                        )}
                        <div className="text-2xl font-bold cursor-pointer">
                            <ResumeTrack track={track} className="w-12" />
                        </div>
                        {!shortVersion && (
                            <div className="text-2xl font-bold cursor-pointer h-full flex justify-start items-end p-1.5">
                                <div onClick={() => editTrack(track)}>
                                    <Icon type="edit" size={5} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Table.Cell>
        </Table.Row>
    );

    const editableRow = (track: Track) => {
        const initialValues = {
            ...track,
            startAtTime: track.startAt.format('HH:mm'),
            stopAtTime: track.stopAt?.format('HH:mm'),
            startAtDay: track.startAt.clone().startOf('day'),
            stopAtDay: track.stopAt ? track.stopAt.clone().endOf('day') : undefined
        };

        return (
            <Table.Row key={track?._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell theme={condensedTdTheme} className={`whitespace-nowrap font-medium text-gray-900 dark:text-white bg-blue-50`}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                            submit(values);
                        }}
                    >
                        {({ values, dirty, handleSubmit }) => (
                            <Form>
                                <div className="flex flex-row items-center mb-3 p-1 border-b border-gray-400">
                                    <div className="flex-1">
                                        <InlineField
                                            name="title"
                                            type="text"
                                            onSave={(field: string, value: string) => {
                                                values.title = value;
                                                submit(values);
                                            }}
                                            isLoading={updating === 'pending'}
                                            className="bg-transparent text-center text-lg font-medium w-full md:w-[24rem] lg:w-auto truncate"
                                        />
                                    </div>
                                    <div className="text-xl font-bold w-32 text-center md:text-right">
                                        {!track.isRunning() && track.formattedDuration()}
                                    </div>
                                    <div className="text-2xl font-bold w-12 cursor-pointer h-full flex justify-end items-center">
                                        <div onClick={() => editTrack(track)}>
                                            <Icon type="close" color="gray-900" size={8} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 justify-between">
                                    <div className="flex flex-row space-x-2 items-center">
                                        <div className="flex-1 flex-col space-y-4">
                                            <div className="flex flex-col items-center md:items-start lg:flex-row space-x-0 lg:space-x-2">
                                                <div className="flex flex-col space-y-1">
                                                    <span className="text-lg font-bold">{t('tracks.project')}</span>
                                                    <div className="flex flex-col space-y-1 border p-2 rounded-md">
                                                        <Badge color="success">
                                                            <div className="flex flex-row space-x-1.5 items-center">
                                                                <Icon type="project" className="" size={3} />
                                                                <div className="text-xs font-bold w-full md:w-64 truncate">{track.project.name}</div>
                                                            </div>
                                                        </Badge>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col space-y-1">
                                                    <span className="text-lg font-bold">{t('tracks.client')}</span>
                                                    <ClientSelectField
                                                        name="client"
                                                        onChange={(client?: Client) => {
                                                            if (client)
                                                                values.client = client;
                                                            else
                                                                delete(values.client);
                                                            submit(values);
                                                        }}
                                                    />
                                                </div>
                                                {track.project?._id && (
                                                    <div className="flex flex-col space-y-1">
                                                        <span className="text-lg font-bold">{t('tracks.change')}</span>
                                                        {track.change?._id && (
                                                            <ChangeListItem
                                                                change={track.change}
                                                                onRemove={(change: Change, e: AnyObj) => {
                                                                    delete(values.change);
                                                                    submit(values);
                                                                }}
                                                            />
                                                        )}
                                                        {!track.change?._id && (
                                                            <div className="relative">
                                                                <input
                                                                    type="text"
                                                                    value={changeFilter}
                                                                    placeholder={t('tracks.search_change') || ''}
                                                                    className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    onChange={(e: AnyObj) => {
                                                                        setChangeFilter(e.target.value);
                                                                    }}
                                                                />
                                                                <Icon type="search" className="absolute top-2 right-2" size={5} color="gray-300" />
                                                            </div>
                                                        )}
                                                        <ChangePicker
                                                            acceptUnestimated
                                                            acceptFinished
                                                            hideLoader
                                                            defaultProjectId={track.project._id}
                                                            changeClassName="rounded-l-none"
                                                            onSelect={(change: Change, e: AnyObj) => {
                                                                if (change)
                                                                    values.change = change;
                                                                else
                                                                    delete(values.change);
                                                                submit(values);
                                                                setChangeFilter("");
                                                            }}
                                                            filterChanges={(changes: Change[]) => {
                                                                if (!changeFilter?.length)
                                                                    return [];

                                                                return changes
                                                                    .filter((change: Change) => change.match(changeFilter))
                                                                    .sort((a: Change, b: Change) => b.slug - a.slug);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 items-start">
                                        <div className="flex flex-col text-xl">
                                            <div className="flex flex-row space-x-4 justify-center items-center font-bold">
                                                <div className="flex flex-col items-center">
                                                    <span className="text-lg font-bold">{t('tracks.start')}</span>
                                                    <DatepickerField
                                                        name="startAtDay"
                                                        onSave={(field: string, value: Moment) => {
                                                            if (!value)
                                                                return;
                                                            values.startAtDay = value;
                                                            submit(values);
                                                        }}
                                                        direction="left"
                                                        inputClassName="bg-transparent border-0 text-center font-medium"
                                                        unclearable
                                                    />
                                                    <Field
                                                        type="time"
                                                        name="startAtTime"
                                                        className="block focus:ring-0 border-0 border-b-2 border-white py-1.5 shadow-sm font-bold text-sm sm:leading-6 w-fit"
                                                        required
                                                    />
                                                </div>
                                                {track.stopAt && (
                                                <div className="flex flex-col items-center">
                                                    <span className="text-lg font-bold">{t('tracks.end')}</span>
                                                        {track.stopAt && (
                                                            <DatepickerField
                                                                name="stopAtDay"
                                                                onSave={(field: string, value: Moment) => {
                                                                    values.stopAtDay = value;
                                                                    submit(values);
                                                                }}
                                                                direction="left"
                                                                inputClassName="bg-transparent border-0 text-center font-medium"
                                                                unclearable
                                                            />
                                                        )}
                                                        <Field
                                                            type="time"
                                                            name="stopAtTime"
                                                            className="block focus:ring-0 border-0 border-b-2 border-white py-1.5 shadow-sm font-bold text-sm sm:leading-6 w-fit"
                                                            required
                                                        />
                                                    </div>
                                                )}
                                                <Button
                                                        type="submit"
                                                        title={t('common.save')}
                                                        icon='check'
                                                        iconColor="black"
                                                        color="none"
                                                        className="hidden w-4 inline ml-1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Table.Cell>
                </Table.Row>
            );
        };

        return (
            <div className="Tracks">
                {Object.keys(tracksByDay).sort((a: string, b: string) => b.localeCompare(a)).map((day: string) => (
                    <Table key={day} striped hoverable className="mb-4">
                        <Table.Body className={!shortVersion ? "divide-y" : ""}>
                            {!shortVersion && (
                            <Table.Row>
                                <Table.Cell theme={condensedTdTheme} className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    <h3 className="flex justify-between items-center text-xl font-bold my-1">
                                        <span className="flex items-center gap-x-2">
                                            <Icon type="date" />
                                            {moment(day).format('LL')}
                                        </span>
                                        <span className="flex items-center gap-x-2">
                                            <Icon type="timer" />
                                            {Track.format(tracksByDay[day].total)}
                                        </span>
                                    </h3>
                                </Table.Cell>
                            </Table.Row>
                            )}
                            {tracksByDay[day]?.tracks.sort((a: Track, b: Track) => b.startAt.unix() - a.startAt.unix()).map((track: Track) => {
                                if (isEditing === track._id)
                                    return editableRow(track);
                                else
                                    return displayedRow(track);
                            })}
                        </Table.Body>
                    </Table>
                ))}
            </div>
        );
    }

    export default Tracks;
