import BaseModel from './BaseModel';
import Project from './Project';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';
import type { IconType } from '../components/Icon';

/** Class representing a commit. */
class Commit extends BaseModel {
    _id: string;
    project: Project;
    change: Change;
    type: string;
    hash: string;
    message: string;
    date: Moment;
    author_name: string;
    author_email: string;
    body: string;
    refs: string;
    tags: string[];

    constructor(properties: AnyObj) {
        super({});

        this._id          = properties._id;
        this.project      = properties.project;
        this.change       = properties.change;
        this.type         = properties.type;
        this.hash         = properties.hash;
        this.message      = properties.message;
        this.date         = moment(properties.date);
        this.author_name  = properties.author_name;
        this.author_email = properties.author_email;
        this.body         = properties.body;
        this.refs         = properties.refs;
        this.tags         = properties.tags;

        if (this.project)
            this.project = new Project(this.project);

        if (this.change)
            this.change = new Change(this.change);
    }

    /**
     * Get the icon to represent this commit type
     * @return {String}
     */
    iconName() : IconType {
        return Change.iconName(this.type);
    }

    /**
     * Get the color to represent this commit type
     * @return {String}
     */
    color() : string {
        return Change.color(this.type);
    }
}

export default Commit;
