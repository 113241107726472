import { Dispatch } from 'redux';
import { Project, Change, Board, Release } from '../../models';
import { Projects } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Status, Callback, Response } from './rest';

const SYNCING         = 'yoda/projects/SYNCING';
const SYNCING_SUCCESS = 'yoda/projects/SYNCING_SUCCESS';
const SYNCING_FAILURE = 'yoda/projects/SYNCING_FAILURE';
const SYNCING_RESET   = 'yoda/projects/SYNCING_RESET';

const UPDATING_MEMBERS           = 'yoda/projects/UPDATING_MEMBERS';
const UPDATING_MEMBERS_SUCCESS   = 'yoda/projects/UPDATING_MEMBERS_SUCCESS';
const UPDATING_MEMBERS_FAILURE   = 'yoda/projects/UPDATING_MEMBERS_FAILURE';
const UPDATING_MEMBERS_RESET     = 'yoda/projects/UPDATING_MEMBERS_RESET';

const GET_CHANGELOG         = 'yoda/projects/GET_CHANGELOG';
const GET_CHANGELOG_SUCCESS = 'yoda/projects/GET_CHANGELOG_SUCCESS';
const GET_CHANGELOG_FAILURE = 'yoda/projects/GET_CHANGELOG_FAILURE';
const GET_CHANGELOG_RESET   = 'yoda/projects/GET_CHANGELOG_RESET';

const GET_DOCUMENTATIONS         = 'yoda/projects/GET_DOCUMENTATIONS';
const GET_DOCUMENTATIONS_SUCCESS = 'yoda/projects/GET_DOCUMENTATIONS_SUCCESS';
const GET_DOCUMENTATIONS_FAILURE = 'yoda/projects/GET_DOCUMENTATIONS_FAILURE';

const SET_AVATAR           = 'yoda/projects/SET_AVATAR';
const SET_AVATAR_SUCCESS   = 'yoda/projects/SET_AVATAR_SUCCESS';
const SET_AVATAR_FAILURE   = 'yoda/projects/SET_AVATAR_FAILURE';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Projects);

initialState.sync          = 'idle' as Status;
initialState.syncProjectId = null;
initialState.syncError     = null;

initialState.membersUpdate      = 'idle' as Status;
initialState.membersUpdateError = null;

initialState.getChangelog          = 'idle' as Status;
initialState.getChangelogProjectId = null;
initialState.getChangelogError     = null;
initialState.changelog = {
    project: null,
    releases: [],
    merged: []
};

initialState.documentationsProjectId = null;
initialState.documentationsError     = null;

const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'project.updated':
            if (!state.project || state.project._id !== data._id)
                return state;

            return {
                ...state,
                project: new Project({
                    ...state.project,
                    ...data,
                })
            };
        case 'change.updated':
            if (!state.project || state.project._id !== data.project)
                return state;

            const changes = state.project.changes.map((change: Change) => {
                if (change._id !== data._id)
                    return change;

                return new Change(data);
            });
            return {
                ...state,
                project: new Project({
                    ...state.project,
                    changes
                })
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle modifications on changes when we are
 * looking at a specific release...
 */
const UPDATING_CHANGE_SUCCESS = 'yoda/change/UPDATING_ITEM_SUCCESS';
const updateChangeReducer = (state = initialState, action: ActionPayload) => {
    if (!state.project || !state.project.releases)
        return state;

    switch (action.type) {
        case UPDATING_CHANGE_SUCCESS:
            const { change } = action;
            const { project } = state;

            // let's replace the original change in the project release
            // with the updated one
            project.releases = project.releases.map((release: Release) => {
                release.changes = release.changes.map((original: Change) => {
                    return original._id === change._id ? new Change(change) : original;
                });
                return release;
            });
            return {
                ...state,
                project: new Project({ ...project })
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle modifications on boards
 */
const UPDATING_BOARD_SUCCESS = 'yoda/board/UPDATING_ITEM_SUCCESS';
const CREATING_BOARD_SUCCESS = `yoda/board/CREATING_ITEM_SUCCESS`;
const DELETING_BOARD_SUCCESS = `yoda/board/DELETING_ITEM_SUCCESS`;
const updateBoardReducer = (state = initialState, action: ActionPayload) => {
    if (!state.project || !state.project.boards)
        return state;

    const { board } = action;
    const { project } = state;

    switch (action.type) {
        case UPDATING_BOARD_SUCCESS:

            // let's replace the original board in the project
            // with the updated one
            project.boards = project.boards.map((original: Board) => {
                return original._id === board._id ? new Board(board) : original;
            });
            return {
                ...state,
                project: new Project({ ...project })
            };
        case CREATING_BOARD_SUCCESS:
            return {
                ...state,
                project: new Project({
                    ...project,
                    boards: [
                        ...project.boards,
                        new Board(board)
                    ]
                })
            };
        case DELETING_BOARD_SUCCESS:
            return {
                ...state,
                project: new Project({
                    ...project,
                    boards: project.boards.filter((b: Board) => b._id !== board._id)
                })
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle attachments creation/deletion
 */
const CREATING_ATTTACHMENT_SUCCESS = `yoda/attachments/CREATING_ITEM_SUCCESS`;
const DELETING_ATTTACHMENT_SUCCESS = `yoda/attachment/DELETING_ITEM_SUCCESS`;
const attachmentsReducer = (state = initialState, action: ActionPayload) => {
    if (!state.project)
        return state;

    switch (action.type) {
        case CREATING_ATTTACHMENT_SUCCESS:
            return {
                ...state,
                project: new Project({
                    ...state.project,
                    nbAttachments: state.project.nbAttachments + 1
                })
            };
        case DELETING_ATTTACHMENT_SUCCESS:
            return {
                ...state,
                project: new Project({
                    ...state.project,
                    nbAttachments: state.project.nbAttachments - 1
                })
            };
        default:
            return state;
    }
};

const avatarReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case SET_AVATAR:
            return {
                ...state,
                projectsUpdate: 'pending',
                projectsUpdateError: null
            };
        case SET_AVATAR_SUCCESS:
            return {
                ...state,
                projectsUpdate: 'succeeded',
                projectsUpdateError: null,
                project: action.project,
                projects: (state.projects || []).map((p: Project) => {
                    if (p._id === action.project?._id)
                        return action.project;
                    return p;
                })
            };
        case SET_AVATAR_FAILURE:
            return {
                ...state,
                projectsUpdate: 'failed',
                projectsUpdateError: action.error
            };
        default:
            return state;
    }
};

const syncReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case SYNCING:
            return {
                ...state,
                sync: 'pending',
                syncProjectId: action.id,
                syncError: null
            };
        case SYNCING_SUCCESS:
            return {
                ...state,
                sync: 'succeeded',
                syncError: null,
                project: (state.project?._id === action.project._id) ? action.project : state.project,
                projects: (state.projects || []).map((p: Project) => {
                    if (p._id === action.project?._id)
                        return action.project;
                    return p;
                })
            };
        case SYNCING_FAILURE:
            return {
                ...state,
                sync: 'failed',
                syncError: action.error
            };
        case SYNCING_RESET:
            return {
                ...state,
                sync: 'idle',
                syncProjectId: null,
                syncError: null
            };
        default:
            return state;
    }
};

const membersUpdateReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case UPDATING_MEMBERS:
            return {
                ...state,
                membersUpdate: 'pending',
                membersUpdateError: null
            };
        case UPDATING_MEMBERS_SUCCESS:
            return {
                ...state,
                membersUpdate: 'succeeded',
                membersUpdateError: null,
                project: action.project,
                projects: (state.projects || []).map((p: Project) => {
                    if (p._id === action.project?._id)
                        return action.project;
                    return p;
                })
            };
        case UPDATING_MEMBERS_FAILURE:
            return {
                ...state,
                membersUpdate: 'failed',
                membersUpdateError: action.error
            };
        case UPDATING_MEMBERS_RESET:
            return {
                ...state,
                membersUpdate: 'idle',
                syncProjectId: null,
                membersUpdateError: null
            };
        default:
            return state;
    }
};

const getChangelogReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_CHANGELOG:
            return {
                ...state,
                getChangelog: 'pending',
                getChangelogProjectId: action.projectId,
                getChangelogError: null
            };
        case GET_CHANGELOG_SUCCESS:
            return {
                ...state,
                getChangelog: 'succeeded',
                getChangelogError: null,
                changelog: action.changelog
            };
        case GET_CHANGELOG_FAILURE:
            return {
                ...state,
                getChangelog: 'failed',
                getChangelogError: action.error
            };
        case GET_CHANGELOG_RESET:
            return {
                ...state,
                getChangelog: 'idle',
                getChangelogProjectId: null,
                getChangelogError: null,
                changelog: {
                    project: null,
                    releases: [],
                    merged: []
                }
            };
        default:
            return state;
    }
};

const getDocumentationsReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_DOCUMENTATIONS:
            return {
                ...state,
                getDocumentations: 'pending',
                documentationsProjectId: action.projectId,
                documentationsError: null
            };
        case GET_DOCUMENTATIONS_SUCCESS:
            return {
                ...state,
                getDocumentations: 'succeeded',
                documentationsError: null,
                project: state.documentationsProjectId === state.project?._id ? new Project({
                    ...state.project,
                    documentations: action.documentations
                }) : state.project
            };
        case GET_DOCUMENTATIONS_FAILURE:
            return {
                ...state,
                getDocumentations: 'failed',
                documentationsError: action.error
            };
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer, avatarReducer,
        updateReducer, deleteReducer, onRemoteUpdateReducer,
        duplicateReducer, syncReducer,
        updateChangeReducer, updateBoardReducer, membersUpdateReducer,
        getChangelogReducer, getDocumentationsReducer, attachmentsReducer
    ]);
}

const syncAction = (id: string, forceRefresh: boolean, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: SYNCING, id});
        return Projects.sync(id, forceRefresh)
            .then((data: Response) => {
                const {project} = data;
                dispatch({type: SYNCING_SUCCESS, project});
                setTimeout(() => {
                    dispatch({type: SYNCING_RESET});
                }, 3000);
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: SYNCING_FAILURE, error: error.message});
                setTimeout(() => {
                    dispatch({type: SYNCING_RESET});
                }, 3000);
                callback && callback(error);
            });
    }
};

const addMembersAction = (id: string, members: string[], callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: UPDATING_MEMBERS});
        return Projects.addMembers(id, members)
            .then((data: Response) => {
                const {project} = data;
                dispatch({type: UPDATING_MEMBERS_SUCCESS, project});
                setTimeout(() => {
                    dispatch({type: UPDATING_MEMBERS_RESET});
                }, 100);
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: UPDATING_MEMBERS_FAILURE, error: error.message});
                setTimeout(() => {
                    dispatch({type: UPDATING_MEMBERS_RESET});
                }, 100);
                callback && callback(error);
            });
    }
};

const removeMembersAction = (id: string, members: string[], callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: UPDATING_MEMBERS});
        return Projects.removeMembers(id, members)
            .then((data: Response) => {
                const {project} = data;
                dispatch({type: UPDATING_MEMBERS_SUCCESS, project});
                setTimeout(() => {
                    dispatch({type: UPDATING_MEMBERS_RESET});
                }, 100);
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: UPDATING_MEMBERS_FAILURE, error: error.message});
                setTimeout(() => {
                    dispatch({type: UPDATING_MEMBERS_RESET});
                }, 100);
                callback && callback(error);
            });
    }
};

const getChangelogAction = (projectId: string, from?: string, merge?: string[], callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: GET_CHANGELOG, projectId});
        return Projects.getChangelog(projectId, from, merge)
            .then((data: Response) => {
                const {changelog} = data;
                dispatch({type: GET_CHANGELOG_SUCCESS, changelog});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: GET_CHANGELOG_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
};

const getDocumentationsAction = (projectId: string, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: GET_DOCUMENTATIONS, projectId});
        return Projects.getDocumentations(projectId)
            .then((data: Response) => {
                const {documentations} = data;
                dispatch({type: GET_DOCUMENTATIONS_SUCCESS, documentations});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: GET_DOCUMENTATIONS_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
}

const setAvatarAction = (id: string, file: File, callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: SET_AVATAR});
        return Projects.setAvatar(id, file).then((data: AnyObj) => {
            const { project } = data;
            dispatch({type: SET_AVATAR_SUCCESS, project});
            callback && callback(/*err*/null);
        })
        .catch((error: Error) => {
            dispatch({type: SET_AVATAR_FAILURE, error: error.message});
            callback && callback(error);
        });
    }
}

const unsetAvatarAction = (id: string, callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: SET_AVATAR});
        return Projects.unsetAvatar(id).then((data: AnyObj) => {
            const { project } = data;
            dispatch({type: SET_AVATAR_SUCCESS, project});
            callback && callback(/*err*/null);
        })
        .catch((error: Error) => {
            dispatch({type: SET_AVATAR_FAILURE, error: error.message});
            callback && callback(error);
        });
    }
}

/* Export CRUD actions */
export const createProject        = createAction;
export const startCreateProject   = startCreateAction;
export const loadProject          = getAction;
export const loadProjects         = listAction;
export const updateProject        = updateAction;
export const deleteProject        = deleteAction;
export const duplicateProject     = duplicateAction;
export const syncProject          = syncAction;
export const addProjectMembers    = addMembersAction;
export const removeProjectMembers = removeMembersAction;
export const getChangelog         = getChangelogAction;
export const getDocumentations    = getDocumentationsAction;
export const setProjectAvatar     = setAvatarAction;
export const unsetProjectAvatar   = unsetAvatarAction;
