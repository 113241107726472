import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../components';
import { Track, Report } from '../../../models';
import Chart from "react-apexcharts";
import moment from 'moment';

interface StatsProps {
    tracks: Track[];
    report: Report;
}

const theme = { palette: 'palette3' };

function Stats(props: StatsProps) {
    const { tracks, report } = props;
    const { t, i18n }        = useTranslation();

    const [graphAlignment, setGraphAlignment]  = useState(true);
    const [graphFrequencey, setGraphFrequency] = useState('day');

    const total = tracks?.reduce((acc: number, track: Track) => acc + track.seconds(), 0);
    const daysTotal: AnyObj = React.useMemo(() => tracks
        .sort((a: Track, b: Track) => a.startAt.isBefore(b.startAt) ? -1 : 1)
        .reduce((acc: AnyObj, track: Track) => {
            let day = track.startAt.format('YYYY-MM-DD');
            // ignore week-ends
            if (track.startAt.isoWeekday() > 5)
                return acc;
            if (graphFrequencey === 'week')
                day = track.startAt.startOf('week').format('YYYY-MM-DD');
            else if (graphFrequencey === 'month')
                day = track.startAt.format('YYYY-MM');
            if (!(day in acc))
                acc[day] = 0;
            acc[day] += track.seconds();

            return acc;
        }, {}),
    [tracks, graphFrequencey]);

    const usersTotals: AnyObj = React.useMemo(() => tracks.reduce((acc: AnyObj, track: Track) => {
        if (!track.author?.fullname())
            return acc;
        if (!(track.author.fullname() in acc))
            acc[track.author.fullname()] = 0;
        acc[track.author.fullname()] += track.seconds();

        return acc;
    }, {}), [tracks]);

    const projectsTotals: AnyObj = React.useMemo(() => tracks.reduce((acc: AnyObj, track: Track) => {
        if (!track.project?.name)
            return acc;
        if (!(track.project.name in acc))
            acc[track.project.name] = 0;
        acc[track.project.name] += track.seconds();

        return acc;
    }, {}), [tracks]);

    const clientsTotal: AnyObj = React.useMemo(() => tracks.reduce((acc: AnyObj, track: Track) => {
        if (!track.client)
            return acc;
        if (!(track.client.name() in acc))
            acc[track.client.name()] = 0;
        acc[track.client.name()] += track.seconds();

        return acc;
    }, {}), [tracks]);

    const changesTotals: AnyObj = React.useMemo(() => {
        const changesTotals: AnyObj = {};
        tracks.forEach((track: Track) => {
            if (!(track.change?._id))
                return;
            const title = `${track.change.slugName()} - ${track.change.localizedTitle(i18n.language)}`;
            if (!title)
                return;
            if (!(title in changesTotals))
                changesTotals[title] = 0;
            changesTotals[title] += track.seconds();
        });

        return changesTotals;
    }, [tracks, i18n.language]);

    const burndownSeries = React.useMemo(() => {
        const series: any[] = [];
        if (!report?.burndown)
            return series;

        series.push({
            name: t('tracks.ideal_estimate') || '',
            data: Object.values(report.burndown).map((d: AnyObj) => ({
                x: moment(d.day).toISOString(),
                y: d.idealEstimate * 3600
            })),
            type: 'area'
        });
        series.push({
            name: t('tracks.real_estimate') || '',
            data: Object.values(report.burndown).map((d: AnyObj) => ({
                x: moment(d.day).toISOString(),
                y: d.realEstimate * 3600
            })),
            type: 'area'
        });
        series.push({
            name: t('tracks.ideal_effort') || '',
            data: Object.values(report.burndown).map((d: AnyObj) => ({
                x: moment(d.day).toISOString(),
                y: d.idealEffort * 3600
            })),
            type: 'line'
        });
        series.push({
            name: t('tracks.real_effort') || '',
            data: Object.values(report.burndown).map((d: AnyObj) => ({
                x: moment(d.day).toISOString(),
                y: d.realEffort * 3600
            })),
            type: 'area'
        });
        series.push({
            name: t('tracks.tracked') || '',
            data: Object.values(report.burndown).map((d: AnyObj) => ({
                x: moment(d.day).toISOString(),
                y: d.tracked * 3600
            })),
            type: 'area'
        });

        return series;
    }, [ report]);

    const today = React.useMemo(() => {
        const today = moment();
        // if we are on saturday or sunday, let's move to the last friday
        if (today.day() % 6 === 0)
            today.day(-2);

        return today;
    }, []);

    return (
        <div className="w-full overflow-x-auto z-0">
            <div className="flex flex-col space-y-2">
                {(total > 0) && (
                <div className="flex flex-row justify-center items-center font-bold text-lg mb-3">
                    <span className="border rounded-md p-2 px-4 bg-gray-900 text-white">
                        <Icon type="timer" color="white" className="inline mr-1.5" size={7} />
                        {t('tracks.total_duration')} {Track.format(total)}
                    </span>
                </div>
                )}
                {(Object.keys(report?.burndown || {}).length > 0) && (
                    <div className="flex flex-row space-x-2 justify-center items-center font-bold text-lg">
                        <div className="border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                <select className="" onChange={(e: AnyObj) => setGraphAlignment(e.target.value === '1')}>
                                    <option value="1" selected={!!graphAlignment}>{t('tracks.aligned_burndown_chart')}</option>
                                    <option value="0" selected={!graphAlignment}>{t('tracks.burndown_chart')}</option>
                                </select>
                            </h3>
                            <Chart
                                options={{
                                    labels: Object.keys(report.burndown),
                                    legend: { position: 'right' },
                                    dataLabels: {
                                        enabled: Object.keys(report.burndown).length < 15,
                                        formatter: value => Track.format(Number(value))
                                    },
                                    tooltip: { enabled: true },
                                    xaxis: {
                                        labels: {
                                            rotate: -45,
                                            formatter: (value) => moment(value).format('LL')
                                        }
                                    },
                                    yaxis: [
                                        {
                                            seriesName: t('tracks.ideal_estimate') || '',
                                            labels: { formatter: (value) => Track.format(value) }
                                        },
                                        {
                                            seriesName: t('tracks.ideal_estimate') || '',
                                            labels: { formatter: (value) => Track.format(value) },
                                            show: false
                                        },
                                        {
                                            seriesName: graphAlignment ?
                                                t('tracks.ideal_effort') || '' :
                                                t('tracks.ideal_estimate') || '',
                                            opposite: !!graphAlignment,
                                            show: !!graphAlignment,
                                            labels: { formatter: (value) => Track.format(value) }
                                        },
                                        {
                                            seriesName: graphAlignment ?
                                                t('tracks.ideal_effort') || '' :
                                                t('tracks.ideal_estimate') || '',
                                            labels: { formatter: (value) => Track.format(value) },
                                            show: false
                                        },
                                        {
                                            seriesName: graphAlignment ?
                                                t('tracks.ideal_effort') || '' :
                                                t('tracks.ideal_estimate') || '',
                                            labels: { formatter: (value) => Track.format(value) },
                                            show: false
                                        },
                                    ],
                                    stroke: {
                                        curve: 'smooth',
                                        width: [3, 3, 2, 2, 2],
                                    },
                                    forecastDataPoints: {
                                        count: Object.keys(report.burndown).length - Object.keys(report.burndown).indexOf(today.format('YYYY-MM-DD')) - 1,
                                        fillOpacity: 0.5,
                                        strokeWidth: undefined,
                                        dashArray: 4,
                                    },
                                    annotations: {
                                        xaxis: [
                                            {
                                                x: today.format('LL'),
                                                borderColor: '#775DD0',
                                                label: {
                                                    text: t('common.today') || ''
                                                }
                                            }
                                        ]
                                    },
                                    theme
                                }}
                                series={ burndownSeries }
                                type="area"
                                width="100%"
                                height="400px"
                            />
                        </div>
                    </div>
                )}
                {(Object.keys(daysTotal).length > 0) && (
                    <div className="flex flex-row space-x-2 justify-center items-center font-bold text-lg">
                        <div className="border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                <select className="" onChange={(e: AnyObj) => setGraphFrequency(e.target.value)}>
                                    <option value="day">{t('tracks.stats_by_day')}</option>
                                    <option value="week">{t('tracks.stats_by_week')}</option>
                                    <option value="month">{t('tracks.stats_by_month')}</option>
                                </select>
                            </h3>
                            <Chart
                                options={{
                                    labels: Object.keys(daysTotal),
                                    legend: { position: 'right' },
                                    dataLabels: { formatter: value => Track.format(Number(value)) },
                                    xaxis: {
                                        labels: {
                                            rotate: -45,
                                            formatter: (value) => graphFrequencey === 'week' ?
                                                moment(value).format('LL') + ' - ' + moment(value).endOf('week').format('LL') :
                                                moment(value).format('LL')
                                        }
                                    },
                                    yaxis: { labels: { formatter: (value) => Track.format(value) }},
                                    stroke: {
                                        curve: graphFrequencey === 'week' ? 'straight' : 'smooth'
                                    },
                                    theme
                                }}
                                series={[{name: t('tracks.total_duration') || '', data: Object.values(daysTotal)}]}
                                type="area"
                                width="100%"
                                height="400px"
                            />
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-x-2 gap-y-2 font-bold text-lg">
                    {Object.keys(usersTotals).length > 1 && (
                        <div className="col-span-1 border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                {t('tracks.stats_by_user')}
                            </h3>
                            <Chart
                                options={{
                                    theme,
                                    labels: Object.keys(usersTotals),
                                    legend: { position: 'bottom' },
                                    yaxis: { labels: { formatter: (value) => Track.format(value) }},
                                }}
                                series={Object.values(usersTotals)}
                                type="donut"
                                width="100%"
                            />
                        </div>
                    )}
                    {Object.keys(projectsTotals).length > 1 && (
                        <div className="col-span-1 border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                {t('tracks.stats_by_project')}
                            </h3>
                            <Chart
                                options={{
                                    theme,
                                    labels: Object.keys(projectsTotals),
                                    legend: { position: 'bottom' },
                                    yaxis: { labels: { formatter: (value) => Track.format(value) }},
                                }}
                                series={Object.values(projectsTotals)}
                                type="donut"
                                width="100%"
                            />
                        </div>
                    )}
                    {(Object.keys(clientsTotal).length > 1) && (
                        <div className="col-span-1 border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                {t('tracks.stats_by_customer')}
                            </h3>
                            <Chart
                                options={{
                                    theme,
                                    labels: Object.keys(clientsTotal),
                                    legend: { position: 'bottom' },
                                    yaxis: { labels: { formatter: (value) => Track.format(value) }},
                                }}
                                series={Object.values(clientsTotal)}
                                type="donut"
                                width="100%"
                            />
                        </div>
                    )}
                    {(Object.keys(changesTotals).length > 0) && (
                        <div className="col-span-1 border rounded-md p-4 w-full">
                            <h3 className="font-bold text-left">
                                {t('tracks.stats_by_card')}
                            </h3>
                            <Chart
                                options={{
                                    theme,
                                    labels: Object.keys(changesTotals),
                                    legend: { position: 'bottom' },
                                    yaxis: { labels: { formatter: (value) => Track.format(value) }},
                                }}
                                series={Object.values(changesTotals)}
                                type="donut"
                                width="100%"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Stats;
