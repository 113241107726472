import Client from './Client';
import { isExpired } from "react-jwt";

interface Credentials {
    email: string,
    password: string
}

class Auth extends Client {
    checkJwt(token: string) {
        return new Promise((resolve, reject) => { resolve(!isExpired(token)); });
    }

    async getUserProfile(){
        return this.GET('/users/profile')
            .then(response => response.json())
            .then(json => {
                if(json.user)
                    return json;
                throw new Error("Could not load profile");
            });
    }

    logIn(credentials: Credentials) {
        return this.POST('/users/login', credentials)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    return json;
                }
                throw new Error(json.err);
            });
    }

    logOut() {
        return this.POST('/users/logout').then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Déconnexion impossible");
        });
    }

    getAuthMethods() {
        return this.GET('/users/auth_methods')
            .then(response => response.json())
            .then(json => {
                if (json.methods) {
                    return json;
                }
                throw new Error(json.err);
            });
    }

    getOpenIdUrl() {
        return this.GET('/openid/url').then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Could not get OpenId redirect url");
        });
    }
}

const AuthApi = new Auth();

export default AuthApi;
export type { Credentials };
