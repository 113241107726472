import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadSprint, updateSprint, loadUsers } from '../../../redux';
import { Alert, Button } from '../../../components';
import SprintForm from '../SprintForm/SprintForm';

function SprintEdit() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    let { id }     = useParams();

    const { sprint, updatingError } = useAppSelector((state: RootState) => state.sprints);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadSprint(id));
    }, [dispatch, id]);

    const submit = function (values: AnyObj) : void {
        dispatch(updateSprint(values));
    }

    return (
        <div className="SprintEdit">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            { t('sprints.edit_sprint', {name: sprint?.name}) }
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button title={t('common.delete')} color="danger" to={`/sprints/delete/${sprint?._id}`} icon="delete" />
                        </span>
                        <span className="sm:ml-3">
                            <Button title={t('common.back')} color="navigateBack" to={`/sprints/${sprint?._id}`} icon="back" />
                        </span>
                    </div>
                </div>
            </header>
            {updatingError && (
                <div className="flex-1">
                    <Alert title={t('sprints.creation_failed') + ' : ' + updatingError} color="failure" icon="warning" />
                </div>
            )}
            <main className="mx-6 my-6 shadow">
                {sprint && (sprint._id === id) && (
                    <SprintForm isCreation={false} initialValues={sprint} onSubmit={submit} />
                )}
            </main>
        </div>
    );
}

export default SprintEdit;
