import React from 'react';
import { useField } from 'formik';
import { MultiSelect } from '..';

export type Value = string | number;
interface MultiSelectOption {
    value: Value;
    label: string;
    disabled?: boolean;
    leftContent?: JSX.Element;
}

interface MultiSelectFieldProps {
    name: string;
    options: MultiSelectOption[];
    placeholder?: string;
    inline?: boolean;
    className?: string;
    selectedClassName?: string;
    optionsClassName?: string;
    optionsWidth?: string | number;
    optionsHeight?: string | number;
};

const MultiSelectField = (props: MultiSelectFieldProps) => {
    const [ field, meta, helpers ] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
        <MultiSelect
            defaultValue={value}
            options={props.options}
            placeholder={props.placeholder}
            onChange={setValue}
            inline={props.inline}
            className={props.className}
            selectedClassName={props.selectedClassName}
            optionsClassName={props.optionsClassName}
            optionsWidth={props.optionsWidth}
            optionsHeight={props.optionsHeight}
        />
    )
};

export default MultiSelectField;
