import RestClient from './RestClient';
import { Track } from '../models';
import _ from 'lodash';

class Tracks extends RestClient {
    constructor() {
        super({
            // rest config
            model: Track,
            entryPoint: 'tracks',
            sortBy: (a: AnyObj, b: AnyObj) => b.startAt - a.startAt,
            id_field: '_id',
            // redux config
            resource: 'track',
            resources: 'tracks',
        });
    }

    getActive() {
        return this.GET(`/${this.entryPoint}/active`, {})
            .then(response => response.json())
            .then(json => {
                if (json.track) {
                    json.track = new Track(json.track);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    getSuggested(params?: AnyObj) {
        // remove empty filter values
        params = _.pickBy(params, _.identity);

        return this.GET(`/${this.entryPoint}/suggested`, params)
            .then(response => response.json())
            .then(json => {
                if (json.tracks) {
                    json.tracks = json.tracks.map((track: AnyObj) => new Track(track));
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    stop() {
        return this.PUT(`/${this.entryPoint}/stop`)
            .then(response => response.json())
            .then(json => {
                if (json.track) {
                    json.track = new Track(json.track);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const TracksApi = new Tracks();

export default TracksApi;
