import BaseModel from './BaseModel';

/** Class representing a documentation. */
class Documentation extends BaseModel {

    id       : string;
    title    : string;
    updatedAt: string;
    url      : string;
    text     : string;
    children: Documentation[];


    constructor(properties: AnyObj) {
        super({});

        this.id        = properties.id;
        this.title     = properties.title;
        this.updatedAt = properties.updatedAt;
        this.url       = properties.url;
        this.text      = properties.text;
        this.children  = properties.children;
    }
}

export default Documentation;
