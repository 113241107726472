import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";

function Goals() {
    const { t } = useTranslation();

    document.title = `Yoda - ${t('app.goals')}`;

    return (
        <div className="Goal">
            <Outlet />
        </div>
    );
}

export default Goals;
