import { Quotes } from '../../services';
import { Dispatch } from 'redux';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Callback, Response } from './rest';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Quotes);

const TO_STEP         = 'yoda/quote/TO_STEP';
const TO_STEP_SUCCESS = 'yoda/quote/TO_STEP_SUCCESS';
const TO_STEP_FAILURE = 'yoda/quote/TO_STEP_FAILURE';
const TO_STEP_RESET   = 'yoda/quote/TO_STEP_RESET';

const toStepReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case TO_STEP:
            return {
                ...state,
                toStep: 'pending',
                toStepChangeId: action._id,
                toStepError: null
            };
        case TO_STEP_SUCCESS:
            return {
                ...state,
                toStep: 'succeeded',
                toStepError: null,
                quote: action.quote
            };
        case TO_STEP_FAILURE:
            return {
                ...state,
                toStep: 'failed',
                toStepError: action.error
            };
        case TO_STEP_RESET:
            return {
                ...state,
                toStep: 'idle',
                toStepChangeId: null,
                toStepError: null,
            };
        default:
            return state;
    }
};

const toStepAction = (_id: string, step: string, params?: AnyObj[], callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: TO_STEP, _id});
        return Quotes.toStep(_id, step, params)
            .then((data: Response) => {
                const {quote} = data;
                dispatch({type: TO_STEP_SUCCESS, quote});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: TO_STEP_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer, toStepReducer
    ]);
}

/* Export CRUD actions */
export const createQuote        = createAction;
export const startCreateQuote   = startCreateAction;
export const loadQuote          = getAction;
export const loadQuotes         = listAction;
export const updateQuote        = updateAction;
export const deleteQuote        = deleteAction;
export const duplicateQuote     = duplicateAction;
export const toQuoteStep        = toStepAction;
