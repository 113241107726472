import BaseModel from './BaseModel';
import Project from './Project';
import Change from './Change';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a goal. */
class Goal extends BaseModel {
    /**
     * Status constants
     */
    static STATUS_FINISHED  = 'finished';
    static STATUS_RUNNING   = 'running';
    static STATUS_COMING    = 'coming';

    _id: string;
    name: string;
    projects: Project[];
    changes: Change[];
    createdAt: Moment;
    updatedAt: Moment;
    startAt: Moment;
    stopAt: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.name      = properties.name;
        this.projects  = (properties.projects || []).map((p: AnyObj) => _.isObject(p) ? new Project(p) : new Project({_id: p}));
        this.changes   = (properties.changes || []).map((c: AnyObj) => new Change(c));
        this.createdAt = moment(properties.createdAt);
        this.updatedAt = moment(properties.updatedAt);
        this.startAt   = moment(properties.startAt);
        this.stopAt    = moment(properties.stopAt);
    }

    static allStatus() : string[] {
        return [
            Goal.STATUS_FINISHED,
            Goal.STATUS_RUNNING,
            Goal.STATUS_COMING
        ];
    }

    statusIndex(status?: string) {
        return Goal.allStatus().indexOf(status || this.status());
    }

    statusColor() {
        const index = this.statusIndex();
        return [
            'yellow',
            'blue',
            'green',
            'red'
        ][index];
    }

    status(): string {
        const now = moment();
        if (this.stopAt.isBefore(now))
            return Goal.STATUS_FINISHED;

        if (this.startAt.isAfter(now))
            return Goal.STATUS_COMING;

        return Goal.STATUS_RUNNING;
    }

    totalEstimated() {
        return (this.changes || []).reduce((acc: number, change: Change) => {
            return acc + change.estimate;
        }, 0);
    }

    progression() : number {
        const totalEstimated = this.totalEstimated();
        if (totalEstimated === 0)
            return 0;

        const totalRealised = this.changes.reduce((acc: number, change: Change) => {
            return acc + (
                change.estimate * change.progression() / 100
            );
        }, 0);

        return totalRealised / totalEstimated * 100;
    }

}

export default Goal;
