import { Config } from '../config';
import { store } from '../redux';

export type FetchOptions = {
    method: string,
    headers: AnyObj,
    body?: string | FormData,
    credentials?: 'omit'|'same-origin'|'include'
}

export default class Client {
    baseUrl: string;
    headers: AnyObj;

    constructor() {
        this.baseUrl = Config.api_end_point;
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    _fetch(
        path: string,
        method: string,
        query?: AnyObj | null,
        body?: AnyObj | null,
        extraHeaders: {} = {}
    ) {
        const subscriberId = store.getState()?.mercure?.subscriberId;
        if (subscriberId)
            this.headers['X-MERCURE-SUBSCRIBER'] = subscriberId;

        let url = `${this.baseUrl}${path}`;
        if (query) {
            let qs = require('qs');
            let q  = qs.stringify(query);
            url = `${url}?${q}`;
        }

        const options: FetchOptions = {
            method,
            headers: { ...this.headers, ...extraHeaders },
            credentials: 'include',
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        return fetch(url, options);
    }

    GET(route: string, query?: AnyObj, extraHeaders: AnyObj = {}) {
        return this._fetch(route, 'GET', query, null, extraHeaders);
    }

    POST(route: string, body?: AnyObj, extraHeaders: AnyObj = {}) {
        return this._fetch(route, 'POST', null, body, extraHeaders);
    }

    PUT(route: string, body?: AnyObj, extraHeaders: AnyObj = {}) {
        return this._fetch(route, 'PUT', null, body, extraHeaders);
    }

    DELETE(route: string, query?: AnyObj, extraHeaders: AnyObj = {}) {
        return this._fetch(route, 'DELETE', query, null, extraHeaders);
    }

    UPLOAD(route: string, body?: AnyObj) {

    }
};
