import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadSprints } from '../../../redux';
import { useNavigate } from "react-router-dom";
import { SprintResume, Button, Icon } from '../../../components';
import { Table } from 'flowbite-react';
import { Sprint } from '../../../models';

function SprintsList() {
    const { t }    = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { sprints } = useAppSelector((state: RootState) => state.sprints);

    useEffect(() => {
        dispatch(loadSprints());
    }, [dispatch]);

    const runningSprints = React.useMemo(() => {
        if (!sprints.length)
            return null;

        return sprints.filter((sprint: Sprint) => sprint.isRunning());
    }, [sprints]);

    const rowClassName = (sprint: Sprint, base?: string) => {
        let className = 'whitespace-nowrap text-gray-900 dark:text-white ' + base;

        if (sprint.getEstimatedHours() > 0) {
            if (sprint.getRemainingHours() === 0)
                return className + ' bg-green-50';
            if (sprint.isFinished())
                return className + ' bg-red-50';
        }

        if (sprint.isRunning())
            return className + ' bg-yellow-50';

        return className;
    };

    return (
        <div className="SprintsList">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="sprint" size={7} className="mr-1.5 inline" />
                            { t('app.sprints') }
                        </h2>
                    </div>
                    <div className="mt-5 flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button className="w-full" title={t('sprints.merge_requests.merge_requests')} color="navigate" to="/sprints/mergerequests" icon="merge" />
                        </span>
                        <span className="sm:ml-3">
                            <Button className="w-full" title={t('sprints.add_a_sprint')} color="navigate" to="/sprints/new" icon="plus" />
                        </span>
                    </div>
                </div>
            </header>
            <main>
                <div className="p-5">

                    {runningSprints?.map((sprint: Sprint) => (
                        <div key={sprint._id} className="mb-4">
                            <h3 className="text-xl font-bold text-center">
                                {t('sprints.name')} {sprint.name}
                                <div className="text-sm italic">
                                    {sprint.startAt.format('L')} - {sprint.stopAt.format('L')}
                                </div>
                            </h3>
                            <SprintResume key={sprint._id} sprint={sprint} showLink />
                        </div>
                    ))}

                    <Table striped hoverable>
                        <Table.Head>
                            <Table.HeadCell>{t('sprints.name')}</Table.HeadCell>
                            <Table.HeadCell className="text-left">{t('sprints.startAt')}</Table.HeadCell>
                            <Table.HeadCell className="text-left">{t('sprints.stopAt')}</Table.HeadCell>
                            <Table.HeadCell className="text-center">{t('sprints.total_hours')}</Table.HeadCell>
                            <Table.HeadCell className="text-center">{t('sprints.estimated')}</Table.HeadCell>
                            <Table.HeadCell className="text-center">{t('sprints.done')}</Table.HeadCell>
                            <Table.HeadCell className="text-center">{t('sprints.todo')}</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {sprints?.map((sprint: Sprint) => (
                                <Table.Row key={sprint?._id} onClick={() => navigate(`/sprints/${sprint._id}`)} className="dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
                                <Table.Cell className={rowClassName(sprint, 'font-bold')}>
                                    <div className="flex-row">
                                        {sprint?.name}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-light')}>
                                    <Icon type="date" className="inline" />&nbsp;
                                    {sprint.startAt.format('L')}
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-light')}>
                                    <Icon type="date" className="inline" />&nbsp;
                                    {sprint.stopAt.format('L')}
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-light')}>
                                    <Icon type="user" className="inline" />&nbsp;
                                    <>
                                        {sprint.getAvailableHours().toFixed(2)} {t('sprints.estimate_units_min')}
                                    </>
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-light')}>
                                    <Icon type="estimated" className="inline" />&nbsp;
                                    <>
                                        {sprint.getEstimatedHours().toFixed(2)} {t('sprints.estimate_units_min')}
                                    </>
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-light')}>
                                    <Icon type="check" className="inline" />&nbsp;
                                    <>
                                        {sprint.getRealisedHours().toFixed(2)} {t('sprints.estimate_units_min')}
                                    </>
                                </Table.Cell>
                                <Table.Cell className={rowClassName(sprint, 'font-bold')}>
                                    <Icon type="todo" className="inline" />&nbsp;
                                    <>
                                        {sprint.getRemainingHours().toFixed(2)} {t('sprints.estimate_units_min')}
                                    </>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        </Table.Body>
                    </Table>
                </div>
            </main>
        </div>
    );
}

export default SprintsList;
