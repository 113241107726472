import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Ability, Select } from '../../../components';
import { Goal, Project } from '../../../models';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadGoals, loadGoal } from '../../../redux';
import { Icon, Button, Reporting, Tooltip } from '../../../components';
import { Progress } from 'flowbite-react';
import moment from 'moment';

function GoalView() {
    const { t, i18n } = useTranslation();
    const dispatch    = useAppDispatch();
    const navigate    = useNavigate();

    const { id } = useParams();

    const { goal, goals, loadingOne } = useAppSelector((state: RootState) => state.goals);

    useEffect(() => {
        dispatch(loadGoals());
    }, [dispatch]);

    useEffect(() => {
        if (id && (loadingOne !== 'pending'))
            dispatch(loadGoal(id));
    }, [dispatch, id]);

    const goToGoal = (goalId: string | number) => {
        return navigate(`/goals/${goalId}`);
    };

    if (!goal)
        return null;

    const goalProgression = (goal: Goal) => {
        const progression = Math.round(goal?.progression());

        return (
            <Tooltip content={progression + '%'} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="lg"
                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    const timeProgression = (goal: Goal) => {
        const nbDays     = goal.stopAt.diff(goal.startAt, 'days');
        const burnedDays = moment().diff(goal.startAt, 'days');

        const progression = Math.round(burnedDays / nbDays * 100);

        return (
            <Tooltip content={progression + '%'} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="lg"
                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    const isLoading = ((loadingOne + '') === 'pending');

    document.title = `Yoda - ${t('changes.goal')} ${goal.name}`;

    return (
        <Ability can="goal:read" redirect="/">
            <div className="GoalView print:font-netox">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8 print:hidden">
                    <div className="mb-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
                            <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="goal" className="inline" size={8} />
                                <Select
                                    onChange={goToGoal}
                                    placeholder={goal?.name}
                                    options={goals.filter((p: Goal) => p._id !== goal?._id).map((goal: Goal) => ({
                                        value: goal._id,
                                        label: goal.name,
                                        className: 'text-xl font-bold',
                                        leftContent: (
                                            <Icon type="goal" className="inline ml-3 border rounded" size={6} />
                                        )
                                    }))}
                                    inline
                                    className=""
                                    optionsWidth={'w-[30rem]'}
                                    optionsHeight={'max-h-96'}
                                    selectedClassName="text-xl font-bold text-gray-900"
                                    optionsClassName="w-[29rem] text-sm font-medium py-2 pl-10 truncate"
                                />
                            </h2>
                        </div>
                        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 mt-2 lg:mt-0">
                            <Ability can="goal:edit">
                                <span className="sm:ml-3">
                                    <Button
                                        to={`/goals/edit/${goal?._id}/`}
                                        title={t('common.edit')}
                                        icon="edit"
                                        color="secondary"
                                    />
                                </span>
                            </Ability>
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/goals" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {isLoading ? (
                    <main className="mx-6 mt-2 flex justify-center items-center h-[calc(100vh-8rem)] w-full">
                        <Icon type="loading" size={20} />
                    </main>
                ) : (
                    <main className="mx-6 mt-2">
                        <div className="mb-6">
                            <h3 className="text-center text-2xl font-bold print:text-left">
                                {goal.name}
                            </h3>
                            <h3 className="text-center text-xs italic font-normal print:hidden">
                                {t('goals.stopAt')}&nbsp;
                                {goal.stopAt.format('LL')}
                            </h3>
                        </div>

                        <div className="mb-6 print:hidden">
                            <hr />
                            <h4 className="mb-4 text-center font-light leading-7 text-gray-900">
                                {t('goals.projects')}&nbsp;:&nbsp;
                                {goal?.projects.map((p: Project) => p.name).join(', ')}
                            </h4>
                            <hr />
                            <h4 className="mb-4 text-center font-light leading-7 text-gray-900">
                                {t('goals.total_estimated', {total: goal.totalEstimated().toFixed(2)})}
                            </h4>
                            <hr />
                        </div>

                        <div className="border rounded p-2 pb-4 mb-4">
                            <h3 className="font-bold">{ t('goals.progression') }</h3>
                            <div className="flex items-center">
                                <div className="w-6">
                                    <Icon type="progression" />
                                </div>
                                <div className="w-full">
                                    { goalProgression(goal) }
                                </div>
                            </div>
                        </div>

                        <div className="border rounded p-2 pb-4 mb-4">
                            <h3 className="font-bold">{ t('goals.time_progression') }</h3>
                            <div className="flex items-center">
                                <div className="w-6">
                                    <Icon type="date" />
                                </div>
                                <div className="w-full">
                                    { timeProgression(goal) }
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mt-2 print:break-before-page">
                            <div className="col-span-1 border print:border-none p-4 print:p-0 rounded-md">
                                <Reporting
                                    goal={goal}
                                    changes={goal?.changes}
                                    tabs={['stats', 'changes', 'report', 'tracks']}
                                />
                            </div>
                        </div>
                    </main>
                )}
            </div>
        </Ability>
    );
}

export default GoalView;
