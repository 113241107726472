import React, { useEffect } from 'react';
import { useField, Field } from 'formik';
import { User } from '../../models';
import { Icon, UserAvatar } from '..';

interface UserAvatarFieldProps {
    name: string;
    users?: User[];
    className?: string;
    defaultValue?: string;
    onSave?: (field: string, value: string) => void;
};

const UserAvatarField = (props: UserAvatarFieldProps) => {
    const { name, users, className, defaultValue, onSave } = props;

    const [ field, meta, helpers ] = useField(name);
    const { setValue } = helpers;
    const { value, touched, error } = meta;

    const assignUser = (id: any) => {
        setValue(id);
        onSave && onSave(`${name}`, id);
    }

    useEffect(() => {
        if (defaultValue)
            setValue(defaultValue);
    }, [defaultValue]);

    const sorted = React.useMemo(() => {
        return users?.sort((a: User, b: User) => {
            return a.fullname() > b.fullname() ? 1 : -1;
        });
    }, [users]);

    const avatar = (user: User) => {
        return (
            <UserAvatar
                key={user.email}
                user={user}
                onClick={() => assignUser(user._id)}
                selected={value === user?._id}
            />
        );
    }

    return (
        <>
            <div className={`grid grid-cols-6 sm:grid-cols-8 md:grid-cols-none md:flex md:justify-between gap-2 gap-y-4 ${className}`}>
                {sorted?.map(user => (
                    <div key={user.email} className="col-span-1">
                        {avatar(user)}
                    </div>
                ))}
                <div className="col-span-1">
                    <div onClick={() => assignUser("")} className="overflow-hidden relative cursor-pointer w-8 h-8 select-none text-center rounded-full text-xs ring ring-offset-0 flex items-center justify-center font-bold hover:bg-blue-100 hover:ring-2 hover:ring-blue-100 ring-1 ring-gray-300 undefined" >
                        <Icon type="close" color="red-600" size={5} />
                    </div>
                </div>
            </div>
            <Field as="input" type="hidden" name={name} />
            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
        </>
    );
};

export default UserAvatarField;
