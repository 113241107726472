import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RTIntervalpicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import type { Moment } from 'moment';

export type Interval = {
    startDate: Moment;
    endDate: Moment;
}

interface IntervalpickerProps {
    onChange: (value: Interval) => void;
    value?: Interval;
    defaultValue?: Interval;
    from?: Moment;
    useRange?: Moment;
    minDate?: Moment;
    maxDate?: Moment;
    direction?: any;
    separator?: any;
    inputClassName?: string;
    toggleClassName?: string;
};

const Intervalpicker = (props: IntervalpickerProps) => {
    const { t, i18n } = useTranslation();

    const {
        defaultValue, onChange, direction, from, separator, useRange,
        minDate, maxDate, inputClassName, toggleClassName
    } = props;

    const [value, setValue] = useState(defaultValue ? {
        startDate: (defaultValue?.startDate || moment()).toDate(),
        endDate: (defaultValue?.endDate || moment()).toDate(),
    } : null);

    const handleValueChange = (newValue: any) => {
        setValue(newValue);
        onChange({
            startDate: moment(newValue.startDate),
            endDate: moment(newValue.endDate)
        });
    }

    useEffect(() => {
        if (props.value)
            setValue({
                startDate: props.value?.startDate.toDate() || moment().toDate(),
                endDate: props.value?.endDate.toDate() || moment().toDate()
            });
    }, [props.value]);

    return (
        <RTIntervalpicker
            i18n={i18n.language}
            startWeekOn="mon"
            asSingle={false}
            useRange={!!useRange}
            startFrom={from?.toDate()}
            minDate={minDate?.toDate()}
            maxDate={maxDate?.toDate()}
            popoverDirection={direction}
            value={value}
            separator={separator || '~'}
            onChange={handleValueChange}
            containerClassName="relative"
            inputClassName={inputClassName || ''}
            toggleClassName={toggleClassName || ''}
            displayFormat={t('common.input_date_format') || ''}
            showShortcuts={true}
            configs={{
                shortcuts: {
                    today: t('common.calendar_periods.TText') || '',
                    yesterday: t('common.calendar_periods.YText') || '',
                    past: period => t(`common.calendar_periods.P-${period}`) || '',
                    currentMonth: t('common.calendar_periods.CMText') || '',
                    pastMonth: t('common.calendar_periods.PMText') || ''
                }
            }}
        />
    );
}

export default Intervalpicker;
