import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export type Value = string | number;
export type SelectOption = {
    value: Value;
    label: string;
    disabled?: boolean;
    leftContent?: JSX.Element;
    rightContent?: JSX.Element | null;
}

interface SelectProps {
    options: SelectOption[];
    defaultValue?: Value;
    onChange: (value: Value) => void;
    inline?: boolean;
    placeholder?: string;
    className?: string;
    containerClassName?: string;
    selectedClassName?: string;
    optionsContainerClassName?: string;
    optionsClassName?: string;
    optionsWidth?: string | number;
    optionsHeight?: string | number;
};

const Select = (props: SelectProps) => {
    const {
        options, placeholder, onChange, defaultValue,
        className, containerClassName, selectedClassName,
        optionsContainerClassName, optionsClassName, inline,
        optionsWidth, optionsHeight
    } = props;

    const [value, setValue] = useState<Value>(defaultValue || "");

    const select = (value: Value) => {
        setValue(value);
        onChange && onChange(value);
    };

    const selected = useMemo(() => {
        return options.find((option: SelectOption) => option.value === value);
    }, [options, value]);

    useEffect(() => {
        if (defaultValue !== value)
            setValue(defaultValue || "");
    }, [defaultValue]);

    return (
        <Listbox value={value} onChange={select}>
            <div className={`relative mt-1 w-full ${className}`}>
                <Listbox.Button className={containerClassName || `relative w-full cursor-default ounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ${inline ? '' : 'shadow-md'}`}>
                    <span className={`block ${selectedClassName || ''}`}>{selected?.label || placeholder}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className={`absolute z-[10000] mt-1 ${optionsWidth ?? 'w-full'} ${optionsHeight} overflow-auto no-scrollbar rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionsContainerClassName || ''}`}>
                        {options.map((option: SelectOption, i: number) => (
                            <Listbox.Option
                                key={i}
                                value={option.value}
                                disabled={!!option.disabled}
                                className={({ active, selected }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${optionsClassName || ''} ${
                                        (active || selected) ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                    }`
                                }
                            >
                                {({ selected }) => (
                                    <span className="flex justify-start items-center">
                                        {option.leftContent ? (
                                            <span className="absolute left-0 flex items-center">
                                                {option.leftContent}
                                            </span>
                                        ) : selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                        <span className={`block ${selected ? 'font-medium' : 'font-normal'}`}>
                                            {option.label}
                                        </span>
                                        {option.rightContent ? (
                                            <span className="absolute right-0 flex items-center">
                                                {option.rightContent}
                                            </span>
                                        ) : null}
                                    </span>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
};

export default Select;
