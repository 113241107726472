import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import type { StoreEnhancerStoreCreator } from 'redux';

import reducer from './ducks/reducer';

const configureStore = (initialState: AnyObj = {})  => {
    const middlewares        = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers         = [middlewareEnhancer];
    const composedEnhancers = compose<StoreEnhancerStoreCreator>(...enhancers);

    const store = createStore(reducer, initialState, composedEnhancers);

    /*if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./ducks/reducer', () => store.replaceReducer(reducer));
    }*/

    return store;
}

const store = configureStore();

export { store };
export * from './ducks';
export * from './hooks';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

interface ActionPayload extends AnyObj {
    type: string;
    [key: string] : any
}

export type { ActionPayload };
