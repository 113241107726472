import RestClient from './RestClient';
import { Board } from '../models';

class Boards extends RestClient {
    constructor() {
        super({
            // rest config
            model: Board,
            entryPoint: 'boards',
            sortBy: 'name',
            id_field: '_id',
            // redux config
            resource: 'board',
            resources: 'boards',
        });
    }
}

const BoardsApi = new Boards();

export default BoardsApi;
