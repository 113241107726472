import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { startCreateSprint, createSprint } from '../../../redux';
import { Alert, Button } from '../../../components';
import SprintForm from '../SprintForm/SprintForm';
import moment from 'moment';
import { Moment } from 'moment';

const nextDay = (day: number) : Moment => {
    const today = moment().isoWeekday();
    return today <= day ?
        moment().isoWeekday(day) :
        moment().add(1, 'weeks').isoWeekday(day);
}

function SprintCreate() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { sprint, created, creatingError } = useAppSelector((state: RootState) => state.sprints);

    useEffect(() => {
        const nextMonday = nextDay(1).startOf('day');
        const nextFriday = nextDay(1).add(4, 'days').endOf('day');
        const defaultSprint = {
            // Year + week of year
            name: nextMonday.format('YYYY-WW'),
            // start next monday
            startAt: nextMonday.toISOString(),
            // stop next friday
            stopAt: nextFriday.toISOString(),
        };
        dispatch(startCreateSprint(defaultSprint));
    }, [dispatch]);

    const submit = function (values: AnyObj) : void {
        dispatch(createSprint(values));
    }

    if (created)
        navigate(`/sprints/${sprint._id}`);

    return (
        <div className="SprintCreate">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-black-900">
                            { t('sprints.add_a_sprint') }
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button title={t('common.back')} color="navigateBack" to="/sprints" icon="back" />
                        </span>
                    </div>
                </div>
            </header>
            {creatingError && (
                <div className="flex-1">
                    <Alert title={t('sprints.creation_failed') + ' : ' + creatingError} color="failure" icon="warning" />
                </div>
            )}
            <main className="mx-6 my-6 shadow">
                {sprint && (!sprint._id) && (
                    <SprintForm isCreation={true} initialValues={sprint} onSubmit={submit} />
                )}
            </main>
        </div>
    );
}

export default SprintCreate;
