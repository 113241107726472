import BaseModel from './BaseModel';
import moment from 'moment';
import type { Moment } from 'moment';
import md5 from "crypto-js/md5";

/** Class representing a user. */
class Client extends BaseModel {
    _id: string;
    id: number;
    refId: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    email?: string;
    address?: string;
    postcode?: string;
    city?: string;
    country?: string;
    createdAt?: Moment;
    updatedAt?: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.id        = properties.id;
        this.refId     = properties.refId;
        this.firstname = properties.firstname;
        this.lastname  = properties.lastname;
        this.company   = properties.company;
        this.email     = properties.email;
        this.address   = properties.address;
        this.postcode  = properties.postcode;
        this.city      = properties.city;
        this.country   = properties.country;

        if ('createdAt' in properties)
            this.createdAt = moment(properties.createdAt);
        if ('updatedAt' in properties)
            this.updatedAt = moment(properties.updatedAt);
    }

    name() {
        return [ this.id, this.company, this.fullname() ]
            .filter(s => !!s)
            .join(' - ');
    }

    fullname() {
        return [ this.firstname, this.lastname ]
            .filter(s => !!s)
            .join(' ');
    }

    getInitials() {
        return this.name().match(/(\b\S)?/g)?.join("")?.match(/(^\S|\S$)?/g)?.join("");
    }

    getAvatarUrl(size: number = 80) {
        return `https://secure.gravatar.com/avatar/${md5((this.email || this.name())?.toLowerCase().trim())}?s=${size}&d=identicon`;
    }
}

export default Client;
