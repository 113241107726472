import { Report, Statistic } from '../models';
import RestClient from './RestClient';

class Statistics extends RestClient {
    constructor() {
        super({
            // rest config
            model: Statistic,
            entryPoint: 'statistics',
            // redux config
            resource: 'statistics',
            resources: 'statistics',
        });
    }

    getStatistics(params?: Array<string>) {
        params = params ? params : ["all"];
        // statistcis overview
        return this.GET(`/${this.entryPoint}/overview`, params)
            .then(response => response.json())
            .then(json => {
                if (json.statistics) {
                    return { statistics: new Statistic(json.statistics) };
                }
                throw new Error(json.flash.error);
            });
    }

    getReport(params?: AnyObj) {
        return this.GET(`/${this.entryPoint}/report`, params)
            .then(response => response.json())
            .then(json => {
                if (json.report) {
                    return { report: new Report(json.report) };
                }
                throw new Error(json.flash.error);
            });
    }
}

const StatisticsApi = new Statistics();

export default StatisticsApi;
