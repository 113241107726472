import { ActionPayload } from '..';
import { Callback } from './rest';
import { Dispatch } from 'redux';

const REMOTE_UPDATE     = 'yoda/mercure/REMOTE_UPDATE';
const SET_SUBSCRIBER_ID = 'yoda/mercure/SET_SUBSCRIBER_ID';

interface UpdateData {
    type: string;
    data: AnyObj;
}

const initialState = {
    subscriberId: null
};

const reducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case SET_SUBSCRIBER_ID:
            return {
                subscriberId: action.subscriberId
            };
        default:
            return state;
    }
};

export default reducer;

export const setSubscriberId = (subscriberId: string, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: SET_SUBSCRIBER_ID, subscriberId});
        callback && callback(/*err*/null);
    }
}

export const onRemoteUpdate = (update: UpdateData, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: REMOTE_UPDATE, update});
        callback && callback(/*err*/null);
    }
}
