import React from 'react';
import { useTranslation } from 'react-i18next';
import { Change } from '../../models';
import { useAppSelector, useAppDispatch } from '../../redux';
import { loadActiveTrack, createTrack, stopActiveTrack } from '../../redux';
import { Icon } from '..';

interface ChangeTrackProps {
    change: Change;
    className?: string;
}

function ChangeTrack(props: ChangeTrackProps) {
    const { change, className } = props;

    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const { user }                                  = useAppSelector(state => state.auth);
    const { active, loadingActive, stoppingActive } = useAppSelector(state => state.tracks);

    const start = (e: AnyObj) => {
        e.stopPropagation();

        let title = `${change.slugName()} - ${change.localizedTitle(i18n.language)}`;
        if (change.isInReview(/*onlyByTeam*/true) && user?._id !== change.assignee?._id)
            title = '[Review] ' + title;

        dispatch(createTrack({
            change,
            title,
            project: change.project,
        }, {}, () => {
            dispatch(loadActiveTrack());
        }));
    };

    const stop = (e: AnyObj) => {
        e.stopPropagation();
        dispatch(stopActiveTrack());
    };

    if (!change)
        return null;

    const isLoading = (
        loadingActive === 'pending' ||
        stoppingActive === 'pending'
    );

    if (!user?.can('track:create'))
        return null;

    return (
        <div className={`group ${className || ''} cursor-pointer`}>
            {active?.change?._id !== change._id && (
                <div onClick={start}>
                    <Icon type={isLoading ? 'loading' : 'start'} color="blue-500" size={8} />
                </div>
            )}
            {active?.change?._id === change._id && (
                <>
                    <Icon type="running" className="group-hover:hidden" color="blue-500" size={8} />
                    <div onClick={stop}>
                        <Icon type={isLoading ? 'loading' : 'stop'} className="hidden group-hover:block" color="red-500" size={8} />
                    </div>
                </>
            )}
        </div>
    );
}

export default ChangeTrack;
