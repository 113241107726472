import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Sprint } from '../../models';
import { Icon } from '../';

interface SprintResumeProps {
    sprint: Sprint;
    showLink?: boolean;
};

const SprintResume = (props: SprintResumeProps) => {
    const { sprint, showLink } = props;

    const { t } = useTranslation();

    const content = React.useMemo(() => {
        if (!sprint)
            return null;

        return (
            <div className="flex flex-col bg-white border-b px-4 py-6 sm:px-6 xl:px-0 rounded-md shadow">
                <div className="grid grid-cols-1 xl:grid-cols-4 space-y-4 xl:space-y-0">
                    <div className="col-span-1 flex justify-center items-center space-x-4 pb-2 xl:pb-0 border-b-2 border-b-gray-500 xl:border-b-0 xl:border-r-2 xl:border-r-gray-500 pb-4 xl:px-4">
                        <Icon type="user" size={12} />
                        <div className="flex flex-col text-center">
                            <span className="text-xl font-bold">
                                {sprint.getAvailableHours().toFixed(2)}&nbsp;
                                {t('sprints.estimate_units_min')}
                            </span>
                            <span className="">
                                {t('sprints.total_hours')}
                            </span>
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-center items-center space-x-4 pb-2 xl:pb-0 border-b-2 border-b-gray-500 xl:border-b-0 xl:border-r-2 xl:border-r-gray-500 pb-4 xl:px-4">
                        <Icon type="list" size={12} />
                        <div className="flex flex-col text-center">
                            <span className="text-xl font-bold">
                                {sprint.getEstimatedHours().toFixed(2)}&nbsp;
                                {t('sprints.estimate_units_min')}
                            </span>
                            <span className="">
                                {t('sprints.total_estimated')}
                            </span>
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-center items-center space-x-4 pb-2 xl:pb-0 pb-4 xl:px-4">
                        <Icon type="check" size={12} />
                        <div className="flex flex-col text-center">
                            <span className="text-xl font-bold">
                                {sprint.getRealisedHours(sprint.stopAt).toFixed(2)}&nbsp;
                                {t('sprints.estimate_units_min')}
                            </span>
                            <span className="">
                                {t('sprints.done')}
                            </span>
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-center items-center space-x-4 pb-2 xl:pb-0 border-b-2 border-b-gray-500 xl:border-b-0 xl:border-r-2 xl:border-r-gray-500 pb-4 xl:px-4">
                        <Icon type="todo" size={12} />
                        <div className="flex flex-col text-center">
                            <span className="text-xl font-bold">
                                {sprint.getRemainingHours().toFixed(2)}&nbsp;
                                {t('sprints.estimate_units_min')}
                            </span>
                            <span className="">
                                {t('sprints.todo')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [t, sprint]);

    if (!showLink)
        return content;

    return (
        <Link to={`/sprints/${sprint._id}`} className="">
            {content}
        </Link>
    );
};

export default SprintResume;
