
import { Documentation } from '../models';
import RestClient from './RestClient';

class Documentations extends RestClient {
    constructor() {
        super({
            // rest config
            model: Documentation,
            entryPoint: 'documentations',
            //sortBy: 'date',
            //id_field: '_id',
            // redux config
            resource: 'documentation',
            resources: 'documentations',
        });
    }

    getDocumentationTree() {
        let url = `/${this.entryPoint}/tree`;

        return this.GET(url, {})
            .then(response => response.json())
            .then(json => {
                if (json.tree)
                    return json;

                throw new Error(json.flash.error);
        });
    }
}
const DocumentationsApi = new Documentations();

export default DocumentationsApi;
