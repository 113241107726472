import React from 'react';
import { Track } from '../../models';
import { useAppSelector, useAppDispatch } from '../../redux';
import { loadTrack, deleteTrack, loadActiveTrack, createTrack, stopActiveTrack } from '../../redux';
import { Icon } from '..';

interface ResumeTrackProps {
    track: Track;
    className?: string;
    hideRemove?: boolean;
}

function ResumeTrack(props: ResumeTrackProps) {
    const { track, className, hideRemove } = props;

    const dispatch = useAppDispatch();

    const { active, loadingActive, stoppingActive } = useAppSelector(state => state.tracks);

    const start = (e: AnyObj) => {
        e.stopPropagation();
        dispatch(createTrack({
            title: track.title,
            change: track.change,
            project: track.project,
            client: track.client,
        }, {}, () => {
            dispatch(loadActiveTrack());
        }));
    };

    const remove = (e: AnyObj) => {
        e.stopPropagation();
        dispatch(deleteTrack(track._id));
    };

    const stop = (e: AnyObj) => {
        e.stopPropagation();
        dispatch(stopActiveTrack((err: Error | null) => {
            dispatch(loadTrack(active._id));
        }));
    };

    if (!track)
        return null;

    const isLoading = (
        loadingActive === 'pending' ||
        stoppingActive === 'pending'
    );

    return (
        <div className={`group ${className || ''} cursor-pointer`}>
            {!active && (
                <div className="flex flex-row justify-end items-center space-x-2">
                    { !hideRemove && (
                        <div onClick={remove}>
                            <Icon type={isLoading ? 'loading' : 'delete'} className="hidden group-hover:block" color="red-500" size={6} />
                        </div>
                    )}
                    <div onClick={start}>
                        <Icon type={isLoading ? 'loading' : 'start'} color="blue-500" size={8} className="roup-hover:hidden" />
                    </div>
                </div>
            )}

            {active && active._id !== track._id && (
                <div className="flex flex-row justify-end items-center space-x-2" onClick={start}>
                    <Icon type={isLoading ? 'loading' : 'start'} color="blue-500" size={8} />
                </div>
            )}

            {active && active._id === track._id && (
                <div className="flex flex-row justify-end items-center space-x-0">
                    <Icon type="running" className="group-hover:hidden" color="blue-500" size={8} />
                    <div onClick={stop}>
                        <Icon type={isLoading ? 'loading' : 'stop'} className="hidden group-hover:block" color="red-500" size={8} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ResumeTrack;
