import React from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector } from '../../redux';
import { Quote } from '../../models';
import type { QuoteItem } from '../../models';
import { formatPrice } from '../../locales';
import logo from '../../assets/images/logo_netoxygen.png';
import moment from 'moment';

interface QuoteTextProps {
    quote: Quote;
    part: string;
}

function QuoteText(props: QuoteTextProps) {
    const { t }           = useTranslation();
    const { quote, part } = props;

    const { user } = useAppSelector((state: RootState) => state.auth);

    if (part === 'netoxygen')
        return (
            <div className="text-black text-xs flex flex-col gap-y-0 text-justify font-netox">
                <img className="h-6 w-fit mb-1" src={logo} alt="Yoda" />
                <p>Avenue d'Aïre 56 - 1203 Genève</p>
                <p>Téléphone : +41 22 364 80 00</p>
                <p>Email : info@netoxygen.ch</p>
            </div>
        );

    if (part === 'date')
        return (
            <div className="text-black text-sm">
                <p>Genève, le {moment().format('LL')}</p>
            </div>
        );

    if (part === 'intro')
        return (
            <div className="text-black text-sm flex flex-col gap-y-2 text-justify">
                <p>Madame, Monsieur,</p>

                <p>Suite à nos dernières discussions pour lesquelles nous vous remercions, nous avons le plaisir de vous faire parvenir l'offre ci-dessous sous forme d'une liste de propositions.</p>

                <p>Net Oxygen est une société constituée principalement d'ingénieurs issus de l'EPFL et d'autres hautes écoles suisses. Depuis maintenant plus de 20 ans, nous mettons à disposition de nos clients notre expérience en matière de services liés aux télécommunications et à Internet ainsi qu'en gestion de parcs informatiques.</p>

                <p>Nos clients apprécient particulièrement le fait d'avoir un interlocuteur unique et de qualité en ce qui concerne leurs moyens informatiques et de télécommunication. Nous avons également à cœur d'offrir des solutions basées sur des standards ouverts et de documenter les parcs informatiques de nos clients afin qu'ils choisissent de poursuivre leur collaboration avec nous, non pas parce que les technologies choisies les y obligent, mais parce qu'ils sont entièrement satisfaits de nos services.</p>

                <p>Nous vous souhaitons une bonne réception de cette offre et n'hésitez pas à prendre contact avec nous pour toute question ou demande de modification.</p>

                <p className="text-right mr-12 mt-4">
                    {user?.firstname} {user?.lastname}
                </p>
            </div>
        );

    if (part === 'approve')
        return (
            <div className="text-black text-sm flex flex-col gap-y-2 text-justify font-netox">
                <p>Validité de l'offre : un mois</p>
                <p>L’offre est soumise à l’acceptation des conditions générales de Net Oxygen Sàrl, disponibles à l’adresse <a className="text-blue-900" href="https://netoxygen.ch/conditions-generales">https://netoxygen.ch/conditions-generales</a>. Tous les prix sont indiqués en francs suisses, hors taxes (TVA 8.1%).</p>
                <p className="font-bold text-lg">Pour accord</p>
                <p>Société............................................................................................................................................</p>
                <p>Monsieur, Madame.......................................................................................................................</p>
                <p>Lieu, date.........................................................................................................................................</p>
                <p>Signature.........................................................................................................................................</p>
            </div>
        );

    if (part === 'pricings' && quote)
        return (
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="p-2 bg-gray-100 text-sm text-bold text-left border">{t('quotes.label')}</th>
                        <th className="p-2 bg-gray-100 text-sm text-bold text-right border border-l-none">{t('quotes.ht_price')}</th>
                    </tr>
                </thead>
                <tbody>
                    {quote.items.filter((item: QuoteItem) => item.price !== 0).map((item: QuoteItem, i: number) => (
                        <tr key={i}>
                            <td className="p-2 text-sm text-medium text-left border flex justify-between">
                                <div dangerouslySetInnerHTML={{ __html: item.title || ''}}></div>
                                {item.free && (
                                    <span className="font-bold px-2">{t('quotes.free_item')}</span>
                                )}
                            </td>
                            <td className={`p-2 text-sm text-medium text-right border border-l-none ${item.free ? 'line-through' : ''}`}>
                                {formatPrice(item.price)}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="p-2 text-md text-medium text-right font-bold border">
                            {t('quotes.total')}
                        </td>
                        <td className="p-2 text-md text-medium text-right font-bold border border-l-none w-36">
                            {formatPrice(quote.price)}
                        </td>
                    </tr>
                </tfoot>
            </table>
        );

    return null;
}

export default QuoteText;
