import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadQuote, updateQuote, loadUsers } from '../../../redux';
import { Ability, Alert, Button } from '../../../components';
import QuoteForm from '../QuoteForm/QuoteForm';

function QuoteEdit() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    let { id }     = useParams();

    const { quote, updatingError } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadQuote(id));
    }, [dispatch, id]);

    const submit = function (values: AnyObj) : void {
        dispatch(updateQuote(values));
    }

    return (
        <Ability can="quote:edit" redirect="/">
            <div className="QuoteEdit">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                { t('quotes.edit_quote', {name: quote?.name}) }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.delete')} color="danger" to={`/quotes/delete/${quote?._id}`} icon="delete" />
                            </span>
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to={`/quotes/${quote?._id}`} icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {updatingError && (
                    <div className="flex-1">
                        <Alert title={t('quotes.creation_failed') + ' : ' + updatingError} color="failure" icon="warning" />
                    </div>
                )}
                <main className="mx-6 my-6 shadow">
                    {quote && (quote._id === id) && (
                        <QuoteForm isCreation={false} initialValues={quote} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default QuoteEdit;
