import { Track } from '../../models';
import { Tracks } from '../../services';
import { createRestSlices, applyReducers, Callback, Response } from './rest';
import { Dispatch } from 'redux';
import { ActionPayload } from '..';

const {
    initialState, resetReducer,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    resetAction,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Tracks);

initialState.active           = undefined;
initialState.loadingActive    = null;
initialState.suggested        = [];
initialState.loadingSuggested = 'idle';

/**
 * We have to add this hook to handle remote modifications on changes
 */
const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'track.updated':
            if (!state.active || state.active._id !== data._id)
                return state;

            const newActive = (!data.stopAt) ? new Track(data) : (
                data._id === state.active?._id ? undefined : state.active
            );
            return {
                ...state,
                active: newActive
            };
        case 'track.created':
            // let's catch the active track
            if (data.stopAt)
                return state;

            return {
                ...state,
                active: new Track(data)
            };
        default:
            return state;
    }
};

const UPDATING_TRACK_SUCCESS = 'yoda/track/UPDATING_ITEM_SUCCESS';
const LOADING_ACTIVE         = 'yoda/track/LOADING_ACTIVE';
const LOADING_ACTIVE_SUCCESS = 'yoda/track/LOADING_ACTIVE_SUCCESS';
const LOADING_ACTIVE_FAILURE = 'yoda/track/LOADING_ACTIVE_FAILURE';
const getActiveReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case LOADING_ACTIVE:
            return {
                ...state,
                loadingActive: 'pending',
                loadingActiveError: null,
                active: null
            };
        case LOADING_ACTIVE_SUCCESS:
            return {
                ...state,
                loadingActive: 'succeeded',
                loadingActiveError: null,
                active: action.track
            };
        case LOADING_ACTIVE_FAILURE:
            return {
                ...state,
                loadingActive: 'failed',
                loadingActiveError: action.error.message,
                active: undefined
            };
        case UPDATING_TRACK_SUCCESS:
            return {
                ...state,
                active: !action.track.stopAt ? action.track : state.active
            };
        default:
            return state;
    }
};

function loadingActive() { return { type: LOADING_ACTIVE } }
function loadingActiveSuccess(track: Track) { return { type: LOADING_ACTIVE_SUCCESS, track } }
function loadingActiveFailure(err: Error) { return { type: LOADING_ACTIVE_FAILURE, error: err } }
const getActiveAction = (callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch(loadingActive());
        return Tracks.getActive()
            .then((data: Response) => {
                const track = data.track;
                dispatch(loadingActiveSuccess(track));
                callback && callback(/*err*/null, track);
            })
            .catch((err: Error) => {
                dispatch(loadingActiveFailure(err))
                callback && callback(err);
            });
    }
};

const STOPPING_ACTIVE         = 'yoda/track/STOPPING_ACTIVE';
const STOPPING_ACTIVE_SUCCESS = 'yoda/track/STOPPING_ACTIVE_SUCCESS';
const STOPPING_ACTIVE_FAILURE = 'yoda/track/STOPPING_ACTIVE_FAILURE';
const stopActiveReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case STOPPING_ACTIVE:
            return {
                ...state,
                stoppingActive: 'pending',
                stoppingActiveError: null,
            };
        case STOPPING_ACTIVE_SUCCESS:
            return {
                ...state,
                stoppingActive: 'succeeded',
                stoppingActiveError: null,
                active: null,
                tracks: state.tracks.map((t: Track) => {
                    return t._id === action.track._id ? action.track : t;
                })
            };
        case STOPPING_ACTIVE_FAILURE:
            return {
                ...state,
                stoppingActive: 'failed',
                stoppingActiveError: action.error.message,
            };
        default:
            return state;
    }
};

function stoppingActive() { return { type: STOPPING_ACTIVE } }
function stoppingActiveSuccess(track: Track) { return { type: STOPPING_ACTIVE_SUCCESS, track } }
function stoppingActiveFailure(err: Error) { return { type: STOPPING_ACTIVE_FAILURE, error: err } }
const stopActiveAction = (callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch(stoppingActive());
        return Tracks.stop()
            .then((data: Response) => {
                const { track } = data;
                dispatch(stoppingActiveSuccess(track));
                callback && callback(/*err*/null);
            })
            .catch((err: Error) => {
                dispatch(stoppingActiveFailure(err))
                callback && callback(err);
            });
    }
};

const LOADING_SUGGESTED         = 'yoda/track/LOADING_SUGGESTED';
const LOADING_SUGGESTED_SUCCESS = 'yoda/track/LOADING_SUGGESTED_SUCCESS';
const LOADING_SUGGESTED_FAILURE = 'yoda/track/LOADING_SUGGESTED_FAILURE';
const getSuggestedReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case LOADING_SUGGESTED:
            return {
                ...state,
                loadingSuggested: 'pending',
                loadingSuggestedError: null,
                suggested: []
            };
        case LOADING_SUGGESTED_SUCCESS:
            return {
                ...state,
                loadingSuggested: 'succeeded',
                loadingSuggestedError: null,
                suggested: action.suggested
            };
        case LOADING_SUGGESTED_FAILURE:
            return {
                ...state,
                loadingSuggested: 'failed',
                loadingSuggestedError: action.error.message,
                suggested: []
            };
        default:
            return state;
    }
};
function loadingSuggested() { return { type: LOADING_SUGGESTED } }
function loadingSuggestedSuccess(suggested: Track[]) { return { type: LOADING_SUGGESTED_SUCCESS, suggested } }
function loadingSuggestedFailure(err: Error) { return { type: LOADING_SUGGESTED_FAILURE, error: err } }
const getSuggestedAction = (params?: AnyObj, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch(loadingSuggested());
        return Tracks.getSuggested(params)
            .then((data: Response) => {
                const tracks = data.tracks;
                dispatch(loadingSuggestedSuccess(tracks));
                callback && callback(/*err*/null, tracks);
            })
            .catch((err: Error) => {
                dispatch(loadingSuggestedFailure(err))
                callback && callback(err);
            });
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        resetReducer,
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer, getActiveReducer,
        stopActiveReducer, onRemoteUpdateReducer,
        getSuggestedReducer
    ]);
}

/* Export CRUD actions */
export const resetTracks         = resetAction;
export const createTrack         = createAction;
export const startCreateTrack    = startCreateAction;
export const loadTrack           = getAction;
export const loadTracks          = listAction;
export const loadActiveTrack     = getActiveAction;
export const stopActiveTrack     = stopActiveAction;
export const updateTrack         = updateAction;
export const deleteTrack         = deleteAction;
export const duplicateTrack      = duplicateAction;
export const loadSuggestedTracks = getSuggestedAction;
