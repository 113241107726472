import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadUser, updateUser } from '../../../redux';
import { Button } from '../../../components';
import UserForm from '../UserForm/UserForm';

function UserEdit() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    let { id }     = useParams();

    const { user, updating } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        if (id)
            dispatch(loadUser(id));
    }, [dispatch, id]);

    const submit = function (values: AnyObj) : void {
        dispatch(updateUser(values));
    }

    return (
        <div className="UserEdit">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            { t('users.edit_user', {name: user?.fullname()}) }
                        </h2>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button title={t('common.delete')} color="danger" to={`/users/delete/${user?._id}`} icon="delete" />
                        </span>
                        <span className="sm:ml-3">
                            <Button title={t('common.back')} color="navigateBack" to="/users" icon="back" />
                        </span>
                    </div>
                </div>
            </header>
            <main className="mx-6 my-6 shadow">
                {user && (user._id === id) && (
                    <UserForm isCreation={false} isLoading={updating === 'pending'} initialValues={user} onSubmit={submit} />
                )}
            </main>
        </div>
    );
}

export default UserEdit;
