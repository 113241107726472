import BaseModel from './BaseModel';
import Project from './Project';
import Board from './Board.js';
import Commit from './Commit';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing an user. */
class Release extends BaseModel {
    _id: string;
    project: Project;
    commit: Commit;
    changes: Change[];
    version: string;
    versionNumber: number;
    date: Moment;
    title: AnyObj;
    description: AnyObj;

    constructor(properties: AnyObj) {
        super({});

        this._id         = properties._id;
        this.project     = properties.project?._id ? new Project(properties.project) : properties.project;
        this.commit      = new Commit(properties.commit ?? {});
        this.changes     = (properties.changes || []).map((change: AnyObj) => new Change(change));
        this.version       = properties.version;
        this.versionNumber = properties.versionNumber;
        this.date          = moment(properties.date);
        this.title         = properties.title;
        this.description   = properties.description;
    }

    /**
     * Build a KanBan board from this release
     *
     * @return {Board}
     */
    board() : Board {
        const board = new Board({
            acceptNewColumns: false,
            columns: [{
                _id: this.version,
                title: 'Release ' + this.version,
                editable: false,
                acceptNewCards: false,
                // we set the release property of the changes, if needed
                changes: this.changes?.map((change: Change) => new Change({
                    ...change,
                    release: {
                        ...(change.release || {}),
                        version: this.version
                    }
                }))
            }]
        });

        return board;
    }

    localizedTitle(lang: string) : string | null {
        if (!this.title)
            return null;

        return (lang in this.title) ? this.title[lang] : this.title[Object.keys(this.title)[0]];
    }

    /**
     * Check if this release has any change of a specific type
     *
     * @param {String} type
     * @return {Boolean}
     */
    hasChangesOfType(type: string) : boolean {
        return this.changesOfType(type).length > 0;
    }

    /**
     * Get this release changes of a specific type
     *
     * @param {String} type
     * @return {Change[]}
     */
    changesOfType(type: string) : Change[]{
        return this.changes.filter(change => change.type === type);
    }
}

export default Release;
