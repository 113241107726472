import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';
import { Disclosure } from '@headlessui/react';
import { Badge } from 'flowbite-react';
import { useLocation } from 'react-router-dom';

function Topbar() {
    const { i18n } = useTranslation();
    const location = useLocation();

    if (location.pathname.includes('/public/support/project/')) {
        return null;
    }

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img className=" h-8 w-auto" src={logo} alt="Yoda" />
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        <h3 className="flex items-center text-3xl font-bold tracking-tight text-white">Yoda</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <div className="flex justify-center text-white ml-3 -space-x-1.5">
                                    <Badge
                                        className="px-2 cursor-pointer"
                                        onClick={() => i18n.changeLanguage('fr')}
                                        color={i18n.language === 'fr' ? 'dark' : 'light'}
                                    >
                                        FR&nbsp;
                                    </Badge>
                                    <Badge
                                        className="px-2 cursor-pointer"
                                        onClick={() => i18n.changeLanguage('en')}
                                        color={i18n.language === 'en' ? 'dark' : 'light'}
                                    >
                                        EN
                                    </Badge>
                                    <Badge
                                        className="px-2 cursor-pointer"
                                        onClick={() => i18n.changeLanguage('de')}
                                        color={i18n.language === 'de' ? 'dark' : 'light'}
                                    >
                                        DE
                                    </Badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    );
}

export default Topbar;
