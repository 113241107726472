import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FieldArray } from 'formik';
import type { FormikValues } from 'formik';
import { RootState, useAppSelector, useAppDispatch, loadChange, resetChanges, updateChange } from '../../../redux';
import { Sprint, Change, User } from '../../../models';
import { ChangeModal, ChangePicker, ChangeListItem, Icon, Button, DatepickerField, UserSelectField } from '../../../components';
import { Tabs} from 'flowbite-react';

interface SprintFormProps {
    isCreation: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function SprintForm(props: SprintFormProps) {
    const { isCreation, initialValues, onSubmit } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [currentChangeId, setCurrentChangeId]           = useState<string | undefined>();
    const [changesPickerVisible, setChangesPickerVisible] = useState(!(initialValues?.changes.length > 0));

    const { user }     = useAppSelector((state: RootState) => state.auth);
    const { change }   = useAppSelector(state => state.changes);
    const { updating } = useAppSelector((state: RootState) => state.sprints);

    const sprint = new Sprint(initialValues);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const validate = (values: AnyObj) => {
        const invalid: AnyObj = {};

        if (!values.name)
            invalid.name = t('common.validation.this_field_is_required');

        if (!values.startAt?.isValid())
            invalid.startAt = t('common.validation.this_field_is_required');

        if (!values.stopAt?.isValid())
            invalid.stopAt = t('common.validation.this_field_is_required');

        if (values.startAt?.isValid() && values.stopAt?.isValid() && values.stopAt.isBefore(values.startAt))
            invalid.stopAt = t('common.validation.this_field_must_be_greater_that_start_at');

        return invalid;
    }

    const filterAvailableChanges= (changes: Change[], values: AnyObj) => {
        return changes.filter((change: Change) => {
            if (values.changes.find((c: Change) => c._id === change._id))
                return false;

            return change.isSprintable();
        });
    };

    const addChange = async (change: Change, callback: () => void) => {
        if (!change)
            return;

        const params = {
            "_id":  change._id,
            "sprints": [
                ...(change.sprints.filter((s: Sprint) => s._id !== sprint._id)),
                sprint._id
            ]
        };

        await dispatch(updateChange(params, /*patch*/true, callback));
    }

    const removeChange = async (change: Change, callback: () => void) => {
        if (!change)
            return;

        const params = {
            "_id":  change._id,
            "sprints": change.sprints.filter((s: Sprint) => s._id !== sprint._id)
        };

        await dispatch(updateChange(params, /*patch*/true, callback));
    }

    const renderChanges = (values: any, remove: any) => {
        if (!values.changes?.length)
            return null;

        const sortedGroups = Sprint.groupChangesByProject(Sprint.sortChangesBy(values.changes, 'project'));

        return (
            <div className={`col-span-${changesPickerVisible ? 2 : 3} flex flex-col space-y-2`}>
                {Object.keys(sortedGroups).map((projectId: string) => (
                    <div key={projectId}>
                        <h3 className="font-bold text-lg">{sortedGroups[projectId].name}</h3>
                        <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                            {sortedGroups[projectId].changes.map((change: Change) => (
                            <div key={change._id} onClick={() => selectChange(change)} className={`col-span-2 lg:col-span-${changesPickerVisible ? 2 : 1}`} >
                                <ChangeListItem
                                    showProject
                                    onRemove={(change: Change, e: AnyObj) => {
                                        e.stopPropagation();
                                        removeChange(change, () => remove(change));
                                    }}
                                    change={change}
                                />
                            </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="SprintForm bg-white border-b px-4 py-6 sm:px-6 lg:px-8">
            <main>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validate={validate}
                    onSubmit={(values, { setSubmitting }) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <Form>
                            <div className="space-y-6">
                                <div className="flex flex-row justify-center">
                                    <div className="grid grid-cols-1 gap-y-2 md:grid-cols-3">
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('sprints.name')}
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    className="h-[42px] block w-full rounded-md md:rounded-r-none border-1 border-gray-300 py-1.5 text-gray-900 font-bold placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="startAt" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('sprints.startAt')}
                                            </label>
                                            <div className="mt-2">
                                                <DatepickerField name="startAt" inputClassName="border-gray-300 border-l-1 md:border-l-0 md:rounded-none rounded-md"/>
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="stopAt" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('sprints.stopAt')}
                                            </label>
                                            <div className="mt-2">
                                                <DatepickerField name="stopAt" inputClassName="rounded-md border-gray-300 border-1 md:border-l-0 md:rounded-l-none"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isCreation && (
                                    <Tabs>
                                        <Tabs.Item icon={() => <Icon type="list" className="mr-1.5 outline-none" />} title={t('sprints.changes')}>
                                            <div className="">
                                                <h4 className="mb-4 text-base font-medium leading-7 text-gray-900">
                                                    {t('sprints.estimated_hours', {total: sprint.getEstimatedHours().toFixed(2)})}
                                                </h4>
                                                <div>
                                                    <FieldArray name="changes">
                                                        {({ remove, push, pop }) => (
                                                            <>
                                                                <div className="grid grid-cols-1 gap-x-4 gap-y-2 lg:grid-cols-3">
                                                                    {changesPickerVisible && (
                                                                        <div className="col-span-1">
                                                                            <ChangePicker
                                                                                onSelect={(change: Change, e: AnyObj) => addChange(change, () => { push({}); pop(); })}
                                                                                className="block w-full"
                                                                                listClassName="max-h-[400px] overflow-auto"
                                                                                selectClassName="max-h-[400px] overflow-auto"
                                                                                filterChanges={(changes: Change[]) => filterAvailableChanges(changes, values)}
                                                                            />
                                                                            <div className="col-span-1 mt-2 md:col-span-3 lg:col-span-4 flex justify-center">
                                                                                <Button
                                                                                    onClick={() => { setChangesPickerVisible(false); push({}); pop(); }}
                                                                                    title={t('sprints.close')}
                                                                                    color="navigate"
                                                                                    icon="close"
                                                                                    small
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className={`col-span-${changesPickerVisible ? 2 : 3} flex flex-col space-y-2`}>
                                                                        {renderChanges(values, remove)}
                                                                    </div>
                                                                </div>
                                                                {!changesPickerVisible && (
                                                                    <div className="col-span-1 mt-2 md:col-span-3 lg:col-span-4 flex justify-center">
                                                                        <Button
                                                                            onClick={() => { setChangesPickerVisible(true); push({}); pop(); }}
                                                                            title={t('sprints.add_cards')}
                                                                            color="navigate"
                                                                            icon="plus"
                                                                            small
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                        <Tabs.Item icon={() => <Icon type="user" className="mr-1.5 outline-none" />} title={t('sprints.resources')}>
                                            <div className="">
                                                <h4 className="mb-4 text-base font-medium leading-7 text-gray-900">
                                                    {t('sprints.available_hours', {total: sprint.getAvailableHours().toFixed(2)})}
                                                </h4>
                                                <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-3 lg:grid-cols-4">
                                                    <FieldArray name="resources">
                                                        {({ insert, remove, push }) => (
                                                            <>
                                                                {values.resources.length > 0 && values.resources.map((resource: AnyObj, i: number) => (
                                                                    <div key={i} className="col-span-1 border my-2 rounded bg-white hover:bg-gray-50">
                                                                        <div className="grid grid-cols-1 gap-x-2 gap-y-2 p-3">
                                                                            <div className="col-span-1">
                                                                                <div className="mt-2">
                                                                                    {resource.member?.work_rate > 0 ? (
                                                                                        <h3 className="font-bold text-xl text-center">{resource.member.fullname()}</h3>
                                                                                    ) : (
                                                                                        <UserSelectField
                                                                                            name={`resources[${i}].member._id`}
                                                                                            placeholder={t('sprints.choose_a_member') || ''}
                                                                                            roles={[User.ROLE_ADMIN, User.ROLE_USER]}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {resource.member?.work_rate > 0 && (
                                                                            <div className="col-span-1">
                                                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-0 gap-y-4 py-3">
                                                                                    <div className="col-span-1 flex flex-row">
                                                                                        <Field
                                                                                            type="number"
                                                                                            name={`resources[${i}].availability.percentage`}
                                                                                            value={resource.member ? (resource.availability?.percentage ?? 100) : 0}
                                                                                            className="block w-full rounded-md rounded-r-none border-gray-300 py-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                                            required
                                                                                        />
                                                                                        <div className="w-6 text-center bg-gray-300 px-1 py-1 rounded-r-md md:rounded-none text-gray-900 font-bold sm:text-sm sm:leading-6">%</div>
                                                                                    </div>
                                                                                    <div className="col-span-1 flex flex-row">
                                                                                        <Field
                                                                                            type="number"
                                                                                            name={`resources[${i}].availability.hours`}
                                                                                            value={(Math.round(((42 * resource.member?.work_rate / 100) * (resource.availability?.percentage / 100) || 0) / 0.5) * 0.5).toFixed(2)}
                                                                                            className="block w-full rounded-l-md md:rounded-l-none md:border-l-0 bg-gray-100 border-gray-300 py-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                                            required
                                                                                        />
                                                                                        <div className="w-6 text-center rounded-r-md bg-gray-300 px-1 py-1 text-gray-600 font-bold sm:text-sm sm:leading-6">h</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                        </div>
                                                                        {resource.member?._id !== user._id && (
                                                                        <div className="cursor-pointer flex justify-center items-center mr-2 mb-2">
                                                                            <Button
                                                                                onClick={() => remove(i)}
                                                                                title={t('sprints.remove_this_member')}
                                                                                color="danger"
                                                                                icon="delete"
                                                                                small
                                                                            />
                                                                        </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                                <div className="col-span-1 md:col-span-3 lg:col-span-4 flex justify-center">
                                                                    <Button
                                                                        onClick={() => push({member: null, availability: {percentage: 100, hours: 0}})}
                                                                        title={t('sprints.add_member')}
                                                                        color="navigate"
                                                                        icon="plus"
                                                                        small
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                    </Tabs>
                                )}
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <Button to="/sprints" title={t('common.cancel')} color="navigateBack" icon="back" />
                                <Button type="submit" title={t('common.save')} color="primary" icon={updating === 'pending' ? 'loading' : 'save'} />
                            </div>

                            {change && change._id === currentChangeId && change.project?.name && (
                                <ChangeModal
                                    project={change.project}
                                    change={change}
                                    show={true}
                                    showProjectName={true}
                                    onClose={() => selectChange()}
                                    onRemove={(values: AnyObj) => { }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </main>
        </div>
    );
}

export default SprintForm;
