import React from 'react';
import { useField } from 'formik';
import { Select } from '..';

import type { SelectOption } from '..';

interface SelectFieldProps {
    name: string;
    options: SelectOption[];
    onChange?: (value: any) => void;
    placeholder?: string;
    inline?: boolean;
    className?: string;
    containerClassName?: string;
    selectedClassName?: string;
    optionsContainerClassName?: string;
    optionsClassName?: string;
    optionsWidth?: string | number;
    optionsHeight?: string | number;
};

const SelectField = (props: SelectFieldProps) => {
    const [ field, meta, helpers ] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    const onChange = (value: any) => {
        if (props.onChange)
            props.onChange(value);

        setValue(value);
    }

    return (
        <Select
            defaultValue={value}
            options={props.options}
            placeholder={props.placeholder}
            onChange={onChange}
            inline={props.inline}
            className={props.className}
            containerClassName={props.containerClassName}
            selectedClassName={props.selectedClassName}
            optionsContainerClassName={props.optionsContainerClassName}
            optionsClassName={props.optionsClassName}
            optionsWidth={props.optionsWidth}
            optionsHeight={props.optionsHeight}
        />
    )
};

export default SelectField;
