import { Comments } from "../../services";
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
} = createRestSlices(Comments);

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer
    ]);
}

/* Export CRUD actions */
export const createComment      = createAction;
export const startCreateComment = startCreateAction;
export const loadComment        = getAction;
export const loadComments       = listAction;
export const updateComment      = updateAction;
export const deleteComment      = deleteAction;
