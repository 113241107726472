import React, { useEffect } from 'react';
import { Icon, SelectField, MultiSelectField } from '../';
import { Group } from '../../models';
import { RootState, useAppSelector, useAppDispatch, loadGroups } from '../../redux';

interface NotificationGroupsFieldProps {
    name: string;
    multiple?: boolean;
    placeholder?: string;
    className?: string;
};

const NotificationGroupsField = (props: NotificationGroupsFieldProps) => {
    const { name, multiple, placeholder, className } = props;

    const dispatch   = useAppDispatch();
    const { groups } = useAppSelector((state: RootState) => state.groups);

    useEffect(() => {
        dispatch(loadGroups());
    }, [dispatch]);

    const options = groups?.filter((a: Group) => !!a.name)
        .sort((a: Group, b: Group) => a.name.localeCompare(b.name))
        .map((group: Group) => ({
            value: group._id,
            label: group.name,
            leftContent: <Icon type="signal" color="blue-600" className="ml-3 inline"/>
        }));

    if (multiple)
        return (
            <MultiSelectField
                name={name}
                options={options}
                placeholder={placeholder}
                className={className}
            />
        );

    return (
        <SelectField
            name={name}
            options={options}
            placeholder={placeholder}
            className={className}
        />
    );
};

export default NotificationGroupsField;
