import BaseModel from './BaseModel';
import Change from './Change';
import Client from './Client';
import Project from './Project';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';
import { formatPrice } from '../locales';

export type QuoteItem = {
    title: string;
    description: string;
    type: string;
    estimate: number;
    price: number;
    free: boolean;
    change?: Change;
}

export type QuoteAction = {
    type?: string;
    date?: Moment;
}

/** Class representing an user. */
class Quote extends BaseModel {
    /**
     * Status constants
     */
    static STATUS_PENDING        = 'pending';
    static STATUS_SUBMITTED      = 'submitted';
    static STATUS_REFUSED        = 'refused';
    static STATUS_ACCEPTED       = 'accepted';
    static STATUS_ADVANCE_BILLED = 'advanceBilled';
    static STATUS_ADVANCE_PAID   = 'advancePaid';
    static STATUS_BILLED         = 'billed';
    static STATUS_PAID           = 'paid';

    _id: string;
    name: string;
    slug: string;
    project: Project;
    client: Client;
    items: QuoteItem[];
    price: number;
    needsAdvance: boolean;
    status: string;
    createdAt?: Moment;
    submittedAt?: Moment;
    acceptedAt?: Moment;
    refusedAt?: Moment;
    advanceBilledAt?: Moment;
    advancePaidAt?: Moment;
    billedAt?: Moment;
    paidAt?: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id          = properties._id;
        this.name         = properties.name;
        this.slug         = properties.slug;
        this.status       = properties.status;
        this.price        = properties.price;
        this.needsAdvance = !!properties.needsAdvance;
        this.createdAt    = properties.createdAt ? moment(properties.createdAt) : undefined;

        this.project = properties.project;
        if (_.isObject(this.project))
            this.project = new Project(this.project);

        this.client = properties.client;
        if (_.isObject(this.client))
            this.client = new Client(this.client);

        this.items = (properties.items || []).map((s: QuoteItem) => ({
            ...s,
            change: s.change && s.change._id ? new Change(s.change) : s.change
        }));

        if (properties.submittedAt)
            this.submittedAt = moment(properties.submittedAt);
        if (properties.acceptedAt)
            this.acceptedAt = moment(properties.acceptedAt);
        if (properties.refusedAt)
            this.refusedAt = moment(properties.refusedAt);
        if (properties.advanceBilledAt)
            this.advanceBilledAt = moment(properties.advanceBilledAt);
        if (properties.advancePaidAt)
            this.advancePaidAt = moment(properties.advancePaidAt);
        if (properties.billedAt)
            this.billedAt = moment(properties.billedAt);
        if (properties.paidAt)
            this.paidAt = moment(properties.paidAt);
    }

    static allStatus() : string[] {
        return [
            Quote.STATUS_PENDING,
            Quote.STATUS_SUBMITTED,
            Quote.STATUS_REFUSED,
            Quote.STATUS_ACCEPTED,
            Quote.STATUS_ADVANCE_BILLED,
            Quote.STATUS_ADVANCE_PAID,
            Quote.STATUS_BILLED,
            Quote.STATUS_PAID
        ];
    }

    statusIndex(status?: string) {
        return Quote.allStatus().indexOf(status || this.status);
    }

    statusColor() {
        const index = this.statusIndex();
        return [
            'yellow',
            'blue',
            'red',
            'green',
            'blue',
            'blue',
            'blue',
            'blue',
        ][index];
    }

    totalEstimated() {
        return (this.items || []).reduce((acc: number, item: QuoteItem) => {
            return acc + item.estimate;
        }, 0);
    }

    progression() : number {
        if (this.statusIndex() < this.statusIndex(Quote.STATUS_ACCEPTED))
            return 0;


        const totalRealised = this.items.reduce((acc: number, item: QuoteItem) => {
            if (!item.change)
                return acc + item.estimate;

            return acc + (
                item.change.estimate * item.change.progression() / 100
            );

        }, 0);

        return totalRealised / this.totalEstimated() * 100;
    }

    lastAction() : QuoteAction {
        const steps = [
            'createdAt',
            'submittedAt',
            'acceptedAt',
            'refusedAt',
            'advanceBilledAt',
            'advancePaidAt',
            'billedAt',
            'paidAt'
        ];

        const action : QuoteAction = {};
        steps.forEach((property: string) => {
            if (this[property as keyof typeof this]) {
                action.type = property;
                action.date = this[property as keyof typeof this] as Moment;
            }
        });

        return action;
    }

    isFinished() {
        return (
            this.status !== Quote.STATUS_REFUSED &&
            this.status !== Quote.STATUS_PAID
        );
    }

    formattedPrice() {
        return formatPrice(this.price || 0);
    }
}

export default Quote;
