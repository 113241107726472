import BaseModel from './BaseModel';

/** Class representing a project documentation. */
class ProjectDocumentation extends BaseModel {
    /**
     * constants
     */
    static SOURCE_OUTLINE = 'outline';
    static SOURCE_URL     = 'url';
    static SOURCE_LOCAL   = 'local';

    static FORMAT_MARKDOWN = 'markdown';
    static FORMAT_OPENAPI = 'openapi';

    name    : string;
    source  : string;
    path    : string;
    format  : string;
    content?: string;

    constructor(properties: AnyObj) {
        super({});

        this.name    = properties.name;
        this.source  = properties.source;
        this.path    = properties.path;
        this.format  = properties.format;
        this.content = properties.content;
    }

    static formatTypes() : string[] {
        return [
            ProjectDocumentation.FORMAT_MARKDOWN,
            ProjectDocumentation.FORMAT_OPENAPI,
        ];
    }

    static sourceTypes() : string[] {
        return [
            ProjectDocumentation.SOURCE_OUTLINE,
            ProjectDocumentation.SOURCE_URL,
            ProjectDocumentation.SOURCE_LOCAL,
        ];
    }
}

export default ProjectDocumentation;
