import { Checklist } from '../models';
import RestClient from './RestClient';

class Checklists extends RestClient {
    constructor() {
        super({
            // rest config
            model: Checklist,
            entryPoint: 'checklists',
            id_field: '_id',

            // redux config
            resource: 'checklist',
            resources: 'checklists',
        });
    }

    updateItem(listId: string, params: AnyObj) {
        return this.PUT(`/${this.entryPoint}/${listId}/item`, params)
            .then(response => response.json())
            .then(json => {
                if (json.checklist) {
                    json.checklist = new Checklist(json.checklist);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    removeItem(listId: string, itemId: string) {
        return this.DELETE(`/${this.entryPoint}/${listId}/item/${itemId}`)
            .then(response => response.json())
            .then(json => {
                if (json.checklist) {
                    json.checklist = new Checklist(json.checklist);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

}

const ChecklistsApi = new Checklists();

export default ChecklistsApi;
