import React, { useState, useEffect } from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch, loadGroups, loadUsers } from '../../../redux';
import { Routine, RoutineAction, Group, User } from '../../../models';
import { Icon } from '../../Icon';
import { MultiSelect } from '../../MultiSelect';

interface NotifyFormProps {
    action: RoutineAction;
    onSave?: (action: RoutineAction) => void;
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function NotifyForm(props: NotifyFormProps) {
    const { action, onSave, authorId, projectId, changeId } = props;

    const { i18n, t } = useTranslation();
    const dispatch    = useAppDispatch();

    const [options, setOptions] = useState<AnyObj>({});

    const { users }  = useAppSelector((state: RootState) => state.users);
    const { groups } = useAppSelector((state: RootState) => state.groups);

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadGroups());
    }, [dispatch]);

    useEffect(() => {
        setOptions(action.options || {});
    }, []);

    useEffect(() => {
        onSave && onSave({
            ...action,
            options
        });
    }, [options]);

    const usersOptions = users?.filter((a: User) => !!a.phone)
        .sort((a: User, b: User) => a.fullname().localeCompare(b.fullname()))
        .map((user: User) => ({
            value: user.phone,
            label: user.fullname(),
            leftContent: <Icon type="user" color="gray-600" className="ml-3 inline"/>
        }));

    const groupsOptions = groups?.filter((a: Group) => !!a.name)
        .sort((a: Group, b: Group) => a.name.localeCompare(b.name))
        .map((group: Group) => ({
            value: group._id,
            label: group.name,
            leftContent: <Icon type="signal" color="blue-600" className="ml-3 inline"/>
        }));

    /**
     *  "recipients": [ "string" ],
     *  "group_recipients": [ "string" ],
     *  "message": "string",
     *  "title": "string"
     */
    return (
        <div className="flex flex-col space-y-4 w-full">
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col items-center">
                    <div>{t('projects.routine.action.notify_recipients')}</div>
                    <small>{t('projects.routine.action.notify_recipients_desc')}</small>
                </label>
                <div className="col-span-3">
                    {options && (
                        <MultiSelect
                            defaultValue={options.recipients || []}
                            options={usersOptions}
                            onChange={(recipients: any) => { setOptions({...options, recipients}); }}
                            className="border rounded border-gray-300 w-full"
                            placeholder={t('projects.routine.action.notify_choose_recipients') || ''}
                        />
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col items-center">
                    <div>{t('projects.routine.action.notify_group_recipients')}</div>
                    <small>{t('projects.routine.action.notify_group_recipients_desc')}</small>
                </label>
                <div className="col-span-3">
                    {options && (
                        <MultiSelect
                            defaultValue={options?.group_recipients || ''}
                            options={groupsOptions}
                            onChange={(group_recipients: any) => setOptions({...options, group_recipients})}
                            className="border rounded border-gray-300 w-full"
                            placeholder={t('projects.routine.action.notify_choose_group_recipients') || ''}
                        />
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col items-center">
                    <div>{t('projects.routine.action.notify_message')}</div>
                    <small>{t('projects.routine.action.notify_message_desc')}</small>
                </label>
                <div className="col-span-3">
                    <textarea
                        value={options?.message || ''}
                        rows={3}
                        onChange={(e: any) => setOptions({...options, message: e.target.value})}
                        className="border rounded border-gray-300 w-full text-sm"
                    >
                        {options?.message}
                    </textarea>
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col items-center">
                    <div>{t('projects.routine.action.notify_title')}</div>
                    <small>{t('projects.routine.action.notify_title_desc')}</small>
                </label>
                <div className="col-span-3">
                    <textarea
                        value={options?.title || ''}
                        rows={3}
                        onChange={(e: any) => setOptions({...options, title: e.target.value})}
                        className="border rounded border-gray-300 w-full text-sm"
                    >
                        {options?.title}
                    </textarea>
                </div>
            </div>
        </div>
    );
}

export default NotifyForm;
