import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadProject, updateProject } from '../../../redux';
import { Ability, Button } from '../../../components';
import ProjectForm from '../ProjectForm/ProjectForm';

function ProjectEdit() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    let { id }     = useParams();

    const { project } = useAppSelector((state: RootState) => state.projects);

    useEffect(() => {
        if (id)
            dispatch(loadProject(id));
    }, [dispatch, id]);

    const submit = function (values: AnyObj) : void {
        dispatch(updateProject(values));
    }

    return (
        <Ability can="project:create" redirect="/projects">
            <div className="ProjectEdit">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                { t('projects.edit_project', {name: project?.name}) }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.delete')} color="danger" to={`/projects/delete/${project?._id}`} icon="delete" />
                            </span>
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to={`/projects/${project?._id}`} icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                <main className="mx-6 my-6 shadow">
                    {project && (project._id === id) && (
                        <ProjectForm isCreation={false} initialValues={project} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default ProjectEdit;
