import React from 'react';
import { useField , Field } from 'formik';

interface InlineFieldProps {
    name: string;
    type?: string;
    as?: string;
    step?: number;
    placeholder?: string;
    className?: string;
    onChange?: (field: string, value: string) => void;
    onSave?: (field: string, value: string) => void;
    isLoading?: boolean;
    label?: JSX.Element;
    required?: boolean;
    readOnly?: boolean;
    saveOnBlur?: boolean;
};

const InlineField = (props: InlineFieldProps) => {
    const { name, as, type, step, placeholder, className, onChange, onSave, isLoading, required, readOnly, saveOnBlur } = props;

    const [ field, meta, helpers ]                = useField(name);
    const { initialValue, value, touched, error } = meta;
    const { setValue }                            = helpers ;

    const handleKeyPress = (e: AnyObj) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onSave && onSave(name, value);
        } else if (e.key === 'Escape' || e.code === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setValue(initialValue);
        }
    }

    const _onChange = (e: AnyObj) => {
        setValue(e.target.value);
        onChange && onChange(name, e.target.value);
    }

    const onBlur = () => {
        if (saveOnBlur) {
            onSave && onSave(name, value);
        }
    }

    return (
        <div className="flex flex-col">
            <Field
                name={name}
                as={as || 'input'}
                type={type || 'text'}
                step={step}
                disabled={!!readOnly || isLoading}
                onChange={ _onChange }
                onBlur={onBlur}
                onKeyPress={handleKeyPress}
                placeholder={placeholder}
                className={ `border-0 p-0 focus:ring-0 ${className ?? ''}` }
                required={required}
            />
            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
        </div>
    );
};

export default InlineField;
