import RestClient from './RestClient';
import { Group } from '../models';

class Groups extends RestClient {
    constructor() {
        super({
            // rest config
            model: Group,
            entryPoint: 'groups',
            id_field: '_id',
            // redux config
            resource: 'group',
            resources: 'groups',
        });
    }
};
const GroupsApi = new Groups();

export default GroupsApi;
