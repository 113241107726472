import { Dispatch } from 'redux';
import { Commit } from '../../models';
import { Commits } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Status, Callback, Response } from './rest';

const {
    initialState,
    getReducer, listReducer,
    getAction, listAction,
} = createRestSlices(Commits);

const LINKING_COMMIT         = 'yoda/commits/LINKING_COMMIT';
const LINKING_COMMIT_SUCCESS = 'yoda/commits/LINKING_COMMIT_SUCCESS';
const LINKING_COMMIT_FAILURE = 'yoda/commits/LINKING_COMMIT_FAILURE';
const LINKING_COMMIT_RESET   = 'yoda/commits/LINKING_COMMIT_RESET';

initialState.linkCommit      = 'idle' as Status;
initialState.linkCommitId    = null;
initialState.linkCommitError = null;

const linkCommitReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case LINKING_COMMIT:
            return {
                ...state,
                linkCommit: 'pending',
                linkCommitId: action.id,
                linkCommitError: null
            };
        case LINKING_COMMIT_SUCCESS:
            const commits = state.commits.map((commit: Commit) => {
                if (commit._id === action.commit._id)
                    return action.commit;
                return commit;
            });
            return {
                ...state,
                linkCommit: 'succeeded',
                linkCommitError: null,
                commits,
            };
        case LINKING_COMMIT_FAILURE:
            return {
                ...state,
                linkCommit: 'failed',
                linkCommitError: action.error
            };
        case LINKING_COMMIT_RESET:
            return {
                ...state,
                linkCommit: 'idle',
                linkCommitId: null,
                linkCommitError: null
            };
        default:
            return state;
    }
};

const linkCommitAction = (commitId: string, changeId: string, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: LINKING_COMMIT, id: commitId});
        return Commits.linkToChange(commitId, changeId)
            .then((data: Response) => {
                const {commit} = data;
                dispatch({type: LINKING_COMMIT_SUCCESS, commit});
                setTimeout(() => {
                    dispatch({type: LINKING_COMMIT_RESET});
                }, 3000);
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: LINKING_COMMIT_FAILURE, error: error.message});
                setTimeout(() => {
                    dispatch({type: LINKING_COMMIT_RESET});
                }, 3000);
                callback && callback(error);
            });
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        getReducer, listReducer, linkCommitReducer
    ]);
}

/* Export CRUD actions */
export const loadCommit  = getAction;
export const loadCommits = listAction;
export const linkCommit  = linkCommitAction;
