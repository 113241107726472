import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch } from '../../redux';
import { loadChange, createComment, updateComment, deleteComment } from '../../redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Timeline } from 'flowbite-react';
import { Log, Comment, User, Change } from '../../models';
import type { Thread } from '../../models';
import { WysiwygField } from '../../components';
import { UserAvatar } from '../';
import { Icon } from '../Icon';
import moment from 'moment';
import { Button } from '../Button';
import ReactTimeAgo from 'react-time-ago';

interface ChangeActivityProps {
    change: Change;
    members: User[];
}

function ChangeActivity(props: ChangeActivityProps) {
    const { change, members } = props;

    const { user }                = useAppSelector((state: RootState) => state.auth);
    const { updating }            = useAppSelector((state: RootState) => state.changes);
    const [ visible, setVisible ] = useState(false);
    const [ showAll, setShowAll ] = useState(false);
    const [ replyTo, setReplyTo ] = useState<Comment | null>(null);
    const today                   = moment();

    // handle scroll to comment
    const { hash }    = useLocation();
    const scrolledRef = React.useRef(false);
    const hashRef     = React.useRef(hash);
    useEffect(() => {
        if (hash) {
            // We want to reset if the hash has changed
            if (hashRef.current !== hash) {
                hashRef.current = hash;
                scrolledRef.current = false;
            }

            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if (!scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    scrolledRef.current = true;
                }
            }
        }
    }, [change, hash]);
    useEffect(() => {
        scrolledRef.current = false;
    }, [hash]);

    const navigate    = useNavigate();
    const { t, i18n } = useTranslation();

    const items = React.useMemo(() => {
        if (!change)
            return [];

        let items: AnyObj[] = [];
        if (change.comments) {
            const indexed = change.comments.map((item: AnyObj, i: number) => (new Comment({
                ...item,
                commentIndex: i
            })));
            Comment.toThreads(indexed as Comment[]).forEach((thread: Thread) => {
                items = items.concat(thread.comments);
            });
        }

        if (visible) {
            items = items.concat(change.logs.filter((log: Log) => {
                return ![Log.TYPE_COMMENT_CREATE, Log.TYPE_COMMENT_UPDATE].includes(log.type);
            }).sort((a: AnyObj, b: AnyObj) => {
                return b.createdAt.diff(a.createdAt);
            }));
        }

        return items;
    }, [change, visible]);

    const dispatch    = useAppDispatch();
    const onSaveComment = async (value: string, _id?: string) => {
        if (_id)
            return await dispatch(updateComment({ _id, message: value }, /*patch*/true));

        await dispatch(createComment({
            change,
            project: change.project,
            message: value,
            threadId: replyTo?.threadId
        }));

        setReplyTo(null);
    }
    const onDeleteComment = async (_id: string) => {
        await dispatch(deleteComment(_id, () => {
            dispatch(loadChange(change._id));
        }));
    }

    const handleActivity = () => {
        setVisible(!visible);
        setShowAll(false);
    };

    const mentions = members.map((user: User) => ({
        text: user.fullname(),
        value: user.username(),
        url: '#'
    }));

    const renderItem = (item: AnyObj) => {
        if (item instanceof Log)
            return (
                <Timeline.Item key={item._id} className='mb-0' theme={{ "root": { "vertical": "mb-6 mt-6 ml-6" }}}>
                    <Timeline.Point
                        icon={() => (
                            <UserAvatar user={item.author} size={7} hideInitials />
                        )}
                    />
                    <Timeline.Content>
                        <Timeline.Body>
                            <div
                                className='text-sm'
                                dangerouslySetInnerHTML={{ __html: item.describe() }}
                            />
                            <div className='text-xs italic'>
                                { (moment.duration(today.diff(item.createdAt)).asHours() < 48) ?
                                    <ReactTimeAgo locale={i18n.language} date={item.createdAt.toDate()}/> :
                                    `${t('logs.on')} ${moment(item.createdAt).locale(i18n.language).format('ll')}`
                                }
                            </div>
                        </Timeline.Body>
                    </Timeline.Content>
                </Timeline.Item>
            );

        if (item instanceof Comment)
            return (
                <Timeline.Item id={item._id} key={item._id} className={`${(item.level || 0) > 0 ? 'ml-[60px]' : ''}  mb-0 ${hash === ('#' + item._id) ? 'bg-blue-100 p-2' : ''}`} theme={{ "root": { "vertical": "mb-6 mt-6 ml-6" }}}>
                    <Timeline.Point className={`${(item.level || 0) > 0 ? 'relative -ml-6' : ''}`} icon={() => (
                        <UserAvatar user={item.author} size={7} hideInitials onClick={() => navigate(`#${item._id}`)}/>
                    )} />
                    <Timeline.Content>
                        <Timeline.Body>
                            {item.author?._id !== user?._id && (
                                <div className="Comment">
                                    <div className="flex flex-row space-x-2 items-center">
                                        <div className='text-sm font-bold'>
                                            { item.authorName() }
                                        </div>
                                        <div className="text-xs italic">
                                            { (moment.duration(today.diff(item.createdAt)).asHours() < 48) ?
                                                <ReactTimeAgo locale={i18n.language} date={item.createdAt.toDate()}/> :
                                                `${t('logs.on')} ${moment(item.createdAt).locale(i18n.language).format('ll')}`
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="content text-sm text-gray-900 border border-gray-200 bg-gray-100 rounded p-2"
                                        dangerouslySetInnerHTML={{ __html: item.message }}
                                    />
                                    {replyTo?._id !== item._id ? (
                                        <div className="text-xs cursor-pointer font-normal mt-0.5 text-gray-500" onClick={() => setReplyTo(item)}>
                                            <Icon type="reply" className="inline mr-0.5" size={3} />
                                            <span className="hover:underline">
                                                {t('common.reply')}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="text-xs cursor-pointer font-normal mt-0.5 text-gray-500" onClick={() => setReplyTo(null)}>
                                            <Icon type="close" className="inline mr-0.5" size={3} />
                                            <span className="hover:underline">
                                                {t('common.cancel')}
                                            </span>
                                        </div>
                                    )}
                                    {replyTo?._id === item._id && (
                                        <div className="my-3 flex flex-row space-x-2 items-start">
                                            <div className="mt-1">
                                                <UserAvatar user={user} size={7} hideInitials />
                                            </div>
                                            <div className="flex-1">
                                                <WysiwygField
                                                    name={`comments[${change?.comments?.length}]`}
                                                    minimal
                                                    mentions={mentions}
                                                    placeholder={t('changes.enter_comment') || ''}
                                                    rows={4}
                                                    containerClassName="mb-6"
                                                    className="text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                    onSave={ onSaveComment }
                                                    isLoading={ updating === 'pending' }
                                                    uploadProps={{
                                                        projectId: change?.project?._id,
                                                        changeId: change?._id
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {item.author?._id === user?._id && (
                                <WysiwygField
                                    name={`comments[${item.commentIndex}].message`}
                                    placeholder={t('changes.enter_comment') || ''}
                                    rows={4}
                                    inline
                                    minimal
                                    mentions={mentions}
                                    label={(
                                        <div className="flex flex-row space-x-2 items-center">
                                            <div className='text-sm font-bold'>
                                                { t('common.me') }
                                            </div>
                                            <div className="text-xs italic">
                                                { (moment.duration(today.diff(item.createdAt)).asHours() < 48) ?
                                                    <ReactTimeAgo locale={i18n.language} date={item.createdAt.toDate()}/> :
                                                    `${t('logs.on')} ${moment(item.createdAt).locale(i18n.language).format('ll')}`
                                                }
                                            </div>
                                        </div>
                                    )}
                                    containerClassName="Comment -mt-1 mb-0"
                                    className="text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    onSave={ (value: string) => onSaveComment(value, item._id) }
                                    onDelete={ () => onDeleteComment (item._id)}
                                    isLoading={ updating === 'pending' }
                                    uploadProps={{
                                        projectId: change?.project?._id,
                                        changeId: change?._id
                                    }}
                                />
                            )}
                        </Timeline.Body>
                    </Timeline.Content>
                </Timeline.Item>
            );

        return null;
    };

    const itemsToShow = showAll ? items : items?.slice(0, 10);

    return (
        <div className="">
            <div className="mt-2">
                {!replyTo?._id && (
                    <div className="my-3 flex flex-row space-x-2 items-start">
                        <div className="mt-1">
                            <UserAvatar user={user} size={7} hideInitials />
                        </div>
                        <div className="flex-1">
                            <WysiwygField
                                name={`comments[${change?.comments?.length}]`}
                                inline
                                editOnClick
                                minimal
                                mentions={mentions}
                                placeholder={t('changes.enter_comment') || ''}
                                rows={4}
                                containerClassName="mb-6"
                                className="text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                onSave={ onSaveComment }
                                isLoading={ updating === 'pending' }
                                uploadProps={{
                                    projectId: change?.project?._id,
                                    changeId: change?._id
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className='text-left'>
                    <span
                        onClick={() => handleActivity()}
                        className="text-gray-600 text-sm font-bold cursor-pointer"
                    >
                        <Icon type={visible ? 'down' : 'right'} className="inline mr-1.5"/>
                        {`${ visible ? t('logs.hide_activity') : t('logs.show_activity') }`}
                    </span>
                </div>
                <Timeline className="ml-4 my-0 mt-5 p-0" theme={{ root: { direction: { vertical: "relative border-l-0" }}}}>
                    { itemsToShow?.map((item: AnyObj) => renderItem(item)) }
                </Timeline>
                {items?.length > 10 && !showAll && (
                    <div className='text-center'>
                        <Button
                            title={t('logs.show_all_activity')}
                            onClick={() => setShowAll(true)}
                            color='none'
                            textColor='gray-400'
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChangeActivity;
