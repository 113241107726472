import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timeline } from 'flowbite-react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { loadLogs } from '../../redux';
import { Log } from '../../models';
import { Icon, UserAvatar } from '..';
import ReactTimeAgo from 'react-time-ago';
import moment from 'moment';

interface LastActivityProps {
    title?: string;
    limit?: number;
    project?: string;
    withHeader?: boolean;
}

function LastActivity(props: LastActivityProps) {
    const { title, withHeader, limit, project } = props;

    const dispatch    = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { logs }    = useAppSelector((state: RootState) => state.logs);
    const today       = moment();

    useEffect(() => {
        const filters: AnyObj = { limit: limit || 10 };
        if (project)
            filters.project = project;
        dispatch(loadLogs(filters));
    }, [dispatch, limit, project]);

    const sorted = useMemo(() => {
        return logs?.sort((a: Log, b: Log) => {
            return a.createdAt?.isAfter(b.createdAt) ? -1 : 1;
        });
    }, [logs]);

    const content = (sorted.length > 0) ? (
        <div className="bg-white dark:bg-black">
            {title && (
                <h2 className="w-fit text-2xl font-bold leading-7 flex items-center text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    <Icon type="activity" size={7} className="mr-1.5"/>
                    {title}
                </h2>
            )}
            <Timeline className="ml-4 my-0 mt-5 p-0">
                {sorted.map((log: Log, i: number) => (
                    <Timeline.Item key={i} className='mb-0' theme={{ "root": { "vertical": "mb-5 ml-6" }}}>
                        <Timeline.Point
                            icon={() => (
                                <UserAvatar user={log.author} size={7} hideInitials />
                            )}
                        />
                        <Timeline.Content>
                            <Timeline.Body>
                                <div className='text-sm' dangerouslySetInnerHTML={{ __html: log.describe(/*fromOutside*/true) }} />
                                <div className='text-xs italic'>
                                    { (moment.duration(today.diff(log.createdAt)).asHours() < 48) ?
                                        <ReactTimeAgo locale={i18n.language} date={log.createdAt.toDate()}/> :
                                        `Le ${moment(log.createdAt).format('ll')}`
                                    }
                                </div>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                ))}
            </Timeline>
        </div>
    ) : (
        <div className="flex justify-start items-center">
            <div className="bg-gray-50 text-center py-3 w-fit">
                <Icon type="thumbs-up" size={10} className="inline"/>&nbsp;
                {t('dashboard.no_activity_for_now')}
            </div>
        </div>
    );

    if (!withHeader)
        return content;
    return (
        <>
            <div className='p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md'>
                <div className="pt-2 pb-3 items-center justify-between border-b border-gray-300">
                    <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900">
                        <Icon type="activity" size={7} className="inline"/>&nbsp;
                        {t('dashboard.last_activity')}
                    </h3>
                </div>
                <div className="py-3">
                    {content}
                </div>
            </div>
        </>
    );
}

export default LastActivity;
