import RestClient from './RestClient';
import { Quote } from '../models';

class Quotes extends RestClient {
    constructor() {
        super({
            // rest config
            model: Quote,
            entryPoint: 'quotes',
            sortBy: 'slug',
            id_field: '_id',
            // redux config
            resource: 'quote',
            resources: 'quotes',
        });
    }

    toStep(_id: string, step: string, params?: AnyObj) {
        params = { _id, step };

        return this.PUT(`/${this.entryPoint}/${_id}/step`, params)
            .then(response => response.json())
            .then(json => {
                if (json.quote) {
                    json.quote = new Quote(json.quote);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const QuotesApi = new Quotes();

export default QuotesApi;
