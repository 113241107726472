import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';

interface MarkdownProps {
    content: string;
    className?: string;
}

function Markdown(props: MarkdownProps) {
    const { content, className } = props;

    const tagClassName = (tag: string) => {
        switch (tag) {
            case 'h1':
                return "font-sans text-2xl font-semibold pb-1.5 mb-4 border-b border-b-gray-300";
            case 'h2':
                return "font-sans text-xl font-semibold pb-1.5 mb-4 border-b border-b-gray-300";
            case 'h3':
                return "font-sans text-lg font-semibold my-1 mt-4 mb-4";
            case 'a':
                return "font-sans text-sm text-[#1f75cb] hover:underline";
            case 'p':
                return "font-sans text-sm mb-4 p-0";
            case 'ul':
                return "list-disc text-sm list-inside mt-1 mb-4";
            case 'li':
                return "text-sm relative mb-1 relative";
            case 'ol':
                return "list-decimal text-sm list-inside mt-1 mb-4";
            case 'pre':
                return "font-sans text-sm bg-gray-800 text-white mb-4 p-4 rounded overflow-auto";
            case 'code':
                return "text-xs bg-gray-800 text-white py-1 px-1 mt-1 mb-2 font-mono font-bold rounded overflow-auto";
        }
    };

    const interceptLinkClick = (e: AnyObj, node: AnyObj) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(node.properties.href, '_blank');
    }

    return (
        <ReactMarkdown
            className={`${className || ''} markdown overflow-auto`}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeHighlight]}
            components={{
                h1: ({node, children, ...props}) => <h1 className={tagClassName('h1')} {...props}>{children}</h1>,
                h2: ({node, children, ...props}) => <h2 className={tagClassName('h2')} {...props}>{children}</h2>,
                h3: ({node, children, ...props}) => <h3 className={tagClassName('h3')} {...props}>{children}</h3>,
                a: ({node, children, ...props}) => <a onClick={(e: AnyObj) => interceptLinkClick(e, node)} className={tagClassName('a')} {...props}>{children}</a>,
                p: ({node, children, ...props}) => <p className={tagClassName('p')} {...props}>{children}</p>,
                ul: ({node, children, ...props}) => <ul className={tagClassName('ul')} {...props}>{children}</ul>,
                li: ({node, children, ...props}) => <li className={tagClassName('li')} {...props}>{children}</li>,
                ol: ({node, children, ...props}) => <ol className={tagClassName('ol')} {...props}>{children}</ol>,
                pre: ({node, children, ...props}) => <pre className={tagClassName('pre')} {...props} >{children}</pre>,
                code: ({node, children, ...props}) => <code className={tagClassName('code')} {...props}>{children}</code>,
            }}
        >
            {content}
        </ReactMarkdown>
    );
}

export default Markdown;
