import { Dispatch } from 'redux';
import { Reminder } from '../../models';
import { Reminders } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Callback } from './rest';

const {
    initialState, resetReducer,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    resetAction, createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
} = createRestSlices(Reminders);

const CHANGE_ROUTINE = 'yoda/reminders/CHANGE_ROUTINE';
const addActionReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case CHANGE_ROUTINE:
            return {
                ...state,
                reminder: { ...state.reminder }
            };
        default:
            return state;
    }
};
/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        resetReducer,
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        addActionReducer
    ]);
}

const changeReminderAction = (reminder: Reminder, callback?: Callback) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CHANGE_ROUTINE, reminder });
        callback && callback(/*err*/null);
    }
}

/* Export CRUD actions */
export const resetReminder       = resetAction;
export const createReminder      = createAction;
export const startCreateReminder = startCreateAction;
export const loadReminder        = getAction;
export const loadReminders       = listAction;
export const updateReminder      = updateAction;
export const deleteReminder      = deleteAction;
export const changeReminder      = changeReminderAction;
