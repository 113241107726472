import RestClient from './RestClient';
import { Release } from '../models';

class Releases extends RestClient {
    constructor() {
        super({
            // rest config
            model: Release,
            entryPoint: 'releases',
            //sortBy: 'date',
            id_field: '_id',
            // redux config
            resource: 'release',
            resources: 'releases',
        });
    }
}

const ReleasesApi = new Releases();
export default ReleasesApi;
