import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux';
import { ChangeListItem, Icon } from "..";
import { useNavigate } from "react-router-dom";
import { Change, Project } from "../../models";
import { Avatar, Badge } from "flowbite-react";
import { t } from "i18next";

interface SearchResultProps {
    query: string;
    onClose: () => void;
}

const SearchResult: React.FC<SearchResultProps> = ({query, onClose}) => {
    const navigate      = useNavigate();
    const changes       = useAppSelector((state) => state.search.results.changes);
    const projects      = useAppSelector((state) => state.search.results.projects);
    const searchResults = [...(changes || []), ...(projects || [])];
    const countResults  = searchResults.length;

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        function handleKeyPress(event: any) {
            if (event.key === "ArrowDown") {
                setSelectedIndex((prevIndex) =>
                    prevIndex + 1 >= countResults ? 0 : prevIndex + 1
                );
            } else if (event.key === "ArrowUp") {
                setSelectedIndex((prevIndex) =>
                    prevIndex - 1 < 0 ? countResults - 1 : prevIndex - 1
                );
            } else if (event.key === "Enter") {
                (document.querySelector('.selectedResult') as HTMLElement)?.click();
            }
        }

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };

    }, [countResults, selectedIndex]);

    if (countResults === 0 && query !== "")
        return (
            <div>
                <h3 className="text-sm font-light text-center mt-3">
                    <Icon type="sad" className="inline mr-1.5" size={6} />
                    {t("search.no_result") || ""}
                </h3>
            </div>
        );

    let index = 0;
    return (
        <div>
            {projects?.length > 0 && (
                <div className="mt-3">
                    <h3 className="text-sm font-bold">
                        <Icon type="project" className="inline mr-1.5" />
                        {t('search.projects')}&nbsp;({projects.length})
                    </h3>
                    {projects.sort((a: Project, b: Project) => a.name.localeCompare(b.name)).map((result) => (
                        <div
                            key={result._id}
                            onClick={() => {
                                navigate(`/projects/${result._id}/`);
                                onClose();
                            }}
                            className={`border rounded p-2 cursor-pointer mt-2 flex items-center justify-between ${index++ === selectedIndex ? "bg-gray-100 selectedResult" : ""}`}
                        >
                            <div className="flex items-center space-x-2">
                                <Avatar img={result.getAvatarUrl()} className="inline w-fit"/>
                                <div className="flex flex-col">
                                    <div className="flex-1">
                                        <h4 className={`text-sm font-light heading-md`}>{result.name}</h4>
                                    </div>
                                    {result.lastRelease?.version &&
                                        <Badge className="w-fit">{result.lastRelease?.version}</Badge>
                                    }
                                </div>
                            </div>

                            <div className="flex items-center">
                                <div
                                    className="w-[80px] text-center whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                >
                                    <Icon type="commit" className="inline"/>&nbsp;
                                    <strong>{result.nbCommits}</strong>
                                </div>
                                <div
                                    className="w-[80px] text-center whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                >
                                    <Icon type="release" className="inline"/>&nbsp;
                                    <strong>{result.nbReleases}</strong>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {changes?.length > 0 && (
                <div className="mt-3">
                    <h3 className="text-sm font-bold">
                        <Icon type="board" className="inline mr-1.5" />
                        {t('search.changes')}&nbsp;({changes.length})
                    </h3>
                    {changes.sort((a: Change, b: Change) => b.slug - a.slug).map((result) => (
                        <ChangeListItem
                            className={`mt-2 ${(index === selectedIndex) ? "selectedResult" : ""}`}
                            change={result}
                            key={result._id}
                            tabIndex={index++ === selectedIndex ? 0 : -1}
                            showProject
                            onClick={() => {
                                navigate(`/projects/${result.project._id}/change/${result._id}`);
                                onClose();
                            }}
                        />
                    ))}
                </div>
            )}

            <div className="mt-4 text-xs flex justify-between">
                <div>{t("search.open_search_bar") || ""} <kbd className="mr-1">shift</kbd><kbd>/</kbd></div>
                <div>{t("search.close_search_bar") || ""} <kbd>esc</kbd></div>
            </div>
        </div>
    );
};

export default SearchResult;
