import BaseModel from './BaseModel';

/** Class representing a notification rule. */
class NotificationRule extends BaseModel {
    /**
     * Events types constants
     */
    static EVENT_RELEASE_CREATED = 'release.created';
    static EVENT_MR_OPEN         = 'mr.open';
    static EVENT_MR_APPROVED     = 'mr.approved';
    static EVENT_CHANGE_CREATED  = 'change.created';

    _id: string;
    events: string[];
    users: string[];
    groups: string[];

    constructor(properties: AnyObj) {
        super({});

        this._id    = properties._id;
        this.events = properties.events || [];
        this.users  = properties.users || [];
        this.groups = properties.groups || [];
    }

    static eventTypes() : string[] {
        return [
            NotificationRule.EVENT_CHANGE_CREATED,
            NotificationRule.EVENT_RELEASE_CREATED,
            NotificationRule.EVENT_MR_OPEN,
            NotificationRule.EVENT_MR_APPROVED,
        ];
    }
}

export default NotificationRule;
