import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux';
import { getChangelog } from '../../../../redux';
import { Release } from '../../../../models';
import { Changelog } from '../../../../components';

function PublicProjectView() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { changelog }       = useAppSelector((state: RootState) => state.projects);
    const { id, from, merge } = useParams();

    useEffect(() => {
        if (id)
            dispatch(getChangelog(id, from, merge?.split(',')));
    }, [dispatch, id]);

    if (!changelog?.project)
        return null;

    const { project, releases, merged } = changelog;

    return (
        <div className="ProjectView">
            <header className="bg-white border-b px-4 py-2 sm:px-6 lg:px-8">
                <div className="mx-auto lg:flex lg:itemProjects-center lg:justify-between">
                    <div className="flex flex-col space-y-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            { project.name }
                        </h2>
                        {merged?.length > 0 && (
                            <h3 className="">
                                <span className="text-md font-medium tracking-tight text-gray-900">&nbsp;
                                    {t('projects.includes')}&nbsp;
                                </span>
                                {merged?.map((mergedProject: AnyObj, i: number) => (
                                    <span className="text-md font-medium tracking-tight text-gray-899">
                                        {mergedProject.name}
                                        {i < merged.length - 2 ? ', ' : (i === merged.length - 2 ? ' ' + t('common.and') + ' ': '')}
                                    </span>
                                ))}
                            </h3>
                        )}
                    </div>
                </div>
            </header>
            <main className="mx-3">
                <div className="-mx-4 flex-1 shrink">
                    <Changelog releases={releases.sort((a: Release, b: Release) => b.versionNumber - a.versionNumber)} />
                </div>
            </main>
        </div>
    );
}

export default PublicProjectView;
