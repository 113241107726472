import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Change } from '../../../models';
import { Formik, Form } from 'formik';
import { QuoteProgress, Button, Icon, InlineField, ToggleField } from '../..';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { addChangeQuote, updateQuote, updateChange } from '../../../redux';
import { QuotePicker } from './QuotePicker';
import type { QuoteItem } from '../../../models';
import type { Status } from '../../../redux';
import _ from 'lodash';

interface BudgetStepsProps {
    change: Change;
}

const pendingStatus: Status = 'pending';

function BudgetSteps(props: BudgetStepsProps) {
    const { change } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { addQuote } = useAppSelector((state: RootState) => state.changes);
    const { updating } = useAppSelector((state: RootState) => state.quotes);

    const createQuote = () => {
        dispatch(addChangeQuote(change._id));
    }

    const setQuoteItemPrice = (field: string, value: string) => {
        const quote = change.quote;
        if (!quote)
            return;

        quote.price = 0;
        quote.items = quote.items.map((item: QuoteItem) => {
            if (item.change?._id === change._id)
                item.price = parseFloat(value);

            quote.price += item.price;
            return item;
        });

        dispatch(updateQuote(quote));
    }

    const onSaveField = async (field: string, value: any ) => {
        const params = { "_id":  change?._id };
        _.set(params, field, value);

        await dispatch(updateChange(params, /*patch*/true));
    }

    if (!change)
        return null;

    const itemIndexInQuote = change.quote?.items?.findIndex((item: QuoteItem) => item.change?._id === change._id);

    const initialValues: AnyObj = change.quote || {};
    initialValues.billingHourly = change.isBilledHourly();

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={() => {}}
        >
            <Form tabIndex={-1} className='bg-white max-h-full rounded-lg'>
                {change.quote && (
                <>
                    <div className="grid grid-cols-6 gap-x-6 gap-y-2 sm:grid-cols-6">
                        <div className="col-span-full -mb-2">
                            <h3 className="font-bold text-sm text-gray-900">{t('changes.quote')}</h3>
                        </div>
                        <div className="col-span-full md:col-span-2 flex flex-col justify-center items-center gap-x1 border border-gray-100 bg-gray-50 p-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                {t('changes.quote_number')}
                            </label>
                            <div className="text-xl font-bold text-center">{change.quote.slug}</div>
                        </div>
                        <div className="col-span-full md:col-span-2 flex flex-col justify-center items-center gap-x1 border border-gray-100 bg-gray-50 p-2 relative">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                {t('changes.item_price')}
                            </label>
                            <div className="flex flex-row items-end">
                                {(updating === pendingStatus)  ? (
                                    <Icon type="loading" size={7} />
                                ) : (
                                    <>
                                        <InlineField
                                            as="input"
                                            type="number"
                                            step={0.5}
                                            name={`items[${itemIndexInQuote}].price`}
                                            onSave={ setQuoteItemPrice }
                                            saveOnBlur
                                            isLoading={ updating === pendingStatus }
                                            readOnly={ !!change.quote.acceptedAt }
                                            className={`arrow-hide w-24 text-right -mt-1 font-bold text-xl mr-1 bg-gray-50`}
                                        />
                                        <div className="text-xl leading-1 font-bold">&nbsp;CHF</div>
                                        {!!change.quote.acceptedAt && (
                                            <Icon type="lock" size={3} className="absolute right-1 bottom-1"/>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-span-full md:col-span-2 flex flex-col justify-center items-center gap-x1 border border-gray-100 bg-gray-50 p-2 relative">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                {t('changes.quote_total')}
                            </label>
                            <div className="flex flex-row items-end">
                                {(updating === pendingStatus)  ? (
                                    <Icon type="loading" size={7} />
                                ) : (
                                    <>
                                        <div className="text-xl leading-1 font-bold">{change?.quote?.formattedPrice()}</div>
                                        <Icon type="lock" size={3} className="absolute right-1 bottom-1"/>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-span-full text-center">
                            <Button
                                small
                                icon={'edit'}
                                iconSize={3}
                                color="primary"
                                onClick={() => navigate(`/quotes/${change?.quote?._id}`)}
                                title={t('changes.edit_quote')}
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <QuoteProgress quote={change.quote} />
                    </div>
                </>
                )}
                {!change.quote && (
                    <div className="flex flex-col items-center gap-y-3">
                        {!change.quote && (
                            <div className="flex flex-row justify-center items-center gap-x-3 rounded-md border p-4">
                                <h3 className={`text-gray-900 text-sm font-${change.isBilledHourly() ? 'light' : 'bold'}`}>
                                    {t('changes.billing_method_fixed')}
                                </h3>
                                <ToggleField
                                    name="billingHourly"
                                    onChange={v => { onSaveField('billingMethod', !!v ? Change.BILLING_METHOD_HOURLY : Change.BILLING_METHOD_FIXED); }}
                                    isLoading={ updating === 'pending' }
                                />
                                <h3 className={`text-gray-900 text-sm font-${change.isBilledHourly() ? 'bold' : 'light'}`}>
                                    {t('changes.billing_method_hourly')}
                                </h3>
                            </div>
                        )}
                        {!change.isBilledHourly() && (
                            <div className="flex-1 flex flex-col justify-start items-center space-y-2 w-full">
                                <Button
                                    icon={addQuote === pendingStatus ? 'loading' : 'plus'}
                                    color="primary"
                                    onClick={() => createQuote()}
                                    title={t('changes.create_quote')}
                                />
                                <QuotePicker change={change} />
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </Formik>
    );
}

export default BudgetSteps;
