import React from 'react';
import { ToggleSwitch } from 'flowbite-react';
import { useField } from 'formik';
import { Icon } from '..';

interface ToggleFieldProps {
    name: string;
    label?: string | undefined;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    onChange?: (value: boolean) => void;
};

const ToggleField = (props: ToggleFieldProps) => {
    const [ field, meta, helpers ] = useField(props.name);
    const { value }                = meta;
    const { setValue }             = helpers;

    if (props.isLoading)
        return <Icon type="loading" size={5} />;

    const onValueChanged = (value: boolean) => {
        if (props.onChange)
            return props.onChange(value);

        setValue(value);
    }

    return (
        <ToggleSwitch
            label={props.label || ''}
            disabled={!!props.disabled}
            checked={!!value}
            onChange={onValueChanged}
            className={props.className}
        />
    )
};

export default ToggleField;
