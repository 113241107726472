import React from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch } from '../../../redux';
import { Routine, RoutineAction } from '../../../models';
import { Icon } from '../../Icon';
import CreateChangeForm from './CreateChangeForm';
import DuplicateChangeForm from './DuplicateChangeForm';
import NotifyForm from './NotifyForm';

interface ActionFormProps {
    action: RoutineAction;
    onSave?: (action: RoutineAction) => void;
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function ActionForm(props: ActionFormProps) {
    const { action, onSave, authorId, projectId, changeId } = props;

    const { i18n, t } = useTranslation();
    const dispatch    = useAppDispatch();

    const form = React.useMemo(() => ({
        'create_change': <CreateChangeForm {...props} />,
        'duplicate_change': <DuplicateChangeForm {...props} />,
        'notify': <NotifyForm {...props} />,
    }[action.type]), [action?.type]);

    return (
        <>
            { form }
        </>
    );
}

export default ActionForm;
