import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadUsers } from '../../../redux';
import { Link } from "react-router-dom";
import { Ability, Button, Icon, UserAvatar } from '../../../components';
import { Table } from 'flowbite-react';
import { User } from '../../../models';

function UsersList() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { users } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    const usersByRole = {
        "adminUsers"    : users?.filter((user: User) => user?.role === User.ROLE_ADMIN),
        "simpleUsers"   : users?.filter((user: User) => user?.role === User.ROLE_USER),
        "externalUsers" : users?.filter((user: User) => user?.role === User.ROLE_EXTERNAL),
        "cliantUsers"   : users?.filter((user: User) => user?.role === User.ROLE_CLIENT)
    }

    return (
        <Ability can="user:read" redirect="/">
            <div className="UsersList">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="user" size={7} className="mr-1.5 inline" />
                                { t('app.users') }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('users.add_a_user')} color="navigate" to="/users/new" icon="plus" />
                            </span>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cos-5 gap-4">
                        {Object.keys(usersByRole).map((key) => (
                        <div className="p-5" key={key}>
                            <Table striped hoverable>
                                <Table.Head>
                                    <Table.HeadCell>{t("users."+key)}</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {(usersByRole[key as keyof typeof usersByRole]).sort((a: User, b: User) => a.firstname > b.firstname).map((user: User) => (
                                        <Table.Row key={user?._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                <Link to={`/users/edit/${user._id}`} className="flex gap-x-3">
                                                    <UserAvatar user={user} hideInitials size={10} />
                                                    <div className="flex-row">
                                                        {user?.fullname()}
                                                        <div className="text-xs text-gray-500">{user.email}</div>
                                                    </div>
                                                </Link>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                        ))}
                    </div>
                </main>
            </div>
        </Ability>
    );
}

export default UsersList;
