import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Change, User, Track, Client } from '../../models';
import { Modal, Tabs,  Badge, Dropdown} from 'flowbite-react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Transition } from '@headlessui/react';
import {
    RootState, useAppSelector, useAppDispatch,
    setImageReleaseNotes, unsetImageReleaseNotes,
    loadClients, updateChange, loadTracks,
    addChecklist,
    loadChecklists
} from '../../redux';
import {
    Ability, Icon, Button, InlineTextareaField, UserAvatarField,
    TranslatableField, TranslatableWysiwygField, TypeField, UserAvatar,
    DatepickerField, InlineField, Alert, ToggleField, ImportanceField,
    ChangeActivity, Confirm, Loader, Uploader, ChangeTrack,
    ImageUploader, ImageListType, Checklists, MoveChangeModal,
    ReminderWidget
} from '..';
import { ProgressSteps } from './ProgressSteps';
import { BudgetSteps } from './BudgetSteps';
import { Sprints } from './Sprints';
import _ from 'lodash';
import ClientSelectField from "../ClientSelectField/ClientSelectField";
import moment from 'moment';

interface ChangeModalProps {
    project: Project;
    change: Change;
    show: boolean;
    showProjectName?: boolean;
    onClose: () => void;
    onRemove: (values: AnyObj) => void;
}

function ChangeModal(props: ChangeModalProps) {
    const { t, i18n } = useTranslation();
    const dispatch    = useAppDispatch();

    const [confirmIsVisible, setConfirmVisible] = useState(false);
    const [moveModalIsVisible, showMoveModal]   = useState(false);
    const [pendingUpdates, setPendingUpdates]   = useState<AnyObj>({});

    const { project, change, show, showProjectName, onClose, onRemove } = props;

    const { user }            = useAppSelector((state: RootState) => state.auth);
    const { users }           = useAppSelector((state: RootState) => state.users);
    const { updating }        = useAppSelector((state: RootState) => state.changes);
    const { board }           = useAppSelector((state: RootState) => state.boards);
    const { tracks, loading } = useAppSelector((state: RootState) => state.tracks);

    const isLoadingtracks = loading === 'pending';

    const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
    const [clientChanged, setClientChanged]   = useState(false);

    useEffect(() => {
        dispatch(loadClients());
    }, [dispatch]);

    useEffect(() => {
        let clientObject = props.change?.client;
        if (!clientChanged) {
            setSelectedClient(clientObject as unknown as Client);
        }
        setClientChanged(false);
    }, [props.change]);

    const members = React.useMemo(() => {
        if (!project || !users)
            return [];

        return users.filter((user: User) => project.hasMember(user));
    }, [project, users]);

    const assignableMembers = React.useMemo(() => {
        if (!project || !users || !user.can('change:assign_self'))
            return [];

        const projectMembers = users.filter((user: User) => project.hasMember(user));
        if (user.can('change:assign'))
            return projectMembers;

        return projectMembers.filter((u: User) => u._id === user._id);
    }, [project, users]);

    const trackedDuration = React.useMemo(() => {
        if (!tracks)
            return null;

        const duration = tracks
            .filter((track: Track) => track.change?._id === change?._id)
            .reduce((acc: number, track: Track) => acc + track.seconds(), 0);

        return duration > 0 ? Track.format(duration) : null;
    }, [change, tracks]);

    const [columnTitle, setColumnTitle] = useState('');

    const isCreation = !(change?._id?.length > 0);

    useEffect(() => {
        if (!change || !project)
            return;

        board?.columns?.forEach((column: AnyObj) => {
            if (column.changes.find((c: Change) => c._id === change._id))
                setColumnTitle(column.title);
        });
        setConfirmVisible(false);
    }, [board?.columns, change, project]);

    useEffect(() => {
        if (!change)
            return;

        dispatch(loadTracks({
            from: change.createdAt,
            change: change._id
        }));
    }, [change]);

    const onChangeField = async (field: string, value: any ) => {
        _.set(pendingUpdates, field, value);
        setPendingUpdates(pendingUpdates);
    }

    const onSaveField = async (field: string, value: any ) => {
        const params = { "_id":  change?._id, ...pendingUpdates };
        _.set(params, field, value);

        await dispatch(updateChange(params, /*patch*/true, () => {
            setPendingUpdates({});
        }));
    }

    if (show && !change)
        return (
            <Loader />
        );

    if (!change)
        return null;

    const handleKeyPress = (e: AnyObj) => {
        if (e.key === 'Escape' || e.code === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onImageRelease = (imageList: ImageListType) => {
        if (imageList.length > 0) {
            const file: File | undefined = imageList[0].file;
            if (file) {
                return dispatch(setImageReleaseNotes(change._id, file));
            }
        }
    };

    const removeImageRelease = () => {
        dispatch(unsetImageReleaseNotes(change._id));
    }

    // Adds a new to-do list to the checklist.
    const addToDoList = () => {
        const checklist = { name : t('changes.checklist.new_list'), items: [], project, change, user };

        dispatch(addChecklist(checklist, {}, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(loadChecklists({change: change._id}));
            document.getElementById('checklists-component')?.scrollIntoView({ behavior: 'smooth' });
        }));
    }

    const userCanDeleteThisCard = user.can('change:delete') ? true : (
        user.can('change:delete:own') && (change.author?._id === user?._id)
    );
    const createdByAndAt = () => {
        if (change.author && change.createdAt)
            return change.author.fullname() + " - " + moment(change.createdAt).locale(i18n.language).format('ll');

        return "";
    };

    return (
        <div className="ChangeModal">
            <Modal onClose={onClose} dismissible={!confirmIsVisible} size="3xl" show={show} className="focus:outline-0">
                <Formik
                    initialValues={change || {}}
                    enableReinitialize={true}
                    onSubmit={() => {}}
                >
                    <Form tabIndex={-1} onKeyDown={handleKeyPress} className='bg-white max-h-full rounded-lg'>
                        <Modal.Header className="w-full" theme={{ "title": "text-xl font-medium text-gray-900 dark:text-white w-full" }}>
                            <div className="flex justify-start w-full">
                                {!isCreation && (
                                    <span className="flex items-center font-semibold bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-800 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300 rounded p-2 text-xs w-fit text-xs mr-1.5">
                                        {change.slugName()}
                                    </span>
                                )}
                                <div className="flex flex-col justify-center w-full">
                                    <div className="m-0 p-0 w-full">
                                        <TranslatableField
                                            as="input"
                                            type="text"
                                            name="title"
                                            hideEditLink
                                            readOnly={!user?.can('change:title:edit')}
                                            className="font-bold"
                                            onChange={ onChangeField }
                                            onSave={ onSaveField }
                                            langs={project?.translation?.languages?.all}
                                            required
                                        />
                                        <ErrorMessage name="title" component="div" />
                                    </div>
                                    <div className="flex flex-row justify-between w-full">
                                        {showProjectName ? (
                                            <small className="flex items-center space-x-2 font-normal text-sm italic leading-6 w-full pl-1 -mt-1">
                                                <UserAvatar user={change.author as User} size={5} hideInitials />
                                                <a
                                                    href={`/projects/${change?.project?._id}/change/${change?._id}`}
                                                    className="focus:outline-none"
                                                    dangerouslySetInnerHTML={{__html: createdByAndAt() + ' ' + t('changes.in_the_project', { project: change.project?.name, }) || ''}}
                                                />
                                            </small>

                                        ) : (
                                            <small className="flex items-center space-x-2 font-normal text-sm italic leading-6 w-full pl-1 -mt-1">
                                                <UserAvatar user={change.author as User} size={5} hideInitials />
                                                <span dangerouslySetInnerHTML={{__html: createdByAndAt() + ' ' + t('changes.in_the_column', {column: columnTitle}) || ''}}
                                                />
                                            </small>
                                        )}
                                        <Ability can="change:ticket:edit">
                                            <div className="group flex">
                                                <Badge color="indigo" className={`w-fit py-1 ${change.ticket_number ? 'group-hover:rounded-r-none' : ''}`}>
                                                    <div className="flex flex-row justify-end items-center">
                                                        <Icon type="ticket" />
                                                        <InlineField
                                                            as="input"
                                                            type="text"
                                                            name="ticket_number"
                                                            placeholder="123456"
                                                            onChange={ onChangeField }
                                                            onSave={ onSaveField }
                                                            isLoading={ updating === 'pending' }
                                                            readOnly={ !user?.can('change:title:edit') }
                                                            className={`w-[50px] text-right text-xs bg-transparent`}
                                                        />
                                                    </div>
                                                </Badge>
                                                {change.ticket_number && (
                                                    <>
                                                        <Button
                                                            title=""
                                                            icon="eye"
                                                            iconSize={4}
                                                            color="navigateBack"
                                                            small
                                                            to={change.ticket_view_url}
                                                            target="_blank"
                                                            className="rounded-none hidden group-hover:block"
                                                        />
                                                        <Button
                                                            title=""
                                                            icon="reply"
                                                            iconSize={4}
                                                            color="navigateBack"
                                                            small
                                                            to={change.ticket_reply_url}
                                                            target="_blank"
                                                            className="rounded-none hidden group-hover:block"
                                                        />
                                                        <Button
                                                            title=""
                                                            icon="close"
                                                            iconSize={4}
                                                            color="navigateBack"
                                                            small
                                                            to={change.ticket_close_url}
                                                            target="_blank"
                                                            className="rounded-l-none hidden group-hover:block"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </Ability>
                                    </div>
                                </div>
                                {user?.can('track:create') && change.assignee?._id === user._id && change.isTimeTrackable() && (
                                    <span className="flex items-center font-semibold bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800 group-hover:bg-blue-200 dark:group-hover:bg-blue-300 rounded p-2 text-xs w-fit text-xs">
                                        <ChangeTrack change={change} />
                                    </span>
                                )}
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ minHeight: '500px' }}>
                            <Transition
                                as={Fragment}
                                appear={true}
                                show={!!change}
                                enter="transition ease-out duration-1000"
                                enterFrom="transform opacity-0"
                                enterTo="transform opacity-100"
                            >
                                <div className="-mt-5">
                                    <div className="w-fit py-1 absolute top-3 right-0 flex items-center" >
                                        <Badge color="indigo" className='mr-1'>
                                            <Icon type="priority" className="inline mr-1" />
                                            {change.priority}
                                        </Badge>
                                        <Dropdown label="" placement="bottom" dismissOnClick={false} renderTrigger={() => (
                                            <div className="cursor-pointer">
                                                <Icon type="menu" className="hover:bg-gray-200 p-1 rounded" size={6} />
                                            </div>
                                        )}>
                                            <Dropdown.Item className="font-medium text-xs w-48" onClick={() => addToDoList()}>
                                                <Icon type="list" className="mr-1.5" size={3} />
                                                {t('changes.checklist.add_a_checklist')}
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item className="font-medium text-xs w-48" onClick={() => showMoveModal(true)}>
                                                <Icon type="move" className="mr-1.5" size={3} />
                                                {t('changes.move_this_change')}
                                            </Dropdown.Item>
                                            {userCanDeleteThisCard && (
                                                <>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => setConfirmVisible(true)} className="font-medium text-xs text-red-500">
                                                        <Icon type="delete" className="mr-1.5" color="red-500" size={3} />
                                                        {t('changes.delete')}
                                                    </Dropdown.Item>
                                                </>
                                            )}
                                        </Dropdown>
                                    </div>
                                    <Tabs>
                                        <Tabs.Item icon={() => <Icon type="list" className="mr-1.5 outline-none" />} title={t('changes.step_progress')}>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="col-span-full">
                                                    <div className="-my-2 border bg-slate-50 p-2 rounded">
                                                        <TypeField
                                                            name="type"
                                                            readOnly={!user?.can('change:type:edit')}
                                                            onSave={ onSaveField }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-full grid grid-cols-3 gap-2">
                                                    <Ability can="change:client:edit">
                                                        <div className={`col-span-${user.can('change:importance:edit') ? 2 : 3} mt-2`}>
                                                            <div className="flex items-center">
                                                                <Icon type="user" color="black" className="inline mr-1"/>
                                                                <div className="text-sm font-medium leading-6 text-gray-900">
                                                                    {selectedClient ? t('changes.client_linked') : t('changes.add_client') }
                                                                </div>
                                                            </div>
                                                            <ClientSelectField
                                                                name="client"
                                                                className="mt-2"
                                                                placeholder={t('projects.search_client') || ""}
                                                                onChange={(client) => {
                                                                    setClientChanged(true);
                                                                    setSelectedClient(client);
                                                                    onSaveField('client', client ?? null);
                                                                }}
                                                            />
                                                        </div>
                                                    </Ability>
                                                    <Ability can="change:importance:edit">
                                                        <div className={`col-span-${user.can('change:client:edit') ? 1 : 1} mt-2`}>
                                                            <div className="flex items-center">
                                                                <Icon type="importance" color="black" className="inline mr-1"/>
                                                                <div className="text-sm font-medium leading-6 text-gray-900">
                                                                    {t('changes.importance') }
                                                                </div>
                                                            </div>
                                                            <ImportanceField
                                                                name="importance"
                                                                className="mt-2 border p-0.5 rounded-md"
                                                                placeholder={t('changes.importance') || ""}
                                                                onChange={importance => {
                                                                    onSaveField('importance', importance);
                                                                }}
                                                            />
                                                        </div>
                                                    </Ability>
                                                </div>
                                                <Ability can="change:description:edit">
                                                    <div className="col-span-full">
                                                        <TranslatableWysiwygField
                                                            name="description"
                                                            inline
                                                            langs={project?.translation?.languages?.all}
                                                            placeholder={t('changes.enter_description') || ''}
                                                            rows={4}
                                                            label={(
                                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                                    <Icon type="description" className="inline mr-1.5" />
                                                                    {t('changes.description')}
                                                                </label>
                                                            )}
                                                            isLoading={ updating === 'pending' }
                                                            onChange={ onChangeField }
                                                            onSave={ onSaveField }
                                                        />
                                                    </div>
                                                </Ability>
                                                <Ability can="change:notes:edit">
                                                    <div className="col-span-full">
                                                        <InlineTextareaField
                                                            label={(
                                                                <label htmlFor="developer_notes" className="block text-sm font-medium leading-6 text-gray-900">
                                                                    <Icon type="notes" className="inline mr-1.5" />
                                                                    {t('changes.developer_notes')}
                                                                </label>
                                                            )}
                                                            name="developer_notes"
                                                            placeholder={t('changes.enter_developer_notes') || ''}
                                                            rows={6}
                                                            onChange={ onChangeField }
                                                            onSave={ onSaveField }
                                                            isLoading={ updating === 'pending' }
                                                            uploadProps={{
                                                                projectId: project?._id,
                                                                changeId: change?._id
                                                            }}
                                                        />
                                                    </div>
                                                </Ability>
                                                <div className={`col-span-full md:col-span-${change.estimate > 0 ? '2' : 'full'} flex flex-col items-center gap-x1 border border-gray-100 bg-${change.estimate > 0 ? 'gray' : 'red'}-50 p-2`}>
                                                    <label htmlFor="estimate" className="block text-sm font-medium leading-6 text-gray-900 mr-2">
                                                        {t('changes.estimate_desc')}
                                                    </label>
                                                    <InlineField
                                                        as="input"
                                                        type="number"
                                                        step={0.5}
                                                        name="estimate"
                                                        onChange={ onChangeField }
                                                        onSave={ onSaveField }
                                                        isLoading={ updating === 'pending' }
                                                        readOnly={ !user?.can('change:estimate') }
                                                        className={`arrow-hide w-10 text-center -mt-1 font-bold text-xl mr-2 bg-${change.estimate > 0 ? 'gray' : 'red'}-50  w-full`}
                                                    />
                                                    { change.estimate > 0 && (
                                                    <label htmlFor="estimate" className="flex-initial text-sm font-medium leading-6 text-gray-900">
                                                        {t('changes.estimate_units')}
                                                    </label>
                                                    )}
                                                    <label htmlFor="estimate" className="h-4 flex justify-center items-center text-xs text-gray-900 mt-1">
                                                        {isLoadingtracks && (
                                                            <Icon type="loading" size={3} />
                                                        )}
                                                        {!isLoadingtracks && trackedDuration && (
                                                            <>
                                                                <Icon type="timer" size={3} />
                                                                {trackedDuration}
                                                            </>
                                                        )}
                                                    </label>
                                                </div>

                                                {change.estimate > 0 && (
                                                    <>
                                                        <div className="col-span-full md:col-span-2 flex flex-col items-center gap-x1 border border-gray-100 bg-gray-50 p-2">
                                                            <label htmlFor="billable" className="block text-sm font-medium leading-6 text-gray-900">
                                                                {t('changes.billable')}
                                                            </label>
                                                            {user?.can('change:estimate') ? (
                                                                <>
                                                                    {change.billable && (change.quote || change.isBilledHourly()) ? (
                                                                        <Icon type="check" size={8} />
                                                                    ) : (
                                                                        <ToggleField
                                                                            name="billable"
                                                                            onChange={v => { onSaveField('billable', v); }}
                                                                            isLoading={ updating === 'pending' }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Icon type={change.billable ? 'check' : 'close'} size={8} />
                                                                </>
                                                            )}
                                                            {change.billable && (
                                                                <span className="text-xs mt-2">
                                                                    {t(`changes.billing_method_${change.billingMethod}`)}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {change.isPlannable() && (
                                                            <>
                                                                <div className="col-span-full md:col-span-2 flex flex-col items-center gap-x1 border border-gray-100 bg-gray-50 p-2">
                                                                    <label htmlFor="deadline" className="block text-sm font-medium leading-6 text-gray-900">
                                                                        {t('changes.deadline')}
                                                                    </label>
                                                                    {user?.can('change:plan') ? (
                                                                        <DatepickerField name="deadline" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                                                                    ) : (
                                                                        <label className="block text-sm font-medium leading-6 text-gray-900 py-2">
                                                                            {change.deadline ? change.deadline.format('LL') : '-'}
                                                                        </label>
                                                                    )}
                                                                    { change.deadline && (
                                                                        <ReminderWidget change={ change } />
                                                                    )}
                                                                </div>
                                                                <div className="col-span-full">
                                                                    <Sprints change={change} readOnly={!user?.can('change:plan')} />
                                                                </div>
                                                            </>
                                                        )}
                                                        {change.deadline && change.isPlannable() && (
                                                        <div className="col-span-full flex flex-col items-center gap-x1 border border-gray-100 bg-gray-50 p-2">
                                                            <label htmlFor="assignee" className="block text-sm font-medium leading-6 text-gray-900">
                                                                {t('changes.assignee')}
                                                            </label>
                                                            {user?.can('change:assign_self') && (
                                                                <div className="mt-2 border w-full bg-slate-50 p-2 rounded text-center">
                                                                    <UserAvatarField name="assignee._id" users={assignableMembers} onSave={ onSaveField }/>
                                                                </div>
                                                            )}
                                                            {!user?.can('change:assign_self') && change.assignee && (
                                                                <label className="text-sm font-bold">{change.assignee.fullname()}</label>
                                                            )}
                                                            {!user?.can('change:assign_self') && !change.assignee && (
                                                                <label className="text-sm italic">{t('changes.no_assignee_yet')}</label>
                                                            )}
                                                        </div>
                                                        )}
                                                    </>
                                                )}
                                                <div className="col-span-full">
                                                    <ProgressSteps change={change} />
                                                </div>
                                                <div id="checklists-component" className="col-span-full">
                                                    <Checklists change={change._id} />
                                                </div>
                                                <div className="col-span-full">
                                                    <ChangeActivity members={ members } change={ change } />
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                        <Tabs.Item disabled={!change.billable} icon={() => <Icon type="law" className="mr-1.5 outline-none" />} title={t('changes.step_budget')}>
                                            {change.estimate > 0 ? (
                                                <BudgetSteps change={change} />
                                                ) : (
                                                <div className="col-span-full">
                                                    <Alert title={t('changes.estimate_to_continue')} icon="warning" color="warning"/>
                                                </div>
                                            )}
                                        </Tabs.Item>
                                        <Tabs.Item icon={() => <Icon type="release" className="mr-1.5 outline-none" />} title={t('changes.step_release')}>
                                            {change.estimate > 0 && (
                                                <>
                                                    <div className="m-0 mb-5 p-0 w-full">
                                                        <TranslatableField
                                                            as="input"
                                                            type="text"
                                                            name="release_notes.title"
                                                            onChange={ onChangeField }
                                                            onSave={ onSaveField }
                                                            className="font-bold"
                                                            langs={project?.translation?.languages?.all}
                                                            label={(
                                                                <label htmlFor="release_notes.title" className="block text-sm font-medium leading-6 text-gray-900">
                                                                    {t('changes.release_notes.title')}
                                                                </label>
                                                            )}
                                                            required
                                                        />
                                                        <ErrorMessage name="title" component="div" />
                                                    </div>
                                                    <div className="m-0 mb-5 p-0 w-full">
                                                        <div className="mt-2">
                                                            <TranslatableWysiwygField
                                                                name="release_notes.description"
                                                                inline
                                                                langs={project?.translation?.languages?.all}
                                                                placeholder={t('changes.enter_description') || ''}
                                                                rows={4}
                                                                label={(
                                                                    <label htmlFor="release_notes.description" className="block text-sm font-medium leading-6 text-gray-900">
                                                                        {t('changes.release_notes.description')}
                                                                    </label>
                                                                )}
                                                                onChange={ onChangeField }
                                                                onSave={ onSaveField }
                                                                isLoading={ updating === 'pending' }
                                                            />
                                                        </div>
                                                    </div>
                                                    <ImageUploader hidePreview onChange={onImageRelease}>
                                                        <div className="col-span-full">
                                                            {change.release_notes?.image && (
                                                                <>
                                                                    <img src={change.getReleaseImageUrl(80, true) || ''} className="w-full max-h-80 object-contain" />
                                                                    <Button
                                                                        title=""
                                                                        icon="delete"
                                                                        onClick={removeImageRelease}
                                                                        iconColor="red-600"
                                                                        className="group-hover:block absolute -bottom-3 -right-5"
                                                                        color="none"
                                                                    />
                                                                </>
                                                            )}
                                                            {!change.release_notes.image && (
                                                                <div className="group relative">
                                                                    <div className="flex items-center justify-center">
                                                                        <Icon type="file" color="black" className="inline mr-1"/>
                                                                        <div className="text-sm font-medium leading-6 text-gray-900">
                                                                            {t('attachments.drag_files')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ImageUploader>
                                                </>
                                            )}
                                            {!(change.estimate > 0) && (
                                                <div className="col-span-full">
                                                    <Alert title={t('changes.estimate_to_continue')} icon="warning" color="warning"/>
                                                </div>
                                            )}
                                        </Tabs.Item>
                                        <Tabs.Item
                                            icon={() => <Icon type="file" className="mr-1.5 outline-none" />}
                                            title={t('changes.step_file') + (change?.nbAttachments > 0 ? ' (' + change.nbAttachments +')' : '')}
                                        >
                                            <Uploader projectId={project?._id} changeId={change._id} />
                                        </Tabs.Item>
                                    </Tabs>
                                </div>
                            </Transition>
                            {moveModalIsVisible && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-50 rounded-lg">
                                    <div className="w-[300px] rounded border flex items-center justify-center bg-gray-50">
                                        <MoveChangeModal
                                            show={moveModalIsVisible}
                                            onClose={() => showMoveModal(false)}
                                            initialBoardId={board?._id}
                                        />
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                    </Form>
                </Formik>
            </Modal>
            <Confirm
                visible={confirmIsVisible}
                onConfirm={() => { onRemove(change); setConfirmVisible(false); }}
                onCancel={() => setConfirmVisible(false)}
                title={t('changes.are_you_sure_to_delete') || ''}
            />
        </div>
    );
}

export default ChangeModal;

