import BaseModel from './BaseModel';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a user. */
class Group extends BaseModel {
    /**
     * Group type constants
     */
    static GROUP_TYPE_SIGNAL  = 'signal';

    _id: string;
    name: string;
    type: string;
    raw: AnyObj;
    createdAt?: Moment;
    updatedAt?: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id       = properties._id;
        this.name      = properties.name;
        this.type      = properties.type;
        this.raw       = properties.raw;

        if ('createdAt' in properties)
            this.createdAt = moment(properties.createdAt);
        if ('updatedAt' in properties)
            this.updatedAt = moment(properties.updatedAt);
    }
}

export default Group;
