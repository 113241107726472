import { Logs } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const { initialState, listReducer, listAction } = createRestSlices(Logs);

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [ listReducer ]);
}

/* Export CRUD actions */
export const loadLogs = listAction;
