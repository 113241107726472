import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Release, Change } from '../../models';
import type { ColumnData } from '../../models';
import { useAppDispatch, useAppSelector, RootState } from '../../redux';
import { loadReleases, cancelCreateChange, deleteChange } from '../../redux';
import { loadProject, loadChange } from '../../redux';
import { ChangeModal, Loader } from '../../components';
import { KanBan } from '../KanBan';
import { Select } from 'flowbite-react';

interface ProjectReleasesProps {
    project: Project;
}

function ProjectReleases(props: ProjectReleasesProps) {
    const { project } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [changeId, setChangeId]   = useState<string>();
    const [releaseId, setReleaseId] = useState();

    const { change }            = useAppSelector((state : RootState) => state.changes);
    const { loading, releases } = useAppSelector((state : RootState) => state.releases);

    useEffect(() => {
        dispatch(loadReleases({project: project._id}));
    }, [dispatch]);

    useEffect(() => {
        if (changeId)
            dispatch(loadChange(changeId));
    }, [dispatch, changeId]);

    const closeModal = () => {
        setChangeId(undefined);
        dispatch(cancelCreateChange());
    };

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(cancelCreateChange());
            dispatch(loadProject(project._id));
        }));
        closeModal();
    };

    const onReleaseChange = (e: AnyObj) => {
        setReleaseId(e.target.value);
    }

    // select first release _id by default
    if (!releaseId && releases?.length > 0)
        setReleaseId(releases[0]._id);

    const release = releases?.find((r: Release) => r._id === releaseId);
    const board   = release?.board();

    return (
        <div className="ProjectReleases relative mt-2">
            <div className="max-w-[calc(100vw-2rem)] mt-0 overflow-x-auto">
                <div className="flex w-full justify-center items-center">
                    <Select onChange={onReleaseChange} value={release?._id}>
                        <option>{t('changes.choose_a_release')}</option>
                        {releases?.map((release: Release) => (
                            <option key={release._id} value={release._id}>
                                V. {release.version}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="flex flex-row justify-stretch items-stretch">
                    {(loading === 'pending') && (
                        <Loader />
                    )}
                    {board && (loading !== 'pending') && (
                        <>
                            <KanBan
                                board={board}
                                expanded={true}

                                onAddColumn={(columnTitle: string) => {}}
                                onEditColumn={(column: ColumnData) => {}}
                                onRemoveColumn={(column: ColumnData) => {}}
                                onAddCard={(initial: AnyObj, boardId: string, columnId: string) => {}}
                                onEditCard={(change: Change) => setChangeId(change._id)}
                                onDragCard={(result: AnyObj) => {}}
                            />
                            {change && (
                                <ChangeModal
                                    project={project}
                                    change={change}
                                    show={true}
                                    onClose={closeModal}
                                    onRemove={onRemoveCard}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProjectReleases;
