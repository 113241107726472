import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBlock, ContentState, EditorState, convertToRaw } from 'draft-js';
import { CharacterMetadata, RichUtils, SelectionState, genKey } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { List, Repeat } from 'immutable';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export type Mention = {
    text: string;
    value: string;
    url?: string;
}

interface WysiwygProps {
    value: string;
    onChange: (value: string, editorState: EditorState) => void;
    className?: string;
    rows?: number;
    minimal?: boolean;
    children?: JSX.Element | JSX.Element[];
    setRef?: (ref: HTMLTextAreaElement) => void;
    mentions?: Mention[];
};

const fullToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    },
    emoji: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    embedded: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        embedCallback: undefined,
        defaultSize: {
            height: 'auto',
                width: 'auto',
        },
    },
    image: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: undefined,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
                width: 'auto',
        },
    },
    remove: { className: undefined, component: undefined },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
    },
};

const simpleToolbar = {
    options: ['inline', 'blockType', 'list', 'link'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    }
};

export const insertLink = (editorState: EditorState, linkText: string, linkUrl: string): EditorState | null => {
    if (!editorState)
        return null;

    const selectionState  = editorState.getSelection();
    const contentState    = editorState.getCurrentContent();
    const currentBlock    = contentState.getBlockForKey(selectionState.getStartKey());
    const currentBlockKey = currentBlock.getKey();
    const blockMap        = contentState.getBlockMap();
    const blocksBefore    = blockMap.toSeq().takeUntil((v) => (v === currentBlock));
    const blocksAfter     = blockMap.toSeq().skipUntil((v) => (v === currentBlock)).rest();
    const newBlockKey     = genKey();

    // add new ContentBlock to editor state with appropriate text
    const list: any = (List(Repeat(CharacterMetadata.create(), linkText.length)) as any);
    const newBlock = new ContentBlock({
        key: newBlockKey,
        type: 'unstyled',
        text: linkText,
        characterList: list
    });

    const newBlockMap = blocksBefore.concat(
        [[currentBlockKey, currentBlock], [newBlockKey, newBlock]],
        blocksAfter
    ).toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = contentState.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    });

    let newEditorState = EditorState.push(editorState, newContent as ContentState, 'split-block');

    // programmatically apply selection on this text
    let newSelection = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: linkText.length
    });

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);

    // create link entity
    const newContentState = newEditorState.getCurrentContent();

    const contentStateWithEntity = newContentState.createEntity(
        'LINK',
        'IMMUTABLE',
        { url: linkUrl, target: '_blank' }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    newEditorState = EditorState.set(newEditorState, { currentContent: contentStateWithEntity });

    newEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);

    // reset selection
    newSelection = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: linkText.length,
        focusKey: newBlockKey,
        focusOffset: linkText.length
    });

    return EditorState.forceSelection(newEditorState, newSelection);
}

export const insertImage = (editorState: EditorState, src: string, alt: string): EditorState | null => {
    if (!editorState)
        return null;

    const selectionState  = editorState.getSelection();
    const contentState    = editorState.getCurrentContent();
    const currentBlock    = contentState.getBlockForKey(selectionState.getStartKey());
    const currentBlockKey = currentBlock.getKey();
    const blockMap        = contentState.getBlockMap();
    const blocksBefore    = blockMap.toSeq().takeUntil((v) => (v === currentBlock));
    const blocksAfter     = blockMap.toSeq().skipUntil((v) => (v === currentBlock)).rest();
    const newBlockKey     = genKey();

    // add new ContentBlock to editor state with appropriate text
    const list: any = (List(Repeat(CharacterMetadata.create(), alt.length)) as any);
    const newBlock = new ContentBlock({
        key: newBlockKey,
        type: 'unstyled',
        text: alt,
        characterList: list
    });

    const newBlockMap = blocksBefore.concat(
        [[currentBlockKey, currentBlock], [newBlockKey, newBlock]],
        blocksAfter
    ).toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = contentState.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    });

    let newEditorState = EditorState.push(editorState, newContent as ContentState, 'split-block');

    // programmatically apply selection on this text
    let newSelection = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: alt.length
    });

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);

    // create link entity
    const newContentState = newEditorState.getCurrentContent();

    const contentStateWithEntity = newContentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        { src, alt, style: { width: '100%', height: '100%' }}
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    newEditorState = EditorState.set(newEditorState, { currentContent: contentStateWithEntity });

    newEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);

    // reset selection
    newSelection = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: alt.length,
        focusKey: newBlockKey,
        focusOffset: alt.length
    });

    return EditorState.forceSelection(newEditorState, newSelection);
}

const Wysiwyg = (props: WysiwygProps) => {
    const { i18n } = useTranslation();

    const { children, value, onChange, className, rows, minimal, mentions, setRef } = props;

    const contentBlock = htmlToDraft(value || "");
    const cState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const eState = EditorState.createWithContent(cState);

    const [editorState, setEditorState] = useState(eState);
    const [focused, setFocused]         = useState(false);

    useEffect(() => {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (!value || content === value)
            return;

        const contentBlock = htmlToDraft(value || "");
        const cState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(cState));
    }, [value]);

    const setEditorReference = (ref: HTMLTextAreaElement) => {
        if (!focused) {
            ref?.focus();
            setFocused(true);
            setRef && setRef(ref);
        }
    }

    useEffect(() => {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        // remove default empty paragraph
        if (content === '<p></p>\n')
            return onChange('', editorState);

        onChange(content, editorState);
    }, [onChange, editorState]);

    const mentionConfig = mentions ? {
        separator: ' ',
        trigger: '@',
        suggestions: mentions
    } : undefined;
    return (
        <div className={`Wysiwyg ${className}`}>
            <Editor
                editorRef={setEditorReference}
                editorState={editorState}
                wrapperClassName=""
                editorClassName=""
                editorStyle={{ minHeight: `${(rows || 4) * 2}rem`, overflow: 'auto'}}
                onEditorStateChange={setEditorState}
                localization={{
                    locale: i18n.language
                }}
                toolbar={ minimal ? simpleToolbar : fullToolbar }
                mention={ mentionConfig }
            />
            <div>
                {children}
            </div>
        </div>
    );
}

export default Wysiwyg;
