import RestClient from './RestClient';
import { Attachment } from '../models';

class Attachments extends RestClient {
    constructor() {
        super({
            // rest config
            model: Attachment,
            entryPoint: 'attachments',
            sortBy: 'type',
            id_field: '_id',
            // redux config
            resource: 'attachment',
            resources: 'attachments',
        });
    }
}

const AttachmentsApi = new Attachments();
export default AttachmentsApi;
