import React, { Fragment, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { addProjectMembers, removeProjectMembers, loadUsers } from '../../redux';
import { Transition } from '@headlessui/react'
import { Avatar } from 'flowbite-react';
import { User } from '../../models';
import { Ability, Icon, Tooltip } from '..';

interface ProjectMembersProps {};

const ProjectMembers = (props: ProjectMembersProps) => {
    const dispatch  = useAppDispatch();

    const [showAll, setShowAll] = useState(false);

    const { user }  = useAppSelector((state: RootState) => state.auth);
    const { users } = useAppSelector((state: RootState) => state.users);
    const { project, membersUpdate } = useAppSelector((state: RootState) => state.projects);

    const toggleMember = (member: User) => {
        if (!user?.can('project:members:edit'))
            return;

        if (project.hasMember(member))
            dispatch(removeProjectMembers(project._id, [member._id]));
        else
            dispatch(addProjectMembers(project._id, [member._id]));
    };

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    const members: User[] = React.useMemo(() => {
        return users?.filter((u: User) => (u._id !== user?._id) && project.hasMember(u)).sort((a: User, b: User) => {
            return a.fullname() > b.fullname() ? 1 : -1;
        });
    }, [project, users, user]);

    const notMembers: User[] = React.useMemo(() => {
        return users?.filter((u: User) => !project.hasMember(u)).sort((a: User, b: User) => {
            return a.fullname() > b.fullname() ? 1 : -1;
        });
    }, [project, users]);

    if (!project || !users)
        return null;

    const avatar = (user: User, locked?: boolean) => {
        return (
            <Tooltip key={user._id} content={user.fullname()} placement="bottom">
                <div>
                    <Avatar
                        key={user._id}
                        rounded
                        bordered
                        stacked
                        color={project.hasMember(user) ? 'purple' :'light'}
                        size="xs"
                        placeholderInitials={user.getInitials()}
                        onClick={() => !locked && toggleMember(user)}
                        className="cursor-pointer text-xs"
                    />
                </div>
            </Tooltip>
        );
    }

    return (
        <div className="flex items-center py-2">
            {/* me */}
            <div className="flex">
                {avatar(user, /*locked*/true)}
            </div>

            {/* members */}
            {members?.length > 0 && (
                <div className="flex border-l ml-2 pl-2">
                    {members.map(user => avatar(user))}
                </div>
            )}

            {/* not members */}
            <Ability can="project:members:edit">
                <>
                    <Transition
                        as={Fragment}
                        appear={true}
                        show={showAll && (notMembers.length > 0)}
                        enter="transition-width ease-out duration-250"
                        enterFrom="transform w-0"
                        enterTo="transform w-full"
                        leave="transition-width ease-out duration-250"
                        leaveFrom="w-full"
                        leaveTo="w-0"
                    >
                        <div className="flex items-center h-full border-l ml-2 pl-2 pr-2 overflow-x-hidden">
                            {notMembers.map(user => avatar(user))}
                        </div>
                    </Transition>

                    {/* plus button */}
                    {notMembers.length > 0 && (
                        <div className="flex items-center pl-2 cursor-pointer" onClick={() => setShowAll(!showAll)}>
                            <div className={`flex items-center justify-center w-6 h-6`}>
                                <Icon
                                    size={5}
                                    type={membersUpdate !== 'idle' ? 'loading' : (showAll ? 'close' : 'plus')}
                                    color={membersUpdate !== 'idle' ? 'purple-900' : (showAll ? 'red-900' : 'gray-400')}
                                />
                            </div>
                        </div>
                    )}
                </>
            </Ability>
        </div>
    );
};

export default ProjectMembers;
