import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Icon } from '../';
import { Client } from '../../models';
import { RootState, useAppSelector, useAppDispatch, loadClients } from '../../redux';
import _ from 'lodash';

interface ClientSelectFieldProps {
    name: string;
    multiple?: boolean;
    placeholder?: string;
    className?: string;
    selectedClassName?: string;
    searchInputClassName?: string;
    resultsClassName?: string;
    onChange?: (value?: Client) => void;
    value?: Client;
};

const ClientSelectField = (props: ClientSelectFieldProps) => {
    const { name, multiple, placeholder, className, selectedClassName, searchInputClassName, resultsClassName, onChange } = props;

    const [search, setSearch] = useState("");

    const [ field, meta, helpers ] = useField(name);
    const { value }                = meta;
    const { setValue }             = helpers;

    const dispatch    = useAppDispatch();
    const { t }       = useTranslation();
    const { clients } = useAppSelector((state: RootState) => state.clients);

    useEffect(() => {
        if (!clients?.length)
            dispatch(loadClients());
    }, [dispatch]);

    useEffect(() => {
        if (props.value && value && props.value._id !== value._id) {
            setValue(props.value);
        } else if (!value && props.value) {
            setValue(props.value);
        }
    }, [props.value, value, setValue]);

    const addClient = (client: Client) => {
        if (!multiple) {
            onChange && onChange(client);
            return setValue(client);
        }

        const newValue = _.clone(value || []);
        newValue.push(client);
        setValue(newValue);
        onChange && onChange(newValue);
    };

    const removeClient = (client: Client) => {
        if (!multiple) {
            onChange && onChange();
            return setValue(null);
        }

        const newValue = _.clone(value).filter((existing: Client) => existing._id !== client._id);
        setValue(newValue);
        onChange && onChange(newValue);
    };

    const handleKeyPress = (e: AnyObj) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
            (results?.length > 0) && addClient(results[0]);
        } else if (e.key === 'Escape' || e.code === 'Escape') {
            setSearch("");
        }
    }

    const results = React.useMemo(() => {
        return search?.length < 3 ? [] : (clients || []).filter((client: Client) => {
            if (!multiple && value?._id === client._id)
                return false;
            if (multiple && value?.find((existing: Client) => existing._id === client._id))
                return false;
            return client.name().toLowerCase().includes(search.toLowerCase());
        }).sort((a: Client, b: Client) => a.id - b.id);
    }, [search, clients, value]);

    return (
        <div className={`flex flex-col w-full ${className || ''}`}>
            {((multiple && value?.length > 0) || (!multiple && value)) && (
                <div className="">
                    {multiple && (value?.length > 0 || placeholder) && (
                        <div className="flex flex-col space-y-1 border p-2 rounded-md">
                            {value?.length === 0 && (
                                <span className="text-sm text-gray-400">{placeholder}</span>
                            )}
                            {value?.map((client: Client) => (
                                <div key={client._id} color="yellow" className={`h-fit font-semibold bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300 rounded p-1 text-xs w-full ${selectedClassName}`}>
                                    <div className="flex flex-row space-between items-center">
                                        <div className="flex-1">
                                            {client.name()}
                                        </div>
                                        <div onClick={() => removeClient(client)} className="cursor-pointer">
                                            <Icon type="close" color="red-600" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!multiple && (value || placeholder) && (
                        <div className="flex flex-col space-y-1 border p-2 rounded-md">
                            {!value ? (
                                <span className="text-sm text-gray-400">{placeholder}</span>
                            ) : (
                                <div key={value?._id} color="yellow" className={`h-fit font-semibold bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300 rounded p-1 text-xs w-full ${selectedClassName}`}>
                                    <div className="flex flex-row space-between items-center">
                                        <div className="flex-1">
                                            {_.isString(value) ? value : value?.name()}
                                        </div>
                                        <div onClick={() => removeClient(value)} className="cursor-pointer">
                                            <Icon type="close" color="red-600" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {(multiple || !value) && (
                <div className="">
                    <div className="relative">
                        <input
                            type="text"
                            value={search}
                            onChange={(e: AnyObj) => setSearch(e.target.value)}
                            onKeyDown={handleKeyPress}
                            placeholder={t('projects.search_client') || ''}
                            className={`block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${searchInputClassName}`}
                        />
                        {search?.length > 0 ? (
                            <span className="absolute top-2 right-2 cursor-pointer" onClick={() => setSearch("")}>
                                <Icon type="close" size={5} color="red-600" />
                            </span>
                        ) : (
                            <Icon type="search" className="absolute top-2 right-2" size={5} color="gray-300" />
                        )}
                    </div>
                    {results?.length > 0 && (
                        <div className={`border rounded-md mt-2 max-h-[20rem] overflow-y-auto ${resultsClassName}`}>
                            {results?.map((client: Client) => (
                                <div key={client._id} onClick={() => addClient(client)} className="border-b p-2 hover:bg-blue-100 cursor-pointer text-sm">
                                    {client.name()}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClientSelectField;
