import React from 'react';
import { Link } from "react-router-dom";
import { Icon } from '..';
import type { IconType } from '../Icon';

interface ButtonProps {
    title: string
    color?: 'none' | 'navigate' | 'navigateBack' | 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'success'
    textColor?: string
    icon?: IconType
    iconColor?: string
    iconSize?:number
    to?: string
    type?: 'button' | 'submit' | 'reset'
    onClick?: () => void
    className?: string
    small?: boolean
    loading?: boolean
    target?: string
}

// working colors : gray red yellow green cyan cyan indigo purple pink
const colorMap = {
    'none': 'transparent',
    'success' : 'green',
    'navigate': 'purple',
    'navigateBack': 'cyan',
    'default': 'gray',
    'primary': 'indigo',
    'secondary': 'cyan',
    'warning': 'yellow',
    'danger': 'red',
};

function Button(props: ButtonProps) {
    const {
        title, color, textColor, icon, iconColor, iconSize, to, type,
        onClick, className, small, loading, target
    } = props;

    const classNames = [
        small ? 'px-1.5' : 'px-3', small ? 'py-1' : 'py-2',
        small ? 'text-xs' : 'text-sm',
        small ? 'rounded' : 'rounded-md',
        color === 'none' ? '' : 'shadow-sm',
        'inline-flex', 'items-center',
        `text-${textColor || 'white'}`, 'font-semibold',
        'focus-visible:outline', 'focus-visible:outline-2',
        'focus-visible:outline-offset-2',
        `focus-visible:outline-${colorMap[color || 'default']}-600`,
        `bg-${colorMap[color || 'default']}-600`,
        `hover:bg-${colorMap[color || 'default']}-500`,
        className,
    ].join(' ');

    if (to) {
        return (
            <Link target={target} to={to} className={classNames} >
                {icon && (
                    <Icon type={loading ? 'loading' : icon} color={iconColor || 'gray'} size={iconSize || 5} className={title ? 'mr-1.5' : ''}/>
                )}
                { title }
            </Link>
        );
    }

    const _onClick = (e: AnyObj) => {
        e.stopPropagation();
        if (onClick)
            onClick();
    };

    return (
        <button type={type || 'button'} className={classNames} onClick={_onClick}>
            {icon && (
                <Icon type={loading ? 'loading' : icon} color={iconColor || 'gray'} size={iconSize || 5} className={title ? 'mr-1.5' : ''}/>
            )}
            { title }
        </button>
    );
}

export default Button;
