export const getUserEnvironmentInfo = () => {
    const userAgent = navigator.userAgent;
    const language = navigator.language;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const mobileDevice = isMobile ? "Yes" : "No";
    const browserVersion = userAgent.match(/(firefox|msie|chrome|safari|trident)\/\d+/i)?.[0] || 'Unknown Version';
    const windowSize = `${window.innerWidth}x${window.innerHeight}`;
    const cookiesEnabled = navigator.cookieEnabled ? "Yes" : "No";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
        userAgent,
        language,
        screenResolution,
        browserInfo: userAgent.match(/(firefox|msie|chrome|safari|trident)/i)?.[0] || 'Unknown Browser',
        osInfo: userAgent.match(/(windows nt|mac os x|linux|ubuntu)/i)?.[0].replace(/_/g, '.') || 'Unknown OS',
        mobileDevice,
        browserVersion,
        windowSize,
        cookiesEnabled,
        timezone
    };
};

export const formatDeveloperNotes = (change: AnyObj, userEnvInfo: AnyObj) => {
    const formattedEnvInfo = Object.entries(userEnvInfo).map(([key, value]) => `${key}: ${value}`).join('\n\n');

    return (
        `### Description\n${change.description}\n___\n` +
        `### Étapes pour reproduire\n${change.steps}\n___\n` +
        `### Résultat attendu\n${change.expectedResult}\n___\n` +
        `### Résultat réel\n${change.actualResult}\n___\n` +
        `### Informations d'environnement\n${formattedEnvInfo}`
    );
};
