import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, ImageUploader, Button, SelectField, UserAvatar } from '../../../components';
import type { ImageListType, IconType } from '../../../components';
import { User } from '../../../models';
import { RootState, useAppSelector, useAppDispatch, setUserAvatar, unsetUserAvatar } from '../../../redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import type { FormikValues } from 'formik';

interface UserFormProps {
    isCreation?: boolean,
    isLoading?: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function UserForm(props: UserFormProps) {
    const { isCreation, initialValues, onSubmit, isLoading } = props;

    const { t }                     = useTranslation();
    const dispatch                  = useAppDispatch();
    const { user }                  = useAppSelector((state: RootState) => state.auth);
    const [activeTab, setActiveTab] = useState(0);

    const validate = (values: AnyObj) => {
        const errors: AnyObj = {};

        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('users.email_is_invalid');
        }

        if (values.password?.length > 0 && values.password_confirmation?.length > 0) {
            if (values.password !== values.password_confirmation)
                errors.password_confirmation = t('users.password_mismatch');
        }
        return errors;
    }

    const roles       = User.roles().filter(role => user.role === User.ROLE_ADMIN || role !== User.ROLE_ADMIN);
    const canEditRole = user?.can('user:role:edit') && (user?._id === initialValues?._id || initialValues?.role !== User.ROLE_ADMIN);

    const onAvatarChange = (imageList: ImageListType) => {
        if (imageList.length > 0) {
            const file: File | undefined = imageList[0].file;
            if (file)
                return dispatch(setUserAvatar(user._id, file));
        }
    };

    const removeAvatar = () => {
        return dispatch(unsetUserAvatar(user._id));
    }

    const tabs = [
        ['general', 'info'],
        ['authentication', 'lock'],
    ];

    return (
        <div className="ProjectForm bg-white border-b p-4 sm:p-6">
            <main className='md:flex text-center md:text-left'>
                <div id="default-sidebar" className="z-40 md:w-64">
                    <div className="p-0 bg-gray-50 dark:bg-gray-800 border border-gray-100 rounded">
                        {!isCreation && (
                            <div className="flex justify-center">
                                <ImageUploader hidePreview onChange={onAvatarChange} className="!border-0">
                                    <div className="group relative">
                                        <UserAvatar noCache user={new User(initialValues)} size={24} noTooltip hideInitials />
                                        {initialValues.avatar && (
                                            <Button
                                                title=""
                                                icon="delete"
                                                onClick={removeAvatar}
                                                iconColor="red-600"
                                                className="hidden group-hover:block absolute -bottom-3 -right-5"
                                                color="none"
                                            />
                                        )}
                                    </div>
                                </ImageUploader>
                            </div>
                        )}
                        <ul className="space-y-0 font-medium text-gray-600 border-t">
                            {tabs.map(([label, icon], i: number) => (
                                <li key={label} className={`cursor-pointer w-full text-sm py-3 ${(activeTab === i) ? "text-gray-900 bg-gray-200" : "hover:bg-gray-100" }`} onClick={() => setActiveTab(i)}>
                                    <p className="ms-3">
                                        <Icon color={activeTab === i ? 'gray-900' : 'gray-500'} type={icon as IconType} className="inline mr-1" />
                                        {t(`users.${label}`)}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='w-full px-4 py-4'>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            onSubmit(values);
                        }}
                    >
                        <Form>
                            <div className="space-y-12">
                                <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 space-x-0 sm:space-x-12">
                                    {activeTab === 0 && (
                                        <div className="flex-1 space-y-3">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                                <div className="col-span-1">
                                                    <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.firstname')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="firstname"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            required
                                                        />
                                                        <ErrorMessage name="firstname" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.lastname')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="lastname"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            required
                                                        />
                                                        <ErrorMessage name="lastname" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                                <div className="col-span-1">
                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.email')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="email"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            required
                                                        />
                                                        <ErrorMessage name="email" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.phone')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="phone"
                                                            placeholder="+41794562136"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            required
                                                        />
                                                        <ErrorMessage name="phone" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                                {canEditRole && (
                                                    <div className="col-span-1">
                                                        <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('users.role')}
                                                        </label>
                                                        <div className="mt-2">
                                                            <SelectField
                                                                name="role"
                                                                placeholder={t('users.choose_a_role') || ''}
                                                                options={roles.map((role: string) => ({
                                                                    value: role,
                                                                    label: t(`users.role_${role}`)
                                                                }))}
                                                            />
                                                            <ErrorMessage name="role" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                        </div>
                                                    </div>
                                                )}
                                                {initialValues?.role !== User.ROLE_CLIENT && (
                                                    <div className="col-span-1">
                                                        <label htmlFor="work_rate" className="block text-sm font-medium leading-6 text-gray-900">
                                                            {t('users.work_rate')}
                                                        </label>
                                                        <div className="mt-2">
                                                            <Field
                                                                type="number"
                                                                name="work_rate"
                                                                placeholder="100"
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                required
                                                            />
                                                            <ErrorMessage name="work_rate" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {activeTab === 1 && (
                                        <div className="flex-1 space-y-3">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                                                <div className="col-span-1">
                                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.password')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="password"
                                                            name="password"
                                                            autoComplete="new-password"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <ErrorMessage name="password" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="password-confirmation" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('users.password_confirmation')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="password"
                                                            name="password_confirmation"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <ErrorMessage name="password_confirmation" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {(!isCreation || activeTab === 1) && (
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <Button to="/users" title={t('common.cancel')} color="navigateBack" icon="back" />
                                    <Button loading={isLoading} type="submit" title={t('common.save')} color="primary" icon="save" />
                                </div>
                            )}
                        </Form>
                    </Formik>
                </div>
            </main>
        </div>
    );
}

export default UserForm;
