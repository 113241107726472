import BaseModel from './BaseModel';
import Release from './Release';
import Change from './Change';
import Client from './Client';
import Commit from './Commit';
import Board from './Board.js';
import User from './User';
import { Projects } from '../services';
import NotificationRule from './NotificationRule';
import ProjectDocumentation from './ProjectDocumentation';
import _ from 'lodash';
import md5 from "crypto-js/md5";

/** Class representing an user. */
class Project extends BaseModel {
    _id: string;
    name: string;
    active: boolean;
    avatar: string;
    members: User[];
    clients: Client[];
    repository: {
        url: string;
        branch: string;
        localPath?: string;
    };
    commitRules: {
        feature: string;
        fix: string;
        refactor: string;
        ui: string;
        i18n: string;
        test: string;
        doc: string;
        lint: string;
        chore: string;
        build: string;
        dependencies: string;
        release: string;
    };
    tagRules: {
        releaseTag: string
    };
    description: string;
    translation: {
        context: string;
        languages: {
            default: string;
            all: string[];
        };
    };
    readme: {
        path?: string;
        content?: string;
    };
    documentations: ProjectDocumentation[]
    notifications: NotificationRule[]
    comments: string;
    nbAttachments: number;
    nbCommits: number;
    nbReleases: number;
    lastRelease?: Release;
    boards: Board[];
    releases: Release[];
    commits: Commit[];
    changes: Change[];
    isFavorite?: boolean;

    constructor(properties: AnyObj) {
        super({});

        this._id               = properties._id;
        this.name              = properties.name;
        this.active            = properties.active;
        this.avatar            = properties.avatar;
        this.members           = (properties.members || []).map((m: AnyObj) => _.isString(m) ? m : new User(m));
        this.clients           = (properties.clients || []).map((c: AnyObj) => _.isString(c) ? c : new Client(c));
        this.repository        = properties.repository || {};
        this.repository.branch = this.repository.branch || 'master';
        this.commitRules       = properties.commitRules;
        this.tagRules          = properties.tagRules;
        this.description       = properties.description;
        this.translation       = properties.translation;
        this.comments          = properties.comments;
        this.readme            = properties.readme;
        this.documentations    = (properties.documentations || []).map((c: AnyObj) => new ProjectDocumentation(c));
        this.notifications     = (properties.notifications || []).map((c: AnyObj) => new NotificationRule(c));
        this.nbAttachments     = properties.nbAttachments || 0;
        this.nbCommits         = properties.nbCommits || 0;
        this.nbReleases        = properties.nbReleases || 0;
        if (properties.lastRelease)
            this.lastRelease = new Release(properties.lastRelease);

        // virtuals
        this.boards      = (properties.boards || []).map((b: AnyObj) => new Board(b));
        this.releases    = (properties.releases || []).map((r: AnyObj) => new Release(r));
        this.commits     = (properties.commits || []).map((c: AnyObj) => new Commit(c));
        this.changes     = (properties.changes || []).map((c: AnyObj) => new Change(c));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() : AnyObj {
        const prepared = _.cloneDeep(this) as AnyObj;

        // remove virtual properties
        delete(prepared.releases);
        delete(prepared.nbReleases);
        delete(prepared.commits);
        delete(prepared.nbCommits);
        delete(prepared.changes);

        return prepared;
    }

    /**
     * Check if this project has a configured repository
     *
     * @return {Boolean}
     */
    hasConfiguredRepo() : boolean {
        return (
            this.repository.url?.length > 0 &&
            this.repository.branch?.length > 0
        );
    }

    /**
     * Check if a user is a member of this project
     *
     * @param {User} user
     * @return {Boolean}
     */
    hasMember(user: User) : boolean {
        let isMember = false;
        this.members?.forEach((u: any) => {
            if (_.isString(u) && u === user._id)
                isMember = true;
            else if (!_.isString(u) && u._id === user._id)
                isMember = true;
        });

        return isMember;
    }

    /**
     * Get the list of this project commits, sorted by decreasing date
     *
     * @return {Commit[]}
     */
    sortedCommits() : Commit[] {
        return (this.commits || []).sort((a: Commit, b: Commit) => {
            if (a.date.isBefore(b.date))
                return 1;
            return a.date.isAfter(b.date) ? -1 : 0;
        });
    }

    /**
     * Get the list of this project releases, sorted by decreasing date
     *
     * @return {Release[]}
     */
    sortedReleases() : Release[] {
        return (this.releases || []).sort((a: Release, b: Release) => {
            return b.versionNumber - a.versionNumber;
        });
    }

    /**
     * Get this project initials
     *
     * @return {String}
     */
    getInitials() {
        if (!this.name)
            return '??';

        return this.name.match(/(\b\S)?/g)?.join("")?.match(/(^\S|\S$)?/g)?.join("");
    }

    /**
     * Get this project avatar url
     *
     * @return {String}
     */
    getAvatarUrl(size: number = 80, noCache: boolean = false) {
        if (this.avatar?.length > 0)
            return [
                Projects.baseUrl,
                Projects.entryPoint,
                this._id,
                'avatar'
            ].join('/') + (noCache ? '?t=' + Date.now() : '');

        return `https://secure.gravatar.com/avatar/${md5(this.name.toLowerCase().trim())}?s=${size}&d=retro`;
    }
}

export default Project;
