import React, { useEffect } from 'react';
import { RootState, useAppSelector, useAppDispatch, setSubscriberId, onRemoteUpdate } from '../../redux';
import { v4 as uuidv4 } from 'uuid';

interface MercureProps {
    hub: string;
    topics: string[];
    onUpdate?: (update: AnyObj) => void;
    children?: JSX.Element | JSX.Element[];
}

const Mercure = (props: MercureProps) => {
    const { onUpdate, hub, topics, children } = props;

    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state : RootState) => state.auth);

    useEffect(() => {
        const subscriberId = uuidv4();
        dispatch(setSubscriberId(subscriberId));

        const url = new URL(hub);
        url.searchParams.append('subscriber', subscriberId);

        topics.forEach(topic => {
            url.searchParams.append('topic', topic);
        });

        const eventSource     = new EventSource(url, { withCredentials: true });
        eventSource.onmessage = (e: AnyObj) => {
            const payload = JSON.parse(e.data) || {};

            // ignore notifications for our own updates
            if (payload.source === subscriberId)
                return;

            // do not consider other users time tracking entries
            const { data, type } = payload;
            if (type.match(/track\./) && data.author?._id !== user?._id)
                return;

            dispatch(onRemoteUpdate(payload));
            onUpdate && onUpdate(payload);
        };

        return () => {
            eventSource.close();
        };
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
}

export default Mercure;
