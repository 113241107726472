import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Project, Board, Change } from '../../models';
import { RootState, useAppSelector, useAppDispatch } from '../../redux';
import { moveChange, loadProject, loadProjects, loadBoards, loadBoard } from '../../redux';
import { SelectField, Button, Icon } from '../../components';
import _ from 'lodash';

interface MoveChangeModalProps {
    show: boolean;
    initialBoardId: string;
    onClose?: () => void;
    onMoved?: (change: Change) => void;
}

function MoveChangeModal(props: MoveChangeModalProps) {
    const { show, initialBoardId, onClose, onMoved } = props;

    const { t }                 = useTranslation();
    const { project, projects } = useAppSelector((state : RootState) => state.projects);

    const { boards }                       = useAppSelector((state : RootState) => state.boards);
    const [projectId, setProjectId]        = useState(project?._id || '');
    const [boardId, setBoardId]            = useState('');
    const [columnId, setColumnId]          = useState('');
    const [selectedProject, selectProject] = useState<Project | undefined>(project);

    const {
        movingChange, moveChangeError, change
    } = useAppSelector((state : RootState) => state.changes);

    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(loadProjects());
    },[dispatch]);

    useEffect(() => {
        if (boardId)
            dispatch(loadBoards({project: projectId}));
    }, [dispatch, boardId]);

    const board = React.useMemo(() => {
        if (!boardId || !boards?.length)
            return null;

        return boards.find((board: Board) => board._id === boardId);
    }, [boardId, boards]);

    const onChangeProject = (_id: any) => {
        setProjectId(_id);

        if (!_id)
            selectProject(undefined);

        selectProject(projects?.find((p: Project) => p._id === _id));
    }

    const navigate = useNavigate();
    const _moveChange = () => {
        const callback = (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            if (err)
                return;

            dispatch(loadBoard(boardId));
            navigate(`/projects/${projectId}/change/${change._id}`);
            onClose && onClose();
        }
        dispatch(moveChange(change._id, projectId, boardId, columnId, callback));
    }
    const close = () => {
        setProjectId('');
        setBoardId('');
        navigate(`/projects/${change.project?._id}/change/${change._id}`);
        onClose && onClose();
    };

    const projectOptions = React.useMemo(() => {
        return projects?.map((project: Project) => ({
            value: project._id,
            label: project.name
        }));
    }, [projects]);

    const boardOptions = React.useMemo(() => {
        const notSmartBoards = selectedProject?.boards?.filter((board: Board) => {
            return _.some(board.columns, (column: AnyObj) => !column.isSmart);
        });
        return notSmartBoards?.map((board: AnyObj) => ({
            value: board._id,
            label: board.name.fr
        }));
    }, [selectedProject]);

    const columnOptions = React.useMemo(() => {
        return board?.columns?.filter((column: AnyObj) => !column.isSmart).map((column: AnyObj) => ({
            value: column._id,
            label: column.title
        }));
    }, [board]);

    return (
        <div className="MoveChangeModal w-full rounded">
            <div className='bg-indigo-600 p-2 text-white rounded-t'>
                <h3 className="flex items-center space-x-2">
                    <Icon type="move" color="white" className="mr-1.5" />
                    {t('changes.move_this_change')}
                </h3>
            </div>
            <div className='h-full p-5'>
                {moveChangeError && (
                    <label className="block font-medium eading-6 text-white bg-red-600 text-xs rounded flex items-center p-2 mb-2">
                        <Icon type="warning" className="inline mr-1.5" color="white" />
                        {t('changes.could_not_move')}
                    </label>
                )}
                <label className="block font-medium leading-6 text-gray-900">
                    <Icon type="project" className="inline mr-1.5" />
                    {t('changes.into_the_project')}
                </label>
                <SelectField
                    name="projectId"
                    onChange={onChangeProject}
                    placeholder={t('changes.choose_a_project') || ''}
                    options={projectOptions}
                />
                {selectedProject && (
                    <div className='mt-5'>
                        {boardOptions && boardOptions.length > 0 ? (
                            <>
                                <label className="block font-medium leading-6 text-gray-900">
                                    <Icon type="list" className="inline mr-1.5" />
                                    {t('changes.into_the_board')}
                                </label>
                                <SelectField
                                    name="boardId"
                                    onChange={setBoardId}
                                    placeholder={t('changes.choose_a_board') || ''}
                                    options={boardOptions}
                                />
                            </>
                        ) : (
                            <span className="block text-sm italic font-bold text-center">
                                {t('changes.this_project_has_no_column')}
                            </span>
                        )}
                    </div>
                )}
                {boardId && (board?._id === boardId) && (
                    <div className='mt-5'>
                        {columnOptions?.length > 0 ? (
                            <>
                                <label className="block font-medium leading-6 text-gray-900">
                                    <Icon type="list" className="inline mr-1.5" />
                                    {t('changes.into_the_column')}
                                </label>
                                <SelectField
                                    name="columnId"
                                    onChange={setColumnId}
                                    placeholder={t('changes.choose_a_column') || ''}
                                    options={columnOptions}
                                />
                            </>
                        ) : (
                            <span className="block text-sm italic font-bold text-center">
                                {board?.columns?.length > 0 ? t('changes.this_project_has_only_smart_columns') : t('changes.this_project_has_no_column')}
                            </span>
                        )}
                    </div>
                )}
                <div className='mt-5 flex justify-between items-center'>
                    <Button
                        small
                        onClick={close}
                        title={t('common.cancel')}
                        color="warning"
                        icon={movingChange === 'pending' ? 'loading' : 'close'}
                    />
                    {columnId && (
                        <Button
                            small
                            onClick={_moveChange}
                            title={t('common.move')}
                            color="primary"
                            icon={movingChange === 'pending' ? 'loading' : 'move'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default MoveChangeModal;
