import React from 'react';
import { Link } from "react-router-dom";
import { Icon } from '..';
import type { IconType } from '../Icon';
import { Breadcrumb as FBBreadcrumb} from 'flowbite-react';

interface ItemType {
    title: string
    to?: string
}

interface BreadcrumbProps {
    items: ItemType[]
    icon?: IconType
}

function Breadcrumb(props: BreadcrumbProps) {
    const { items, icon } = props;

    return (
        <div className="Breadcrumb flex items-center bg-gray-100 text-white px-4 py-2 sm:px-6 lg:px-8 h-10">
            <FBBreadcrumb aria-label="Breadcrumb">
                {items.map((item, i) => (
                    <FBBreadcrumb.Item key={i}>
                        {(i === 0) && (
                            <Icon type={icon || 'home'} className="mr-1.5" />
                        )}
                        {item.to && <Link to={item.to}>{item.title}</Link>}
                        {!item.to && item.title}
                    </FBBreadcrumb.Item>
                ))}
            </FBBreadcrumb>
        </div>
    );
}

export default Breadcrumb;

