import BaseModel from './BaseModel';
import moment from 'moment';
import type { Moment } from 'moment';

/** Class representing a support ticket. */
class Support extends BaseModel {

    static STATUS_OPEN        = 'open';
    static STATUS_IN_PROGRESS = 'in_progress';
    static STATUS_RESOLVED    = 'resolved';
    static STATUS_CLOSED      = 'closed';

    _id: string;
    projectId: string;
    subject: string;
    description: string;
    status: string;
    createdAt?: Moment;
    updatedAt?: Moment;

    constructor(properties: AnyObj) {
        super({});

        this._id = properties._id;
        this.projectId = properties.projectId;
        this.subject = properties.subject;
        this.description = properties.description;
        this.status = properties.status;

        if ('createdAt' in properties) {
            this.createdAt = moment(properties.createdAt);
        }
        if ('updatedAt' in properties) {
            this.updatedAt = moment(properties.updatedAt);
        }

    }

    static statuses(): string[] {
        return [
            Support.STATUS_OPEN,
            Support.STATUS_IN_PROGRESS,
            Support.STATUS_RESOLVED,
            Support.STATUS_CLOSED,
        ];
    };

}

export default Support;
