import React, { useRef, useState } from 'react';
import { useField , Field } from 'formik';
import { Button, Markdown, Uploader } from '..';
import { Attachment } from '../../models';
import { t } from 'i18next';

interface InlineTextareaFieldProps {
    name: string;
    rows?: number;
    placeholder?: string;
    onChange?: (field: string, value: string) => void;
    onSave?: (field: string, value: string) => void;
    isLoading: boolean;
    readOnly?: boolean;
    label?: JSX.Element;
    uploadProps?: {
        projectId?: string;
        changeId?: string;
    }
};

const InlineTextareaField = (props: InlineTextareaFieldProps) => {
    const { label, name, rows, uploadProps, placeholder, onChange, onSave, isLoading, readOnly } = props;

    const [ isEditing, setEditing]                = useState(false);
    const [ field, meta, helpers ]                = useField(name);
    const { initialValue, value, touched, error } = meta;
    const { setValue }                            = helpers ;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const _onChange = (e: AnyObj) => {
        setValue(e.target.value);
        onChange && onChange(name, e.target.value);
    }

    const onClose = () => {
        setEditing(false);
        setValue(initialValue);
    }

    React.useMemo(() => {
        if (isLoading === false)
            setEditing(false);
    }, [isLoading]);

    const showTextarea = () => {
        if (readOnly)
            return;

        setEditing(true);
        // we use the setTimeout here to have the "time"
        // to get the textarea ref
        setTimeout(fixHeight, 0);
    };

    const fixHeight = () => {
        if (textAreaRef?.current) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.current.style.height = "0px";
            const scrollHeight = textAreaRef.current.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            textAreaRef.current.style.height = (Math.max(scrollHeight, 60)) + "px";
        }
    }

    const insertAttachment = (attachment: Attachment) => {
        if (!textAreaRef.current)
            return;

        const input = textAreaRef.current;

        let tag = `[${attachment.name}](${attachment.downloadUrl()})`;
        if (attachment.isPicture()) {
            tag = `![${attachment.name}](${attachment.downloadUrl()})`;
        }
        const cursorPosition = input.selectionStart;
        let before = input.value.substring(0, cursorPosition);
        let after  = input.value.substring(cursorPosition, input.value.length);
        setValue(before + tag + after);
    }

    if (readOnly || !isEditing)
        return (
            <div>
                <div className="mb-1 flex justify-between">
                    {label}
                    {!readOnly && value && (
                        <div className="text-xs cursor-pointer font-bold border-none text-gray-500" onClick={showTextarea}>
                            {t('common.edit')}
                        </div>
                    )}
                </div>
                <div onClick={showTextarea} className={readOnly ? '' : 'cursor-pointer'}>
                    {value ? (
                        <div className={`border border-gray-100 p-2 bg-gray-50 text-gray-900 ${!readOnly ? 'hover:bg-gray-100' : ''} text-gray-500 rounded`}>
                            <Markdown content={value} />
                        </div>
                    ) : (
                        <div className={`border border-gray-100 p-2 bg-gray-50 text-gray-900 ${!readOnly ? 'hover:bg-gray-100' : ''} text-gray-500 italic rounded text-sm`}>
                            <div>{placeholder || ''}</div>
                        </div>
                    )}
                </div>
            </div>
        );

    return (
        <div className="border w-full rounded-lg flex flex-col">
            <Field
                as="textarea"
                name={name}
                placeholder={placeholder}
                innerRef={textAreaRef}
                rows={rows}
                className="border-none rounded-lg focus:ring-gray-100 focus-visible:no-underline text-sm"
                autoFocus
                onChange={ _onChange }
            />

            {uploadProps?.projectId && (
                <Uploader
                    projectId={uploadProps.projectId}
                    changeId={uploadProps.changeId}
                    onUpload={insertAttachment}
                    hideFiles
                />
            )}

            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
            <div className="flex justify-end mt-2 bg-gray-50 p-2 order border-gray-100 rounded-md">
                <Button className="text-2xl font-bold" textColor="red-500" icon="close" color="none" onClick={onClose} title={t('common.cancel')} iconColor="red-500" small={true}/>
                <Button icon={isLoading ? 'loading' : 'save'} color="primary" onClick={() => onSave && onSave(name, value)} type="button" title={t('common.save')} small={true}/>
            </div>
        </div>
    );
};

export default InlineTextareaField;
