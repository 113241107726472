import { applyReducers, createRestSlices } from './rest';
import { ActionPayload } from '..';
import { Documentations } from '../../services';
import { Dispatch } from 'redux';

const GET_DOCUMENTATIONS_TREE         = 'yoda/documentations/TREE';
const GET_DOCUMENTATIONS_TREE_SUCCESS = 'yoda/documentations/TREE_SUCCESS';
const GET_DOCUMENTATIONS_TREE_FAILURE = 'yoda/documentations/TREE_FAILURE';

const {
    initialState, getReducer, getAction
} = createRestSlices(Documentations);

initialState.tree = null;

/* Find the documentation tree in Outline*/
const getDocumentationTreeReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_DOCUMENTATIONS_TREE:
            return {
                ...state,
                loadingError: null
            };
        case GET_DOCUMENTATIONS_TREE_SUCCESS:
            return {
                ...state,
                loadingError: null,
                tree: action.tree
            };
        case GET_DOCUMENTATIONS_TREE_FAILURE:
            return {
                ...state,
                loadingError: action.error
            };
        default:
            return state;
    }
};


/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        getDocumentationTreeReducer, getReducer
    ]);
}

function gettingDocumentationTree() { return { type: GET_DOCUMENTATIONS_TREE } }
function gettingDocumentationTreeSuccess(tree: AnyObj) { return { type: GET_DOCUMENTATIONS_TREE_SUCCESS, tree } }
function gettingDocumentationTreeFailure(error: AnyObj) { return { type: GET_DOCUMENTATIONS_TREE_FAILURE, error } }
export function getDocumentationTree() {
    return async (dispatch: Dispatch) => {
        dispatch(gettingDocumentationTree());

        Documentations.getDocumentationTree().then((data: AnyObj) => {
            const tree = data.tree;
            dispatch(gettingDocumentationTreeSuccess(tree));
        }).catch(error => {
            console.error('Une erreur s\'est produite :', error);
            dispatch(gettingDocumentationTreeFailure(error));
        });
    }
}

export const loadDocumentation = getAction;