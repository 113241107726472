import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField, MultiSelectField } from '../';
import { NotificationRule } from '../../models';

interface NotificationEventsFieldProps {
    name: string;
    multiple?: boolean;
    placeholder?: string;
    className?: string;
};

const NotificationEventsField = (props: NotificationEventsFieldProps) => {
    const { name, multiple, placeholder, className } = props;

    const { t } = useTranslation();

    const options = NotificationRule.eventTypes().map((type: string) => ({
        value: type,
        label: t(`projects.notifications_events_types.${type}`)
    }));

    if (multiple)
        return (
            <MultiSelectField
                name={name}
                options={options}
                placeholder={placeholder}
                className={className}
            />
        );

    return (
        <SelectField
            name={name}
            options={options}
            placeholder={placeholder}
            className={className}
        />
    );
};

export default NotificationEventsField;
