import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'flowbite-react';
import { RootState, loadUsers, resetChanges, loadChanges, loadChange, useAppDispatch, useAppSelector } from '../../../redux';
import { UserAvatar, Loader, Select, ChangeListItem, ChangeModal, Icon } from '../../../components';
import type { IconType } from '../../../components';
import { Project, Change, Log, User } from '../../../models';

type Filters = {
    teamReview: Project | null;
    clientReview: Project | null;
    pendingDeployment: Project | null;
}

function SprintMergeRequests() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();
    const [filters, setFilters]                 = useState<Filters>({
        teamReview: null,
        clientReview: null,
        pendingDeployment: null,
    });

    const { user } = useAppSelector(state => state.auth);
    const { changes, change, loading } = useAppSelector((state: RootState) => state.changes);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const projects = React.useMemo(() => {
        const map: AnyObj = {};
        changes.forEach((c: Change) => {
            map[c.project._id] = c.project;
        });

        return Object.values(map);
    }, [changes]);

    useEffect(() => {
        dispatch(resetChanges());
        dispatch(loadUsers());
        dispatch(loadChanges({
            'progress.status': [Change.STATUS_TEAM_REVIEW, Change.STATUS_CLIENT_REVIEW, Change.STATUS_PENDING_DEPLOYMENT].join(','),
            with: 'logs'
        }));
    }, [dispatch]);

    const [usersWhoRequests, setUsersWhoRequests] = useState([] as User[]);
    const [userFilterId, setUserFilterId] = useState("" as string);

    const teamReviewChanges        = React.useMemo(() => changes?.filter((change: Change) => change?.progress.status === Change.STATUS_TEAM_REVIEW), [changes]);
    const clientReviewChanges      = React.useMemo(() => changes?.filter((change: Change) => change?.progress.status === Change.STATUS_CLIENT_REVIEW), [changes]);
    const pendingDeploymentChanges = React.useMemo(() => changes?.filter((change: Change) => change?.progress.status === Change.STATUS_PENDING_DEPLOYMENT), [changes]);

    changes.forEach((change: Change) => {
        if (change?.assignee?._id && !usersWhoRequests.some(user => user._id === change?.assignee?._id)) {
            setUsersWhoRequests([...usersWhoRequests, change.assignee]);
            usersWhoRequests.sort((a: User) => a.firstname === user.firstname ? -1 : 1);
        }
    });

    const onSelect = (change: Change) => {
        const logTeamReview = change.logs.filter((log) => log.type === Log.TYPE_CHANGE_TEAM_REVIEW_STARTED);
        if (logTeamReview[0]?.context?.url) {
            window.open(logTeamReview[0].context.url, '_blank');
        }
    };

    const filterOnProject = (type: keyof typeof filters, projectId: string | number) => {
        setFilters({
            ...filters,
            [type]: projects.find((p: Project) => p._id === projectId)
        });
    };

    const buildList = (type: keyof typeof filters, status: string, title: string, icon: IconType, changes: [Change]) => {
        const filter = filters[type];

        const sortedChanges = changes
            .filter((change: Change) => change?.progress.status === status)
            .filter((change: Change) => (userFilterId?.length === 0) || (change.assignee?._id === userFilterId))
            .sort((a: Change, b: Change) => a.slug - b.slug);

        let projects: AnyObj = {};
        sortedChanges.forEach((c: Change) => {
            projects[c.project._id] = c.project;
        });
        projects =  Object.values(projects);

        const filteredChanges = sortedChanges.filter((change: Change) => !filter || change?.project?._id === filter._id);

        const projectOptions = projects.map((project: Project) => ({
            value: project._id,
            label: `${project.name} (${sortedChanges.filter((s: Change) => s.project._id === project._id).length})`,
            className: 'text-xl font-bold',
            leftContent: (
                <Avatar img={project.getAvatarUrl(40)} placeholderInitials={project.getInitials()} className="inline ml-2" />
            )
        }));
        projectOptions.unshift({
            value: '',
            label: `${title} (${sortedChanges.length})`,
            className: 'text-xl font-bold',
            leftContent: <Avatar placeholderInitials={'..'} className="inline ml-2" />
        });

        return (
            <div className="p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md">
                <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900 border-b p-2">
                    <Icon type={icon} size={7} />
                    <Select
                        options={projectOptions}
                        placeholder={`${filters[type]?.name} (${filteredChanges.length})`}
                        inline
                        className=""
                        onChange={(value: any) => filterOnProject(type, value)}
                        optionsWidth={'w-[20rem]'}
                        optionsHeight={'max-h-96'}
                        selectedClassName="text-2xl font-bold text-gray-900"
                        optionsClassName="py-4 pl-14"
                    />
                </h3>
                {filteredChanges.length > 0 && filteredChanges.map((c: Change) => (
                    <div key={c._id} onClick={() => selectChange(c)} className="col-span-2 lg:col-span-1" >
                        <ChangeListItem onSelect={onSelect} change={c} className={"bg-blue-50 border-blue-200 my-2"} showProject/>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="SprintMergeRequests">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-start lg:space-x-5">
                    <div className="flex-1 my-1">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="merge" size={7} className="mr-1.5 inline" />
                            { t('sprints.merge_requests.merge_request_list') }
                        </h2>
                    </div>
                    {loading !== 'pending' && (
                        <div className="flex-1 flex justify-end">
                            <div className="flex border bg-blue-100 p-1 px-3 rounded justify-start space-x-2">
                                {usersWhoRequests.map((u: User, index: number) => (
                                    <UserAvatar
                                        key={u._id}
                                        user={u}
                                        onClick={() => setUserFilterId(u._id === userFilterId ? '' : u._id)}
                                        selected={u._id === userFilterId}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </header>
            {(loading === 'pending') ? (
                <Loader />
            ) : (
                <main>
                    <div className="grid grid-cols-3 gap-5 p-5 px-8">
                        {buildList('teamReview', Change.STATUS_TEAM_REVIEW, t('sprints.merge_requests.team_review'), 'eye', teamReviewChanges)}
                        {buildList('clientReview', Change.STATUS_CLIENT_REVIEW, t('sprints.merge_requests.client_review'), 'test', clientReviewChanges)}
                        {buildList('pendingDeployment', Change.STATUS_PENDING_DEPLOYMENT, t('sprints.merge_requests.pending_deployment'), 'planned', pendingDeploymentChanges )}
                    </div>

                    {change && change._id === currentChangeId && change.project?.name && (
                        <ChangeModal
                            project={change.project}
                            change={change}
                            show={true}
                            showProjectName={true}
                            onClose={() => selectChange()}
                            onRemove={(values: AnyObj) => { }}
                        />
                    )}
                </main>
            )}
        </div>
    );
}

export default SprintMergeRequests;
