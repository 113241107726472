import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './redux';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import TimeAgo from 'javascript-time-ago';
import i18n from './locales/i18n';

import fr from 'javascript-time-ago/locale/fr.json';
import en from 'javascript-time-ago/locale/en.json';
import de from 'javascript-time-ago/locale/de.json';

import routes from './routes';
const router = createBrowserRouter(routes, { basename: '/' });
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

TimeAgo.addDefaultLocale(fr);
TimeAgo.addLocale(en);
TimeAgo.addLocale(de);

if (i18n) {}

root.render(
/**
 * We should use <React.StrictMode> but it causes some issues
 * in react-beautiful-dnd component...
 */
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
