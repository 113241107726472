import { Change, Comment, Quote } from '../../models';
import { Changes } from '../../services';
import { Dispatch } from 'redux';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Status, Callback, Response } from './rest';

const {
    initialState, resetReducer,
    createReducer, startCreateReducer, cancelCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction, cancelCreateAction,
    resetAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Changes);

initialState.toStep         = 'idle' as Status;
initialState.toStepChangeId = null;
initialState.toStepError    = null;

initialState.addQuote         = 'idle' as Status;
initialState.addQuoteChangeId = null;
initialState.addQuoteError    = null;

initialState.reviewChanges = [];
initialState.triageChanges = [];

const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'change.updated':
            if (!state.change || state.change._id !== data._id)
                return state;

            return {
                ...state,
                change: new Change(data)
            };
        case 'quote.updated':
            if (!state.change)
                return state;

            if (!data.items.map((item: AnyObj) => item.change?._id).includes(state.change._id))
                return state;

            return {
                ...state,
                change: new Change({
                    ...state.change,
                    quote: new Quote(data)
                })
            };
        default:
            return state;
    }
};

const TO_STEP         = 'yoda/change/TO_STEP';
const TO_STEP_SUCCESS = 'yoda/change/TO_STEP_SUCCESS';
const TO_STEP_FAILURE = 'yoda/change/TO_STEP_FAILURE';
const TO_STEP_RESET   = 'yoda/change/TO_STEP_RESET';

const CREATE_BUG_REPORT = 'yoda/change/CREATE_BUG_REPORT';
const CREATE_BUG_REPORT_SUCCESS = 'yoda/change/CREATE_BUG_REPORT_SUCCESS';
const CREATE_BUG_REPORT_FAILURE = 'yoda/change/CREATE_BUG_REPORT_FAILURE';

const ADD_CHANGE_TO_COLUMN = 'yoda/board/ADD_CHANGE_TO_COLUMN';
const ADD_CHANGE_TO_COLUMN_SUCCESS = 'yoda/board/ADD_CHANGE_TO_COLUMN_SUCCESS';
const ADD_CHANGE_TO_COLUMN_FAILURE = 'yoda/board/ADD_CHANGE_TO_COLUMN_FAILURE';

const GET_REVIEW_CHANGES         = 'yoda/change/GET_REVIEW_CHANGES';
const GET_REVIEW_CHANGES_SUCCESS = 'yoda/change/GET_REVIEW_CHANGES_SUCCESS';
const GET_REVIEW_CHANGES_FAILURE = 'yoda/change/GET_REVIEW_CHANGES_FAILURE';

const reviewChangesReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_REVIEW_CHANGES:
            return {
                ...state,
                reviewChangesStatus: 'pending',
                reviewChangesError: null
            };
        case GET_REVIEW_CHANGES_SUCCESS:
            return {
                ...state,
                reviewChangesStatus: 'success',
                reviewChanges: action.changes,
                reviewChangesError: null
            };
        case GET_REVIEW_CHANGES_FAILURE:
            return {
                ...state,
                reviewChangesStatus: 'failed',
                reviewChangesError: action.error
            };
        default:
            return state;
    }
};

const GET_TRIAGE_CHANGES         = 'yoda/change/GET_TRIAGE_CHANGES';
const GET_TRIAGE_CHANGES_SUCCESS = 'yoda/change/GET_TRIAGE_CHANGES_SUCCESS';
const GET_TRIAGE_CHANGES_FAILURE = 'yoda/change/GET_TRIAGE_CHANGES_FAILURE';

const triageChangesReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_TRIAGE_CHANGES:
            return {
                ...state,
                triageChangesStatus: 'pending',
                triageChangesError: null
            };
        case GET_TRIAGE_CHANGES_SUCCESS:
            return {
                ...state,
                triageChangesStatus: 'success',
                triageChanges: action.changes,
                triageChangesError: null
            };
        case GET_TRIAGE_CHANGES_FAILURE:
            return {
                ...state,
                triageChangesStatus: 'failed',
                triageChangesError: action.error
            };
        default:
            return state;
    }
};

// Reducer pour gérer les états de création de rapport de bug
const bugReportReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case CREATE_BUG_REPORT:
            return {
                ...state,
                change: null,
                bugReportStatus: 'pending',
                bugReportError: null,
                bugReportId: action._id
            };
        case CREATE_BUG_REPORT_SUCCESS:
            return {
                ...state,
                bugReportStatus: 'success',
                change: action.change,
                bugReportError: null
            };
        case CREATE_BUG_REPORT_FAILURE:
            return {
                ...state,
                change: null,
                bugReportStatus: 'failed',
                bugReportError: action.error
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle comments creation/deletion
 */
const CREATING_COMMENT_SUCCESS = `yoda/comment/CREATING_ITEM_SUCCESS`;
const UPDATING_COMMENT_SUCCESS = `yoda/comment/UPDATING_ITEM_SUCCESS`;
const commentsReducer = (state = initialState, action: ActionPayload) => {
    if (!state.change)
        return state;

    switch (action.type) {
        case CREATING_COMMENT_SUCCESS:
            return {
                ...state,
                change: new Change({
                    ...state.change,
                    comments: [...state.change.comments, action.comment]
                })
            };
        case UPDATING_COMMENT_SUCCESS:
            const comments = state.change.comments?.map((comment: Comment) => {
                if (comment._id !== action.comment._id)
                    return comment;

                return action.comment;
            })
            return {
                ...state,
                change: new Change({
                    ...state.change,
                    comments
                })
            };
        default:
            return state;
    }
};


const toStepReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case TO_STEP:
            return {
                ...state,
                toStep: 'pending',
                toStepChangeId: action._id,
                toStepError: null
            };
        case TO_STEP_SUCCESS:
            return {
                ...state,
                toStep: 'succeeded',
                toStepError: null,
                change: action.change
            };
        case TO_STEP_FAILURE:
            return {
                ...state,
                toStep: 'failed',
                toStepError: action.error
            };
        case TO_STEP_RESET:
            return {
                ...state,
                toStep: 'idle',
                toStepChangeId: null,
                toStepError: null,
            };
        default:
            return state;
    }
};

const toStepAction = (_id: string, step: string, params?: AnyObj, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: TO_STEP, _id});
        return Changes.toStep(_id, step, params)
            .then((data: Response) => {
                const {change} = data;
                dispatch({type: TO_STEP_SUCCESS, change});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: TO_STEP_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
};

const boardUpdateReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case ADD_CHANGE_TO_COLUMN:
            return {
                ...state,
                updateStatus: 'pending',
                updateError: null
            };
        case ADD_CHANGE_TO_COLUMN_SUCCESS:
            return {
                ...state,
                updateStatus: 'success',
                board: action.updatedBoard,
                updateError: null
            };
        case ADD_CHANGE_TO_COLUMN_FAILURE:
            return {
                ...state,
                updateStatus: 'failed',
                updateError: action.error
            };
        default:
            return state;
    }
};

const ADD_QUOTE         = 'yoda/change/ADD_QUOTE';
const ADD_QUOTE_SUCCESS = 'yoda/change/ADD_QUOTE_SUCCESS';
const ADD_QUOTE_FAILURE = 'yoda/change/ADD_QUOTE_FAILURE';

const addQuoteReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case ADD_QUOTE:
            return {
                ...state,
                addQuote: 'pending',
                addQuoteChangeId: action._id,
                addQuoteError: null
            };
        case ADD_QUOTE_SUCCESS:
            return {
                ...state,
                addQuote: 'succeeded',
                addQuoteError: null,
                change: action.change
            };
        case ADD_QUOTE_FAILURE:
            return {
                ...state,
                addQuote: 'failed',
                addQuoteError: action.error
            };
        default:
            return state;
    }
};

const UPDATING_QUOTE_SUCCESS = 'yoda/quote/UPDATING_ITEM_SUCCESS';
const TO_QUOTE_STEP_SUCCESS  = 'yoda/quote/TO_STEP_SUCCESS';
const updateQuoteReducer = (state = initialState, action: ActionPayload) => {
    if (!state.change)
        return state;

    switch (action.type) {
        case TO_QUOTE_STEP_SUCCESS:
        case UPDATING_QUOTE_SUCCESS:
            const quote = action.quote;
            if (!quote.items.map((item: AnyObj) => item.change?._id).includes(state.change._id))
                return state;

            return {
                ...state,
                change: new Change({
                    ...state.change,
                    quote
                })
            };
        default:
            return state;
    }
};

const addQuoteAction = (_id: string, params?: AnyObj[], callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: ADD_QUOTE, _id});
        return Changes.addQuote(_id, params)
            .then((data: Response) => {
                const {change} = data;
                dispatch({type: ADD_QUOTE_SUCCESS, change});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: ADD_QUOTE_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
};

const reviewChangesAction = (params: AnyObj, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: GET_REVIEW_CHANGES });
        params = {
            ...params,
            'progress.status': Change.STATUS_TEAM_REVIEW,
            with: 'logs'
        };
        return Changes.list(params)
            .then((data: Response) => {
                const { changes } = data;
                dispatch({ type: GET_REVIEW_CHANGES_SUCCESS, changes });
                callback && callback(null, changes);
            })
            .catch((error: Error) => {
                dispatch({ type: GET_REVIEW_CHANGES_FAILURE, error: error.message });
                callback && callback(error);
            });
    };
};

const triageChangesAction = (params: AnyObj, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: GET_TRIAGE_CHANGES });
        params = {
            ...params,
            'progress.status': Change.STATUS_PENDING_TRIAGE,
            with: 'logs'
        };
        return Changes.list(params)
            .then((data: Response) => {
                const { changes } = data;
                dispatch({ type: GET_TRIAGE_CHANGES_SUCCESS, changes });
                callback && callback(null, changes);
            })
            .catch((error: Error) => {
                dispatch({ type: GET_TRIAGE_CHANGES_FAILURE, error: error.message });
                callback && callback(error);
            });
    };
};

const createBugReportAction = (id: string, params: AnyObj, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: CREATE_BUG_REPORT, id });

        return Changes.createBugReport(id, params)
            .then((change) => {
                dispatch({
                    type: CREATE_BUG_REPORT_SUCCESS, change
                });
                callback && callback(null, change);
            })
            .catch((error: Error) => {
                dispatch({
                    type: CREATE_BUG_REPORT_FAILURE,
                    error: error.message
                });

                callback && callback(error);
            });
    };
};

const addChangeToBoardColumnAction = (
    projectId: string,
    columnId: string,
    changeId: string,
    callback?: Callback
) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: ADD_CHANGE_TO_COLUMN, projectId });

        Changes.addChangeToColumn(projectId, columnId, changeId)
            .then((updatedBoard) => {
                dispatch({
                    type: ADD_CHANGE_TO_COLUMN_SUCCESS,
                    updatedBoard
                });
                if (callback) callback(null, updatedBoard);
            })
            .catch((error: Error) => {
                dispatch({
                    type: ADD_CHANGE_TO_COLUMN_FAILURE,
                    error: error.message
                });
                if (callback) callback(error);
            });
    };
};

const SET_IMAGE           = 'yoda/changes/SET_IMAGE';
const SET_IMAGE_SUCCESS   = 'yoda/changes/SET_IMAGE_SUCCESS';
const SET_IMAGE_FAILURE   = 'yoda/changes/SET_IMAGE_FAILURE';

initialState.setImage        = 'idle' as Status;
initialState.setImageSuccess = null;
initialState.setImageError   = null;

const setImageReleaseReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case SET_IMAGE:
            return {
                ...state,
                setImage: 'loading',
                setImageError: null
            };
        case SET_IMAGE_SUCCESS:
            return {
                ...state,
                setImageSuccess: 'success',
                change: action.change,
                setImageError: null
            };
        case SET_IMAGE_FAILURE:
            return {
                ...state,
                setImage: 'failure',
                setImageError: action.error
            };
        default:
            return state;
    }
};

const UNSET_IMAGE           = 'yoda/changes/UNSET_IMAGE';
const UNSET_IMAGE_SUCCESS   = 'yoda/changes/UNSET_IMAGE_SUCCESS';
const UNSET_IMAGE_FAILURE   = 'yoda/changes/UNSET_IMAGE_FAILURE';

initialState.unsetImage        = 'idle' as Status;
initialState.unsetImageSuccess = null;
initialState.unsetImageError   = null;
const unsetImageReleaseReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case UNSET_IMAGE:
            return {
                ...state,
                unsetImage: 'loading',
                unsetImageError: null
            };
        case UNSET_IMAGE_SUCCESS:
            return {
                ...state,
                unsetImageSuccess: 'success',
                change: action.change,
                unsetImageError: null
            };
        case UNSET_IMAGE_FAILURE:
            return {
                ...state,
                unsetImage: 'failure',
                unsetImageError: action.error
            };
        default:
            return state;
    }
};

const MOVE_CHANGE         = 'yoda/board/MOVE_CHANGE';
const MOVE_CHANGE_SUCCESS = 'yoda/board/MOVE_CHANGE_SUCCESS';
const MOVE_CHANGE_FAILURE = 'yoda/board/MOVE_CHANGE_FAILURE';
const moveChangeReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case MOVE_CHANGE:
            return {
                ...state,
                movingChange: 'pending',
                moveChangeError: null
            };
        case MOVE_CHANGE_SUCCESS:
            return {
                ...state,
                movingChange: 'success',
                change: action.change,
                moveChangeError: null
            };
        case MOVE_CHANGE_FAILURE:
            return {
                ...state,
                movingChange: 'failed',
                moveChangeError: action.error
            };
        default:
            return state;
    }
};
const moveChangeAction = (
    changeId: string,
    projectId: string,
    boardId: string,
    columnId: string,
    callback?: Callback
) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: MOVE_CHANGE });

        Changes.move(changeId, projectId, boardId, columnId)
            .then((data: Response) => {
                const {change} = data;
                dispatch({
                    type: MOVE_CHANGE_SUCCESS,
                    change
                });
                if (callback) callback(null, change);
            })
            .catch((error: Error) => {
                dispatch({
                    type: MOVE_CHANGE_FAILURE,
                    error: error.message
                });
                if (callback) callback(error);
            });
    };
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        resetReducer,
        createReducer, startCreateReducer, cancelCreateReducer,
        getReducer, listReducer, onRemoteUpdateReducer,
        updateReducer, deleteReducer,
        duplicateReducer, toStepReducer,
        bugReportReducer, boardUpdateReducer,
        reviewChangesReducer, triageChangesReducer,
        addQuoteReducer, updateQuoteReducer,
        commentsReducer,
        setImageReleaseReducer, unsetImageReleaseReducer,
        moveChangeReducer
    ]);
};

const setImageReleaseAction = (id: string, file: File, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: SET_IMAGE});
        return Changes.setImageRelease(id, file)
            .then((data: Response) => {
                dispatch({type: SET_IMAGE_SUCCESS, change: data?.change});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: SET_IMAGE_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
};

const unsetImageReleaseAction = (id: string, callback?: Callback) => {
    return (dispatch: Dispatch) => {
        dispatch({type: SET_IMAGE});
        return Changes.unsetImageRelease(id)
            .then((data: AnyObj) => {
                const { change } = data;
                dispatch({type: SET_IMAGE_SUCCESS, change});
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: SET_IMAGE_FAILURE, error: error.message});
                callback && callback(error);
            });
    }
}
/* Export CRUD actions */
export const resetChanges           = resetAction;
export const createChange           = createAction;
export const startCreateChange      = startCreateAction;
export const cancelCreateChange     = cancelCreateAction;
export const loadChange             = getAction;
export const loadChanges            = listAction;
export const updateChange           = updateAction;
export const deleteChange           = deleteAction;
export const duplicateChange        = duplicateAction;
export const toChangeStep           = toStepAction;
export const addChangeQuote         = addQuoteAction;
export const createBugReport        = createBugReportAction;
export const addChangeToBoardColumn = addChangeToBoardColumnAction;
export const getReviewChanges       = reviewChangesAction;
export const getTriageChanges       = triageChangesAction;
export const setImageReleaseNotes   = setImageReleaseAction;
export const unsetImageReleaseNotes = unsetImageReleaseAction;
export const moveChange             = moveChangeAction;
