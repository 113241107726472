import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Change } from '../../models';
import type { ColumnData, BoardFilters } from '../../models';
import { loadProject, createBoard, updateBoard, deleteBoard, useAppDispatch, useAppSelector } from '../../redux';
import { Filters } from './Filters';
import { Board } from '../../models';
import { Ability, ProjectBoard, Button, Icon, Select } from '../../components';
import type { IconType } from '../../components';
import { Button as FButton, TextInput } from 'flowbite-react';
import _ from 'lodash';

interface ProjectBoardsProps {
    project: Project;
    changeId?: string;
}

function ProjectBoards(props: ProjectBoardsProps) {
    const { project, changeId } = props;

    const { t, i18n } = useTranslation();

    const [filters, setFilters]                     = useState<BoardFilters>({} as BoardFilters);
    const [showOptions, setShowOptions]             = useState(false);
    const [activeBoard, setActiveBoard]             = useState<Board>();
    const [currentBoardTitle, setCurrentBoardTitle] = useState("");
    const [newBoardTemplate, setNewBoardTemplate]   = useState<Board>();
    const [newBoardTitle, setNewBoardTitle]         = useState("");
    const [showList, setListVisible]                = useState(false);

    const { user }  = useAppSelector(state => state.auth);
    const { board, updating, deleting, creating } = useAppSelector(state => state.boards);

    const dispatch = useAppDispatch();

    const filterableUsers = React.useMemo(() => {
        if (!board)
            return [];

        let users: AnyObj = {};
        board.columns.forEach((column: ColumnData) => {
            column.changes.forEach((change: Change) => {
                if (change.assignee?._id)
                    users[change.assignee._id] = change.assignee;
            });
        });

        return Object.values(users);
    }, [board]);

    const selectBoard = (board: Board) => {
        setActiveBoard(board);
        setCurrentBoardTitle(board.name.fr);
    }

    if (!activeBoard && project?.boards?.length > 0)
        selectBoard(project.boards[0]);

    const handleKeyPress = (e: AnyObj, enterCallback: () => void, escapeCallback?: () => void) => {
        if (e.key === 'Enter' || e.code === 'Enter')
            enterCallback();
        if (escapeCallback && (e.key === 'Escape' || e.code === 'Escape'))
            escapeCallback();
    }

    const renameBoard = () => {
        if (!activeBoard)
            return;

        dispatch(updateBoard({
            _id: activeBoard._id,
            name: {
                'fr': currentBoardTitle,
                'en': currentBoardTitle,
                'de': currentBoardTitle
            }
        }, /*patch*/true));
    };

    const _deleteBoard = () => {
        if (!activeBoard)
            return;

        dispatch(deleteBoard(activeBoard._id, () => {
            dispatch(loadProject(project._id));
            selectBoard(project.boards[0] || null);
        }));
    };

    const addBoard = () => {
        const newBoard = newBoardTemplate ?? {
            project: project._id,
            name: {
                'fr': newBoardTitle,
                'en': newBoardTitle,
                'de': newBoardTitle
            },
            columns: []
        };

        dispatch(createBoard(newBoard, {}, () => {
            setShowOptions(false);
            setNewBoardTemplate(undefined);
            setNewBoardTitle('');
        }));
    };

    const theme = {
        "base": "group flex items-stretch items-center justify-center p-0 text-center font-medium relative outline-none transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] inline-flex",
        "color": {
            "gray": "text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 focus:text-cyan-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700",
            "info": "text-white bg-cyan-700 border border-transparent enabled:hover:bg-cyan-800 dark:bg-cyan-600 dark:enabled:hover:bg-cyan-700 dark:focus"
        }
    };

    const handleFilters = (filters: BoardFilters) => {
        setFilters(filters);
    }

    const boardCanBeRemoved = _.flatten(board?.columns.map((column: ColumnData) => column.isSmart ? [] : column.changes)).length === 0;

    const predefinedBoards: Record<string, Board> = React.useMemo(() => Board.predefinedBoards(project), [project?._id]);
    const predefinedBoardsOptions = Object.keys(predefinedBoards).map((type: string) => ({
        value: type,
        label: predefinedBoards[type].name[i18n.language + ""],
        leftContent: <Icon type={predefinedBoards[type].icon as IconType} className="ml-2" size={5} />
    }));
    predefinedBoardsOptions.unshift({
        value: "",
        label: t('projects.choose_a_predefined_board'),
        leftContent: <span />
    });

    const selectPredefinedBoard = (type: string | number) => {
        const boards = Board.predefinedBoards(project);
        setNewBoardTemplate(boards[type]);
    }

    return (
        <div className="ProjectBoards">
            <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-x-2 md:space-y-0">
                <Ability can="board:edit">
                    <Button
                        className="border-none -ml-3"
                        color="none"
                        title=""
                        textColor="cyan-700"
                        iconSize={6}
                        icon={showOptions ? 'close' : 'menu'}
                        onClick={() => setShowOptions(!showOptions)}
                    />
                </Ability>

                <div className="flex-1 flex justify-center items-center md:justify-start">
                    {board && project.boards.length > 1 && (
                        <FButton.Group>
                            {project.boards.map((board: Board) => (
                                <FButton size="xs" theme={theme} key={board._id} onClick={() => selectBoard(board)} color={board._id === activeBoard?._id ? 'info' : 'gray'}>
                                    <Icon type={board?.icon as IconType} className="inline mr-1.5" color={board._id === activeBoard?._id ? 'white' : 'gray-900'} size={3.5} />
                                    {board.name.fr}
                                </FButton>
                            ))}
                        </FButton.Group>
                    )}
                    {board && project.boards.length === 1 && (
                        <FButton size="xs" theme={theme} key={board?._id} color="info">
                            <Icon type={board?.icon as IconType} className="inline mr-1.5" color={board._id === activeBoard?._id ? 'white' : 'gray-900'} size={3.5} />
                            {board?.name?.fr}
                        </FButton>
                    )}
                </div>
                <div className="flex justify-center items-center md:justify-start">
                    <FButton.Group>
                        <FButton size="xs" theme={theme} onClick={() => setListVisible(false)} color={!showList ? 'info' : 'gray'}>
                            <Icon type="board" className="inline mr-1.5" color={!showList ? 'white' : 'gray-900'} size={3.5} />
                            {t('projects.kanban_view')}
                        </FButton>
                        <FButton size="xs" theme={theme} onClick={() => setListVisible(true)} color={showList ? 'info' : 'gray'}>
                            <Icon type="list" className="inline mr-1.5" color={showList ? 'white' : 'gray-900'} size={3.5} />
                            {t('projects.list_view')}
                        </FButton>
                    </FButton.Group>
                </div>
                <div className="flex-1 flex justify-center md:justify-end">
                    <Filters
                        currentUser={user}
                        users={filterableUsers}
                        onChange={handleFilters}
                        initialValues={{ search: '', user: null }}
                    />
                </div>
            </div>

            <div className="max-w-[calc(100vw-2rem)] mt-0 overflow-x-auto mt-1">
                <div className="flex flex-row justify-stretch items-stretch">
                    {showOptions && (
                        <div className={`relative pr-2 ${showOptions ? 'flex flex-col space-y-3' : 'hidden'} min-w-[280px]`}>
                            {board && (
                                <Ability can="board:edit">
                                    <div className="p-2 bg-gray-100 h-100">
                                        <h3 className="font-medium text-sm">
                                            {t('projects.rename_board')}
                                        </h3>
                                        <TextInput
                                            sizing="sm"
                                            rightIcon={() => <Icon type={updating === 'pending' ? 'loading' : 'edit'} />}
                                            placeholder={t('projects.rename_board') as string}
                                            type="text"
                                            onChange={e => setCurrentBoardTitle(e.target.value)}
                                            onKeyUp={(e) => handleKeyPress(e, renameBoard)}
                                            value={currentBoardTitle}
                                        />
                                    </div>
                                    {board && boardCanBeRemoved && (
                                    <div className="p-2 bg-gray-100 h-100">
                                        <Ability can="board:delete">
                                            <h3 className="font-medium text-sm">
                                                {t('projects.delete_this_board')}
                                            </h3>
                                            <div className="flex justify-center">
                                                <Button
                                                    small
                                                    className="mt-2"
                                                    color="danger"
                                                    title={t('projects.delete_this_board')}
                                                    iconSize={4}
                                                    icon={deleting === 'pending' ? 'loading' : 'delete'}
                                                    onClick={_deleteBoard}
                                                />
                                            </div>
                                        </Ability>
                                    </div>
                                    )}
                                </Ability>
                            )}

                            <Ability can="board:create">
                                <div className="p-2 flex flex-col space-y-2 bg-gray-100 h-100">
                                    <h3 className="font-medium text-sm">
                                        {t('projects.add_board')}
                                    </h3>
                                    <Select
                                        options={predefinedBoardsOptions}
                                        onChange={selectPredefinedBoard}
                                    />
                                    {!newBoardTemplate && (
                                        <>
                                            <div className=" flex flex-row space-x-1 justify-center items-center">
                                                <hr className="flex-1 mb-0"/>
                                                <span className="text-xs font-bold">
                                                    {t('common.or')}
                                                </span>
                                                <hr className="flex-1 mb-0"/>
                                            </div>
                                            <TextInput
                                                sizing="sm"
                                                rightIcon={() => <Icon type="plus" />}
                                                placeholder={t('projects.add_empty_board') as string}
                                                type="text"
                                                onChange={e => setNewBoardTitle(e.target.value)}
                                                onKeyUp={(e) => handleKeyPress(e, addBoard, () => setNewBoardTitle(""))}
                                                value={newBoardTitle}
                                            />
                                        </>
                                    )}
                                    {(newBoardTemplate || newBoardTitle) && (
                                        <div className="flex justify-center">
                                            <Button
                                                small
                                                className="mt-2"
                                                color="primary"
                                                title={t('common.add')}
                                                icon={creating === 'pending' ? 'loading' : 'plus'}
                                                iconSize={4}
                                                onClick={() => addBoard()}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Ability>
                        </div>
                    )}
                    {activeBoard && (
                        <ProjectBoard
                            boardId={activeBoard._id}
                            project={project}
                            changeId={changeId}
                            filters={filters}
                            showList={showList}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProjectBoards;
