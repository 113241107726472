import RestClient from './RestClient';
import { Client } from '../models';

class Clients extends RestClient {
    constructor() {
        super({
            // rest config
            model: Client,
            entryPoint: 'clients',
            sortBy: 'company',
            id_field: '_id',
            // redux config
            resource: 'client',
            resources: 'clients',
        });
    }
}

const ClientsApi = new Clients();

export default ClientsApi;
