import RestClient from './RestClient';
import { Sprint } from '../models';

class Sprints extends RestClient {
    constructor() {
        super({
            // rest config
            model: Sprint,
            entryPoint: 'sprints',
            sortBy: (a: AnyObj, b: AnyObj) => b.startAt - a.startAt,
            id_field: '_id',
            // redux config
            resource: 'sprint',
            resources: 'sprints',
        });
    }

    addMembers(id: string, members: string[]) {
        return this.POST(`/${this.entryPoint}/${id}/members`, members)
            .then(response => response.json())
            .then(json => {
                if (json.sprint) {
                    json.sprint = new Sprint(json.sprint);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    removeMembers(id: string, members: string[]) {
        return this.DELETE(`/${this.entryPoint}/${id}/members/${members.join(',')}`)
            .then(response => response.json())
            .then(json => {
                if (json.sprint) {
                    json.sprint = new Sprint(json.sprint);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    getCurrent() {
        return this.GET(`/${this.entryPoint}/current`)
            .then(response => response.json())
            .then(json => {
                if (json.sprint) {
                    json.sprint = new Sprint(json.sprint);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const SprintsApi = new Sprints();

export default SprintsApi;
