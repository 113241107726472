import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, Field } from 'formik';
import { Tooltip } from '..';
import { Change } from '../../models';
import { Icon } from '..';

interface TypeFieldProps {
    name: string;
    readOnly?: boolean;
    defaultValue?: string;
    className?: string;
    onSave?: (field: string, value: string) => void;
};

const TypeField = (props: TypeFieldProps) => {
    const { name, readOnly, defaultValue, className, onSave } = props;

    const { t } = useTranslation();

    const [ field, meta, helpers ]  = useField(name);
    const { setValue }              = helpers;
    const { value, touched, error } = meta;

    useEffect(() => {
        if (defaultValue)
            setValue(defaultValue);
    }, [defaultValue]);

    const types = Change.types();

    const save = (type: string) => {
        if (readOnly)
            return;

        if (onSave)
            return onSave(name, type);
        setValue(type);
    }

    const avatar = (type: string) => {
        let className = 'w-7 h-7 select-none text-center rounded-lg text-xs border ring ring-offset-2 hover:bg-blue-100 hover:ring-2 hover:ring-blue-100 flex items-center justify-center';
        if (value === type)
            className += ' bg-blue-100 ring-2';
        else
            className += ' bg-gray-100 ring-1 ring-gray-300';

        if (!readOnly)
            className += ' cursor-pointer';

        return (
            <Tooltip content={t(`projects.${type}`) || ''}>
                <div
                    onClick={() => save(type)}
                    className={className}
                >
                    <Icon type={Change.iconName(type)} size={3} />
                </div>
            </Tooltip>
        );
    }

    return (
        <>
            <div className={`grid grid-cols-6 sm:grid-cols-8 md:grid-cols-none md:flex md:justify-between gap-2 gap-y-4 ${className}`}>
                {types?.map(type => (
                    <div key={type} className={`col-span-1 ${type === Change.TYPE_NONE ? 'border-gray-300 pl-0 md:pl-4 md:border-l-2' : ''}`}>
                        {avatar(type)}
                    </div>
                ))}
            </div>
            <Field as="input" type="hidden" name={name} />
            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
        </>
    );
};

export default TypeField;
