import RestClient from './RestClient';
import { Commit } from '../models';

class Commits extends RestClient {
    constructor() {
        super({
            // rest config
            model: Commit,
            entryPoint: 'commits',
            id_field: '_id',
            // redux config
            resource: 'commit',
            resources: 'commits',
        });
    }

    linkToChange(commitId: string, changeId: string) {
        return this.PUT(`/${this.entryPoint}/${commitId}/link_to/${changeId}`, {})
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const CommitsApi = new Commits();

export default CommitsApi;
