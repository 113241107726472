import RestClient from './RestClient';
import { Reminder } from '../models';

class Reminders extends RestClient {
    constructor() {
        super({
            // rest config
            model: Reminder,
            entryPoint: 'reminders',
            //sortBy: 'date',
            id_field: '_id',
            // redux config
            resource: 'reminder',
            resources: 'reminders',
        });
    }
}

const RemindersApi = new Reminders();
export default RemindersApi;
