export default {
  everyText : 'chaque',
  emptyMonths : 'tous les mois',
  emptyMonthDays : "tous les jours du mois",
  emptyMonthDaysShort : "jour du mois",
  emptyWeekDays : "tous les jours de la semaine",
  emptyWeekDaysShort : "jour de la semaine",
  emptyHours : 'toutes les heures',
  emptyMinutes : "chaque minute",
  emptyMinutesForHourPeriod : "chaque",
  yearOption : "année",
  monthOption : "mois",
  weekOption : "semaine",
  dayOption : "jour",
  hourOption : "heure",
  minuteOption : "minute",
  rebootOption : "redémarrage",
  prefixPeriod : 'Chaque',
  prefixMonths : 'en',
  prefixMonthDays : 'le',
  prefixWeekDays : 'le',
  prefixWeekDaysForMonthAndYearPeriod : "et",
  prefixHours : 'à',
  prefixMinutes : ':',
  prefixMinutesForHourPeriod : "à",
  suffixMinutesForHourPeriod : "minute(s)",
  errorInvalidCron : "Expression cron non valide",
  clearButtonText : 'Effacer',
  weekDays : [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  months : [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    'Décembre',
  ],
  altWeekDays : [
    'DIM',
    'LUN',
    'MAR',
    'MER',
    'JEU',
    'VEN',
    'SAM',
  ],
  altMonths : [
    'JAN',
    'FEV',
    'MAR',
    'AVR',
    'MAI',
    'JUIN',
    'JUIL',
    'AOU',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
};
