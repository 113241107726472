import React from 'react';
import { useField } from 'formik';
import { Datepicker } from '..';
import type { Moment } from 'moment';

interface DatepickerFieldProps {
    name: string;
    direction?: string;
    onSave?: (field: string, value: Moment) => void;
    inputClassName?: string;
    unclearable?: boolean;
};

const DatepickerField = (props: DatepickerFieldProps) => {
    const { name, direction, onSave, inputClassName, unclearable } = props;

    const [ field, meta, helpers ] = useField(name);
    const { setValue } = helpers;
    const { value, touched, error } = meta;

    const onChange = React.useMemo(() => {
        return (value: Moment) => {
            setValue(value);
            onSave && onSave(`${name}`, value);
        };
    }, [setValue]);

    return (
        <>
            <Datepicker
                value={value}
                defaultValue={value}
                direction={direction}
                inputClassName={inputClassName}
                onChange={onChange}
                unclearable={unclearable}
            />
            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
        </>
    );
};

export default DatepickerField;
