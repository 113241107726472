import React, { useState, useEffect } from 'react';
import { Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Board, Commit, Project, Change } from '../../models';
import type { ColumnData } from '../../models';
import { Button, Select, Loader } from '../../components';
import { Icon } from '..';
import { Label, Timeline, Pagination, Badge } from 'flowbite-react';
import { loadBoards, loadCommits, linkCommit, RootState, useAppDispatch, useAppSelector } from '../../redux';
import _ from 'lodash';

interface CommitsTimelineProps {
    project: Project;
    page?: number,
    nbPerPage?: number,
}

function CommitsTimeline(props: CommitsTimelineProps) {
    const { project, page, nbPerPage } = props;

    const { i18n, t } = useTranslation();
    const dispatch    = useAppDispatch();

    const [typeFilter, setTypeFilter]           = useState("");
    const [filtered, setFiltered]               = useState([]);
    const [visible, setVisible]                 = useState([]);
    const [changeFilter, setChangeFilter]       = useState<string|undefined>(undefined);
    const [currentPage, setCurrentPage]         = useState(page || 1);
    const [currentCommit, setCurrentCommit]     = useState<Commit | null>(null);
    const [currentChangeId, setCurrentChangeId] = useState("");

    const { boards }           = useAppSelector(state => state.boards);
    const { loading, commits } = useAppSelector((state : RootState) => state.commits);

    const pageLength = nbPerPage || 10;

    useEffect(() => {
        if (project) {
            dispatch(loadCommits({
                project: project._id,
                limit: 1000
            }));
            dispatch(loadBoards({
                project: project._id
            }));
        }
    }, [dispatch, project]);

    const changes: Change[] = React.useMemo(() => {
        if (!boards)
            return [];

        return _.flattenDeep(
            boards.map((board: Board) => board.columns.map((column: ColumnData) => column.changes))
        );
    }, [boards]);

    useEffect(() => {
        setCurrentPage(1);
        setFiltered(commits?.filter((commit: Commit) => !typeFilter || commit.type === typeFilter) as never[]);
    }, [typeFilter, commits]);

    useEffect(() => {
        setVisible(filtered?.slice((currentPage -1) * pageLength, currentPage * pageLength) as never[]);
    }, [commits, currentPage, filtered, pageLength]);

    const toggleCurrentCommit = (commit: Commit) => {
        setCurrentCommit((currentCommit?._id !== commit._id ? commit : null));
    };

    const totalPages = React.useMemo(() => {
        let totalPages = 1;
        if (filtered?.length > 0) {
            totalPages = filtered?.length / pageLength;
            if ((filtered?.length % pageLength) > 0)
                totalPages = parseInt(totalPages + "") + 1;
        }
        return totalPages;
    }, [filtered, pageLength]);

    const selectableChanges = React.useMemo(() => {
        if (!currentCommit || !changeFilter?.length)
            return [];

        const selectable = changes?.filter(change => change.type === currentCommit.type)
            .filter((change: Change) => change.match(changeFilter))
            .sort((a: Change, b: Change) => { return a.slug > b.slug ? -1 : 1; })
            .slice(0, 20);

        return selectable;
    }, [changes, currentCommit, changeFilter]);

    const linkCommitToChange = () => {
        if (!currentCommit || !currentChangeId)
            return false;

        dispatch(linkCommit(currentCommit._id, currentChangeId));
    };

    if (loading === 'pending')
        return <Loader />;

    return (
        <Card className="CommitsTimeline">
            <h2 className="text-2xl font-bold leading-7 flex items-center text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                <Icon type="commit" size={7} />
                {t('projects.commits')}
            </h2>
            <div className="w-fit mx-auto">
                <Button
                    small
                    title={t('projects.all_commits')}
                    color={!typeFilter ? 'primary' : 'default'}
                    onClick={() => setTypeFilter("")}
                    className="rounded-none rounded-l"
                />
                {['release', 'feature', 'fix'].map((type, i) => (
                    <Button
                        small
                        key={type}
                        title={t(`projects.${type}`)}
                        color={type === typeFilter ? 'primary' : 'default'}
                        onClick={() => setTypeFilter(type)}
                        className={`rounded-none ${i === 2 ? "rounded-r" : ""}`}
                    />
                ))}
            </div>
            <Timeline>
                {visible?.map((commit: Commit) => (
                    <Timeline.Item key={commit.hash} className="mb-5">
                    <Timeline.Point icon={() => <div className="w-fit mx-auto"><Icon type="date" size={3} /></div>}/>
                    <Timeline.Content className={`p-3 pt-0 ${currentCommit?._id === commit._id ? 'border pt-3 bg-blue-50' : ''}`}>
                        <Timeline.Time>
                            {commit.date.format('LLL')}
                        </Timeline.Time>
                        <Timeline.Title onClick={() => toggleCurrentCommit(commit)} className="truncate cursor-pointer">
                            <Icon type={commit.iconName()} size={5} className="inline" color={commit.color()} />&nbsp;
                            <span title={commit.message}>
                                {commit.message}
                            </span>
                        </Timeline.Title>
                        <Timeline.Body>
                            <div className="flex space-x-2 mt-1">
                                <Badge color="gray" className="w-fit">
                                    <div className="flex items-center">
                                        <Icon type="user" size={3}className="inline"/>&nbsp;
                                        {commit.author_name}
                                    </div>
                                </Badge>
                                {commit.type !== 'ignored' && (
                                <Badge color={commit.color()} className="w-fit">
                                    {t(`projects.${commit.type}`)}
                                </Badge>
                                )}
                                {commit.tags?.map(tag => (
                                <Badge key={tag} color="warning" className="w-fit">
                                    {tag}
                                </Badge>
                                ))}
                            </div>
                            {commit.change && (
                            <div className="mt-1">
                                <Badge color="green" className="w-fit">
                                    <Icon type="check" size={3} className="inline"/>&nbsp;
                                    {commit.change.slug} - {commit.change.localizedTitle(i18n.language)}
                                </Badge>
                            </div>
                            )}
                            {(currentCommit?._id === commit._id) && (
                            <div className="mt-5 w-fit">
                                <Badge color="green" className="w-fit">
                                    #{commit.hash.substring(0, 8)}
                                </Badge>
                                {(commit.body?.length > 0)  && (
                                    <div className="mt-2 text-sm font-bold text-black">
                                        {commit.body}
                                    </div>
                                )}
                                {!(commit.body?.length > 0) && (
                                    <div className="mt-2 text-sm font-light text-gray-900">
                                        {t('projects.no_body_commit')}
                                    </div>
                                )}
                                {!commit.change && (
                                <div className="mt-2">
                                    <div className="mb-2 block">
                                        <Label value={t('projects.link_commit_to_change') || ""} />
                                    </div>
                                    <div className="flex space-x-0 justify-start">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                value={changeFilter}
                                                placeholder={t('tracks.search_change') || ''}
                                                className={`block w-full ${selectableChanges.length > 0 ? 'rounded-l-md' : 'rounded-md'} border-0 py-1.5 pr-10 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6`}
                                                onChange={(e: AnyObj) => {
                                                    setChangeFilter(e.target.value);
                                                }}
                                            />
                                            <Icon type="search" className="absolute top-2 right-2" size={5} color="gray-300" />
                                        </div>
                                        {selectableChanges.length > 0 && (
                                            <Select
                                                className="!mt-0"
                                                containerClassName="relative w-full cursor-default ounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                                onChange={(value?: string | number) => setCurrentChangeId(value?.toString() || "")}
                                                placeholder={t('projects.choose_a_change') || ''}
                                                options={selectableChanges.map(change => ({
                                                    value: change._id,
                                                    label: `${change.slugName()} - ${change.localizedTitle(i18n.language)}`
                                                }))}
                                            />
                                        )}
                                        {currentChangeId && (
                                            <Button
                                                onClick={linkCommitToChange}
                                                icon="save"
                                                color="primary"
                                                title={t('projects.link_commit')}
                                                className="rounded-l-none"
                                            />
                                        )}
                                    </div>
                                </div>
                                )}
                            </div>
                            )}
                        </Timeline.Body>
                    </Timeline.Content>
                </Timeline.Item>
                ))}
            </Timeline>
            <div className="flex items-center justify-center text-center">
                <Pagination
                    currentPage={currentPage}
                    onPageChange={n => setCurrentPage(n)}
                    showIcons
                    totalPages={totalPages}
                    previousLabel=""
                    nextLabel=""
                />
            </div>
        </Card>
    );
}

export default CommitsTimeline;
