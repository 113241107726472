import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadQuotes } from '../../../redux';
import { useNavigate } from "react-router-dom";
import { Ability, Loader, Button, Icon, Tooltip, Select } from '../../../components';
import type { Value } from '../../../components';
import { Button as FButton, Progress, Table, Badge } from 'flowbite-react';
import { Quote } from '../../../models';
import { formatPrice } from '../../../locales';
import _ from 'lodash';

interface SortOptions {
    property?: string;
    asc: boolean;
};

interface Filters {
    status: string[];
    name?: string;
    project?: Value;
    client?: Value;
};

const condensedTdTheme = {
    "base": "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-2 py-1 h-12"
};

function QuotesList() {
    const { t }    = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const searchInput     = useRef<HTMLInputElement>(null);
    const [sort, setSort] = useState<SortOptions>({
        property: 'slug',
        asc: false
    });

    const oldFilters     = localStorage.getItem('quotesFilters');
    const defaultFilters = oldFilters?.length ?
        JSON.parse(localStorage.getItem('quotesFilters') || '') : {
            status: [],
            project: undefined,
            client: undefined
        };

    const [filters, setFilters] = useState<Filters>(defaultFilters);

    const { quotes, loading } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        const params: AnyObj = {};
        if (filters.status.length)
            params.status = filters.status.join(',');

        if (filters.project)
            params.project = filters.project;

        if (filters.client)
            params.client = filters.client;

        localStorage.setItem('quotesFilters', JSON.stringify(filters));

        dispatch(loadQuotes(params));
    }, [dispatch, filters.status, filters.project, filters.client]);

    const rowClassName = (quote: Quote, base?: string) => {
        let className = 'whitespace-nowrap text-gray-900 dark:text-white ' + base;
        return className;
    };

    const sortIcon = (property: string) => {
        const newSort: SortOptions = {
            property,
            asc: sort.property === property ? !sort.asc : true
        };
        const icon  = sort.property === property && !sort.asc ? 'down' : 'up';
        const color = sort.property === property ? 'gray-900' : 'gray-400';

        return (
            <div className="inline cursor-pointer w-8" onClick={() => setSort(newSort)}>
                <Icon type={icon} color={color} className="inline ml-1.5" size={5} />
            </div>
        );
    };

    const quoteProgression = (quote: Quote) => {
        const progression = Math.round(quote?.progression());

        return (
            <Tooltip content={progression + '%'} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="sm"
                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    const sortedQuotes = React.useMemo(() => {
        if (!quotes?.length)
            return [];

        if (!sort.property)
            return quotes;

        return quotes.filter((quote: Quote) => {
            if (!filters.name?.length)
                return true;

            return (
                quote.name.match(new RegExp(filters.name, 'gi')) ||
                quote.slug.toString().match(new RegExp(filters.name, 'gi'))
            );
        }).sort((a: Quote, b: Quote) => {
            let result = 0;
            switch (sort.property) {
                case 'slug':
                    result = a.slug > b.slug ? 1 : -1;
                    break;
                case 'name':
                    result = a.name > b.name ? 1 : -1;
                    break;
                case 'status':
                    result = a.statusIndex() > b.statusIndex() ? 1 : -1;
                    break;
                case 'price':
                    result = a.price - b.price;
                    break;
                case 'submittedAt':
                    result = (a.submittedAt|| 0)  > (b.submittedAt|| 0)  ? 1 : -1;
                    break;
                case 'project':
                    result = a.project.name > b.project.name ? 1 : -1;
                    break;
            }

            return sort.asc ? result : result * -1;
        });
    }, [quotes, sort, filters.name]);

    const filterOnName = (name: string) => {
        setFilters({ ...filters, name });
    }

    const filterOnProject = (project: Value) => {
        setFilters({
            ...filters,
            project
        });
    }

    const filterOnClient = (client: Value) => {
        setFilters({
            ...filters,
            client
        });
    }

    const filterOnStatus = (status: string) => {
        let newStatus = _.clone(filters.status);
        if (newStatus.includes(status))
            newStatus = newStatus.filter((s: string) => s !== status);
        else
            newStatus.push(status);
        setFilters({
            ...filters,
            status: newStatus
        });
    }

    const resetProjectFilter = () => {
        setFilters({
            ...filters,
            project: undefined
        });
    }

    const resetClientFilter = () => {
        setFilters({
            ...filters,
            client: undefined
        });
    }

    const resetStatusFilter = () => {
        setFilters({
            ...filters,
            status: []
        });
    }

    const projectsOptions = React.useMemo(() => {
        return _.uniqBy(quotes.map((q: Quote) => q.project), '_id')
            .filter((p: AnyObj) => !!p)
            .sort((a: AnyObj, b: AnyObj) => a.name.localeCompare(b.name))
            .map((p: AnyObj) => ({
                label: p.name,
                value: p._id,
            }));
    }, [quotes]);

    const clientsOptions = React.useMemo(() => {
        return _.uniqBy(quotes.map((q: Quote) => q.client), '_id')
            .filter((c: AnyObj) => !!c)
            .sort((a: AnyObj, b: AnyObj) => (a.company || a.firstname).localeCompare((b.company || b.firstname)))
            .map((c: AnyObj) => ({
                label: c.name(),
                value: c._id
            }));
    }, [quotes]);

    const statusFilterBtnClassname = (i: number, max: number) => {
        let className = 'flex-1 w-40 focus:ring-0 ';
        if (i === 0)
            className += 'rounded-bl-md rounded-r-none';
        else if (i === max)
            className += 'border-t border-l-0 rounded-l-none';
        else
            className += 'border-t border-l-0 rounded-none';


        return className;
    }

    const handleKeyPress = (e: AnyObj) => {
        if ((e.key === 'Enter' || e.code === 'Enter') && sortedQuotes.length)
            return navigate(`/quotes/${quotes[0]._id}`);

        if (e.key === 'Escape' || e.code === 'Escape')
            setFilters({
                ...filters,
                name: ''
            });
    }

    if (searchInput?.current)
        searchInput.current.focus();

    const total = React.useMemo(() => {
        if (!sortedQuotes?.length)
            return 0;
        return sortedQuotes.reduce((acc: number, quote: Quote) => {
            return acc + quote.price;
        }, 0);
    }, [sortedQuotes]);

    return (
        <Ability can="quote:read" redirect="/">
            <div className="QuotesList">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="law" size={7} className="mr-1.5 inline" />
                                { t('app.quotes') }
                            </h2>
                        </div>
                        <div className="mt-5 flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 lg:mt-0">
                            <div className="relative flex">
                                <input
                                    type="text"
                                    value={filters?.name}
                                    onChange={e =>filterOnName(e.target.value)}
                                    onKeyUp={handleKeyPress}
                                    ref={searchInput}
                                    className="w-full h-9 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md focus:ring-gray-300 border-0 focus:border-0 py-0 pr-8"
                                />
                                <Icon type="search" color="gray-600" className="absolute top-2.5 right-2.5" />
                            </div>
                            <Ability can="quote:create">
                                <span className="sm:ml-3">
                                    <Button className="w-full" title={t('quotes.add_a_quote')} color="navigate" to="/quotes/new" icon="plus" />
                                </span>
                            </Ability>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="p-5">
                        <div className="mb-4 w-100 overflow-x-auto text-center">
                            <div className="flex flex-row inline-flex">
                                {Quote.allStatus().map((status: string, i: number) => (
                                    <FButton
                                        key={status}
                                        color={filters.status.includes(status) ? 'info' : 'gray'}
                                        onClick={() => filterOnStatus(status)}
                                        className={statusFilterBtnClassname(i, Quote.allStatus().length - 1)}
                                    >
                                        {t(`quotes.status_type.${status}`)}
                                    </FButton>
                                ))}
                                {(filters.status.length > 0) && (
                                    <Button
                                        title=""
                                        icon="close"
                                        color="none"
                                        iconColor="red-900"
                                        className="shadow-none"
                                        onClick={() => resetStatusFilter()}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mb-2 text-center flex justify-center">
                            <div className="flex justify-center">
                                <Select
                                    onChange={filterOnProject}
                                    options={projectsOptions}
                                    optionsWidth={'max-w-[800px]'}
                                    containerClassName={'relative w-full cursor-default ounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-md flex-1'}
                                    optionsContainerClassName={'!w-[600px] !max-w-[100vw]'}
                                    optionsClassName={''}
                                    defaultValue={filters.project}
                                    placeholder={t('quotes.filter_on_project') || ''}
                                />
                                {filters.project && (
                                    <div className="bg-white shadow-md mt-1">
                                        <Button
                                            title=""
                                            icon="close"
                                            color="none"
                                            iconColor="red-900"
                                            className="shadow-none"
                                            onClick={() => resetProjectFilter()}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-center">
                                <Select
                                    onChange={filterOnClient}
                                    options={clientsOptions}
                                    optionsWidth={'max-w-[800px]'}
                                    containerClassName={'relative w-full cursor-default ounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-md flex-1'}
                                    optionsContainerClassName={'!w-[600px] !max-w-[100vw]'}
                                    optionsClassName={''}
                                    defaultValue={filters.client}
                                    placeholder={t('quotes.filter_on_client') || ''}
                                />
                                {filters.client && (
                                    <div className="bg-white shadow-md mt-1">
                                        <Button
                                            title=""
                                            icon="close"
                                            color="none"
                                            iconColor="red-900"
                                            className="shadow-none"
                                            onClick={() => resetClientFilter()}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {loading === 'pending' ? (
                            <Loader />
                        ) : (
                            <div className="w-full overflow-x-auto">
                                <Table striped hoverable className="w-full">
                                    <Table.Head>
                                        <Table.HeadCell className="w-fit p-0 text-center">#</Table.HeadCell>
                                        <Table.HeadCell>{t('quotes.project')}{sortIcon('project')}</Table.HeadCell>
                                        <Table.HeadCell>{t('quotes.slug')}{sortIcon('slug')}</Table.HeadCell>
                                        <Table.HeadCell>{t('quotes.name')}{sortIcon('name')}</Table.HeadCell>
                                        <Table.HeadCell>{t('quotes.status')}{sortIcon('status')}</Table.HeadCell>
                                        <Table.HeadCell className="text-center">{t('quotes.last_action')}{sortIcon('submittedAt')}</Table.HeadCell>
                                        <Table.HeadCell className="text-right">{t('quotes.price')}{sortIcon('price')}</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {sortedQuotes?.map((quote: Quote, i: number) => (
                                            <Table.Row key={quote?._id} onClick={() => navigate(`/quotes/${quote._id}`)} className="dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-light text-gray-600 p-0 text-center')}>
                                                    {i + 1}
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-light')}>
                                                    <div className="flex-row font-bold">
                                                        {quote?.project?.name}
                                                    </div>
                                                    {quote?.client && (
                                                        <small className="font-light">{quote.client.name()}</small>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-bold')}>
                                                    <div className="flex-row truncate">
                                                        {quote?.slug}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-light')}>
                                                    <div className="flex-row truncate">
                                                        <div>{quote?.name}</div>
                                                        { quoteProgression(quote) }
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-light')}>
                                                    <div className="flex flex-row justify-center items-center">
                                                        <Badge color={quote.statusColor()} className="w-fit">
                                                            {t(`quotes.status_type.${quote?.status}`)}
                                                        </Badge>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-light text-center')}>
                                                    <Icon type="date" className="inline" />&nbsp;
                                                    {quote.lastAction().date?.format('L')}
                                                </Table.Cell>
                                                <Table.Cell theme={condensedTdTheme} className={rowClassName(quote, 'font-bold text-right')}>
                                                    <div className="flex-row truncate">
                                                        {quote.formattedPrice()}
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                            <Table.Row key="-1" className="dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell>
                                                    <h3 className="text-right text-xl font-bold text-gray-900">Total</h3>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <h3 className="text-right text-xl font-bold text-gray-900">
                                                        {formatPrice(total)}
                                                    </h3>
                                                </Table.Cell>
                                            </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </Ability>
    );
}

export default QuotesList;
