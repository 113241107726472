import React, { useState } from 'react';
import { useField } from 'formik';
import { WysiwygField } from '..';
import { useTranslation } from 'react-i18next';

interface TranslatableWysiwygFieldProps {
    name: string;
    langs?: string[];
    label?: JSX.Element;
    rows?: number;
    inline?: boolean;
    placeholder?: string;
    required?: boolean;
    className?: string;
    containerClassName?: string;
    children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
    onChange?: (field: string, value: string) => void;
    onSave?: (field: string, value: string) => void;
    isLoading: boolean;
};

const TranslatableWysiwygField = (props: TranslatableWysiwygFieldProps) => {
    const {
        name, langs, label, rows, placeholder, inline,
        required, className, containerClassName, onChange, onSave, isLoading
    } = props;

    const { t } = useTranslation();

    const [ isEditing, setIsEditing ] = useState(!inline);
    const [ field, meta, helpers ]    = useField(name);
    const { setValue }                = helpers;
    const { initialValue, value }     = meta;

    // following should work but for now it returns only ['fr']...
    const languages = langs?.length ? langs : ['fr', 'en', 'de'];
    const [selectedLang, setSelectedLang] = useState(languages[0]);

    const select_lang = (lang: string) => {
        setSelectedLang(lang);
    }

    const onClose = () => {
        setIsEditing(false);
        setValue(initialValue)
    }

    React.useMemo(() => {
        if (isLoading === false)
            setIsEditing(!inline);
    }, [isLoading]);

    if (!isEditing)
        return (
            <div className={`flex flex-col justify-between gap-0 ${containerClassName}`}>
                {(label || value?.[languages[0]]) && (
                    <div className="mb-1 flex justify-between">
                        {label}
                        {value?.[languages[0]] && (
                            <div className="text-xs cursor-pointer font-bold border-none text-gray-500" onClick={() => setIsEditing(true)}>
                                {t('common.edit')}
                            </div>
                        )}
                    </div>
                )}
                <div onClick={() => setIsEditing(true)} className="cursor-pointer">
                    {value?.[languages[0]] ? (
                        <div className="Wysiwyg content border border-gray-100 p-2 bg-gray-50 text-gray-900 hover:bg-gray-100 text-gray-500 rounded text-sm">
                            <div dangerouslySetInnerHTML={{ __html: value[languages[0]] }} />
                        </div>
                    ) : (
                        <div className="border border-gray-100 p-2 bg-gray-50 text-gray-900 hover:bg-gray-100 text-gray-500 italic rounded text-sm">
                            <div>{ placeholder }</div>
                        </div>
                    )}
                </div>
            </div>
        );

    return (
        <div className={`TranslatableWysiwygField ${containerClassName}`}>
            <div className="flex justify-between gap-0">
                <div>
                    {label}
                </div>
                {languages.length > 1 && (
                    <div className="border rounded rounded-b-none overflow-hidden ">
                        {languages.map((l) => (
                            <span key={l} onClick={(e) => { select_lang(l); return false; }} className={`cursor-pointer font-light py-3 text-sm px-3 ${selectedLang === l ? "bg-blue-100" : ""}`}>
                                {l.toUpperCase()}
                            </span>
                        ))}
                    </div>
                )}
            </div>

            {languages.length && languages.map((l, i) => (
                <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                    <WysiwygField
                        name={`${name}.${l}`}
                        rows={rows}
                        id={`name.${l}`}
                        className={`text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${className}`}
                        required={!!required}
                        placeholder={placeholder}
                        onChange={ (value: string) => { onChange && onChange(`${name}.${l}`, value); }}
                        onSave={ (value: string) => { onSave && onSave(`${name}.${l}`, value); onClose(); }}
                        onClose={ onClose }
                        isLoading={isLoading}
                    />
                </div>
            ))}
        </div>
    );
}

export default TranslatableWysiwygField;
