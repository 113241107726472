import React from 'react';
import { useTranslation } from 'react-i18next';

interface ValuesListProps {
    data       : any;
    className? : string;
    i18nKey?   : string;
    total?     : number;
}

function ValuesList(props: ValuesListProps) {
    const { t } = useTranslation();
    const { i18nKey, data, total, className } = props;

    if (data) {
        return (
            <div className='flex'>
                <div className={`w-full pb-2 pt-1 bg-white drop-shadow-md whitespace-nowrap overflow-auto text-xs flex justify-between ${className}`}>
                    {Object.entries(data).map(([key, val]: [string, any], index: number) => (
                        <span key={index} className={`${val === 0 ? "hidden" : ""} mx-2`}>
                            {i18nKey ? t(i18nKey + key ) + ":" : ""} {val}
                        </span>
                    ))}
                    {total &&
                        <span className='mx-2'>{t('common.total')}: {total}</span>
                    }
                </div>
            </div>
        );
    }

    return null

}
export default ValuesList;
