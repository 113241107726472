import React, { useState, useEffect } from 'react';
import { Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Release, Project, Change } from '../../models';
import { Icon, Loader } from '..';
import { Timeline, Pagination } from 'flowbite-react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { loadReleases } from '../../redux';

interface ChangelogProps {
    releases?: Release[];
    project?: Project;
    page?: number,
    nbPerPage?: number,
    isPublic?: boolean,
}

function Changelog(props: ChangelogProps) {
    const { page, nbPerPage, isPublic } = props;

    const { t, i18n }   = useTranslation();
    const storeReleases = useAppSelector((state: RootState) => state.releases.releases);
    const { loading }   = useAppSelector((state: RootState) => state.releases);

    const [filtered, setFiltered]       = useState([]);
    const [visible, setVisible]         = useState([]);
    const [currentPage, setCurrentPage] = useState(page || 1);

    const pageLength = nbPerPage || 10;

    const releases = React.useMemo(() => {
        if (props.releases)
            return props.releases;
        return storeReleases;
    }, [props.releases, storeReleases]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (props.project)
            dispatch(loadReleases({project: props.project._id}));
    }, [dispatch, props.project]);

    useEffect(() => {
        setCurrentPage(1);
        setFiltered(releases as never[]);
    }, [releases]);

    useEffect(() => {
        setVisible(filtered?.slice((currentPage -1) * pageLength, currentPage * pageLength) as never[]);
    }, [releases, currentPage, filtered, pageLength]);

    const orderedTypesToShow = [
        Change.TYPE_FEATURE,
        Change.TYPE_FIX,
        Change.TYPE_UI,
        Change.TYPE_I18N,
        Change.TYPE_DOC,
        Change.TYPE_TEST,
        Change.TYPE_BUILD,
        Change.TYPE_REFACTOR,
    ];
    if (!isPublic) {
        orderedTypesToShow.push(Change.TYPE_LINT);
        orderedTypesToShow.push(Change.TYPE_CHORE);
        orderedTypesToShow.push(Change.TYPE_DEPS);
    };

    const totalPages = React.useMemo(() => {
        let totalPages = 1;
        if (filtered?.length > 0) {
            totalPages = filtered?.length / pageLength;
            if ((filtered?.length % pageLength) > 0)
                totalPages = parseInt(totalPages + "") + 1;
        }
        return totalPages;
    }, [filtered, pageLength]);

    const releaseSummary = (release: Release) => {
        if (!release)
            return null;

        return orderedTypesToShow.filter(type =>release.hasChangesOfType(type)).map(type => (
            <div key={type} className="flex flex-col space-y-2 mt-1">
                <h3 className="text-black text-lg underline font-bold">
                    <Icon type={Change.iconName(type)} size={5} className="inline mr-1.5" />
                    {t(`projects.${type}_plural`)}
                </h3>
                {release.changesOfType(type).map((change: Change) => (
                    <div key={change._id}>
                        <span className="text-gray-900">- {change.localizedReleaseTitle(i18n.language)}</span>
                        <p className="text-sm font-light mb-2">
                            {change.localizedReleaseDescription(i18n.language)?.split('\n').map((item, key) => (
                                <span key={key} dangerouslySetInnerHTML={{ __html: item }} />
                            ))}
                        </p>
                        {change.release_notes?.image && (
                            <span
                                className="border inline-block text-2xl text-center font-bold uppercase text-slate-500 bg-cover rounded w-[300px] h-[140px]"
                                style={{
                                    backgroundImage: `url(${change.getReleaseImageUrl()})`,
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        ));
    };

    if (loading === 'pending')
        return <Loader />;

    return (
        <Card className="Changelog">
            <h2 className="text-2xl font-bold leading-7 flex items-center text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                <Icon type="changelog" size={7} className="mr-1.5" />
                {t('projects.changelog')}
            </h2>
            {(releases.length > 0) && (
                <>
                    <Timeline>
                        {visible?.map((release: Release) => (
                        <Timeline.Item key={release._id} className="mb-5">
                            <Timeline.Point icon={() => <div className="w-fit mx-auto"><Icon type="release" size={3} /></div>}/>
                            <Timeline.Content>
                                <Timeline.Title className="truncate">
                                    <span>
                                        {release.version}
                                    </span>
                                </Timeline.Title>
                                <Timeline.Time>
                                    {release.date.format('LLL')}
                                </Timeline.Time>
                                <Timeline.Body>
                                    {releaseSummary(release)}
                                </Timeline.Body>
                            </Timeline.Content>
                        </Timeline.Item>
                        ))}
                    </Timeline>
                    <div className="flex items-center justify-center text-center">
                        <Pagination
                            currentPage={currentPage}
                            onPageChange={n => setCurrentPage(n)}
                            showIcons
                            totalPages={totalPages}
                            previousLabel=""
                            nextLabel=""
                        />
                    </div>
                </>
            )}
        </Card>
    );
}

export default Changelog;
