import BaseModel from './BaseModel';

interface BurndownDay {
    day: string;
    idealEffort: number;
    idealEstimate: number;
    realEffort: number;
    realEstimate: number;
    tracked: number;
}

/** Class representing a statistics. */
class Report extends BaseModel {
    changes: {
        estimated_hours: number;
        assigned_hours: number;
        deployed_hours: number;
        to_deploy_hours: number;

        nb_assigned: number;
        nb_deployed: number;
        nb_estimated: number;

        billable_estimated_hours: number;
        billable_assigned_hours: number;
        billable_deployed_hours: number;
        billable_to_deploy_hours: number;

        deployment_ratio: number;
        assignment_ratio: number;

        tracked: {
            nb_tracked: number;
            estimated_hours: number;
            tracked_hours: number;
            billable_tracked_hours: number;
            tracking_ratio: number;
        },
        type: {
            bugs: {
                assignment_ratio: number;
                deployment_ratio: number;
                nb_assigned: number;
                nb_deployed: number;
                nb_estimated: number;
            },
            features: {
                assignment_ratio: number;
                deployment_ratio: number;
                nb_assigned: number;
                nb_deployed: number;
                nb_estimated: number;
            },
            other: {
                assignment_ratio: number;
                deployment_ratio: number;
                nb_assigned: number;
                nb_deployed: number;
                nb_estimated: number;
            }
        },
    };

    tracks: {
        average_hours_per_track: number;
        nb: number;
        tracked_hours: number;
    }

    users: {
        nb: number;
        worked_hours: number;
        remaining_hours: number;
        worked_man_days: number;
        remaining_man_days: number;
        tracking_ratio: number;
    };

    burndown: Record<string, BurndownDay>;

    constructor(properties: AnyObj) {
        super(properties);

        this.changes  = properties.changes;
        this.tracks   = properties.tracks;
        this.users    = properties.users;
        this.burndown = properties.burndown;
    }
}

export default Report;
