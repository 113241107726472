import { Dispatch } from 'redux';
import { Sprint, Change } from '../../models';
import { Sprints } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';

const TO_STEP_SUCCESS         = 'yoda/change/TO_STEP_SUCCESS';
const UPDATING_CHANGE_SUCCESS = 'yoda/change/UPDATING_ITEM_SUCCESS';

const GET_CURRENT         = 'yoda/sprint/GET_CURRENT';
const GET_CURRENT_SUCCESS = 'yoda/sprint/GET_CURRENT_SUCCESS';
const GET_CURRENT_FAILURE = 'yoda/sprint/GET_CURRENT_FAILURE';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Sprints);

// current sprint
initialState.current = null;

/**
 * We have to add this hook to handle modifications on changes when we are
 * looking at a specific sprint...
 */
const updateChangeReducer = (state = initialState, action: ActionPayload) => {
    if (!state.sprint && !state.current)
        return state;

    switch (action.type) {
        case UPDATING_CHANGE_SUCCESS:
        case TO_STEP_SUCCESS:
            const { change }          = action;
            const { sprint, current } = state;

            const newState = { ...state };

            // let's remove this change from the sprint
            if (sprint) {
                const inSprint  = change.sprints.reduce((acc: boolean, s: Sprint) => acc || s._id === sprint._id, false);

                sprint.changes = sprint.changes.filter((original: Change) => {
                    return original._id !== change._id;
                });
                if (inSprint)
                    // let's add/replace the original change in the sprint
                    // with the updated one
                    sprint.changes.push(change);

                newState.sprint = new Sprint({ ...sprint })
            }

            // let's remove this change from the current
            if (current) {
                const inCurrent = change.sprints.reduce((acc: boolean, s: Sprint) => acc || s._id === current._id, false);

                current.changes = current.changes.filter((original: Change) => {
                    return original._id !== change._id;
                });
                if (inCurrent)
                    // let's add/replace the original change in the current
                    // with the updated one
                    current.changes.push(change);

                newState.current = new Sprint({ ...current })
            }

            return newState;
        default:
            return state;
    }
};

const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if ((!state.sprint && !state.current) || action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'change.updated':
            const change      = new Change(data);
            const { sprint }  = state;
            const { current } = state;

            const newState = { ...state };

            if (sprint) {
                const inSprint = change.sprints.reduce((acc: boolean, s: Sprint) => acc || s._id === sprint._id, false);

                // let's remove this change from the sprint
                sprint.changes = sprint.changes.filter((original: Change) => {
                    return original._id !== change._id;
                });
                if (inSprint)
                    // let's add/replace the original change in the sprint
                    // with the updated one
                    sprint.changes.push(change);

                newState.sprint = new Sprint({ ...sprint })
            }

            if (current) {
                const inCurrent = change.sprints.reduce((acc: boolean, s: Sprint) => acc || s._id === current._id, false);

                // let's remove this change from the current
                current.changes = current.changes.filter((original: Change) => {
                    return original._id !== change._id;
                });
                if (inCurrent)
                    // let's add/replace the original change in the current
                    // with the updated one
                    current.changes.push(change);

                newState.current = new Sprint({ ...current })
            }

            return newState;
        default:
            return state;
    }
};

const getCurrentReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case GET_CURRENT:
            return {
                ...state,
                loadingError: null
            };
        case GET_CURRENT_SUCCESS:
            return {
                ...state,
                loadingError: null,
                current: action.sprint
            };
        case GET_CURRENT_FAILURE:
            return {
                ...state,
                loadingError: action.error,
                current: null
            };
        default:
            return state;
    }
};
/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer, updateChangeReducer,
        onRemoteUpdateReducer,
        getCurrentReducer
    ]);
}

const getCurrentAction = () => {
    return (dispatch: Dispatch) => {
        dispatch({type: GET_CURRENT});
        return Sprints.getCurrent()
            .then((data: Response) => {
                if ("sprint" in data) {
                    const { sprint } = data;
                    dispatch({type: GET_CURRENT_SUCCESS, sprint});
                }
            })
            .catch((error: Error) => {
                dispatch({type: GET_CURRENT_FAILURE, error: error.message});
            });
    }
}
/* Export CRUD actions */
export const createSprint        = createAction;
export const startCreateSprint   = startCreateAction;
export const loadSprint          = getAction;
export const loadSprints         = listAction;
export const updateSprint        = updateAction;
export const deleteSprint        = deleteAction;
export const duplicateSprint     = duplicateAction;
export const currentSprint       = getCurrentAction;
