import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Change, Sprint } from '../../models';
import { Icon, Loader } from '..';
import moment from 'moment';
import { ChangeModal } from '../ChangeModal';
import { ChangeListItem } from '../ChangeListItem';
import { currentSprint, loadChange, resetChanges, useAppDispatch, useAppSelector } from '../../redux';

const MySprint = () => {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    const { user }    = useAppSelector(state => state.auth);
    const { current } = useAppSelector(state => state.sprints);

    const { change, loadingOne }  = useAppSelector(state => state.changes);

    useEffect(() => {
        dispatch(currentSprint());
    }, [dispatch]);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const userId = user?._id;

    const changes = React.useMemo(() => {
        if (!current?.changes?.length) {
            return [];
        }

        const groups = Sprint.groupChangesByUser(Sprint.sortChangesBy(current.changes, 'priority'));
        if (userId && (userId in groups))
            return groups[userId].changes;

        return [];
    }, [user?._id, current?.changes]);

    if (!changes?.length)
        return null;

    return (
        <div className="p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md">

            {loadingOne === 'pending' && <Loader />}

            <div className="pt-3 pb-2 items-center justify-between border-b border-gray-300">
                <h3 className="flex items-center text-2xl font-bold tracking-tight text-gray-900">
                    <Icon type="sprint" size={7} className="inline" />&nbsp;{t('dashboard.current_sprint')} - {current?.name}
                    {changes?.length > 0 && <span>&nbsp;({changes.length})</span>}
                </h3>
            </div>
            <div className="py-4">
                <h3 className="font-medium text-xs flex items-center space-x-4">
                    <Icon type="list" size={4} className="inline mr-1" />
                    {current.getEstimatedHours(/*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                    <Icon type="check" size={4} className="inline mr-1" />&nbsp;
                    {current.getRealisedHours(moment(), /*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                    <Icon type="todo" size={4} className="inline mr-1" />&nbsp;
                    {current.getRemainingHours(moment(), /*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                </h3>
                <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                    {changes.map((change: Change) => (
                        <div key={change._id} onClick={() => selectChange(current.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                            <ChangeListItem
                                showProject
                                change={change}
                                className={
                                    `${change.isFinished() ?
                                            'bg-green-50 border-green-200' :
                                            change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`
                                }
                            />
                        </div>
                    ))}
                </div>
                {change && change._id === currentChangeId && change.project?.name && (
                    <ChangeModal
                        project={change.project}
                        change={change}
                        show={true}
                        showProjectName={true}
                        onClose={() => selectChange()}
                        onRemove={(values: AnyObj) => { }}
                    />
                )}
            </div>
        </div>
    );
}

export default MySprint;
