import RestClient from './RestClient';
import { Goal } from '../models';

class Goals extends RestClient {
    constructor() {
        super({
            // rest config
            model: Goal,
            entryPoint: 'goals',
            sortBy: 'startAt',
            id_field: '_id',
            // redux config
            resource: 'goal',
            resources: 'goals',
        });
    }
}

const GoalsApi = new Goals();

export default GoalsApi;
