import { Statistics } from '../../services';
import { Dispatch } from 'redux';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import type { Status, Response } from './rest';

const { initialState } = createRestSlices(Statistics);

initialState.statistics = null;
initialState.report     = null;

const RESET_REPORT = 'yoda/statistics/RESET_REPORT';

const GET_STATISTICS         = 'yoda/statistics/GET_STATISTICS';
const GET_STATISTICS_SUCCESS = 'yoda/statistics/GET_STATISTICS_SUCCESS';
const GET_STATISTICS_FAILURE = 'yoda/statistics/GET_STATISTICS_FAILURE';

const GET_REPORT         = 'yoda/statistics/GET_REPORT';
const GET_REPORT_SUCCESS = 'yoda/statistics/GET_REPORT_SUCCESS';
const GET_REPORT_FAILURE = 'yoda/statistics/GET_REPORT_FAILURE';

const reducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case RESET_REPORT:
            return {
                ...initialState,
                report: null
            };
        case GET_STATISTICS:
            return {
                ...state,
                loadingOne: 'pending' as Status,
                loadingError: null
            };
        case GET_STATISTICS_SUCCESS:
            return {
                ...state,
                loadingOne: 'succeeded' as Status,
                loadingError: null,
                statistics: action.statistics
            };
        case GET_STATISTICS_FAILURE:
            return {
                ...state,
                loadingOne: 'failed' as Status,
                loadingError: action.error
            };
        case GET_REPORT:
            return {
                ...state,
                loadingOne: 'pending' as Status,
                loadingError: null
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                loadingOne: 'succeeded' as Status,
                loadingError: null,
                report: action.report
            };
        case GET_REPORT_FAILURE:
            return {
                ...state,
                loadingOne: 'failed' as Status,
                loadingError: action.error
            };
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        reducer
    ]);
};

const getStatisticsAction = (params?: Array<string>) => {
    if (!params)
        params = ["all"];

    return (dispatch: Dispatch) => {
        dispatch({type: GET_STATISTICS});
        return Statistics.getStatistics(params)
            .then((data: Response) => {
                if ("statistics" in data) {
                    const { statistics} = data;
                    dispatch({type: GET_STATISTICS_SUCCESS, statistics});
                }
            })
            .catch((error: Error) => {
                console.log('error', error.message);

                dispatch({type: GET_STATISTICS_FAILURE, error: error.message});
            });
    }
}

const resetReportAction = (params?: Array<string>) => {
    return (dispatch: Dispatch) => {
        dispatch({type: RESET_REPORT});
    };
}

const getReportAction = (params?: AnyObj) => {
    if (!params)
        params = ["all"];

    return (dispatch: Dispatch) => {
        dispatch({type: GET_REPORT});
        return Statistics.getReport(params)
            .then((data: Response) => {
                if (data?.report) {
                    const { report} = data;
                    dispatch({type: GET_REPORT_SUCCESS, report});
                }
            })
            .catch((error: Error) => {
                console.log('error', error.message);

                dispatch({type: GET_REPORT_FAILURE, error: error.message});
            });
    }
}
/* Export CRUD actions */
export const resetReport   = resetReportAction;
export const getStatistics = getStatisticsAction;
export const getReport     = getReportAction;
