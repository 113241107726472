import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadGoal, updateGoal, loadUsers } from '../../../redux';
import { Ability, Alert, Button } from '../../../components';
import GoalForm from '../GoalForm/GoalForm';

function GoalEdit() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    let { id }     = useParams();

    const { goal, updatingError } = useAppSelector((state: RootState) => state.goals);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadGoal(id));
    }, [dispatch, id]);

    const submit = function (values: AnyObj) : void {
        dispatch(updateGoal(values));
    }

    return (
        <Ability can="goal:edit" redirect="/">
            <div className="GoalEdit">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                { t('goals.edit_goal', {name: goal?.name}) }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.delete')} color="danger" to={`/goals/delete/${goal?._id}`} icon="delete" />
                            </span>
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to={`/goals/${goal?._id}`} icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {updatingError && (
                    <div className="flex-1">
                        <Alert title={t('goals.creation_failed') + ' : ' + updatingError} color="failure" icon="warning" />
                    </div>
                )}
                <main className="mx-6 my-6 shadow">
                    {goal && (goal._id === id) && (
                        <GoalForm isCreation={false} initialValues={goal} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default GoalEdit;
