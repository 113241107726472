interface ConfigProperties {
    api_end_point: string;
    mercure_hub: string;
    app_version: string;
};

const Config : ConfigProperties = {
    api_end_point: process.env.REACT_APP_API_ROOT as string,
    mercure_hub: process.env.REACT_APP_MERCURE_HUB as string,
    app_version: process.env.REACT_APP_VERSION as string,
};

export default Config;
