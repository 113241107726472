import { Boards } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import { ActionPayload } from '..';
import { Board, Change } from '../../models';
import type { ColumnData } from '../../models';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    duplicateReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
    duplicateAction,
} = createRestSlices(Boards);

/**
 * We have to add this hook to handle remote modifications on changes
 */
const UPDATE = 'yoda/mercure/REMOTE_UPDATE';
const onRemoteUpdateReducer = (state = initialState, action: ActionPayload) => {
    if (action.type !== UPDATE)
        return state;

    const { update: { data, type }} = action;
    switch (type) {
        case 'board.updated':
            if (!state.board || state.board._id !== data._id)
                return state;

            return {
                ...state,
                board: new Board({
                    ...state.board,
                    ...data,
                })
            };
        case 'change.updated':
            if (!state.board)
                return state;

            let columns;
            if (data.type === Change.TYPE_NONE && data.progress?.status === Change.STATUS_FINISHED) {
                // let's remove the non dev task from the board since it's finished
                columns = state.board.columns.map((column: ColumnData) => {
                    column.changes = column.changes.filter((original: Change) => {
                        return original._id !== data._id;
                    });
                    return column;
                });
            } else {
                // let's replace the original change in the board
                // with the updated one
                columns = state.board.columns.map((column: ColumnData) => {
                    column.changes = column.changes.map((original: Change) => {
                        return original._id === data._id ? new Change(data) : original;
                    });
                    return column;
                });
            }
            return {
                ...state,
                board: new Board({
                    ...state.board,
                    columns
                })
            };
        default:
            return state;
    }
};

/**
 * We have to add this hook to handle modifications on changes
 */
const TO_STEP_SUCCESS         = 'yoda/change/TO_STEP_SUCCESS';
const UPDATING_CHANGE_SUCCESS = 'yoda/change/UPDATING_ITEM_SUCCESS';
const updateChangeReducer = (state = initialState, action: ActionPayload) => {
    if (!state.board)
        return state;

    switch (action.type) {
        case TO_STEP_SUCCESS:
        case UPDATING_CHANGE_SUCCESS:
            const { change } = action;
            const { board } = state;

            if (change.type === Change.TYPE_NONE && change.progress?.status === Change.STATUS_FINISHED) {
                // let's remove the non dev task from the board since it's finished
                board.columns = board.columns.map((column: ColumnData) => {
                    column.changes = column.changes.filter((original: Change) => {
                        return original._id !== change._id;
                    });
                    return column;
                });
            } else {
                // let's replace the original change in the board
                // with the updated one
                board.columns = board.columns.map((column: ColumnData) => {
                    column.changes = column.changes.map((original: Change) => {
                        return original._id === change._id ? change : original;
                    });
                    return column;
                });
            }
            return {
                ...state,
                board: new Board({ ...board })
            };
        default:
            return state;
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer,
        updateChangeReducer, onRemoteUpdateReducer,
    ]);
}

/* Export CRUD actions */
export const createBoard        = createAction;
export const startCreateBoard   = startCreateAction;
export const loadBoard          = getAction;
export const loadBoards         = listAction;
export const updateBoard        = updateAction;
export const deleteBoard        = deleteAction;
export const duplicateBoard     = duplicateAction;
