import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { startCreateQuote, createQuote } from '../../../redux';
import { Ability, Alert, Button } from '../../../components';
import QuoteForm from '../QuoteForm/QuoteForm';

function QuoteCreate() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { quote, created, creatingError } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        const defaultQuote = {};
        dispatch(startCreateQuote(defaultQuote));
    }, [dispatch]);

    const submit = function (values: AnyObj) : void {
        dispatch(createQuote(values));
    }

    if (created)
        navigate(`/quotes/edit/${quote._id}`);

    document.title = `Yoda - ${t('quotes.add_a_quote')}`;

    return (
        <Ability can="quote:create" redirect="/">
            <div className="QuoteCreate">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-black-900">
                                { t('quotes.add_a_quote') }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/quotes" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {creatingError && (
                    <div className="flex-1">
                        <Alert title={t('quotes.creation_failed') + ' : ' + creatingError} color="failure" icon="warning" />
                    </div>
                )}
                <main className="mx-6 my-6 shadow">
                    {quote && (!quote._id) && (
                        <QuoteForm isCreation={true} initialValues={quote} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default QuoteCreate;
