import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RTDatepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';
import type { Moment } from 'moment';

interface DatepickerProps {
    value?: Moment;
    defaultValue: Moment;
    onChange: (value: Moment) => void;
    direction?: any;
    from?: Moment;
    inputClassName?: string;
    unclearable?: boolean;
};

const Datepicker = (props: DatepickerProps) => {
    const { t, i18n } = useTranslation();

    const { defaultValue, onChange, direction, from, inputClassName, unclearable } = props;

    const [value, setValue] = useState(defaultValue ? {
        startDate: (defaultValue || moment()).toDate(),
        endDate: (defaultValue || moment()).toDate(),
    } : null);

    const handleValueChange = (newValue: any) => {
        setValue(newValue);
        onChange(moment(newValue.startDate));
    }

    useEffect(() => {
        if (props.value)
            setValue({
                startDate: props.value?.toDate() || moment().toDate(),
                endDate: props.value?.toDate() || moment().toDate()
            });
    }, [props.value]);

    return (
        <RTDatepicker
            i18n={i18n.language}
            startWeekOn="mon"
            asSingle={true}
            useRange={false}
            startFrom={from?.toDate()}
            popoverDirection={direction}
            value={value}
            onChange={handleValueChange}
            containerClassName={`relative ${unclearable ? 'unclearable' : ''}`}
            inputClassName={`relative transition-all duration-300 py-2.5 pl-4 w-40 max-w-full tracking-wide rounded font-bold text-sm placeholder-gray-400 focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20 ${inputClassName}`}
            displayFormat={t('common.input_date_format') || ''}
        />
    );
}

export default Datepicker;
