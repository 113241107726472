import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'flowbite-react';
import { useAppDispatch, useAppSelector } from "../../redux";
import { search, resetSearch } from "../../redux/ducks/search";
import SearchBar from "../SearchBar/SearchBar";
import SearchResult from "../SearchResult/SearchResult";
import _ from 'lodash';
import {useTranslation} from "react-i18next";

interface SearchModalProps {
    visible: boolean;
    onClose: () => void;
}

const SearchModal: React.FC<SearchModalProps> = ({ visible, onClose }) => {
    const { i18n }          = useTranslation();
    const [query, setQuery] = useState("");
    const dispatch          = useAppDispatch();
    const lang              = i18n.language ?? "fr";
    const lastQuery         = useAppSelector((state) => state.search.query);

    const debouncedSearch = useCallback(_.throttle((lastQuery, query) => {
        if (query?.length >= 3) {
            if (lastQuery !== query)
                dispatch(search(query, lang));
        } else {
            dispatch(resetSearch());
        }
    }, 500, {leading: false, trailing: true}), []);

    const onQueryChange = (query: string) => {
        setQuery(query);
        debouncedSearch(lastQuery, query);
    };

    return (
        <Modal show={visible} onClose={onClose} className="items-start" dismissible>
            <Modal.Header>Recherche</Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => e.preventDefault()}>
                    <SearchBar query={query} onQueryChange={onQueryChange} />
                </form>
                <SearchResult query={query} onClose={onClose} />
            </Modal.Body>
        </Modal>
    );
};

export default SearchModal;
