import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadQuote, deleteQuote } from '../../../redux';
import { Ability, Icon, Button } from '../../../components';

function QuoteDelete() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let { id }     = useParams();

    const { quote, deleting, deleted } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        if (id)
            dispatch(loadQuote(id));
    }, [dispatch, id]);

    const doDelete = () => {
        dispatch(deleteQuote(quote._id));
    };

    if (deleted)
        navigate('/quotes');

    return (
        <Ability can="quote:delete" redirect="/">
            <div className="QuoteDelete">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="quote" size={7} />&nbsp;
                                { t('quotes.delete_quote', {name: quote?.name}) }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to={`/quotes/${quote?._id}`} icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                <main className="mx-6 my-6">
                    {quote && (quote._id === id) && (
                    <div className="flex">
                        <div className="w-fit mx-auto">
                            <h1 className="mx-auto font-bold p-2 mb-2">
                                { t('quotes.sure_to_delete_quote', {name: quote?.name}) }
                            </h1>
                            <div className="mt-5 flex justify-evenly">
                                <Button title={t('common.cancel')} color="navigateBack" to="/quotes" icon="back" />
                                <Button
                                    onClick={doDelete}
                                    title={t('common.delete')}
                                    icon="delete"
                                    loading={deleting === 'pending'}
                                    color="danger"
                                />
                            </div>
                        </div>
                    </div>
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default QuoteDelete;
