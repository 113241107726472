import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadDocumentation, getDocumentationTree, useAppDispatch, useAppSelector } from '../../../redux';
import { Icon, Markdown } from '../../../components';
import { Card } from 'flowbite-react';
import Documentation from '../../../models/Documentation'
import { i18n } from '../../../locales';
import moment from 'moment'

interface DocListProps {
    data: Documentation[];
}
interface DocItemProps {
    item: Documentation;
}

function DocumentationsList() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { tree, documentation } = useAppSelector(state => state.documentations);
    const [showDocumentation, setShowDocumentation] = useState('');
    moment.locale(i18n.language);

    useEffect(() => {
        dispatch(getDocumentationTree());
    }, [dispatch]);

    const selected = (item: Documentation) => {
        if (item.children.length === 0) {
            setShowDocumentation(item.id);
            dispatch(loadDocumentation(item.id));
        }
    };

    const DocList: FC<DocListProps> = ({ data }) => (
        <div>
            {data.map((item) => (
                <DocItem key={item.id} item={item}/>
            ))}
        </div>
    );

    const DocItem: FC<DocItemProps> = ({ item }) => (
        <div
            key={item.id}
            id={item.id}
            className={`w-full text-sm ${(showDocumentation === item.id) && item.children.length === 0 ? "bg-blue-100 hover:bg-blue-200" : item.children.length === 0 ? "cursor-pointer bg-gray-100 hover:bg-gray-200" : "bg-gray-100"} p-1 pl-2 my-1 rounded-md`}
            onClick={() => selected(item)}
        >
            {item.title}
            {item.children.length > 0 &&
                <ul>
                    {item.children.map((child) => (
                        <li
                            key={child.id}
                            className={`w-full text-sm ${(showDocumentation === child.id) && child.children.length === 0 ? "bg-blue-100 hover:bg-blue-200" : child.children.length === 0 ? "bg-gray-100 hover:bg-gray-200 font-light" : "bg-gray-100"} pl-2 my-1 rounded-md`}
                            onClick={() => selected(child)}
                        >
                            <DocItem item={child} />
                        </li>
                    ))}
                </ul>
            }
        </div>
    );

    document.title = `Yoda - ${t('app.documentations')}`;

    return (
        <div className="DocumentationList">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="comment" size={7} className="mr-1.5 inline" />
                            { t('documentations.documentation') }
                        </h2>
                    </div>
                </div>
            </header>
            <main>
                <Card className="Documentations">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-full md:col-span-3">
                        {tree &&
                            <DocList data={tree} />
                        }
                        </div>
                        <div className="col-span-full md:col-span-9">
                        {documentation &&
                        <>
                            <Markdown key={documentation.id} content={documentation.text} className="border p-4" />
                            <div className='flex justify-between'>
                                <p>{documentation.id}</p>
                                <a href={`${documentation.url}`} target="_blank" rel="noreferrer">{t('documentations.outline_btn')}</a>
                                <p>{t('documentations.outline_last_update')} : {moment(documentation.updatedAt).format('L')}</p>
                            </div>
                        </>
                        }
                        </div>
                    </div>
            </Card>
           </main>
        </div>
    );
}

export default DocumentationsList;
