import React from 'react';
import { useAppSelector, RootState } from '../../redux';
import { Navigate } from "react-router-dom";

interface AbilityProps {
    can: string;
    children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
    redirect?: string;
}

function Ability(props: AbilityProps) {
    const { can, children, redirect } = props;
    const { user } = useAppSelector((state: RootState) => state.auth);

    if (!user)
        return null;

    if (!user.can(can))
        return redirect ? <Navigate to={redirect} /> : null;

    return (
        <>
            { children }
        </>
    );
}

export default Ability;
