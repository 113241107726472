import React from 'react';
import { useTranslation } from 'react-i18next';
import { Quote } from '../../models';
import { RootState, useAppSelector, useAppDispatch } from '../../redux';
import { Formik, Form } from 'formik';
import { Ability, Button, Icon, DatepickerField, ToggleField } from '..';
import { toQuoteStep, updateQuote } from '../../redux';
import type { Status } from '../../redux';
import _ from 'lodash';

interface QuoteProgressProps {
    quote: Quote;
}

const pendingStatus: Status = 'pending';

const QuoteProgress = (props: QuoteProgressProps) => {
    const { quote } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { user }             = useAppSelector((state: RootState) => state.auth);
    const { toStep, updating } = useAppSelector((state: RootState) => state.quotes);

    const goToStep = async (step: string) => {
        if (!quote)
            return;

        await dispatch(toQuoteStep(quote._id, step));
    }

    const onSaveField = async (field: string, value: any ) => {
        const params = { _id:  quote?._id };
        _.set(params, field, value);

        await dispatch(updateQuote(params, /*patch*/true));
    }

    return (
        <Formik
            initialValues={quote || {}}
            enableReinitialize={true}
            onSubmit={() => {}}
        >
            <Form tabIndex={-1} className='max-h-full rounded-lg'>
                <div className="grid grid-cols-6 gap-x-6 gap-y-2 sm:grid-cols-6">
                    {quote?.submittedAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="submittedAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.quoted_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="submittedAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.submittedAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}
                    {quote?.acceptedAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="acceptedAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.quote_accepted_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="acceptedAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.acceptedAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}
                    {quote?.refusedAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="refusedAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.quote_refused_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="refusedAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.refusedAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}
                    {quote?.acceptedAt && (
                        <>
                            <div className="col-span-full mt-4 -mb-2">
                                <h3 className="font-bold text-sm text-gray-900">{t('changes.advance')}</h3>
                            </div>
                            <div className="col-span-full flex flex-col items-center gap-x1 border border-gray-100 bg-gray-50 p-2">
                                <label htmlFor="needsAdvance" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t('changes.needsAdvance')}
                                </label>
                                {quote.advancePaidAt ? (
                                    <Icon type="check" size={8} />
                                ) : (
                                    <>
                                        {user?.can('quote:edit') ? (
                                            <ToggleField
                                                name="needsAdvance"
                                                onChange={v => { onSaveField('needsAdvance', v); }}
                                                isLoading={ updating === pendingStatus }
                                            />
                                        ) : (
                                            <Icon type={quote.needsAdvance ? 'check' : 'close'} size={8} />
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {quote?.advanceBilledAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="advanceBilledAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.advance_billed_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="advanceBilledAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.advanceBilledAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}
                    {quote?.advancePaidAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="advancePaidAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.advance_paid_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="advancePaidAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.advancePaidAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}
                    {quote?.billedAt && (
                        <>
                            <div className="col-span-full mt-4 -mb-2">
                                <h3 className="font-bold text-sm text-gray-900">{t('changes.bill')}</h3>
                            </div>
                            <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                                <label htmlFor="billedAt" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t('changes.billed_at_editable')}
                                </label>
                                {user?.can('quote:edit') ? (
                                    <DatepickerField name="billedAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                                ) : (
                                    <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                        { quote.billedAt.format('LL') }
                                    </label>
                                )}
                            </div>
                        </>
                    )}
                    {quote?.paidAt && (
                    <div className="col-span-full md:col-span-3 flex flex-row justify-between items-center gap-x1 border border-gray-100 bg-gray-50 pl-2">
                        <label htmlFor="paidAt" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('changes.paid_at_editable')}
                        </label>
                        {user?.can('quote:edit') ? (
                            <DatepickerField name="paidAt" onSave={ onSaveField } direction="left" inputClassName="bg-gray-50 border-gray-50"/>
                        ) : (
                            <label className="block text-sm font-medium leading-6 text-gray-900 mr-2 py-2">
                                { quote.paidAt.format('LL') }
                            </label>
                        )}
                    </div>
                    )}

                    <div className={`col-span-full rounded p-2 bg-${quote.statusColor()}-100 border-${quote.statusColor()}-200 font-normal text-md text-center font-bold leading-6 my-4`}>
                        <Icon type="law" className="inline mr-1" />
                        {t(`quotes.status_type.${quote?.status || 'pending'}`)}
                    </div>

                    {quote && user?.can('quote:changeStep') && (quote.status === Quote.STATUS_PENDING) && (!quote.submittedAt) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            <Button
                                icon={toStep === pendingStatus ? 'loading' : 'check'}
                                color="primary"
                                onClick={() => goToStep(Quote.STATUS_SUBMITTED)}
                                title={t('changes.set_quoted')}
                            />
                        </div>
                    )}

                    {(quote?.status === Quote.STATUS_SUBMITTED) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:justify-between w-full text-sm font-medium leading-6 text-gray-900">
                                <Ability can="quote:changeStep">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'close'}
                                        color="warning"
                                        onClick={() => goToStep(Quote.STATUS_REFUSED)}
                                        title={t('changes.client_refused_quote')}
                                    />
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'right'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_ACCEPTED)}
                                        title={t('changes.client_accepted_quote')}
                                    />
                                </Ability>
                                <Ability can="quote:accept">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'close'}
                                        color="warning"
                                        onClick={() => goToStep(Quote.STATUS_REFUSED)}
                                        title={t('changes.refuse_quote')}
                                    />
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'right'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_ACCEPTED)}
                                        title={t('changes.accept_quote')}
                                    />
                                </Ability>
                            </div>
                        </div>
                    )}
                    {quote?.needsAdvance && (
                        <>
                            {user?.can('quote:changeStep') && (quote.status === Quote.STATUS_ACCEPTED) && !quote.advanceBilledAt && (
                                <div className="col-span-full flex flex-col items-center gap-x1">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'check'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_ADVANCE_BILLED)}
                                        title={t('changes.set_advance_billed')}
                                    />
                                </div>
                            )}
                            {user?.can('quote:changeStep') && (quote.status === Quote.STATUS_ADVANCE_BILLED) && !quote.advancePaidAt && (
                                <div className="col-span-full flex flex-col items-center gap-x1">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'check'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_ADVANCE_PAID)}
                                        title={t('changes.set_advance_paid')}
                                    />
                                </div>
                            )}
                            {user?.can('quote:changeStep') && (quote.status === Quote.STATUS_ADVANCE_PAID) && (
                                <div className="col-span-full flex flex-col items-center gap-x1">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'check'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_BILLED)}
                                        title={t('changes.set_billed')}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {!quote?.needsAdvance && (
                        <>
                            {user?.can('quote:changeStep') && (quote?.status === Quote.STATUS_ACCEPTED) && (
                                <div className="col-span-full flex flex-col items-center gap-x1">
                                    <Button
                                        icon={toStep === pendingStatus ? 'loading' : 'check'}
                                        color="primary"
                                        onClick={() => goToStep(Quote.STATUS_BILLED)}
                                        title={t('changes.set_billed')}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {user?.can('quote:changeStep') && (quote?.status === Quote.STATUS_BILLED) && (
                        <div className="col-span-full flex flex-col items-center gap-x1">
                            <Button
                                icon={toStep === pendingStatus ? 'loading' : 'check'}
                                color="primary"
                                onClick={() => goToStep(Quote.STATUS_PAID)}
                                title={t('changes.set_paid')}
                            />
                        </div>
                    )}
                </div>
            </Form>
        </Formik>
    );
}

export default QuoteProgress;
