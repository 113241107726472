import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch, RootState } from '../../redux';
import { detectedLogout } from '../../redux';
import { useLocation } from "react-router-dom";
import { Icon } from '..';
import * as fetchIntercept from 'fetch-intercept';

interface AuthVerifyProps {}

function AuthVerify(props: AuthVerifyProps) {
    const { t }    = useTranslation();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { tokenExpired } = useAppSelector((state : RootState) => state.auth);

    useEffect(() => {
        const unregister = fetchIntercept.register({
            response: response => {
                if (response.status === 401)
                    dispatch(detectedLogout());
                return response;
            }
        });

        return () => {
            unregister();
        };
    }, [dispatch, location]);

    if (!tokenExpired)
        return null;

    setTimeout(() => {
        window.location.reload();
    }, 3000);

    return (
        <div className="flex flex-col gap-2 p-4 text-sm text-red-700 bg-red-100 border-red-500 dark:bg-red-200 dark:text-red-800 rounded-lg">
            <div className="flex">
                <span className="text-lg font-medium flex items-center">
                    <Icon type="warning" color="danger" size={4} />
                    {t('login.token_expired')}
                </span>
            </div>
            <span className="font-medium flex italic">
                {t('login.you_are_redirected')}
            </span>
        </div>
    );
}

export default AuthVerify;
