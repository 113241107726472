import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

interface AutoSizeTextareaProps {
    onChange?: (value: string) => void;
    onKeyDown?: (e: AnyObj) => void;
    defaultValue?: string;
    className?: string;
};

const AutoSizeTextarea = (props: AutoSizeTextareaProps) => {
    const {
        onChange, onKeyDown, defaultValue, className
    } = props;

    const ref               = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState<string>(defaultValue || "");

    const handleChange = (e: AnyObj) => {
        onChange && onChange(e.target.value);
        setValue(e.target.value);
    };

    const handleKeyDown = (e: AnyObj) => {
        onKeyDown && onKeyDown(e);
    };

    useEffect(() => {
        if (defaultValue !== value)
            setValue(defaultValue || "");
    }, [defaultValue]);

    useLayoutEffect(() => {
        const textarea = ref.current;
        if (textarea) {
            textarea.style.height = '0px';
            const scrollHeight    = textarea.scrollHeight;
            textarea.style.height = scrollHeight + 'px';
        }
    }, [value]);

    return (
        <textarea
            className={`no-scrollbar ${className}`}
            rows={1}
            ref={ref}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value}
        />
    );
};

export default AutoSizeTextarea;
