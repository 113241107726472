import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';


interface TooltipProps {
    content: any;
    children: any;
    className?: string;
    placement?: "top" | "bottom" | "left" | "right" | "auto";
    light?: boolean;
};


const Tooltip = (props: TooltipProps) => {

    if (!props.content)
        return props.children;

    return (
        <Tippy className={props.className ?? ''} content={props.content} placement={props.placement ?? 'top'} theme={props.light ? 'light' : undefined} >
            {props.children}
        </Tippy>
    );
};

export default Tooltip;
