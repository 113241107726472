import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Change, Quote } from '../../../../models';
import { Button, Icon } from '../../..';
import { RootState, useAppSelector, useAppDispatch } from '../../../../redux';
import { loadQuotes, updateQuote } from '../../../../redux';

interface QuotePickerProps {
    change: Change;
}

function QuotePicker(props: QuotePickerProps) {
    const { change } = props;

    const [pickerIsVisible, setPickerVisible] = useState(false);

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { updating }         = useAppSelector((state: RootState) => state.changes);
    const { quotes, loading } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        if (pickerIsVisible)
            dispatch(loadQuotes({
                project: change.project?._id,
                status: Quote.STATUS_PENDING
            }));
    }, [dispatch, pickerIsVisible, change.project?._id]);

    if (!change)
        return null;

    const addToQuote = async (quote: Quote) => {
        if (!change)
            return;

        const title = change.title[Object.keys(change.title)[0]];
        const params = {
            "_id":  quote._id,
            "items": [
                ...quote.items,
                {
                    title,
                    type: change.type,
                    estimate: change.estimate,
                    price: 0,
                    change: change._id,
                }
            ]
        };

        setPickerVisible(false);
        await dispatch(updateQuote(params, /*patch*/true));
    }

    if (updating === 'pending')
        return (
            <div className="flex justify-center items-center border border-gray-100 bg-gray-50 p-2">
                <Icon type="loading" size={6} />
            </div>
        );

    return (
        <div>
            {!pickerIsVisible && (
                <div className="">
                    <Button
                        icon="plus"
                        title={t('changes.add_to_quote')}
                        onClick={() => setPickerVisible(true)}
                        color="primary"
                    />
                </div>
            )}
            {pickerIsVisible && (
                <div  className="bg-gray-100 p-2 col-span-full flex-col space-y-2 text-center">
                    <label className="block text-sm font-light leading-6 text-gray-900">{t('changes.choose_a_quote')}</label>
                    {loading === 'pending' ? (
                        <div className="flex justify-center items-center">
                            <Icon type="loading" size={6} />
                        </div>
                    ) : (
                        <>
                            <div className="grid grid-cols-2 gap-1 place-content-center">
                                {quotes.slice().reverse().map((quote: Quote) => (
                                    <div key={quote._id} className="col-span-1">
                                        <div
                                            className="flex h-fit items-center gap-1 bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300 rounded p-1 text-xs font-normal border cursor-pointer w-full"
                                            onClick={(e: any) => addToQuote(quote)}
                                        >
                                            <Icon type="quote" color="indigo" className="inline mr-1.5"/>
                                            <span className="">{quote.slug}</span>&nbsp;|&nbsp;
                                            <span className="w-100 truncate no-wrap">{quote.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Button
                                icon="close"
                                title={t('common.cancel')}
                                onClick={() => setPickerVisible(false)}
                                iconSize={3}
                                color="primary"
                                small
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default QuotePicker;
