import RestClient from './RestClient';
import { Change } from '../models';
import { store } from '../redux';
import type { FetchOptions } from './Client';

class Changes extends RestClient {
    constructor() {
        super({
            // rest config
            model: Change,
            entryPoint: 'changes',
            sortBy: 'title.fr',
            id_field: '_id',
            // redux config
            resource: 'change',
            resources: 'changes',
        });
    }

    toStep(_id: string, step: string, params?: AnyObj) {
        params = { _id, step };

        return this.PUT(`/${this.entryPoint}/${_id}/step`, params)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    move(_id: string, projectId: string, boardId?: string, columnId?: string) {
        const params = { _id, projectId, boardId, columnId };

        return this.PUT(`/${this.entryPoint}/${_id}/move`, params)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    addQuote(_id: string, params?: AnyObj) {
        params = { _id };
        return this.POST(`/${this.entryPoint}/${_id}/quote`, params)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    // Create a Change
    createBugReport(_id: string, bugReportData: AnyObj) {
        let data = {...bugReportData, _id};
        return this.POST(`/support`, data)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json.change;
                }
                throw new Error(json.flash.error);
            });
    }

    // Adds a Change to the support board
    addChangeToColumn(projectId: string, columnId: string, changeId: string) {
        return this.PUT(`/support/${projectId}/project`, { columnId, changeId })
            .then(response => response.json())
            .then(json => {
                if (!json.success){
                    throw new Error(json);
                }
                return json;
            });
    }

    setImageRelease(id: string, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        const headers: AnyObj = {};
        const subscriberId = store.getState()?.mercure?.subscriberId;
        if (subscriberId)
            headers['X-MERCURE-SUBSCRIBER'] = subscriberId;

        let url = `${this.baseUrl}/${this.entryPoint}/${id}/releaseImage`;
        const options: FetchOptions = {
            method: 'POST',
            body: formData,
            headers,
            credentials: 'include',
        };

        return fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    unsetImageRelease(id: string) {
        return this.DELETE(`/${this.entryPoint}/${id}/releaseImage`)
            .then(response => response.json())
            .then(json => {
                if (json.change) {
                    json.change = new Change(json.change);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const ChangesApi = new Changes();
export default ChangesApi;
