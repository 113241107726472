import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadProject, deleteProject } from '../../../redux';
import { Ability, Icon, Button } from '../../../components';

function ProjectDelete() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let { id }     = useParams();

    const { project, deleting, deleted } = useAppSelector((state: RootState) => state.projects);

    useEffect(() => {
        if (id)
            dispatch(loadProject(id));
    }, [dispatch, id]);

    const doDelete = () => {
        dispatch(deleteProject(project._id));
    };

    if (deleted)
        navigate('/projects');

    return (
        <Ability can="project:create" redirect="/projects">
            <div className="ProjectDelete">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="project" size={7} />&nbsp;
                                { t('projects.delete_project', {name: project?.name}) }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to={`/projects/${project?._id}`} icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                <main className="mx-6 my-6">
                    {project && (project._id === id) && (
                    <div className="flex">
                        <div className="w-fit mx-auto">
                            <h1 className="mx-auto font-bold p-2 mb-2">
                                { t('projects.sure_to_delete_project', {name: project?.name}) }
                            </h1>
                            <p className="border rounded p-2 mb-2">
                                <Icon type="right" className="mr-1.5 inline" />
                                { t('projects.x_releases_will_be_deleted', {nb: project?.releases.length}) }
                            </p>
                            <p className="border rounded p-2 mb-2">
                                <Icon type="right" className="mr-1.5 inline" />
                                { t('projects.x_commmits_will_be_deleted', {nb: project?.commits.length}) }
                            </p>
                            <div className="mt-5 flex justify-evenly">
                                <Button title={t('common.cancel')} color="navigateBack" to="/projects" icon="back" />
                                <Button
                                    onClick={doDelete}
                                    title={t('common.delete')}
                                    icon="delete"
                                    loading={deleting === 'pending'}
                                    color="danger"
                                />
                            </div>
                        </div>
                    </div>
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default ProjectDelete;
