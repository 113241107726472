import React from 'react';
import { useTranslation } from 'react-i18next';
import { Change } from '../../models';
import { SelectField } from '../';

interface ImportanceFieldProps {
    name: string;
    placeholder?: string;
    className?: string;
    onChange?: (value?: number) => void;
};

const ImportanceField = (props: ImportanceFieldProps) => {
    const { name, placeholder, className, onChange } = props;

    const { t } = useTranslation();

    const options = Change.getImportanceValues().map(value => ({
        value,
        label: t(`changes.importance_value_${value}`)
    }));

    return (
        <SelectField
            name={name}
            options={options}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
        />
    );
};

export default ImportanceField;
