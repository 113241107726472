import React from 'react';
import { Outlet } from "react-router-dom";
import Topbar from './Topbar';

import { i18n } from '../../locales';
import moment from 'moment'
import 'moment/locale/fr';
moment.locale(i18n.language);

function Public() {
    return (
        <div className="Public h-screen">
            <Topbar />
            <div className="flex">
                <div className="w-full border-r">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Public;
