import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { startCreateGoal, createGoal } from '../../../redux';
import { Ability, Alert, Button } from '../../../components';
import GoalForm from '../GoalForm/GoalForm';

function GoalCreate() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { goal, created, creatingError } = useAppSelector((state: RootState) => state.goals);

    useEffect(() => {
        const defaultGoal = {};
        dispatch(startCreateGoal(defaultGoal));
    }, [dispatch]);

    const submit = function (values: AnyObj) : void {
        dispatch(createGoal(values));
    }

    if (created)
        navigate(`/goals/edit/${goal._id}`);

    document.title = `Yoda - ${t('goals.add_a_goal')}`;

    return (
        <Ability can="goal:create" redirect="/">
            <div className="GoalCreate">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-black-900">
                                { t('goals.add_a_goal') }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/goals" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {creatingError && (
                    <div className="flex-1">
                        <Alert title={t('goals.creation_failed') + ' : ' + creatingError} color="failure" icon="warning" />
                    </div>
                )}
                <main className="mx-6 my-6 shadow">
                    {goal && (!goal._id) && (
                        <GoalForm isCreation={true} initialValues={goal} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default GoalCreate;
