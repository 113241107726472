import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Change, User } from '../../models';
import type { ColumnData } from '../../models';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../redux';
import { cancelCreateChange, createChange, deleteChange } from '../../redux';
import { loadBoard, updateBoard, loadChange } from '../../redux';
import { ChangeModal, Loader } from '../../components';
import type { BoardFilters } from '../../models';
import { KanBan } from '../KanBan';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import './ProjectBoard.css';

interface ProjectBoardProps {
    project: Project;
    boardId: string;
    changeId?: string;
    user?: User;
    filters?: BoardFilters;
    showList?: boolean;
}

function ProjectBoard(props: ProjectBoardProps) {
    const { t, i18n } = useTranslation();

    const { project, boardId, filters, showList } = props;

    const [showModal, setShowModal]     = useState(false);

    const { change }            = useAppSelector(state => state.changes);
    const { board, loadingOne } = useAppSelector(state => state.boards);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadBoard(boardId));
    }, [dispatch, project, boardId]);

    useEffect(() => {
        /**
         * If a change is provided in the props, we load if from
         * the API and open the modal
         */
        if (props.changeId) {
            dispatch(loadChange(props.changeId));
            return setShowModal(true);
        }

        /**
         * Otherwise, we close the modal : needed when we go backward
         * while the modal is open.
         */
        setShowModal(false);
    }, [dispatch, props.changeId]);

    const boardToShow = React.useMemo(() => {
        if (!board)
            return undefined;

        return filters ? board.filterOn(filters) : board;
    }, [board, filters]);

    const closeModal = () => {
        /**
         * we reset the current change
         */
        dispatch(cancelCreateChange());
        navigate(`/projects/${board?.project?._id}`);
    };

    const onAddColumn = (columnTitle: string) => {
        board.columns.push({
            _id: uuidv4(),
            title: columnTitle,
            editable: true,
            acceptNewCards: true,
            changes: []
        });
        dispatch(updateBoard(board));
    };

    const onEditColumn = (updatedColumn: ColumnData) => {
        board.columns = board.columns.map((column: ColumnData) => {
            if (column._id === updatedColumn._id)
                return updatedColumn;

            return column;
        });
        dispatch(updateBoard(board));
    };

    const onDuplicateColumn = (column: ColumnData) => {
        board.columns.push({
            ...column,
            _id: uuidv4(),
            title: `${column.title} - copy`,
            changes: []
        });
        dispatch(updateBoard(board));
    };

    const onRemoveColumn = (column: ColumnData) => {
        board.columns = board.columns.filter((c: ColumnData) => c._id !== column._id);
        dispatch(updateBoard(board));
    };

    const onEditCard = (change: Change) => {
        /**
         * We make a navigation step to edit a card, this will
         * open the modal.
         */
        navigate(`/projects/${board.project?._id}/change/${change?._id}`);
    };

    const onRemoveCard = (change: AnyObj) => {
        dispatch(deleteChange(change._id, (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
            dispatch(cancelCreateChange());
            dispatch(loadBoard(board._id));
        }));
        closeModal();
    };

    const onCancelModal = () => {
        closeModal();
    };

    const onAddCard = (values: AnyObj, boardId: string, columnId: string, showNew: boolean) => {
        values.project = board.project._id;
        const params = { boardId, columnId };
        dispatch(createChange(
            values,
            params,
            (err: Error | null, result?: AnyObj | AnyObj[] | null) => {
                dispatch(loadBoard(board._id));
                const change = result as Change;
                if (showNew && change)
                    navigate(`/projects/${board.project?._id}/change/${change._id}`);
            }
        ));
    };

    const onDragCard = (result: AnyObj) => {

        const {type, source, destination} = result;

        // dropped outside a column
        if (!destination) {
            return;
        }

        // if we dragged a column
        if (type === 'column') {
            const column = board.columns[source.index];
            board.columns.splice(source.index, 1);
            board.columns.splice(destination.index, 0, column);
            return dispatch(updateBoard(board));
        }

        const sourceColumnId = source.droppableId;
        const targetColumnId = destination.droppableId;

        // if the source and destination are the same, do nothing
        if (sourceColumnId === targetColumnId && destination.index === source.index) {
            return;
        }

        // If the card is moved within the same column and just needs an index change
        if (sourceColumnId === targetColumnId) {
            board.columns = board.columns.map((column: ColumnData) => {
                if (column._id === sourceColumnId) {
                    const changeId = column.changes[source.index];
                    column.changes.splice(source.index, 1);
                    column.changes.splice(destination.index, 0, changeId);
                }

                return column;
            });
            return dispatch(updateBoard(board));
        }

        //If the card has been moved to a different column
        const changeId = result.draggableId;
        const changes: Change[] = _.flatten(board.columns.map((column: ColumnData) => column.changes));
        const change: Change | undefined =  changes?.find((c: Change) => c._id === changeId);
        board.columns = board.columns.map((column: ColumnData) => {
            if (column._id === sourceColumnId) {
                column.changes = column.changes.filter((c: Change) => c._id !== changeId);
            } else if (column._id === targetColumnId) {
                column.changes.splice(destination.index, 0, change || changeId);
            }

            return column;
        });
        return dispatch(updateBoard(board));
    };

    if (change?.title && props.changeId)
        document.title = `Yoda - ${t('changes.change')} ${change.title[i18n.language]}`;

    return (
        <div className="ProjectBoard w-full">
            {(!boardToShow || (loadingOne === 'pending')) && (
                <Loader />
            )}
            {boardToShow && (
                <div className='justify-stretch'>
                    <KanBan
                        board={boardToShow}
                        onAddColumn={onAddColumn}
                        onEditColumn={onEditColumn}
                        onDuplicateColumn={onDuplicateColumn}
                        onRemoveColumn={onRemoveColumn}
                        onAddCard={onAddCard}
                        onEditCard={onEditCard}
                        onDragCard={onDragCard}
                        showList={showList}
                    />
                    <ChangeModal
                        project={project}
                        change={change}
                        show={showModal}
                        onClose={onCancelModal}
                        onRemove={onRemoveCard}
                        showProjectName={board?.project?._id !== change?.project?._id}
                    />
                </div>
            )}
        </div>
    );
}

export default ProjectBoard;
