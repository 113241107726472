import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'flowbite-react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { loadReleases } from '../../redux';
import { Project, Release } from '../../models';
import { ReleasesList } from '..';
import { Icon, Select } from '..';

type LastReleasesFilter = {
    project?: Project;
}
export type { LastReleasesFilter };

interface LastReleasesProps {
    withHeader?: boolean;
}

function LastReleases(props: LastReleasesProps) {
    const { withHeader } = props;

    const [filters, setFilters] = useState<LastReleasesFilter>({});

    const { t }        = useTranslation();
    const { releases } = useAppSelector((state: RootState) => state.releases);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadReleases({
            'committed': true,
            'limit': 6,
            'project': filters.project?._id
        }));
    }, [dispatch, filters.project]);

    const sorted = useMemo(() => {
        return releases?.filter((r: Release) => {
            return true;
        }).sort((a: Release, b: Release) => {
            return a.date?.isAfter(b.date) ? -1 : 1;
        });
    }, [releases]);

    const projects = React.useMemo(() => {
        const map: AnyObj = {};
        releases.forEach((r: Release) => {
            if (r.project)
                map[r.project._id] = r.project;
        });

        return Object.values(map);
    }, [releases]);

    const filterOnProject = (projectId: string | number) => {
        setFilters({
            ...filters,
            project: projects.find((p: Project) => p._id === projectId)
        });
    };

    const projectOptions = projects.filter((p: Project) => p._id !== filters.project?._id).map((project: Project) => ({
        value: project._id,
        label: project.name,
        className: 'text-xl font-bold',
        leftContent: (
            <Avatar placeholderInitials={project.getInitials()} className="inline ml-2" />
        )
    }));
    projectOptions.unshift({
        value: '',
        label: t('dashboard.last_releases'),
        className: 'text-xl font-bold',
        leftContent: (
            <Avatar placeholderInitials={'..'} className="inline ml-2" />
        )
    });

    const content = (sorted.length > 0) ? (
        <ReleasesList releases={sorted} />
    ) : (
        <div className="flex justify-start items-center">
            <div className="bg-gray-50 text-center py-3 w-fit">
                <Icon type="thumbs-up" size={10} className="inline"/>&nbsp;
                {t('dashboard.no_releases_for_now')}
            </div>
        </div>
    );

    if (!withHeader)
        return content;

    return (
        <>
            <div className='p-1 px-3 bg-white dark:bg-black rounded-lg drop-shadow-md'>
                <div className="flex items-center border-b border-gray-300">
                    <h3 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                        <Icon type="release" size={7} className="inline"/>&nbsp;
                        <Select
                            onChange={filterOnProject}
                            placeholder={filters.project?.name}
                            options={projectOptions}
                            inline
                            className=""
                            optionsWidth={'w-[20rem]'}
                            optionsHeight={'max-h-96'}
                            selectedClassName="text-2xl font-bold text-gray-900"
                            optionsClassName="py-4 pl-14"
                        />
                    </h3>
                </div>
                <div className="py-3">
                    <div className='rounded-lg'>
                        {content}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LastReleases;
