import Client from './Client';
import _ from 'lodash';
import { BaseModel } from '../models';

type Model = {
    new (properties: AnyObj): BaseModel;
    getListingFields() : string[];
};

interface RestClientConfig {
    entryPoint: string;
    resource: string;
    resources: string;
    model: Model;
    id_field?: string;
    sortBy?: string | ((a: AnyObj, b: AnyObj) => number);
}

class RestClient extends Client {
    LIST_PATH: string;
    GET_PATH: string;
    UPDATE_PATH: string;
    CREATE_PATH: string;
    DELETE_PATH: string;

    entryPoint: string;
    resource: string;
    resources: string;
    model: Model;
    id_field: string;
    sortBy?: string | ((a: AnyObj, b: AnyObj) => number);

    constructor(config: RestClientConfig) {
        super();

        this.LIST_PATH   = '';
        this.GET_PATH    = '';
        this.UPDATE_PATH = '';
        this.CREATE_PATH = '';
        this.DELETE_PATH = '';

        this.entryPoint = config.entryPoint;
        this.resource   = config.resource;
        this.resources  = config.resources;
        this.model      = config.model;
        this.id_field   = config.id_field || 'id';
        this.sortBy     = config.sortBy;
    }

    list(params?: AnyObj) {
        // remove empty filter values
        params = _.pickBy(params, _.identity);

        // stringify fields
        const fields = (this.model.getListingFields() || []).join(',');

        return this.GET(`/${this.entryPoint}${this.LIST_PATH}`, { ...params, fields })
            .then(response => response.json())
            .then(json => {
                if (json[this.resources]) {
                    json[this.resources] = json[this.resources].map((r: AnyObj) => new this.model(r));
                    if (this.sortBy) {
                        if (typeof this.sortBy === 'string')
                            json[this.resources] = _.sortBy(json[this.resources], this.sortBy);
                        else
                            json[this.resources] = json[this.resources].sort(this.sortBy);
                    }

                    return json;
                }
                throw new Error(json.error || '');
            });
    }

    get(id: string) {
        return this.GET(`/${this.entryPoint}${this.GET_PATH}/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.error || '');
            });
    }

    create(payload: AnyObj, params?: AnyObj) {
        payload = new this.model(payload);

        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        let data = { [this.resource] : payload };
        if (params)
            data = { ...params, ...data };

        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, data)
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.error || '');
            });
    }

    update(payload: AnyObj, patch?: boolean) {
        if (!patch) {
            payload = new this.model(payload);
            if ('prepareForUpdate' in payload)
                payload = payload.prepareForUpdate();
        }

        return this.PUT(
                `/${this.entryPoint}${this.UPDATE_PATH}/${payload[this.id_field]}`,
                { [this.resource]: payload }
            ).then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.error || '');
            });
    }

    duplicate(payload: AnyObj) {
        payload = new this.model(payload);
        if ('prepareForDuplicate' in payload)
            payload = payload.prepareForDuplicate();
        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, { [this.resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.error || '');
            });
    }

    delete(id: string) {
        return this.DELETE(`/${this.entryPoint}${this.DELETE_PATH}/${id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.error)
                    throw new Error(json.error);

                return json;
            });
    }
}

export default RestClient;
export type { RestClientConfig };
