import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Board } from '../../models';
import { Column } from "./Column";
import { List } from "./List";
import type { Change, ColumnData } from '../../models';
import { Icon } from '..';
import { TextInput } from 'flowbite-react';

interface KanBanProps {
    board: Board;
    expanded?: boolean;
    showList?: boolean;
    onAddColumn: (columnTitle: string) => void;
    onEditColumn: (column: ColumnData) => void;
    onDuplicateColumn?: (column: ColumnData) => void;
    onRemoveColumn: (column: ColumnData) => void;
    onAddCard: (initial: AnyObj, boardId: string, columnId: string, showNew: boolean) => void;
    onEditCard: (change: Change) => void;
    onDragCard: (result: AnyObj) => void;
}

const KanBan = (props: KanBanProps) => {
    const addColumnInput = useRef<HTMLInputElement>(null);
    const [newColumnTitle, setNewColumnTitle] = useState("");

    const { t } = useTranslation();
    const {
        board, expanded, showList,
        onAddColumn, onEditColumn, onDuplicateColumn, onRemoveColumn,
        onAddCard, onEditCard, onDragCard,
    } = props;

    const addColumn = () => {
        if (newColumnTitle.length > 0)
            onAddColumn(newColumnTitle);

        // reset input
        setNewColumnTitle("");

        // focus on input
        if (addColumnInput.current)
            addColumnInput.current.focus();
    }

    const handleKeyPress = (e: AnyObj, enterCallback: () => void, escapeCallback?: () => void) => {
        if (e.key === 'Enter' || e.code === 'Enter')
            enterCallback();
        if (escapeCallback && (e.key === 'Escape' || e.code === 'Escape'))
            escapeCallback();
    }

    if(!board.columns.length)
        return (
            <div className="flex flex-col justify-center items-center w-full h-full bg-lightGrey dark:bg-veryDarkGrey">
                <h2 className="heading-lg text-mediumGrey font-light text-center">
                    {t('projects.empty_board')}<br/>
                    {t('projects.add_first_column')}
                </h2>
                <div className="flex align-start shrink-0 p-2 bg-gray-50 rounded-lg">
                    <TextInput
                        rightIcon={() => <Icon type="plus" />}
                        placeholder={t('projects.add_column') as string}
                        type="text"
                        onChange={e => setNewColumnTitle(e.target.value)}
                        onKeyUp={(e) => handleKeyPress(e, addColumn, () => setNewColumnTitle(""))}
                        value={newColumnTitle}
                        ref={addColumnInput}
                    />
                </div>
            </div>
        );

    if (showList)
        return (
            <List board={board} onEditCard={onEditCard} />
        );

    return (
        <main className="overflow-x-auto no-scrollbar h-full w-full">
            <div className={`h-full w-${expanded ? 'full' : 'fit'} overflow-y-hidden scrollbar-thin scrollbar-thumb-mainPurple scrollbar-track-transparent flex flex-1 space-x-4`}>
                <DragDropContext onDragEnd={onDragCard}>
                    <Droppable droppableId="board" type="column" direction="horizontal" >
                        {(provided: AnyObj) => (
                            <div className="w-full flex min-h-[calc(100vh-14rem)] items-start space-x-2 " {...provided.droppableProps} ref={provided.innerRef}>
                                {board.columns.filter((column: ColumnData) => !column.isSmart || !column.rules.hideIfEmpty || column.changes.length > 0).map((column: ColumnData, i) => (
                                    <Column
                                        key={column._id}
                                        index={i}
                                        column={column}
                                        expanded={expanded}
                                        onEditColumn={onEditColumn}
                                        onDuplicateColumn={onDuplicateColumn}
                                        onRemoveColumn={onRemoveColumn}
                                        onAddCard={(initial: AnyObj, showNew: boolean) => onAddCard(initial, board._id, column._id, showNew)}
                                        onEditCard={onEditCard}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {board.acceptNewColumns && (
                <div className="flex align-start w-[280px] h-[60px] shrink-0 p-2 border rounded-lg">
                    <TextInput
                        rightIcon={() => <Icon type="plus" />}
                        placeholder={t('projects.add_column') as string}
                        type="text"
                        onChange={e => setNewColumnTitle(e.target.value)}
                        onKeyUp={(e) => handleKeyPress(e, addColumn, () => setNewColumnTitle(""))}
                        value={newColumnTitle}
                        className="w-full"
                    />
                </div>
                )}
            </div>
        </main>
    );
}

export default KanBan
