import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { resetReport, resetTracks, loadTracks, loadUsers, loadProjects, loadClients, getReport } from '../../redux';
import { ChangesList, Intervalpicker, ClientSelectField, Loader, Button, Icon, Select } from '../';
import { Tabs } from 'flowbite-react';
import { Change, Client, User, Project, Sprint, Goal } from '../../models';
import { Tracks } from './Tracks';
import { Stats } from './Stats';
import { Report } from './Report';
import moment from 'moment';
import type { Moment } from 'moment';
import type { Interval } from '../../components';

type ReportingProps = {
    user?: User;
    project?: Project;
    client?: Client;
    sprint?: Sprint;
    goal?: Goal;
    from?: Moment;
    to?: Moment;
    tabs?: string[];
    changes?: Change[];
};

type Filters = {
    user?: User;
    project?: Project;
    client?: Client;
    sprint?: Sprint;
    goal?: Goal;
    from: Moment;
    to: Moment;
};

function Reporting(props: ReportingProps) {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState<Filters>({
        user: props.user,
        project: props.project,
        client: props.client,
        sprint: props.sprint,
        goal: props.goal,
        from: props.from || moment().startOf('day'),
        to: props.to || moment().endOf('day')
    });

    const tabs = props.tabs || ['tracks', 'stats', 'report'];

    const { users }    = useAppSelector((state: RootState) => state.users);
    const loadingUsers = useAppSelector((state: RootState) => state.users.loading);

    const { user }                      = useAppSelector((state: RootState) => state.auth);
    const { clients }                   = useAppSelector((state: RootState) => state.clients);
    const { projects }                  = useAppSelector((state: RootState) => state.projects);
    const { report }                    = useAppSelector((state: RootState) => state.statistics);
    const { tracks, updating, loading } = useAppSelector((state: RootState) => state.tracks);

    useEffect(() => {
        if (loadingUsers !== 'pending') {
            dispatch(loadUsers());
            dispatch(loadProjects());
            dispatch(loadClients());
        }
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(resetTracks());
            dispatch(loadTracks({
                author: filters.user?._id,
                project: filters.project?._id,
                client: filters.client?._id,
                sprint: filters.sprint?._id,
                goal: filters.goal?._id,
                from: filters.from.toISOString(),
                to: filters.to.toISOString()
            }));
            dispatch(resetReport());
            dispatch(getReport({
                user: filters.user?._id,
                project: filters.project?._id,
                client: filters.client?._id,
                sprint: filters.sprint?._id,
                goal: filters.goal?._id,
                from: filters.from.toISOString(),
                to: filters.to.toISOString(),
            }));
        }
    }, [dispatch, user, filters]);

    const applyFilters = (value: Interval) => {
        setFilters({
            ...filters,
            from: value?.startDate.startOf('day'),
            to: value?.endDate.endOf('day')
        });
    };

    const onChangedUser = (userId?: string | number) => {
        setFilters({
            ...filters,
            user: users?.find((u: User) => u._id === userId)
        });
    };

    const onChangedProject = (projectId?: string | number) => {
        setFilters({
            ...filters,
            project: projects?.find((p: Project) => p._id === projectId)
        });
    };

    const onChangedClient = (clientId?: string | number) => {
        setFilters({
            ...filters,
            client: clients?.find((p: Client) => p._id === clientId)
        });
    };

    const trackableUsers = React.useMemo(() => {
        const trackable = users?.filter((u: User) => {
            if (filters.sprint && !filters.sprint.resources.map((r: AnyObj) => r.member._id).includes(u._id))
                return false;

            return u._id !== user?._id && [User.ROLE_ADMIN, User.ROLE_USER].includes(u.role);
        }).sort((a: User, b: User) => a.fullname().localeCompare(b.fullname()));
        trackable.unshift(user);

        return trackable;
    }, [users, user]);

    const userOptions = React.useMemo(() => trackableUsers.map((user: User) => ({
        value: user._id,
        label: user.fullname()
    })), [trackableUsers]);

    const projectOptions = React.useMemo(() => projects?.filter((project: Project) => {
        if (!props.goal?.projects)
            return true;

        return props.goal.projects.map((p: Project) => p._id).includes(project._id);
    }).map((project: Project) => ({
        value: project._id,
        label: project.name
    })), [projects]);

    return (
        <div className="Reporting">
            <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start space-y-2 space-x-0 lg:space-x-2 lg:space-y-0">
                {!filters.sprint && !filters.goal && (
                <div className="flex-1 w-full">
                    <Intervalpicker
                        value={{ startDate: filters.from, endDate: filters.to }}
                        inputClassName="w-full rounded-md focus:ring-0 border-gray-900 font-bold bg-blue-100 dark:bg-blue-900 dark:placeholder:text-blue-100 placeholder:text-sm"
                        toggleClassName="absolute bg-gray-900 border-1 border-gray-400 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                        separator={"->"}
                        onChange={applyFilters}
                        maxDate={moment()}
                        direction='down'
                    />
                </div>
                )}
                {user?.can('track:see_other') && (
                    <div className="flex-1 flex w-full">
                        <Select
                            defaultValue={filters.user?._id}
                            options={userOptions}
                            placeholder={t('tracks.select_user') || ''}
                            onChange={onChangedUser}
                            className="!mt-0 !w-full border border-gray-400 rounded-lg rounded-r-none"
                            containerClassName="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-md"
                            selectedClassName="h-[26px] font-bold flex items-center"
                            inline
                        />
                        <div className="flex flex-0 h-[44px] w-[45px] bg-gray-900 shadow-md border border-gray-400 border-l-0 rounded-lg rounded-l-none">
                            <Button
                                title=""
                                icon={filters.user ? 'close' : 'user'}
                                color="none"
                                iconColor="white"
                                className="shadow-none"
                                onClick={() => onChangedUser(undefined)}
                            />
                        </div>
                    </div>
                )}
                {!props.project && (!props.goal || props.goal.projects?.length > 1) && (
                    <div className="flex-1 flex w-full">
                        <Select
                            defaultValue={filters.project?._id}
                            options={projectOptions}
                            placeholder={t('tracks.select_project') || ''}
                            onChange={onChangedProject}
                            className="!mt-0 !w-full border border-gray-400 rounded-lg rounded-r-none"
                            containerClassName="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-md"
                            selectedClassName="h-[26px] font-bold flex items-center"
                            inline
                        />
                        <div className="flex flex-0 h-[44px] w-[45px] bg-gray-900 shadow-md border border-gray-400 border-l-0 rounded-lg rounded-l-none">
                            <Button
                                title=""
                                icon={filters.project ? 'close' : 'project'}
                                color="none"
                                iconColor="white"
                                className="shadow-none"
                                onClick={() => onChangedProject(undefined)}
                            />
                        </div>
                    </div>
                )}
                {!props.goal && (
                    <div className="flex-1 flex w-full">
                        <Formik
                            initialValues={filters}
                            enableReinitialize
                            onSubmit={(values, { setSubmitting }) => {}}
                        >
                            <ClientSelectField
                                name="client"
                                onChange={(client?: Client) => { onChangedClient(client?._id) }}
                                className="!mt-0 !w-full !p-0 border border-gray-400 rounded-lg rounded-r-none"
                                selectedClassName="!text-gray-900 !bg-white !text-sm font-bold"
                                searchInputClassName="h-[42px] !ring-0"
                                resultsClassName="!mt-0 !rounded-none"
                                value={filters.client}
                            />
                        </Formik>
                        <div className="flex flex-0 h-[44px] w-[45px] bg-gray-900 shadow-md border border-gray-400 border-l-0 rounded-lg rounded-l-none">
                            <Button
                                title=""
                                icon={filters.client ? 'close' : 'client'}
                                color="none"
                                iconColor="white"
                                className="shadow-none"
                                onClick={() => onChangedClient(undefined)}
                            />
                        </div>
                    </div>
                )}
            </div>
            {((loading === 'pending' as string) || (updating === 'pending' as string)) && <Loader /> }
            <Tabs className="mt-2">
                {tabs.map((tab: string) => ({
                    'tracks': (
                        <Tabs.Item key={tab} icon={() => <Icon type="list" className="mr-1.5 outline-none" />} title={t('report.tracking')}>
                            {!tracks?.length ? (
                                <div className="my-4 flex flex-col justify-center items-center">
                                    <Icon type="sad" size={24} color="gray-300" />
                                    <span className="text-gray-400 italic">{t('tracks.nothing_found')}</span>
                                </div>
                            ) : (
                                <Tracks tracks={tracks} />
                            )}
                        </Tabs.Item>
                    ),
                    'stats': (
                        <Tabs.Item key={tab} icon={() => <Icon type="chart" className="mr-1.5 outline-none" />} title={t('report.stats')}>
                            <Stats tracks={tracks} report={report} />
                        </Tabs.Item>
                    ),
                    'report': (
                        <Tabs.Item key={tab} icon={() => <Icon type="report" className="mr-1.5 outline-none" />} title={t('report.report')}>
                            <Report report={report} />
                        </Tabs.Item>
                    ),
                    'changes': (
                        <Tabs.Item key={tab} icon={() => <Icon type="feature" className="mr-1.5 outline-none" />} title={t('report.changes_tab')}>
                            <ChangesList
                                // Filter by status and priority
                                changes={props.changes?.sort((a: Change, b: Change) => {
                                    if (a.statusIndex() === b.statusIndex()) {
                                        return b.priority - a.priority; // Inverse the order of priority
                                    }
                                    return a.statusIndex() - b.statusIndex();
                                }) || []}
                                withHeader
                            />
                        </Tabs.Item>
                    )
                }[tab]))}
            </Tabs>
        </div>
    );
}

export default Reporting;
