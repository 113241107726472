import React from 'react';
import { useTranslation } from 'react-i18next';
import { Release } from '../../models';
import { Link } from "react-router-dom";
import { Avatar, Badge, Table } from 'flowbite-react';
import { Icon } from '..';

interface ReleasesListProps {
    releases: Release[];
}

const condensedTdTheme = {
    "base": "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-2 py-0"
};

function ReleasesList(props: ReleasesListProps) {
    const { releases } = props;

    const { t } = useTranslation();

    return (
        <div className="w-100 overflow-x-auto">
            <Table hoverable>
                <Table.Head>
                    <Table.HeadCell>{t('projects.name')}</Table.HeadCell>
                    <Table.HeadCell className="md:w-[150px] text-center">{t('projects.release_version')}</Table.HeadCell>
                    <Table.HeadCell className="md:w-[150px] text-center">{t('projects.release_date')}</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {releases?.map((release: Release) => (
                    <Table.Row key={release?._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell theme={condensedTdTheme} className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <div className="flex flex-row items-center space-x-2">
                                <Avatar img={release.project?.getAvatarUrl(30)} size="sm" className="inline my-2 w-[30px]" />
                                <Link to={`/projects/${release.project?._id}/release/${release._id}`} className="flex gap-x-3">
                                    <div className="flex-row">
                                        {release.project?.name}
                                    </div>
                                </Link>
                            </div>
                        </Table.Cell>
                        <Table.Cell theme={condensedTdTheme}>
                            <Badge color="green" size="xs" className="w-fit md:w-full">
                                <span className="font-normal flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-1">
                                    <Icon type="release" color="green" />
                                    <span>{release.version}</span>
                                </span>
                            </Badge>
                        </Table.Cell>
                        <Table.Cell theme={condensedTdTheme} className="text-center whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <div className="flex rounded-b-lg">
                                <Badge color="indigo" size="xs" className="w-fit md:w-full">
                                    <span className="font-normal flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-1">
                                        <Icon type="date" color="indigo" />
                                        <span>{release.date?.format('LLL')}</span>
                                    </span>
                                </Badge>
                            </div>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
        </div>
    );
}

export default ReleasesList;
