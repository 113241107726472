import React from 'react';
import { Alert as FBAlert} from 'flowbite-react';
import { Icon } from '..';
import type { IconType } from '../Icon';

interface AlertProps {
    title: string
    color?: 'info' | 'failure' | 'success' | 'warning'
    icon: IconType
}

const iconColorMap = {
    info: 'blue',
    failure: 'red',
    success: 'green',
    warning: 'yellow',
};

function Alert(props: AlertProps) {
    const { title, color, icon } = props;
    return (
        <FBAlert color={color}>
            <span className="font-medium flex">
                <Icon type={icon} color={iconColorMap[color || 'info']} />&nbsp;
                {title}
            </span>
        </FBAlert>
    );
}

export default Alert;
