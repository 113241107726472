import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Alert, TextInput } from 'flowbite-react';
import { HiExclamation} from 'react-icons/hi';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { getAuthMethods, authenticate, getOpenIdUrl } from '../../redux';
import type { Credentials } from '../../services';
import { Button, Icon } from '../../components';
import { Config } from '../../config';
import './Login.css';

function Login() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { authMethods, isLoggingIn, logInErrorMessage, isLoadingOpenidUrl } = useAppSelector((state : RootState) => state.auth);

    useEffect(() => {
        dispatch(getAuthMethods());
    }, []);

    const submit = function (values: Credentials) : void {
        dispatch(authenticate(values));
    }

    const getSsoRedirectUrl = () => {
        dispatch(getOpenIdUrl((err: Error | null, url?: string) => {
            if (url)
                window.location.href = url;
        }));
    }

    const emailFieldComponent = (props: AnyObj) => (
        <TextInput
            type="email"
            autoComplete="off"
            icon={() => <Icon type="email" />}
            {...props}
            theme={{ "field": { "input": { "withAddon": { "off": "rounded-sm" }}}}}
            className="border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-cyan-600 focus:border-cyan-600"
        />
    );

    const passwordFieldComponent = (props: AnyObj) => (
        <TextInput
            type="password"
            autoComplete="off"
            icon={() => <Icon type="lock" />}
            {...props}
            theme={{ "field": { "input": { "withAddon": { "off": "rounded-sm" }}}}}
            className="border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-cyan-600 focus:border-cyan-600"
        />
    );

    document.title = `Yoda - ${t('login.sign_in')}`;

    return (
        <div className="Login">
            <div className="mx-auto md:h-screen flex flex-col justify-center items-center px-6 pt-8 pt:mt-0">
                <div className="bg-white shadow rounded-sm md:mt-0 w-full sm:max-w-screen-sm xl:p-0">
                    <div className="p-6 pb-0 sm:p-8 lg:p-16 lg:pb-0">
                        <a href="/" className="text-2xl font-semibold flex justify-center items-center mb-8 lg:mb-10 hover:no-underline">
                            <img src={ logo } className="h-10 mr-4" alt="Logo" />
                            <span className="self-center text-4xl font-bold whitespace-nowrap">Yoda</span>
                        </a>
                        { logInErrorMessage && (
                            <Alert color="failure" icon={HiExclamation}>
                                <span>
                                    <span className="font-medium">
                                        {logInErrorMessage}
                                    </span>
                                </span>
                            </Alert>
                        )}
                        {authMethods?.password && (
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                if (!values.email) {
                                    return { email: t('login.email_is_required') };
                                }
                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    return { email: t('login.email_is_invalid') };
                                }
                                return {};
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                submit(values);
                            }}
                        >
                             {({ isSubmitting }) => (
                                 <Form className="mt-8 space-y-4">
                                    <div>
                                        <label className="text-sm font-medium text-gray-900 block mb-2">
                                            { t('login.email') }
                                        </label>
                                        <Field as={emailFieldComponent} name="email" required />
                                        <ErrorMessage name="email" component="div" />
                                    </div>
                                    <div>
                                        <label className="text-sm font-medium text-gray-900 block mb-2">
                                            { t('login.password') }
                                        </label>
                                        <Field as={passwordFieldComponent} name="password" required />
                                        <ErrorMessage name="password" component="div" />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <Button
                                            className="rounded-sm"
                                            icon="user"
                                            type="submit"
                                            color="secondary"
                                            loading={isLoggingIn}
                                            title={t('login.sign_in')}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        )}
                        {authMethods?.openid && (
                            <div className="mt-4">
                                {authMethods.password && (
                                    <div className="flex justify-center items-center h-fit space-x-3 mt-3 mb-2">
                                        <hr className="flex-1 mb-0" />
                                        <span className="text-sm font-light">
                                            {t('login.or_login_with')}
                                        </span>
                                        <hr className="flex-1 mb-0" />
                                    </div>
                                )}
                                <div className="flex justify-center items-center">
                                    <Button
                                        className="rounded-sm"
                                        icon={isLoadingOpenidUrl ? 'loading' : 'sso'}
                                        color="primary"
                                        loading={isLoggingIn}
                                        title={t('login.sso')}
                                        onClick={getSsoRedirectUrl}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-5 mb-5 flex justify-center">
                        <small>v.{Config.app_version}</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
