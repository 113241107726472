import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Ability, Button, Icon, Tooltip } from "../..";
import { Change } from '../../../models';
import { ColumnModal } from './ColumnModal';
import type { ColumnData } from '../../../models';
import { Badge, TextInput, Dropdown } from 'flowbite-react';
import { Card } from "../Card";

interface ColumnProps {
    column: ColumnData;
    expanded?: boolean;
    index: number;
    onEditColumn: (column: ColumnData) => void;
    onDuplicateColumn?: (column: ColumnData) => void;
    onRemoveColumn: (column: ColumnData) => void;
    onAddCard: (initial: AnyObj, showNew: boolean) => void;
    onEditCard: (change: Change) => void;
}

const Column = (props: ColumnProps) => {
    const { column, index, expanded, onEditColumn, onDuplicateColumn, onRemoveColumn, onAddCard, onEditCard } = props;

    const { t } = useTranslation();

    const addCardInput = useRef<HTMLInputElement>(null);

    const [edit, setEdit]                 = useState(false);
    const [newCardTitle, setNewCardTitle] = useState("");

    const addCard = (showNew: boolean) => {
        if (newCardTitle.length > 0) {
            let title = newCardTitle;
            let type  = Change.TYPE_NONE; // non dev task
            if (newCardTitle.match(/feat: (.+)/)) {
                title = newCardTitle.substring(6);
                type  = Change.TYPE_FEATURE;
            } else if (newCardTitle.match(/fix: (.+)/)) {
                title = newCardTitle.substring(5);
                type  = Change.TYPE_FIX;
            } else if (newCardTitle.match(/ui: (.+)/)) {
                title = newCardTitle.substring(4);
                type  = Change.TYPE_UI;
            } else if (newCardTitle.match(/i18n: (.+)/)) {
                title = newCardTitle.substring(6);
                type  = Change.TYPE_I18N;
            }
            const initial: AnyObj = {
                type: type,
                title: {
                    fr: title,
                    en: title,
                    de: title
                }
            };
            onAddCard(initial, !!showNew);

            //reset input
            setNewCardTitle("");

            // focus on input
            if (addCardInput.current)
                addCardInput.current.focus();
        }
    }

    const handleKeyPress = (e: AnyObj, enterCallback: (shift: boolean) => void, escapeCallback?: () => void) => {
        if (e.key === 'Enter' || e.code === 'Enter')
            enterCallback(!e.shiftKey);
        if (escapeCallback && (e.key === 'Escape' || e.code === 'Escape'))
            escapeCallback();
    }

    const doneEstimationBadge = (column: ColumnData) => {
        const doneStatus = [
            Change.STATUS_FINISHED,
            Change.STATUS_DEPLOYED,
            Change.STATUS_PENDING_DEPLOYMENT
        ];

        const done = column?.changes?.filter((change: Change) => {
            return doneStatus.includes(change.progress?.status);
        }).reduce((memo, change) => memo + Number(change.estimate), 0);

        if (done === 0)
            return null;

        return(
            <Tooltip content={t('changes.done_estimation', {done})}>
                <div>
                    <Badge color="success" className="ml-1 w-fit whitespace-nowrap">
                        <Icon type="check" size={3} className="inline"/>&nbsp;
                        {done} {t('changes.estimate_units_min')}
                    </Badge>
                </div>
            </Tooltip>
        );
    }

    const totalEstimationBadge = (column: ColumnData) => {
        const total = column?.changes?.reduce((memo, change: Change) => memo + Number(change.estimate), 0);

        if (total === 0)
            return null;

        return (
            <Tooltip content={t('changes.total_estimation', {total})}>
                <div>
                    <Badge color="warning" className="ml-1 w-fit whitespace-nowrap">
                        <Icon type="estimated" size={3} className="inline"/>&nbsp;
                            {total} {t('changes.estimate_units_min')}
                    </Badge>
                </div>
            </Tooltip>
        );
    }

    return (
        <Draggable draggableId={column._id} index={index}>
            {(provided: AnyObj, snapshot: AnyObj) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={expanded ? 'w-full' : 'w-[280px]'}
                >
                    <div className={`h-full p-1 ${snapshot?.isDragging && !snapshot?.isDropAnimating ? 'opacity-80 shadow rotate-[5deg]' : ''} ${expanded ? '' : 'flex flex-col justify-between bg-' + (column.isSmart ? 'indigo-200' : 'blue-100') + ' border rounded-lg'} overflow-y-auto max-h-[100vh] md:max-h-[calc(100vh-14em)]`}>
                        <div {...provided.dragHandleProps}>
                            <div className={`flex flex-col space-y-1 font-bold p-1 rounded ${expanded ? 'bg-blue-100' : 'bg-gray-50'}`}>
                                <div className="flex w-full justify-between items-start">
                                    <div className="flex space-x-1 items-center">
                                        <Icon type={column.isSmart ? 'magic' : 'column'} />
                                        <span>{column.title}</span>
                                    </div>
                                    {column.editable && (
                                        <Tooltip content={t('projects.column_settings')}>
                                            <div>
                                                <Dropdown label="" placement="bottom" dismissOnClick={false} renderTrigger={() => (
                                                    <div>
                                                        <Icon type="menu" className="hover:bg-gray-200 p-1 rounded" size={6} />
                                                    </div>
                                                )}>
                                                    <Dropdown.Item onClick={() => setEdit(true)} className="font-medium text-xs">
                                                        <Icon type="edit" className="mr-1.5" size={3} />
                                                        {t('projects.edit_this_column')}
                                                    </Dropdown.Item>
                                                    {column.isSmart && onDuplicateColumn && (
                                                        <Dropdown.Item onClick={() => onDuplicateColumn(column)} className="font-medium text-xs">
                                                            <Icon type="copy" className="mr-1.5" size={3} />
                                                            {t('projects.duplicate_this_column')}
                                                        </Dropdown.Item>
                                                    )}
                                                    {(column.changes.length === 0 || column.isSmart) && (
                                                        <>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item onClick={() => onRemoveColumn(column)} className="font-medium text-xs text-red-500">
                                                                <Icon type="delete" className="mr-1.5" color="red-500" size={3} />
                                                                {t('projects.delete_this_column')}
                                                            </Dropdown.Item>
                                                        </>
                                                    )}
                                                </Dropdown>
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                                <div className="flex w-full justify-start items-center">
                                    <Tooltip content={t('projects.done_card')}>
                                        <div>
                                            <Badge color="light" className="w-fit whitespace-nowrap">
                                                <Icon type="list" size={3} className="inline mr-1"/>&nbsp;
                                                {column.changes?.length}
                                            </Badge>
                                        </div>
                                    </Tooltip>
                                    {column.changes.length > 0 && (
                                        <>
                                            {totalEstimationBadge(column)}
                                            {doneEstimationBadge(column)}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className={`mt-2 ${expanded ? '' : 'h-full no-scrollbar overflow-y-auto overscroll-none bg-' + (column.isSmart ? 'indigo-100' : 'blue-50' ) + ' rounded-lg p-1 pb-0 shadow'}`}>
                            {column.isSmart ? (
                                <ul className={`${expanded ? 'grid grid-cols-4 gap-1' : 'h-full min-h-[70px] flex flex-col'}`}>
                                    {column.changes.map((change: Change, j: number) => (
                                        <Card
                                            key={change._id}
                                            change={change}
                                            expanded={expanded}
                                            onEdit={onEditCard}
                                            index={j}
                                            isSmart
                                        />
                                    ))}
                                </ul>
                            ) : (
                                <Droppable droppableId={column._id} type="card">
                                    {(provided: AnyObj) => (
                                        <ul className={`${expanded ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1' : 'h-full min-h-[70px] flex flex-col'}`} {...provided.droppableProps} ref={provided.innerRef}>
                                            {column.changes.map((change: Change, j: number) => (
                                                <Card
                                                    key={change._id}
                                                    change={change}
                                                    expanded={expanded}
                                                    onEdit={onEditCard}
                                                    index={j}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            )}
                        </div>

                        {column.acceptNewCards && (
                            <Ability can="change:create">
                                <div className="flex align-start shrink-0 mt-2 p-1 bg-gray-50 rounded-lg">
                                    <Tooltip content={t('projects.open_created_card_with_shift')}>
                                        <TextInput
                                            rightIcon={() => newCardTitle.length ? null : <Icon type="plus" />}
                                            placeholder={t('projects.add_card') as string}
                                            title={t('projects.add_card') as string}
                                            type="text"
                                            onChange={e => setNewCardTitle(e.target.value)}
                                            onKeyUp={e => handleKeyPress(e, addCard, () => setNewCardTitle(""))}
                                            value={newCardTitle}
                                            ref={addCardInput}
                                            className={`w-full ${newCardTitle.length > 0 ? 'noBorder' : ''}`}
                                        />
                                    </Tooltip>
                                    {newCardTitle.length > 0 && (
                                        <Button
                                            title=''
                                            icon='check'
                                            textColor='green-600'
                                            color='none'
                                            onClick={() => addCard(false)}
                                            className="border-none outline-none sm:hidden"
                                            small
                                        />
                                    )}
                                </div>
                            </Ability>
                        )}
                    </div>
                    {edit && (
                        <ColumnModal
                            column={column}
                            show={edit}
                            onClose={() => setEdit(false)}
                            onSave={onEditColumn}
                        />
                    )}
                </div>
            )}
        </Draggable>
    )
};

export default Column;
