import { Users } from '../../services';
import { Status, createRestSlices, applyReducers, Callback } from './rest';
import { ActionPayload } from '..';
import { Dispatch } from 'redux';
import { User } from '../../models';

const UPDATING_FAVORITES           = 'yoda/users/UPDATING_FAVORITES';
const UPDATING_FAVORITES_SUCCESS   = 'yoda/users/UPDATING_FAVORITES_SUCCESS';
const UPDATING_FAVORITES_FAILURE   = 'yoda/users/UPDATING_FAVORITES_FAILURE';
const UPDATING_FAVORITES_RESET     = 'yoda/users/UPDATING_FAVORITES_RESET';

const SET_AVATAR           = 'yoda/users/SET_AVATAR';
const SET_AVATAR_SUCCESS   = 'yoda/users/SET_AVATAR_SUCCESS';
const SET_AVATAR_FAILURE   = 'yoda/users/SET_AVATAR_FAILURE';

const {
    initialState,
    createReducer, startCreateReducer,
    getReducer, listReducer,
    updateReducer, deleteReducer,
    createAction, startCreateAction,
    getAction, listAction,
    updateAction, deleteAction,
} = createRestSlices(Users);

initialState.usersUpdate      = 'idle' as Status;
initialState.usersUpdateError = null;

const usersUpdateReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case UPDATING_FAVORITES:
            return {
                ...state,
                usersUpdate: 'pending',
                usersUpdateError: null
            };
        case UPDATING_FAVORITES_SUCCESS:
            return {
                ...state,
                usersUpdate: 'succeeded',
                usersUpdateError: null,
                user: action.user,
                users: (state.users || []).map((u: User) => {
                    if (u._id === action.user?._id)
                        return action.user;
                    return u;
                })
            };
        case UPDATING_FAVORITES_FAILURE:
            return {
                ...state,
                usersUpdate: 'failed',
                usersUpdateError: action.error
            };
        case UPDATING_FAVORITES_RESET:
            return {
                ...state,
                usersUpdate: 'idle',
                syncuserId: null,
                usersUpdateError: null
            };
        default:
            return state;
    }
};

const avatarReducer = (state = initialState, action: ActionPayload) => {
    switch (action.type) {
        case SET_AVATAR:
            return {
                ...state,
                usersUpdate: 'pending',
                usersUpdateError: null
            };
        case SET_AVATAR_SUCCESS:
            return {
                ...state,
                usersUpdate: 'succeeded',
                usersUpdateError: null,
                user: action.user,
                users: (state.users || []).map((u: User) => {
                    if (u._id === action.user?._id)
                        return action.user;
                    return u;
                })
            };
        case SET_AVATAR_FAILURE:
            return {
                ...state,
                usersUpdate: 'failed',
                usersUpdateError: action.error
            };
        default:
            return state;
    }
};
/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action: ActionPayload) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer, usersUpdateReducer,
        avatarReducer
    ]);
}

const addFavoriteProjectAction = (projects: string[], callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: UPDATING_FAVORITES});
        return Users.addFavoriteProjects(projects).then((data: Response) => {
            const user = data;
            dispatch({type: UPDATING_FAVORITES_SUCCESS, user});
            setTimeout(() => {
                dispatch({type:UPDATING_FAVORITES_RESET});
            }, 100);
            callback && callback(/*err*/null);
        })
        .catch((error: Error) => {
            dispatch({type: UPDATING_FAVORITES_FAILURE, error: error.message});
            setTimeout(() => {
                dispatch({type: UPDATING_FAVORITES_RESET});
            }, 100);
            callback && callback(error);
        });
    }
}

const removeFavoriteProjectsAction = (projects: string[], callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: UPDATING_FAVORITES});
        return Users.removeFavoriteProjects(projects)
            .then((data: Response) => {
                const user = data;
                dispatch({type: UPDATING_FAVORITES_SUCCESS, user});
                setTimeout(() => {
                    dispatch({type: UPDATING_FAVORITES_RESET});
                }, 100);
                callback && callback(/*err*/null);
            })
            .catch((error: Error) => {
                dispatch({type: UPDATING_FAVORITES_FAILURE, error: error.message});
                setTimeout(() => {
                    dispatch({type: UPDATING_FAVORITES_RESET});
                }, 100);
                callback && callback(error);
            });
    }
};

const setAvatarAction = (id: string, file: File, callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: SET_AVATAR});
        return Users.setAvatar(id, file).then((data: AnyObj) => {
            const { user } = data;
            dispatch({type: SET_AVATAR_SUCCESS, user});
            callback && callback(/*err*/null);
        })
        .catch((error: Error) => {
            dispatch({type: SET_AVATAR_FAILURE, error: error.message});
            callback && callback(error);
        });
    }
}

const unsetAvatarAction = (id: string, callback?: Callback) => {

    return (dispatch: Dispatch) => {
        dispatch({type: SET_AVATAR});
        return Users.unsetAvatar(id).then((data: AnyObj) => {
            const { user } = data;
            dispatch({type: SET_AVATAR_SUCCESS, user});
            callback && callback(/*err*/null);
        })
        .catch((error: Error) => {
            dispatch({type: SET_AVATAR_FAILURE, error: error.message});
            callback && callback(error);
        });
    }
}

/* Export CRUD actions */
export const createUser             = createAction;
export const startCreateUser        = startCreateAction;
export const loadUser               = getAction;
export const loadUsers              = listAction;
export const updateUser             = updateAction;
export const deleteUser             = deleteAction;
export const addFavoriteProjects    = addFavoriteProjectAction;
export const removeFavoriteProjects = removeFavoriteProjectsAction;
export const setUserAvatar          = setAvatarAction;
export const unsetUserAvatar        = unsetAvatarAction;
