import _ from 'lodash';
import BaseModel from './BaseModel';
import Change from './Change';
import ChecklistItem from './ChecklistItem';
import Project from './Project';
import User from './User';

/** Class representing a Checklist. */
class Checklist extends BaseModel {

    _id       : string;
    name      : string;
    items    ?: ChecklistItem[];
    change   ?: Change;
    project  ?: Project;
    assignee ?: User;
    createdAt : Date;

    constructor(properties: AnyObj) {
        super({});

        this._id        = properties._id;
        this.name       = properties.name;
        this.items      = properties.items || [];
        this.createdAt  = properties.createdAt;
        this.items      = properties.items.map((item: ChecklistItem) => new ChecklistItem(item));
        if (properties.change)
            this.change = new Change(_.isString(properties.change) ?
                { _id: properties.change } :
                properties.change
            );
        if (properties.project)
            this.project = new Project(properties.project);
        if (properties.assignee)
            this.assignee = new User(properties.assignee);
    }

    /**
     * Get a value betwwen 0 and 100 representing the
     * progression of this checklist,
     *
     * @return {Number}
     */
    progression() : number {
        if (!this.items || !(this.items?.length > 0))
            return 0;

        const done = this.items.filter((i: ChecklistItem) => i.done);
        if (done.length === 0)
            return 0;

        return (done.length * 100 / this.items.length);
    }
}
export default Checklist;
