import Client from './Client';
import {Change, Project} from "../models";

class Search extends Client {
    search(query: string, lang: string) {
        return this.GET(`/search/${encodeURIComponent(query)}?lang=${encodeURIComponent(lang)}`)
            .then(response => response.json())
            .then(json => {
                if (json.projects)
                    json.projects = json.projects.map((p: AnyObj) => new Project(p))
                if (json.changes)
                    json.changes = json.changes.map((c: AnyObj) => new Change(c))
                return json;
            })
            .catch(error => {
                console.error('Search API error:', error);
                throw error;
            });
    }
}

const SearchApi = new Search();

export default SearchApi;
