import RestClient from './RestClient';
import { Comment } from '../models';

class Comments extends RestClient {
    constructor() {
        super({
            // rest config
            model: Comment,
            entryPoint: 'comments',
            sortBy: 'createdAt',
            id_field: '_id',
            // redux config
            resource: 'comment',
            resources: 'comments',
        });
    }
}

const CommentsApi = new Comments();
export default CommentsApi;
