import _ from 'lodash';
import { Attachments, Projects, Changes } from '../services';
import BaseModel from './BaseModel';
import User from './User';
import Change from './Change';
import moment from 'moment';
import type { Moment } from 'moment';

class Attachment extends BaseModel {
    _id: string;
    name: string;
    type: string;
    size: number;
    change?: Change;
    project: string;
    author?: User;
    createdAt?: Moment;
    updatedAt?: Moment;

    static MIME_TYPES_BMP  = "image/bmp";
    static MIME_TYPES_GIF  = "image/gif";
    static MIME_TYPES_JPG  = "image/jpeg";
    static MIME_TYPES_PNG  = "image/png";
    static MIME_TYPES_WEBP = "image/webp";
    static MIME_TYPES_SVG  = "image/svg+xml";
    static MIME_TYPES_TIF  = "image/tiff";

    constructor(properties: AnyObj) {
        super({})
        this._id       = properties._id;
        this.name      = properties.name;
        this.type      = properties.type;
        this.size      = properties.size;;
        this.change    = properties.change ? new Change(properties.change) : undefined;
        this.project   = properties.project;
        this.author    = properties.author ? new User(properties.author) : undefined;
        this.createdAt = properties.createdAt ? moment(properties.createdAt) : undefined;
        this.updatedAt = properties.updatedAt ? moment(properties.updatedAt) : undefined;
    }

    downloadUrl() {
        return [
            Attachments.baseUrl,
            Attachments.entryPoint,
            this._id
        ].join('/');
    }

    static createChangeAttachmentUrl(id: string) {
        return [
            Changes.baseUrl,
            Changes.entryPoint,
            id,
            Attachments.entryPoint
        ].join('/');
    }

    static createBugReportAttachmentUrl(id: string) {
        return [
            Changes.baseUrl,
            'support',
            id,
            Attachments.entryPoint
        ].join('/');
    }

    static loadBugReportAttachmentUrl(id: string) {
        return [
            Changes.baseUrl,
            'support',
            id,
            Attachments.entryPoint
        ].join('/');
    }

    static createProjectAttachmentUrl(id: string) {
        return [
            Projects.baseUrl,
            Projects.entryPoint,
            id,
            Attachments.entryPoint
        ].join('/');
    }

    isPicture() {
        switch(this.type) {
            case Attachment.MIME_TYPES_JPG:
            case Attachment.MIME_TYPES_GIF:
            case Attachment.MIME_TYPES_WEBP:
            case Attachment.MIME_TYPES_PNG:
            case Attachment.MIME_TYPES_TIF:
            case Attachment.MIME_TYPES_SVG:
                return true;
            default :
                return false
        }
    }

    getExtension() {
        return this.name.substr(this.name.lastIndexOf('.') + 1 - this.name.length);
    }

    static acceptedFiles() {
        return [
            '.pdf',
            '.bmp', '.gif', 'jpeg', '.jpg', '.png', '.webp', '.svg', '.psd', '.tiff',
            '.odt', '.ods', '.odp', '.odg',
            '.csv', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx',
            '.css', '.html', '.log', '.txt', '.xml', '.json', '.yaml', '.yml',
            '.zip', '.bzip', '.bzip2', '.rar', 'tar', '.gz'
        ].join(',');
    }
}

export default Attachment;
