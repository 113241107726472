import RestClient from './RestClient';
import { User } from '../models';
import { store } from '../redux';
import type { FetchOptions } from './Client';

class Users extends RestClient {
    constructor() {
        super({
            // rest config
            model: User,
            entryPoint: 'users',
            sortBy: 'lastname',
            id_field: '_id',
            // redux config
            resource: 'user',
            resources: 'users',
        });
    }

    addFavoriteProjects(projects: string[]) {
        return this.POST(`/${this.entryPoint}/projects`, projects)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json.user;
                }
                throw new Error(json.flash.error);
            });
    }

    removeFavoriteProjects(projects: string[]) {
        return this.DELETE(`/${this.entryPoint}/projects/${projects.join(',')}`)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json.user;
                }
                throw new Error(json.flash.error);
            });
    }

    setAvatar(id: string, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        const headers: AnyObj = {};
        const subscriberId = store.getState()?.mercure?.subscriberId;
        if (subscriberId)
            headers['X-MERCURE-SUBSCRIBER'] = subscriberId;

        let url = `${this.baseUrl}/${this.entryPoint}/${id}/avatar`;
        const options: FetchOptions = {
            method: 'POST',
            body: formData,
            headers,
            credentials: 'include',
        };

        return fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    unsetAvatar(id: string) {
        return this.DELETE(`/${this.entryPoint}/${id}/avatar`)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const UsersApi = new Users();

export default UsersApi;
