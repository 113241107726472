import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { startCreateUser, createUser } from '../../../redux';
import { Ability, Button } from '../../../components';
import UserForm from '../UserForm/UserForm';

function UserCreate() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { user, created } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        dispatch(startCreateUser());
    }, [dispatch]);

    const submit = function (values: AnyObj) : void {
        dispatch(createUser(values));
    }

    if (created)
        navigate(`/users/edit/${user._id}`);

    return (
        <Ability can="user:create" redirect="/">
            <div className="UserCreate">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                { t('users.add_a_user') }
                            </h2>
                        </div>
                        <div className="mt-5 flex lg:ml-4 lg:mt-0">
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/users" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                <main className="mx-6 my-6 shadow">
                    {user && (!user._id) && (
                        <UserForm isCreation={true} initialValues={user} onSubmit={submit} />
                    )}
                </main>
            </div>
        </Ability>
    );
}

export default UserCreate;
