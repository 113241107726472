import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, ChangeModal } from '../../../components';
import { Sprint, Change } from '../../../models';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadSprints, loadSprint, loadUsers } from '../../../redux';
import { loadChange, resetChanges } from '../../../redux';
import { SprintResume, Icon, Button, ChangeListItem, Reporting } from '../../../components';
import { Tabs} from 'flowbite-react';
import moment from 'moment';

function SprintView() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const [activeTab, setActiveTab]             = useState(0);
    const [currentChangeId, setCurrentChangeId] = useState<string | undefined>();

    const { user }                        = useAppSelector((state: RootState) => state.auth);
    const { change }                      = useAppSelector(state => state.changes);
    const { sprint, sprints, loadingOne } = useAppSelector((state: RootState) => state.sprints);

    useEffect(() => {
        dispatch(loadSprints());
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadSprint(id));
    }, [dispatch, id]);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const goToSprint = (sprintId: string | number) => {
        return navigate(`/sprints/${sprintId}`);
    };

    const sortedDoneChangesByProject = React.useMemo(() => {
        if (!sprint?.changes?.length)
            return {};

        return Sprint.groupChangesByProject(Sprint.sortChangesBy(sprint.changes.filter((c: Change) => c.isFinished()), 'project'));
    }, [sprint?.changes]);

    const sortedToDoChangesByProject = React.useMemo(() => {
        if (!sprint?.changes?.length)
            return {};

        return Sprint.groupChangesByProject(Sprint.sortChangesBy(sprint.changes.filter((c: Change) => !c.isFinished()), 'project'));
    }, [sprint?.changes]);

    const sortedChangesByProject = React.useMemo(() => {
        if (!sprint?.changes?.length)
            return {};

        return Sprint.groupChangesByProject(Sprint.sortChangesBy(sprint.changes, 'project'));
    }, [sprint?.changes]);

    const sortedChangesByUser = React.useMemo(() => {
        if (!sprint?.changes?.length)
            return {};

        const groups = Sprint.groupChangesByUser(Sprint.sortChangesBy(sprint.changes, 'assignee'));
        const userId = user?._id;
        if (userId && (userId in groups)) {
            const sortedGroups: AnyObj = {
                [userId]: {
                    name: t('sprints.me'),
                    changes: groups[userId].changes
                }
            };
            Object.keys(groups).forEach((key: string) => {
                if (key !== userId)
                    sortedGroups[key] = groups[key];
            });
            return sortedGroups;
        }

        return groups;
    }, [t, user?._id, sprint?.changes]);

    const unassignedChanges = React.useMemo(() => {
        if (!sprint?.changes?.length)
            return [];

        return sprint.changes.filter((change: Change) => !change.assignee);
    }, [sprint?.changes]);

    const previousSprint = React.useMemo(() => {
        if (!sprint || sprints?.length < 2)
            return null;

        const sorted = sprints.sort((a: Sprint, b: Sprint) => a.startAt.unix() - b.startAt.unix());
        const index  = sprints.findIndex((s: Sprint) => s._id === sprint._id);
        if (index > 0)
            return sorted[index - 1];

        return null;
    }, [sprints, sprint]);

    const nextSprint = React.useMemo(() => {
        if (!sprint || sprints?.length < 2)
            return null;

        const sorted = sprints.sort((a: Sprint, b: Sprint) => a.startAt.unix() - b.startAt.unix());
        const index  = sprints.findIndex((s: Sprint) => s._id === sprint._id);
        if (index < sprints.length - 1)
            return sorted[index + 1];

        return null;
    }, [sprints, sprint]);

    if (!sprint)
        return null;

    const isLoading = ((loadingOne + '') === 'pending');

    return (
        <div className="SprintView">
            <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                <div className="mb-2 mx-auto lg:flex lg:items-center lg:justify-between">
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
                        <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                            <Icon type="sprint" className="inline" size={8} />
                            <Select
                                onChange={goToSprint}
                                placeholder={sprint?.name}
                                options={sprints.filter((p: Sprint) => p._id !== sprint?._id).map((sprint: Sprint) => ({
                                    value: sprint._id,
                                    label: sprint.name,
                                    className: 'text-xl font-bold',
                                    leftContent: (
                                        <Icon type="sprint" className="inline ml-1 border rounded p-2" size={12} />
                                    )
                                }))}
                                inline
                                className=""
                                optionsWidth={'w-[20rem]'}
                                optionsHeight={'max-h-96'}
                                selectedClassName="text-3xl font-bold text-gray-900"
                                optionsClassName="text-xl font-medium py-4 pl-14"
                            />
                        </h2>
                    </div>
                    <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 mt-2 lg:mt-0">
                        <span className="sm:ml-3">
                            <Button
                                to={`/sprints/edit/${sprint?._id}/`}
                                title={t('common.edit')}
                                icon="edit"
                                color="secondary"
                            />
                        </span>
                        <span className="sm:ml-3">
                            <Button title={t('common.back')} color="navigateBack" to="/sprints" icon="back" />
                        </span>
                    </div>
                </div>
            </header>
            {isLoading ? (
                <main className="mx-6 mt-2 flex justify-center items-center h-[calc(100vh-8rem)] w-full">
                    <Icon type="loading" size={20} />
                </main>
            ) : (
                <main className="mx-6 mt-2">
                    <div className="px-4 py-0 sm:px-4 md:px-0 mb-2">
                        <div className="flex flex-row md:items-center">
                            {previousSprint && (
                                <div onClick={(e: any) => goToSprint(previousSprint._id)} className="cursor-pointer flex justify-start md:justify-center md:justify-start">
                                    <Icon type="left" size={6} />
                                    <div className="flex flex-col">
                                        <h3 className="text-md w-full text-gray-900 font-bold">
                                            {previousSprint.name}
                                        </h3>
                                    </div>
                                </div>
                            )}
                            <div className="flex-1 w-full flex flex-col md:flex-row justify-center space-x-0 space-y-4 md:space-y-0 md:space-x-16">
                                <div className="flex-1 flex -md:flex-col justify-center md:justify-end items-center space-y-0 space-x-4 py-4 md:py-0">
                                    <Icon type="sprint" size={12} />
                                    <div className="flex flex-col">
                                        <h3 className="flex flex-col justify-center items-center text-lg w-full text-gray-900 font-bold">
                                            <span>{t('sprints.name')}</span>
                                            <span>{sprint?.name}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex-1 flex -md:flex-col justify-center md:justify-start items-center space-y-0 space-x-4 py-4 md:py-0">
                                    <Icon type="date" size={12} className="-md:w-full" />
                                    <div className="flex flex-col">
                                        <div className="w-full text-gray-900 font-medium flex justify-center md:justify-end">
                                            {t('sprints.startAt')}&nbsp;
                                            <span className="font-bold">{sprint.startAt.format('LL')}</span>
                                        </div>
                                        <div className="w-full text-gray-900 font-medium flex justify-center md:justify-end">
                                            {t('sprints.stopAt')}&nbsp;
                                            <span className="font-bold">{sprint.stopAt.format('LL')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {nextSprint && (
                                    <div onClick={(e: any) => goToSprint(nextSprint._id)} className="cursor-pointer flex justify-center md:justify-start items-center">
                                        <div className="flex flex-col">
                                            <h3 className="text-md w-full text-gray-900 font-bold">
                                                {nextSprint.name}
                                            </h3>
                                        </div>
                                        <Icon type="right" size={6} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <SprintResume sprint={sprint} />

                    {sprint?.changes?.length > 0 ? (
                        <Tabs onActiveTabChange={setActiveTab} className="mt-4">
                            <Tabs.Item icon={() => <Icon type="progress" className="mr-1.5 outline-none" />} title={t('sprints.progress')}>
                                {Object.keys(sortedToDoChangesByProject).length > 0 && (
                                    <div className="-mx-4 col-span-3 flex flex-col space-y-2 mb-4 bg-white shadow rounded-md p-4">
                                        <h3 className="mb-2 flex items-center space-x-2">
                                            <Icon type="todo" size={8} className="inline mr-1.5"/>
                                            <span className="text-xl font-bold">
                                                {t('sprints.todo')}
                                            </span>
                                            <span className="pt-1 text-sm font-medium italic">
                                                {sprint.getRemainingHours().toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                            </span>
                                        </h3>
                                        {Object.keys(sortedToDoChangesByProject).map((projectId: string, i: number) => (
                                            <div className={`border ${i % 2 === 1 ? 'bg-gray-100' : 'bg-gray-50'} p-4`} key={projectId}>
                                                <h3 className="font-bold text-md flex items-center space-x-2 ">
                                                    <Icon type="project" size={4} className="inline mr-1" />
                                                    {sortedToDoChangesByProject[projectId].name}
                                                </h3>
                                                <h3 className="font-medium text-xs flex items-center space-x-4">
                                                    <Icon type="todo" size={4} className="inline mr-1" />
                                                    {sprint.getRemainingHours(moment(), projectId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                </h3>
                                                <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                                                    {sortedToDoChangesByProject[projectId].changes.map((change: Change) => (
                                                        <div key={change._id} onClick={() => selectChange(sprint.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                                                        <ChangeListItem showProject change={change} className={`${change.isFinished() ? 'bg-green-50 border-green-200' : change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}/>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {Object.keys(sortedDoneChangesByProject).length > 0 && (
                                    <div className="-mx-4 col-span-3 flex flex-col space-y-2 mb-4 bg-white shadow rounded-md p-4">
                                        <h3 className="mb-2 flex items-center space-x-2">
                                            <Icon type="check" size={8} className="inline mr-1.5"/>
                                            <span className="text-xl font-bold">
                                                {t('sprints.done')}
                                            </span>
                                            <span className="pt-1 text-sm font-medium italic">
                                                {sprint.getRealisedHours().toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                            </span>
                                        </h3>
                                        {Object.keys(sortedDoneChangesByProject).map((projectId: string, i: number) => (
                                            <div className={`border ${i % 2 === 1 ? 'bg-gray-100' : 'bg-gray-50'} p-4`} key={projectId}>
                                                <h3 className="font-bold text-md flex items-center">
                                                    <Icon type="project" size={4} className="inline mr-1" />
                                                    {sortedDoneChangesByProject[projectId].name}
                                                </h3>
                                                <h3 className="font-medium text-xs flex items-center space-x-4">
                                                    <Icon type="check" size={4} className="inline mr-1" />&nbsp;
                                                    {sprint.getRealisedHours(moment(), projectId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                </h3>
                                                <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                                                    {sortedDoneChangesByProject[projectId].changes.map((change: Change) => (
                                                        <div key={change._id} onClick={() => selectChange(sprint.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                                                        <ChangeListItem showProject change={change} className={`${change.isFinished() ? 'bg-green-50 border-green-200' : change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}/>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Tabs.Item>
                            <Tabs.Item icon={() => <Icon type="project" className="mr-1.5 outline-none" />} title={t('sprints.by_project')}>
                                <div className="-mx-4 col-span-3 flex flex-col space-y-2 mb-4 bg-white shadow rounded-md p-4">
                                    {Object.keys(sortedChangesByProject).map((projectId: string, i: number) => (
                                        <div className={`border ${i % 2 === 1 ? 'bg-gray-100' : 'bg-gray-50'} p-4`} key={projectId}>
                                            <h3 className="font-bold text-lg flex items-center">
                                                <Icon type="project" size={4} className="inline mr-1" />
                                                {sortedChangesByProject[projectId].name}
                                            </h3>
                                            <h3 className="font-medium text-xs flex items-center space-x-4">
                                                <Icon type="list" size={4} className="inline mr-1" />
                                                {sprint.getEstimatedHours(projectId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                <Icon type="check" size={4} className="inline mr-1" />&nbsp;
                                                {sprint.getRealisedHours(moment(), projectId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                <Icon type="todo" size={4} className="inline mr-1" />&nbsp;
                                                {sprint.getRemainingHours(moment(), projectId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                            </h3>
                                            <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                                                {sortedChangesByProject[projectId].changes.map((change: Change) => (
                                                    <div key={change._id} onClick={() => selectChange(sprint.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                                                    <ChangeListItem showProject change={change} className={`${change.isFinished() ? 'bg-green-50 border-green-200' : change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}/>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tabs.Item>
                            <Tabs.Item icon={() => <Icon type="user" className="mr-1.5 outline-none" />} title={t('sprints.by_resource')}>
                                <div className="-mx-4 col-span-3 flex flex-col space-y-2 mb-4 bg-white shadow rounded-md p-4">
                                    {Object.keys(sortedChangesByUser).map((userId: string, i: number) => (
                                        <div className={`border ${i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50'} p-4`} key={userId}>
                                            <h3 className="font-bold text-lg flex items-center">
                                                <Icon type="user" size={4} className="inline mr-1" />
                                                {sortedChangesByUser[userId].name}
                                            </h3>
                                            <h3 className="font-medium text-xs flex items-center space-x-4">
                                                <Icon type="list" size={4} className="inline mr-1" />
                                                {sprint.getEstimatedHours(/*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                <Icon type="check" size={4} className="inline mr-1" />&nbsp;
                                                {sprint.getRealisedHours(moment(), /*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                                <Icon type="todo" size={4} className="inline mr-1" />&nbsp;
                                                {sprint.getRemainingHours(moment(), /*projectId*/null, userId).toFixed(2)}&nbsp;{t('sprints.estimate_units_min')}
                                            </h3>
                                            <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                                                {sortedChangesByUser[userId].changes.map((change: Change) => (
                                                    <div key={change._id} onClick={() => selectChange(sprint.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                                                    <ChangeListItem showProject change={change} className={`${change.isFinished() ? 'bg-green-50 border-green-200' : change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}/>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {unassignedChanges.length > 0 && (
                                        <div className={`border bg-red-200 p-4`}>
                                            <h3 className="font-bold text-lg flex items-center">
                                                <Icon type="user" size={4} className="inline mr-1" />
                                                {t('sprints.unassigned')}
                                            </h3>
                                            <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                                                {unassignedChanges.map((change: Change) => (
                                                    <div key={change._id} onClick={() => selectChange(sprint.changes.find((c: Change) => c._id === change._id))} className="col-span-2 lg:col-span-1" >
                                                    <ChangeListItem showProject change={change} className={`${change.isFinished() ? 'bg-green-50 border-green-200' : change.isInReview() ? 'bg-blue-50 border-blue-200' : 'bg-white'}`}/>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Tabs.Item>
                            <Tabs.Item
                                icon={() => <Icon type="report" className="mr-1.5 outline-none" />}
                                title={t('projects.reporting')}
                            >
                                {activeTab === 3 && (
                                    <div className="flex-1 shrink">
                                        <Reporting
                                            sprint={sprint}
                                            tabs={['stats', 'report', 'tracks']}
                                        />
                                    </div>
                                )}
                            </Tabs.Item>
                        </Tabs>
                    ) : (
                        <div className="mt-4 flex justify-center items-center space-x-4">
                            <Icon type="list" size={8} />
                            <span className="italic text-gray-900">
                                {t('sprints.no_cards_for_now')}
                            </span>
                        </div>
                    )}

                    {change && change._id === currentChangeId && change.project?.name && (
                        <ChangeModal
                            project={change.project}
                            change={change}
                            show={true}
                            showProjectName={true}
                            onClose={() => selectChange()}
                            onRemove={(values: AnyObj) => { }}
                        />
                    )}
                </main>
            )}
        </div>
    );
}

export default SprintView;
