import BaseModel from './BaseModel';

/** Class representing a statistics. */
class Statistic extends BaseModel {

    changes: AnyObj;
    quotes : AnyObj;

    constructor(properties: AnyObj) {
        super({});

        this.changes = properties.changes;
        this.quotes  = properties.quotes;
    }
}

export default Statistic;
