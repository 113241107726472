import React, { useState, useEffect } from 'react';
import { useTranslation }  from 'react-i18next';
import { RootState, useAppSelector, useAppDispatch, loadChange, loadBoards } from '../../../redux';
import { RoutineAction, Change } from '../../../models';
import { ChangePicker } from '../../ChangePicker';
import { ChangeListItem } from '../../ChangeListItem';
import { Select } from '../../Select';
import _ from 'lodash';

interface DuplicateChangeFormProps {
    action: RoutineAction;
    onSave?: (action: RoutineAction) => void;
    authorId?: string;
    projectId?: string;
    changeId?: string;
}

function DuplicateChangeForm(props: DuplicateChangeFormProps) {
    const { action, onSave, projectId } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [options, setOptions] = useState<AnyObj>({});

    const { change } = useAppSelector((state : RootState) => state.changes);
    const { boards } = useAppSelector((state : RootState) => state.boards);

    useEffect(() => {
        setOptions(action.options);
    }, []);

    useEffect(() => {
        if (options?._id)
            dispatch(loadChange(options._id));
    }, [dispatch, options?._id]);

    useEffect(() => {
        if (change)
            dispatch(loadBoards({project: change.project._id}));
    }, [dispatch, change]);

    useEffect(() => {
        onSave && onSave({
            ...action,
            options
        });
    }, [options]);

    const unsetChange = () => {
        const { _id, ...rest } = options;
        setOptions({ ...rest });
    };

    const boardOptions = React.useMemo(() => {
        return boards?.filter((board: AnyObj) => {
            return _.some(board.columns, (column: AnyObj) => !column.isSmart);
        })?.map((board: AnyObj) => ({
            value: board._id,
            label: board.name.fr
        }));
    }, [change, boards]);

    const columnOptions = React.useMemo(() => {
        const board = boards?.find((board: AnyObj) => board._id === options?.boardId);
        return board?.columns.filter((column: AnyObj) => !column.isSmart).map((column: AnyObj) => ({
            value: column._id,
            label: column.title
        }));
    }, [boards, options?.boardId]);

    /**
     * "_id": "string",
     * "deadlineOffset": 0,
     * "boardId": "string",
     * "columnId": "string"
     */
    return (
        <div className="flex flex-col space-y-4 w-full">
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col items-center">
                    {t('projects.routine.action.choose_change_to_duplicate')}
                </label>
                <div className="col-span-3">
                    {options?._id ? (
                        <>
                            {change && <ChangeListItem className="flex-1 bg-white" change={change} onRemove={unsetChange}/>} </>
                    ) : ( <ChangePicker
                            onSelect={(change: Change) => setOptions({...options, _id: change?._id})}
                            acceptUnestimated
                            acceptFinished
                            defaultProjectId={projectId}
                            hideLoader
                            listClassName="max-h-[400px] overflow-auto"
                            filterChanges={(changes: Change[]) => changes.sort((a: Change, b: Change) => b.slug - a.slug)}
                        />
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col space-y-0 justify-center">
                    <div>{t('projects.routine.action.deadline_offset')}</div>
                    <small>{t('projects.routine.action.deadline_offset_desc')}</small>
                </label>
                <div className="col-span-3">
                    <input
                        type="number"
                        value={options?.deadlineOffset || 0}
                        onChange={(e: any) => setOptions({...options, deadlineOffset: e.target.value})}
                        placeholder={t('changes.deadline_offset') || ''}
                        className="border rounded border-gray-300 w-full"
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                <label className="text-sm col-span-1 flex-col space-y-0 justify-center">
                    <div>{t('projects.routine.action.choose_board')}</div>
                    <small>{t('projects.routine.action.choose_board_desc')}</small>
                </label>
                <div className="col-span-3">
                    {boardOptions && (
                        <Select
                            defaultValue={options?.boardId}
                            onChange={(boardId: any) => setOptions({...options, boardId})}
                            placeholder={t('changes.choose_a_board') || ''}
                            options={boardOptions}
                        />
                    )}
                </div>
            </div>
            {columnOptions && (
                <div className="grid grid-cols-1 xl:grid-cols-4 gap-2 bg-purple-50 p-2 border rounded">
                    <label className="text-sm col-span-1 flex-col justify-center">
                        <div>{t('projects.routine.action.choose_column')}</div>
                        <small>{t('projects.routine.action.choose_column_desc')}</small>
                    </label>
                    <div className="col-span-3">
                        <Select
                            defaultValue={options?.columnId}
                            onChange={(columnId: any) => setOptions({...options, columnId})}
                            placeholder={t('changes.choose_a_column') || ''}
                            options={columnOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default DuplicateChangeForm;
