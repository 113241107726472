import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, getDocumentations } from '../../redux';
import { Markdown, Icon } from '../../components';
import { ProjectDocumentation } from '../../models';
import { Card } from 'flowbite-react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

interface DocumentationListProps {
    projectId: string;
}

const DocumentationList = (props: DocumentationListProps) => {

    const { t }         = useTranslation();
    const dispatch      = useAppDispatch();
    const { projectId } = props;
    const { project }    = useAppSelector(state => state.projects);
    const documentations = project.documentations;

    const readme         = project.readme?.content ? { "path": project.readme.path, "content": project.readme.content } : null;
    const displayDefault = readme == null ? (documentations.length >= 1 ? documentations[0].name : '') : readme.path;

    const [showDocumentation, setShowDocumentation] = useState(displayDefault);

    useEffect(() => {
        if (projectId)
            dispatch(getDocumentations(projectId));
    }, [dispatch, projectId]);

    const markdownsDocs = documentations.filter((doc: ProjectDocumentation) => doc.format == 'markdown');
    const openapi       = documentations.filter((doc: ProjectDocumentation) => doc.format == 'openapi');

    const selected = (name: string) => {
        setShowDocumentation(name);
    };

    return (
        <Card className="DocumentationList">
            <h2 className="text-2xl font-bold leading-7 flex items-center text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                <Icon type="comment" size={7} className="mr-1.5" />
                {t('projects.documentation')}
            </h2>
            <>
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-full md:col-span-3">
                        {readme != null &&
                            <div
                                id={readme.path}
                                className={`cursor-pointer w-full border ${showDocumentation === readme.path ? "bg-blue-100 hover:bg-blue-200" : "bg-gray-100 hover:bg-gray-200"} p-2 my-1 rounded-md`}
                                onClick={() => selected(readme.path)}
                            >
                                {readme.path.substring(1)}
                            </div>
                        }
                        {documentations.length > 0 && documentations.map(function (doc: ProjectDocumentation, index: string) {
                            return (
                                <div
                                    key={index}
                                    id={index}
                                    className={`cursor-pointer w-full border ${showDocumentation === doc.name ? "bg-blue-100 hover:bg-blue-200" : "bg-gray-100 hover:bg-gray-200"} p-2 my-1 rounded-md`}
                                    onClick={() => selected(doc.name)}
                                >
                                    {doc.name}
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-span-full md:col-span-9">
                        {/* Display readme documentation */}
                        {readme != null && showDocumentation === readme.path &&
                            <Markdown
                                content={readme.content || t('projects.no_documentation')}
                                className="border p-4"
                            />
                        }
                        {/* Display Ouline documentation */}
                        {markdownsDocs.length > 0 && markdownsDocs.map(function (doc: ProjectDocumentation, index: string) {
                            return (
                                <>
                                    {showDocumentation && showDocumentation === doc.name &&
                                        <Markdown key={index} content={doc.content || t('projects.no_documentation')} className="border p-4" />
                                    }
                                </>
                            )
                        })}
                        {/* Display openapi documentation */}
                        {openapi.length > 0 && openapi.map(function (openapi: ProjectDocumentation, index: string) {
                            return (
                                <>
                                    {showDocumentation && showDocumentation === openapi.name &&
                                        <SwaggerUI url={openapi.path} />
                                    }
                                </>
                            )

                        })}
                    </div>
                </div>
            </>
        </Card>
    );
}

export default DocumentationList;
