import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Ability, Select } from '../../../components';
import { Quote, Change } from '../../../models';
import type { QuoteItem } from '../../../models';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadQuotes, loadQuote, loadUsers } from '../../../redux';
import { QuoteText, QuoteProgress, Icon, Button, ChangeListItem } from '../../../components';
import { formatPrice } from '../../../locales';
import { Badge } from 'flowbite-react';

function QuoteView() {
    const { t, i18n } = useTranslation();
    const dispatch    = useAppDispatch();
    const navigate    = useNavigate();

    const { id } = useParams();

    const { quote, quotes, loadingOne } = useAppSelector((state: RootState) => state.quotes);

    useEffect(() => {
        dispatch(loadQuotes());
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        if (id)
            dispatch(loadQuote(id));
    }, [dispatch, id]);

    const goToQuote = (quoteId: string | number) => {
        return navigate(`/quotes/${quoteId}`);
    };

    if (!quote)
        return null;

    const isLoading = ((loadingOne + '') === 'pending');

    document.title = `Yoda - ${t('changes.quote')} ${quote.name}`;

    return (
        <Ability can="quote:read" redirect="/">
            <div className="QuoteView print:font-netox">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8 print:hidden">
                    <div className="mb-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0">
                            <h2 className="flex items-center text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="law" className="inline" size={8} />
                                <Select
                                    onChange={goToQuote}
                                    placeholder={quote?.name}
                                    options={quotes.filter((p: Quote) => p._id !== quote?._id).map((quote: Quote) => ({
                                        value: quote._id,
                                        label: `${quote.slug} | ${quote.name}`,
                                        className: 'text-xl font-bold',
                                        leftContent: (
                                            <Icon type="law" className="inline ml-3 border rounded" size={6} />
                                        )
                                    }))}
                                    inline
                                    className=""
                                    optionsWidth={'w-[30rem]'}
                                    optionsHeight={'max-h-96'}
                                    selectedClassName="text-xl font-bold text-gray-900"
                                    optionsClassName="w-[29rem] text-sm font-medium py-2 pl-10 truncate"
                                />
                            </h2>
                        </div>
                        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 mt-2 lg:mt-0">
                            <Ability can="quote:edit">
                                <span className="sm:ml-3">
                                    <Button
                                        to={`/quotes/edit/${quote?._id}/`}
                                        title={t('common.edit')}
                                        icon="edit"
                                        color="secondary"
                                    />
                                </span>
                            </Ability>
                            <span className="sm:ml-3">
                                <Button title={t('common.back')} color="navigateBack" to="/quotes" icon="back" />
                            </span>
                        </div>
                    </div>
                </header>
                {isLoading ? (
                    <main className="mx-6 mt-2 flex justify-center items-center h-[calc(100vh-8rem)] w-full">
                        <Icon type="loading" size={20} />
                    </main>
                ) : (
                    <main className="mx-6 mt-2">
                        <div className="my-4">
                            <div className="hidden print:block print:mb-8">
                                <QuoteText quote={quote} part="netoxygen" />
                            </div>
                            <div className="flex flex-col-reverse md:flex-row print:flex-row space-y-2 print:space-y-0 md:space-y-0 justify-between items-start">
                                <div className="flex-1 print:flex-auto">
                                    <h3 className="text-lg font-bold text-center md:text-left print:text-left">
                                        {t('quotes.quote_number', {slug: quote.slug})}
                                    </h3>
                                    <h3 className="text-md font-medium text-center md:text-left print:text-left">
                                        {t('quotes.quote_project', {project: quote.project?.name})}
                                    </h3>
                                </div>
                                <div className="flex-1">
                                    {quote.client && (
                                        <div className="mb-2">
                                            <h3 className="text-xl font-bold text-center md:text-right print:text-left">
                                                {quote.client.company}
                                            </h3>
                                            <h3 className="text-md font-bold text-center md:text-right print:text-left">
                                                {quote.client.fullname()}
                                            </h3>
                                            <h3 className="text-sm font-medium text-center md:text-right print:text-left">
                                                {quote.client.address.split(/\\r|\\n/).map((part: string, index: number) => (
                                                    <div key={index}>{part}</div>
                                                ))}
                                            </h3>
                                            <h3 className="text-sm font-medium text-center md:text-right print:text-left">
                                                {quote.client.postcode}&nbsp;{quote.client.city}
                                            </h3>
                                            <h3 className="text-sm font-medium text-center md:text-right print:hidden">
                                                {quote.client.email}
                                            </h3>
                                        </div>
                                    )}
                                    <div className="hidden print:block print:my-4 text-left">
                                        <QuoteText quote={quote} part="date" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 mb-6">
                            <h3 className="text-center text-2xl font-bold print:text-left">
                                {quote.name}
                            </h3>
                            <h3 className="text-center text-xs italic font-normal print:hidden">
                                {t('quotes.last_action')}&nbsp;
                                {quote.lastAction().date.format('LL')}
                            </h3>
                        </div>

                        <div className="hidden print:block">
                            <QuoteText quote={quote} part="intro" />
                        </div>

                        <div className="mb-6 print:hidden">
                            <hr />
                            <h4 className="text-center text-xl font-bold leading-7 text-gray-900">
                                {t('quotes.total_price', {total: quote.formattedPrice()})}
                            </h4>
                            <h4 className="mb-4 text-center font-light leading-7 text-gray-900">
                                {t('quotes.total_estimated', {total: quote.totalEstimated().toFixed(2)})}
                            </h4>
                            <hr />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mt-2 print:break-before-page">
                            <div className="col-span-1 border print:border-none p-4 print:p-0 rounded-md">
                                <h3 className="text-xl font-bold mb-2">
                                    {t('quotes.content')}
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                                    {quote.items.filter((item: QuoteItem) => item.type !== Change.TYPE_IGNORED).map((item: QuoteItem, i: number) => (
                                        <div key={i} className="col-span-1 rounded print:rounded-none bg-white border hover:bg-gray-50 break-inside-avoid">
                                            <div className="grid grid-cols-1 gap-x-2 gap-y-2 p-3">
                                                <div className="col-span-1">
                                                    <div className="flex flex-row gap-x-2 rounded-md border-gray-300 text-gray-900">
                                                        <div className="w-32">
                                                            <Badge color={Change.color(item.type)} className="w-32">
                                                                {t(`projects.${item.type}`)}
                                                            </Badge>
                                                        </div>
                                                        <span className="text-md font-medium">
                                                            <div dangerouslySetInnerHTML={{ __html: item.title || ''}}></div>
                                                            <span className="text-sm italic font-normal block">
                                                                <div dangerouslySetInnerHTML={{ __html: item.description || item.change?.localizedDescription(i18n.language) || ''}}></div>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-span-1 flex flex-row gap-2">
                                                    <div className="flex-1 text-left text-gray-900 font-bold text-sm">
                                                    </div>
                                                    <div className="flex-1 flex flex-row gap-x-2 justify-end text-right text-gray-900 font-bold text-sm">
                                                        {item.estimate > 0 && (
                                                            <span>{item.estimate} h</span>
                                                        )}
                                                        {item.price !== 0 && formatPrice(item.price)}
                                                    </div>
                                                </div>
                                                {item.change && (
                                                <div className="col-span-1 print:hidden" onClick={() => navigate(`/projects/${quote.project?._id}/change/${item.change?._id}`)}>
                                                    <ChangeListItem change={item.change} showProgression />
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mt-2 print:break-inside-avoid">
                            <div className="mt-5 print:block print:mt-8 print:break-inside-avoid">
                                <h3 className="text-xl font-bold mb-2">
                                    {t('quotes.pricings_resume')}
                                </h3>
                                <QuoteText quote={quote} part="pricings" />
                            </div>
                            <div className="mt-5 col-span-1 flex flex-col space-y-2 print:hidden print:break-before-page">
                                <h3 className="text-xl font-bold">
                                    {t('quotes.status_progression')}
                                </h3>
                                <div className="bg-white p-2 border rounded-md">
                                    <QuoteProgress quote={quote} />
                                </div>
                            </div>
                            <div className="hidden print:block print:mt-8 print:break-inside-avoid">
                                <h3 className="text-xl font-bold mb-2">
                                    {t('quotes.conditions')}
                                </h3>
                                <QuoteText quote={quote} part="approve" />
                            </div>
                        </div>
                    </main>
                )}
            </div>
        </Ability>
    );
}

export default QuoteView;
