import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, addFavoriteProjects, removeFavoriteProjects, useAppDispatch, useAppSelector } from '../../../redux';
import { loadProjects } from '../../../redux';
import { useNavigate, Link } from "react-router-dom";
import { Ability, Button, Icon } from '../../../components';
import { Avatar, Badge, Table } from 'flowbite-react';
import { Project } from '../../../models';

interface ProjectsFilters {
    name?: string;
}

function ProjectsList() {
    const { t }    = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState<ProjectsFilters>({name: ''});

    const { projects } = useAppSelector((state: RootState) => state.projects);
    const { user }     = useAppSelector(state => state.auth);

    const handleProjectInFavorite = (idProject:string) => {
        const findProject: string = user.favoriteProjects.find((p: string) => p === idProject)
        findProject ? dispatch(removeFavoriteProjects([findProject])) : dispatch(addFavoriteProjects([idProject]));
    }

    const processedProjects: Project[] = useMemo(() => {
        if (!projects?.length)
            return [];
        return [].concat(
            projects.filter((p: Project) => user.favoriteProjects.includes(p._id)).sort((a: Project, b: Project) => (a.name > b.name))
        ).concat(
            projects.filter((p: Project) => !user.favoriteProjects.includes(p._id)).sort((a: Project, b: Project) => (a.name > b.name))
        );
    }, [projects, user.favoriteProjects]);


    useEffect(() => {
        dispatch(loadProjects());
    }, [dispatch]);

    const sortedProjects = React.useMemo(() => {
        return processedProjects?.filter((p: Project) => {
            if (filters?.name?.length)
                return p.name.match(new RegExp(filters.name, 'gi'));

            return true;
        });
    }, [processedProjects, filters]);

    const filterOnName = (name: string) => {
        setFilters({ ...filters, name });
    }

    const handleKeyPress = (e: AnyObj) => {
        if ((e.key === 'Enter' || e.code === 'Enter') && sortedProjects.length)
            return navigate(`/projects/${sortedProjects[0]._id}`);

        if (e.key === 'Escape' || e.code === 'Escape')
            setFilters({
                ...filters,
                name: ''
            });
    }

    const renderProject = (project: Project) => (
        <li key={project._id} className="relative md:flex border rounded-lg bg-gray-50 hover:bg-blue-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 justify-between">
            <Link to={`/projects/${project._id}`} className="p-3 text-base font-medium text-sm text-gray-900 dark:text-white hover:no-underline flex w-full">
                <Avatar img={project.getAvatarUrl()} className="inline w-fit" />
                <div className="ms-3 w-full flex flex-col">
                    <div className="flex justify-start items-center">
                        <div className="sm:w-full truncate w-[200px]">{project?.name}</div>
                        {project.lastRelease && (
                            <Badge className="inline-block ml-2">{project.lastRelease?.version}</Badge>
                        )}
                    </div>
                    <span className="text-xs text-gray-500 mt-1 flex items-center">
                        {project.repository?.branch}
                        {project.lastRelease && project.nbCommits > 0 && (
                            <span className="hidden sm:block w-[80px] text-center whitespace-nowrap font-medium text-gray-500 dark:text-white">
                                <Icon type="commit" className="inline ml-2" />&nbsp;
                                {project.nbCommits}
                                {project.nbReleases > 0 && (
                                    <>
                                    <Icon type="release" className="inline ml-4" />&nbsp;
                                    {project.nbReleases}
                                        <Icon type="date" className="inline ml-4 mr-1" />
                                    {project.lastRelease?.date?.format('LL') || ''}
                                    </>
                                )}
                            </span>
                        )}
                    </span>
                </div>
            </Link>
            <Button
                className="h-1/2 my-auto absolute bottom-1 right-1"
                onClick={()=>handleProjectInFavorite(project._id)}
                title=''
                type='button'
                color='none'
                iconColor='yellow-500'
                icon={user.favoriteProjects.find((p: string) => p === project._id) ? "fullStar" : "emptyStar"}
            />
        </li>
    );

    const favoriteProjects = sortedProjects.filter((p: Project) => user && user.favoriteProjects.includes(p._id));
    const otherProjects    = sortedProjects.filter((p: Project) => user && !user.favoriteProjects.includes(p._id));

    return (
        <Ability can="project:read" redirect="/">
            <div className="ProjectsList">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="project" size={7} className="mr-1.5 inline" />
                                { t('app.projects') }
                            </h2>
                        </div>
                        <div className="mt-5 flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 lg:mt-0">
                            <div className="relative flex">
                                <input
                                    type="text"
                                    value={filters?.name}
                                    onChange={e =>filterOnName(e.target.value)}
                                    onKeyUp={handleKeyPress}
                                    className="w-full h-9 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md focus:ring-gray-300 border-0 focus:border-0 py-0 pr-8"
                                />
                                <Icon type="search" color="gray-600" className="absolute top-2.5 right-2.5" />
                            </div>
                            <Ability can="project:create">
                                <span className="sm:ml-3">
                                    <Button className="w-full" title={t('projects.add_a_project')} color="navigate" to="/projects/new" icon="plus" />
                                </span>
                            </Ability>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="p-5">
                        {favoriteProjects.length > 0 && (
                            <>
                            <div className="py-2">
                                <h5 className="flex items-center text-2xl font-bold tracking-tight">
                                    <Icon type="fullStar" size={7} className="inline text-yellow-500" />&nbsp;{t('projects.favorite')} ({favoriteProjects.length})
                                </h5>
                            </div>
                            <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700 mb-3">
                                {favoriteProjects.map((p: Project) => renderProject(p))}
                            </ul>
                            </>
                        )}

                        {otherProjects.length > 0 && (
                            <>
                            <div className="py-2">
                                <h5 className="flex items-center text-2xl font-bold tracking-tight">
                                    <Icon type="project" size={7} className="inline" />&nbsp;{t('app.projects')} ({otherProjects.length})
                                </h5>
                            </div>
                            <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700 mb-3">
                                {otherProjects.map((p: Project) => renderProject(p))}
                            </ul>
                            </>
                        )}
                    </div>
                </main>
            </div>
        </Ability>
    );
}

export default ProjectsList;
