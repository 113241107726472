import BaseModel from './BaseModel';
import User from './User';

/** Class representing a items for Checklist. */
class ChecklistItem extends BaseModel {

    _id     : string;
    name    : string;
    done    : boolean;
    assignee: User;

    constructor(properties: AnyObj) {
        super({});

        this._id      = properties._id;
        this.name     = properties.name;
        this.done     = properties.done;
        this.assignee = properties.assignee;
    }
}
export default ChecklistItem;
