import React, { useState } from 'react';
import { Button } from '..';
import ImageUploading from 'react-images-uploading';
import type { ImageListType } from 'react-images-uploading';

export type { ImageListType };

interface ImageUploaderProps {
    max?: number;
    children?: JSX.Element;
    onChange?: (imageList: ImageListType) => void;
    hidePreview?: boolean;
    className?: string;
}

const ImageUploader = (props: ImageUploaderProps) => {
    const [images, setImages] = useState<ImageListType>([]);

    const onChange = (imageList: ImageListType, addUpdatedIndex?: number[] | undefined) => {
        setImages(imageList);
        props.onChange && props.onChange(imageList);
    };

    return (
        <div className="ImageUploader">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={props.max || 1}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div className={`my-2 text-center border-2 border-gray-300 border-dotted p-4 hover:bg-gray-100 ${isDragging ? 'border-gray-600' : ''} ${props.className ?? ''}`} {...dragProps}>
                        <div onClick={onImageUpload}>
                            {props.children}

                            {!props.hidePreview && imageList.map((image, index) => (
                                <div key={index}>
                                    <img src={image['data_url']} alt="" width="100" />
                                    <div className="hidden group-hover:block flex justify-center absolute bottom-0 right-0">
                                        <Button
                                            title=""
                                            color="none"
                                            icon="delete"
                                            iconColor="red-600"
                                            iconSize={5}
                                            onClick={() => onImageRemove(index)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </ImageUploading>
    </div>
    );
};

export default ImageUploader;
