import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Change } from '../../models';
import { Modal} from 'flowbite-react';
import { Field, Formik, Form } from 'formik';
import { RootState, useAppSelector, useAppDispatch } from '../../redux';
import { startCreateChange, createChange, loadProject, loadProjects, loadBoard } from '../../redux';
import { SelectField, Button, Icon } from '../../components';

interface QuickChangeModalProps {
    show: boolean;
    onClose?: () => void;
    onAdded?: (change: Change) => void;
}

const modalTheme = {
    "content": {
        "inner": "relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col h-[600px] max-h-[90vh]"
    }
};
const headerTheme = {
    "title": "text-lg font-medium text-white dark:text-white w-full",
    "close": {
        "base": "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-white hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
    }
};

function QuickChangeModal(props: QuickChangeModalProps) {
    const { show, onClose, onAdded } = props;

    const { t }                 = useTranslation();
    const { project, projects } = useAppSelector((state : RootState) => state.projects);

    const { board }                        = useAppSelector((state : RootState) => state.boards);
    const { creating, created, change }    = useAppSelector((state : RootState) => state.changes);
    const [projectId, setProjectId]        = useState(project?._id || '');
    const [boardId, setBoardId]            = useState('');
    const [columnId, setColumnId]          = useState('');
    const [selectedProject, selectProject] = useState<Project | undefined>(project);

    const dispatch = useAppDispatch();

    useEffect(()=>{
        dispatch(loadProjects());
    },[dispatch]);

    React.useMemo(() => {
        if (show)
            dispatch(startCreateChange());
    }, [dispatch, show]);

    useEffect(() => {
        if (boardId)
            dispatch(loadBoard(boardId));
    }, [dispatch, boardId]);

    const onChangeProject = (_id: any) => {
        setProjectId(_id);

        if (!_id)
            selectProject(undefined);

        selectProject(projects?.find((p: Project) => p._id === _id));
    }

    const createNewChange = (change: AnyObj) => {
        if (!change.projectId)
            return;

        if (change.title.length > 0) {
            let title = change.title;
            let type  = Change.TYPE_IGNORED;
            if (change.title.match(/feat: (.+)/)) {
                title = change.title.substring(6);
                type  = Change.TYPE_FEATURE;
            } else if (change.title.match(/fix: (.+)/)) {
                title = change.title.substring(5);
                type  = Change.TYPE_FIX;
            } else if (change.title.match(/ui: (.+)/)) {
                title = change.title.substring(4);
                type  = Change.TYPE_UI;
            } else if (change.title.match(/i18n: (.+)/)) {
                title = change.title.substring(6);
                type  = Change.TYPE_I18N;
            }

            const initial: AnyObj = {
                type,
                project : change.projectId,
                developer_notes: change.developer_notes,
                title: {
                    fr: title,
                    en: title,
                    de: title
                }
            };

            const params = { boardId, columnId };
            dispatch(createChange(initial, params));
        }
    }

    if (created && change && change) {
        dispatch(startCreateChange());
        onAdded && onAdded(change);

        if (selectedProject && selectedProject._id === project?._id)
            dispatch(loadProject(selectedProject._id));
    }

    const projectOptions = React.useMemo(() => {
        return projects?.map((project: Project) => ({
            value: project._id,
            label: project.name
        }));
    }, [projects]);

    const boardOptions = React.useMemo(() => {
        return selectedProject?.boards?.map((board: AnyObj) => ({
            value: board._id,
            label: board.name.fr
        }));
    }, [selectedProject]);

    const columnOptions = React.useMemo(() => {
        return board?.columns?.map((column: AnyObj) => ({
            value: column._id,
            label: column.title
        }));
    }, [board]);

    return (
        <div className="QuickChangeModal">
            <Modal theme={modalTheme} className='bg-indigo-500 backdrop-blur-sm' dismissible show={show} size="md" popup onClose={onClose}>
                <Modal.Header className="w-full bg-indigo-500" theme={headerTheme}>
                    <div className="flex justify-start w-full">
                        <div className="flex flex-col justify-center w-full">
                            <div className="m-0 p-0 w-full">
                                <Icon type="plus" color="white" className="inline mr-1.5" />
                                {t('projects.add_card')}
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="flex flex-col p-2 pt-5">
                    <Formik initialValues={{projectId, columnId, title: '', developer_notes: ''}} onSubmit={(values: {}) => createNewChange(values)}>
                        <Form className='h-full'>
                            <label className="block font-medium leading-6 text-gray-900">
                                <Icon type="project" className="inline mr-1.5" />
                                {t('changes.choose_a_project')}
                            </label>
                            <SelectField
                                name="projectId"
                                onChange={onChangeProject}
                                placeholder={t('changes.choose_a_project') || ''}
                                options={projectOptions}
                            />
                            {selectedProject && (
                                <div className='mt-5'>
                                    {boardOptions && boardOptions.length > 0 ? (
                                        <>
                                            <label className="block font-medium leading-6 text-gray-900">
                                                <Icon type="list" className="inline mr-1.5" />
                                                {t('changes.choose_a_board')}
                                            </label>
                                            <SelectField
                                                name="boardId"
                                                onChange={setBoardId}
                                                placeholder={t('changes.choose_a_board') || ''}
                                                options={boardOptions}
                                            />
                                        </>
                                    ) : (
                                        <span className="block text-sm italic font-bold text-center">
                                            {t('changes.this_project_has_no_column')}
                                        </span>
                                    )}
                                </div>
                            )}
                            {boardId && (board?._id === boardId) && (
                                <div className='mt-5'>
                                    {columnOptions?.length > 0 ? (
                                        <>
                                            <label className="block font-medium leading-6 text-gray-900">
                                                <Icon type="list" className="inline mr-1.5" />
                                                {t('changes.choose_a_column')}
                                            </label>
                                            <SelectField
                                                name="columnId"
                                                onChange={setColumnId}
                                                placeholder={t('changes.choose_a_column') || ''}
                                                options={columnOptions}
                                            />
                                        </>
                                    ) : (
                                        <span className="block text-sm italic font-bold text-center">
                                            {t('changes.this_project_has_no_column')}
                                        </span>
                                    )}
                                </div>
                            )}
                            {columnId && (
                                <div className='mt-5'>
                                    <label className="block font-medium leading-6 text-gray-900">
                                        <Icon type="edit" className="inline mr-1.5" />
                                        {t('changes.enter_title')}
                                    </label>
                                    <Field
                                        type="text"
                                        name="title"
                                        required
                                        autoFocus
                                        placeholder="feat: Nouvelle fonctionnalité"
                                        className="mb-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    />
                                    <label className="block font-medium leading-6 text-gray-900 mt-5">
                                        <Icon type="comment" className="inline mr-1.5" />
                                        {t('changes.describe')}
                                    </label>
                                    <Field
                                        component="textarea"
                                        name="developer_notes"
                                        rows="3"
                                        className="mb-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            )}
                            {columnId && (
                                <div className='mt-5 flex justify-center'>
                                    <Button
                                        type="submit"
                                        title={t('common.save')}
                                        color="primary"
                                        icon={creating === 'pending' ? 'loading' : 'save'}
                                    />
                                </div>
                            )}
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default QuickChangeModal;
