import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadGoals } from '../../../redux';
import { useNavigate, Link } from "react-router-dom";
import { Ability, Loader, Button, Icon, Tooltip, Select } from '../../../components';
import type { Value } from '../../../components';
import { Avatar, Button as FButton, Card, Progress, Badge } from 'flowbite-react';
import { Goal, Project } from '../../../models';
import { formatPrice } from '../../../locales';
import _ from 'lodash';
import moment from 'moment';

interface SortOptions {
    property?: string;
    asc: boolean;
};

interface Filters {
    status: string;
    name?: string;
    project?: Value;
};

const cardTheme = {
    "root": {
        "base": "flex rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800",
        "children": "flex h-full flex-col justify-between gap-4 p-0",
        "horizontal": {
            "off": "flex-col",
            "on": "flex-col md:max-w-xl md:flex-row"
        },
        "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
    },
    "img": {
        "base": "",
        "horizontal": {
            "off": "rounded-t-lg",
            "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        }
    }
};
function GoalsList() {
    const { t }    = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const searchInput = useRef<HTMLInputElement>(null);

    const oldFilters     = localStorage.getItem('goalsFilters');
    const defaultFilters = oldFilters?.length ?
        JSON.parse(localStorage.getItem('goalsFilters') || '') : {
            status: Goal.STATUS_RUNNING,
            project: undefined
        };

    const [filters, setFilters] = useState<Filters>(defaultFilters);

    const { goals, loading } = useAppSelector((state: RootState) => state.goals);

    useEffect(() => {
        const params: AnyObj = {};

        if (filters.status)
            params[filters.status] = true;

        if (filters.project)
            params.project = filters.project;

        localStorage.setItem('goalsFilters', JSON.stringify(filters));

        dispatch(loadGoals(params));
    }, [dispatch, filters.status, filters.project]);

    const goalProgression = (goal: Goal) => {
        const progression = Math.round(goal?.progression());

        return (
            <Tooltip content={t('goals.progression_value', { progression })} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="md"
                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    const timeProgression = (goal: Goal) => {
        const nbDays     = goal.stopAt.diff(goal.startAt, 'days');
        const burnedDays = moment().diff(goal.startAt, 'days');

        const progression = Math.round(burnedDays / nbDays * 100);

        return (
            <Tooltip content={t('goals.time_progression_value', { progression })} placement="top">
                <div>
                    <Progress
                        progress={progression}
                        size="md"
                        color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                    />
                </div>
            </Tooltip>
        );
    };

    const sortedGoals = React.useMemo(() => goals.filter((goal: Goal) => {
        if (!filters.name?.length)
            return true;
        return goal.name.match(new RegExp(filters.name, 'gi'));
    }).sort((a: Goal, b: Goal) => {
        return a.startAt.isAfter(b.startAt) ? 1 : (
            a.stopAt.isAfter(b.stopAt) ? 1 : -1
        );
    }), [goals, filters.name]);

    const filterOnName = (name: string) => {
        setFilters({ ...filters, name });
    }

    const filterOnProject = (project: Value) => {
        setFilters({
            ...filters,
            project
        });
    }

    const filterOnStatus = (status: string) => {
        setFilters({
            ...filters,
            status
        });
    }

    const resetProjectFilter = () => {
        setFilters({
            ...filters,
            project: undefined
        });
    }

    const projectsOptions = React.useMemo(() => {
        return _.uniqBy(_.flatten(goals?.map((g: Goal) => g.projects)), '_id')
            .filter(Boolean)
            .sort((a: any, b: any) => a.name?.localeCompare(b.name))
            .map((p: any) => ({
                label: p.name,
                value: p._id,
            }));
    }, [goals]);

    const statusFilterBtnClassname = (i: number, max: number) => {
        let className = 'flex-1 w-40 focus:ring-0 ';
        if (i === 0)
            className += 'rounded-bl-md rounded-r-none';
        else if (i === max)
            className += 'border-t border-l-0 rounded-l-none';
        else
            className += 'border-t border-l-0 rounded-none';


        return className;
    }

    const handleKeyPress = (e: AnyObj) => {
        if ((e.key === 'Enter' || e.code === 'Enter') && sortedGoals.length)
            return navigate(`/goals/${goals[0]._id}`);

        if (e.key === 'Escape' || e.code === 'Escape')
            setFilters({
                ...filters,
                name: ''
            });
    }

    if (searchInput?.current)
        searchInput.current.focus();

    return (
        <Ability can="goal:read" redirect="/">
            <div className="GoalsList">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="goal" size={7} className="mr-1.5 inline" />
                                { t('app.goals') }
                            </h2>
                        </div>
                        <div className="mt-5 flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 lg:mt-0">
                            <div className="relative flex">
                                <input
                                    type="text"
                                    value={filters?.name}
                                    onChange={e =>filterOnName(e.target.value)}
                                    onKeyUp={handleKeyPress}
                                    ref={searchInput}
                                    className="w-full h-9 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md focus:ring-gray-300 border-0 focus:border-0 py-0 pr-8"
                                />
                                <Icon type="search" color="gray-600" className="absolute top-2.5 right-2.5" />
                            </div>
                            <Ability can="goal:create">
                                <span className="sm:ml-3">
                                    <Button className="w-full" title={t('goals.add_a_goal')} color="navigate" to="/goals/new" icon="plus" />
                                </span>
                            </Ability>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="p-5">
                        <div className="mb-4 w-100 overflow-x-auto text-center">
                            <div className="flex flex-row inline-flex">
                                {Goal.allStatus().map((status: string, i: number) => (
                                    <FButton
                                        key={status}
                                        color={filters.status === status ? 'info' : 'gray'}
                                        onClick={() => filterOnStatus(status)}
                                        className={statusFilterBtnClassname(i, Goal.allStatus().length - 1)}
                                    >
                                        {t(`goals.status_type.${status}`)}
                                    </FButton>
                                ))}
                            </div>
                        </div>
                        <div className="mb-2 text-center flex justify-center">
                            <div className="flex justify-center">
                                <Select
                                    onChange={filterOnProject}
                                    options={projectsOptions}
                                    optionsWidth={'max-w-[800px]'}
                                    containerClassName={'relative w-full cursor-default ounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-md flex-1'}
                                    optionsContainerClassName={'!w-[600px] !max-w-[100vw]'}
                                    optionsClassName={''}
                                    defaultValue={filters.project}
                                    placeholder={t('goals.filter_on_project') || ''}
                                />
                                {filters.project && (
                                    <div className="bg-white shadow-md mt-1">
                                        <Button
                                            title=""
                                            icon="close"
                                            color="none"
                                            iconColor="red-900"
                                            className="shadow-none"
                                            onClick={() => resetProjectFilter()}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {loading === 'pending' ? (
                            <Loader />
                        ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
                                {sortedGoals?.map((goal: Goal, i: number) => (
                                    <Link key={goal._id} to={`/goals/${goal._id}`} className="p-3 text-base font-medium text-sm text-gray-900 dark:text-white hover:no-underline flex w-full">
                                        <Card theme={cardTheme} key={i} className="span-cols-1 relative cursor-pointer hover:bg-gray-50 w-full">
                                            <div className="absolute top-2 right-2 flex flex-row justify-center items-center">
                                                <Badge color={goal.statusColor()} className="w-fit">
                                                    {t(`goals.status_type.${goal?.status()}`)}
                                                </Badge>
                                            </div>
                                            <div className="flex flex-col space-y-2 items-start p-4">
                                                <div className="flex space-x-2 items-center">
                                                    <Icon type="goal" size={6} />
                                                    <h3 className="text-xl">{goal?.name}</h3>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center text-xs">
                                                        <Icon type="date" className="inline" size={4} />&nbsp;
                                                        {goal.startAt?.format('L')}
                                                        {' - '}
                                                        {goal.stopAt?.format('L')}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 pt-2 w-full">
                                                    {goal?.projects?.map((p: Project) => (
                                                    <div key={p._id} className="span-cols-1 flex space-x-2 p-1 items-center border rounded">
                                                        <Avatar size="xs" img={p.getAvatarUrl()} className="inline w-fit" />
                                                        <div className="text-xs">{p.name}</div>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col justify-end space-y-2 border-t p-2 rounded-b-lg">
                                                <div className="flex items-center">
                                                    <div className="w-6">
                                                        <Icon type="progression" />
                                                    </div>
                                                    <div className="w-full">
                                                        { goalProgression(goal) }
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="w-6">
                                                        <Icon type="date" />
                                                    </div>
                                                    <div className="w-full">
                                                        { timeProgression(goal) }
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </Ability>
    );
}

export default GoalsList;
