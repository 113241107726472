import React from 'react';
import { Icon } from '..';
import type { IconType } from '../Icon';
import { Button, Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

interface ConfirmProps {
    visible: boolean
    title?: string
    iconType?: IconType
    confirmText?: string
    cancelText?: string
    onConfirm?: () => void
    onCancel?: () => void
}

function Confirm(props: ConfirmProps) {
    const { t } = useTranslation();

    const {
        title, iconType, confirmText, cancelText,
        onConfirm, onCancel, visible
    } = props;


    return (
        <Modal show={visible} size="md" popup onClose={() => onCancel && onCancel()}>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    {iconType && (
                        <Icon type={iconType} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    )}
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        {title || t('common.are_you_sure')}
                    </h3>
                    <div className="flex justify-center gap-4">
                        {onConfirm && (
                        <Button color="failure" onClick={onConfirm}>
                            {confirmText ?? t('common.yes')}
                        </Button>
                        )}
                        {onCancel && (
                        <Button color="gray" onClick={onCancel}>
                            {cancelText ?? t('common.no')}
                        </Button>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Confirm;
