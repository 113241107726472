import React, { useState } from 'react';
import { useField } from 'formik';
import { Icon, Button, Confirm, Wysiwyg, Uploader, insertLink, insertImage } from '..';
import { Attachment } from '../../models';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import type { Mention } from '..';

interface WysiwygFieldProps {
    name: string;
    label?: JSX.Element;
    rows?: number;
    id?: string;
    inline?: boolean;
    placeholder?: string;
    required?: boolean;
    className?: string;
    containerClassName?: string;
    onClose?: () => void;
    onChange?: (value: string) => void;
    onSave: (value: string) => void;
    onDelete?: () => void;
    children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
    editOnClick?: boolean;
    hideButtons?: boolean;
    minimal?: boolean;
    isLoading: boolean;
    mentions?: Mention[];
    uploadProps?: {
        projectId?: string;
        changeId?: string;
    }
};
const WysiwygField = (props: WysiwygFieldProps) => {
    const {
        name, className, containerClassName, rows, children, editOnClick, minimal, uploadProps,
        label, inline, placeholder, isLoading, onClose, onChange, onSave, onDelete, hideButtons, mentions
    } = props;

    const { t } = useTranslation();

    const [ isEditing, setIsEditing ]           = useState(!inline);
    const [confirmIsVisible, setConfirmVisible] = useState(false);
    const [editorState, setEditorState]         = useState<EditorState>();

    const [ field, meta, helpers ]  = useField(name);
    const { setValue }              = helpers;

    const { initialValue, value, touched, error } = meta;

    const _onChange = React.useMemo(() => {
        return (value: string, editorState: EditorState) => {
            setValue(value);
            setEditorState(editorState);
            onChange && onChange(value);
        };
    }, [setValue]);

    const _onClose = () => {
        setIsEditing(false);
        setValue(initialValue)
        onClose && onClose();
    }

    React.useMemo(() => {
        if (isLoading === false)
            setIsEditing(!inline);
    }, [isLoading]);

    const insertAttachment = (attachment: Attachment) => {
        if (!editorState)
            return;

        const newState = attachment.isPicture() ?
            insertImage(editorState, attachment.downloadUrl(), attachment.name) :
            insertLink(editorState, attachment.name, attachment.downloadUrl());
        if (newState) {
            const result = draftToHtml(convertToRaw(newState.getCurrentContent()));
            setValue(result);
        }
    }

    if (!isEditing)
        return (
            <div className={`flex flex-col justify-between gap-0 ${containerClassName}`}>
                {(label || value) && (
                    <div className="mb-1 flex justify-between">
                        {label}
                        {value && (
                            <div className="flex">
                                <div className="flex items-center text-xs cursor-pointer font-normal border-none text-gray-500" onClick={() => setIsEditing(true)}>
                                    <Icon type="edit" className="inline mr-0.5" size={3} />
                                    <span className="hover:underline">
                                        {t('common.edit')}
                                    </span>
                                </div>
                                {onDelete && (
                                    <div className="flex items-center text-xs cursor-pointer font-normal border-none text-gray-500" onClick={() => setConfirmVisible(true)}>
                                        <Icon type="delete" className="inline ml-3 mr-0.5" size={3} />
                                        <span className="hover:underline">
                                            {t('common.delete')}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div onClick={() => editOnClick && setIsEditing(true)} className="cursor-pointer">
                    {value ? (
                        <div className={`Wysiwyg content border border-gray-100 p-2 bg-gray-50 text-gray-900 ${editOnClick ? 'hover:bg-gray-100' : ''} text-gray-500 rounded text-sm`}>
                            <div dangerouslySetInnerHTML={{ __html: value }} />
                        </div>
                    ) : (
                        <div className="border border-gray-100 p-2 bg-gray-50 text-gray-900 hover:bg-gray-100 text-gray-500 italic rounded text-sm">
                            <div>{ placeholder }</div>
                        </div>
                    )}
                </div>
                {onDelete && (
                    <Confirm
                        visible={confirmIsVisible}
                        onConfirm={() => onDelete()}
                        onCancel={() => setConfirmVisible(false)}
                        title={t('changes.are_you_sure_to_delete_comment') || ''}
                    />
                )}
            </div>
        );

    return (
        <>
            <Wysiwyg
                value={value}
                onChange={_onChange}
                className={className}
                rows={rows}
                minimal={minimal}
                mentions={mentions}
            >
                <div>
                    <>{ children }</>
                    {uploadProps?.projectId && (
                        <Uploader
                            projectId={uploadProps.projectId}
                            changeId={uploadProps.changeId}
                            onUpload={insertAttachment}
                            hideFiles
                        />
                    )}
                    {!hideButtons && (
                        <div className="flex justify-end mt-2 bg-gray-50 p-2 order border-gray-100 rounded-md">
                            <Button
                                className="text-2xl font-bold"
                                textColor="red-500"
                                icon="close"
                                color="none"
                                onClick={_onClose}
                                title={t('common.cancel')}
                                iconColor="red-500"
                                small
                            />
                            <Button
                                icon={isLoading ? 'loading' : 'save'}
                                color="primary"
                                onClick={() => { onSave && onSave(value); _onClose(); }}
                                type="button"
                                title={t('common.save')}
                                small
                            />
                        </div>
                    )}
                </div>
            </Wysiwyg>
            {touched && error ? (
                <div className="error">{error}</div>
            ) : null}
        </>
    );
};

export default WysiwygField;
